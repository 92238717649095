import NProgress from 'nprogress'
import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import MealRepository from '../../infra/MealRepository'
import MealEvent from '../../event/MealEvent'

const onSearch = async pagination => {
  NProgress.start()
  const response = await MealRepository.onSearch(pagination).catch(e => e)
  NProgress.done()
  if (response instanceof Error) {
    appActions.showAlert(response, '一覧取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(MealEvent.onSearch, response)

  return true
}

export default onSearch
