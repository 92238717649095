import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'

const onUpdateInvoicing = async invoiceId => {
  if (window.confirm('請求中にすると請求書がお客様に送信されます。実行しますか？')) {
    const response = await repository.updateInvoicing(invoiceId).catch(e => e)
    if (response instanceof Error) {
      appActions.showAlert(response, '更新に失敗しました')
      return false
    }

    appActions.snackbar('この請求を請求中に変更しました', 'success', true)

    return true
  }

  return false
}

export default onUpdateInvoicing
