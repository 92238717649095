import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import roomRepository from '../../infra/RoomRepository'

const fetchRooms = async () => {
  const response = await roomRepository.fetchByUser().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '宿情報の取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchRooms, {
    rooms: response
  })

  return true
}

export default fetchRooms
