const TaskEvent = {
  initialize: 'task/initialize',
  fetchOne: 'task/fetch-one',
  onEdit: 'task/on-edit',
  updateTaskStatus: 'task/update-task-status',
  onSearch: 'task/search',
  onEditSearchCondition: 'task/edit-search-condition'
}

export default TaskEvent
