/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The TaskSummary model module.
 * @module model/TaskSummary
 * @version 1.0.0
 */
export default class TaskSummary {
  /**
   * Constructs a new <code>TaskSummary</code>.
   * @alias module:model/TaskSummary
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>TaskSummary</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TaskSummary} obj Optional instance to populate.
   * @return {module:model/TaskSummary} The populated <code>TaskSummary</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TaskSummary()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String')
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} title
   */
  title = undefined
  /**
   * @member {String} description
   */
  description = undefined
  /**
   * Enum(open, closed)
   * @member {String} status
   */
  status = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
}
