import AppDispatcher from '../../core/AppDispatcher'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onEdit = (reservation, name, value) => {
  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onEdit, {
    reservation: reservation,
    name: name,
    value: value
  })

  return true
}

export default onEdit
