import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const onEdit = event => {
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.onEdit, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEdit
