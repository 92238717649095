import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const onCancelEditView = async () => {
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.onClear)
  history.goBack()

  return true
}

export default onCancelEditView
