import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const initialize = (close_date, reservation_id, room_id) => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.initialize, { close_date: close_date })

  return true
}

export default initialize
