import fetchReservation from './fetchReservation'
import onEdit from './onEdit'
import onEditDetail from './onEditDetail'
import onEditAccommodationTax from './onEditAccommodationTax'
import onUpdateAccommodationTax from './onUpdateAccommodationTax'
import requestSave from './requestSave'

const TransactionAction = {
  fetchReservation,
  onEdit,
  onEditDetail,
  onEditAccommodationTax,
  onUpdateAccommodationTax,
  requestSave
}

export default TransactionAction
