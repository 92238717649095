import ApiClient from '../ApiClient'
import AccommodationTaxApi from '../api/AccommodationTaxApi'
import OwnerReportApi from '../api/OwnerReportApi'

export class ReportAccommodationTaxRepository {
  constructor() {
    this._ownerReportApi = new OwnerReportApi(ApiClient.instance)
    this._accommodationTaxApi = new AccommodationTaxApi(ApiClient.instance)
  }

  // 宿泊税の一覧取得
  fetchAccommodationTax(date, hotel_ids) {
    const body = { search_check_in_date: date.beginningOfMonth().toISODate(), search_hotel_ids: hotel_ids }
    return this._ownerReportApi.searchAccommodationTaxForOwner({ monthly_report: body })
  }

  // 宿泊税の詳細
  fetchAccommodationTaxDetail(accommodation_tax_id) {
    return this._ownerReportApi.getDailyDlosingsOfAccommodationTaxForOwner(accommodation_tax_id)
  }

  requestUpdate(reservationId, accommodation_tax) {
    const tax = {
      price: accommodation_tax.accommodation_price,
      ota_commission_include: accommodation_tax.include_commission,
      ota_commission_rate: accommodation_tax.commission_rate,
      tax_per_day: accommodation_tax.tax_price,
      number_of_guests: accommodation_tax.people,
      number_of_nights: accommodation_tax.stay,
      memo: accommodation_tax.memo
    }
    const body = { accommodation_tax: tax }
    return this._accommodationTaxApi.updateAccommodationTax(reservationId, accommodation_tax.id, body)
  }
}

// singleton
export default new ReportAccommodationTaxRepository()
