import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import EventScheduleRepository from '../../infra/EventScheduleRepository'
import EventScheduleEvent from '../../event/EventScheduleEvent'

const fetchOne = async eventScheduleId => {
  const response = await EventScheduleRepository.fetchOne(eventScheduleId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(EventScheduleEvent.fetchOne, { eventSchedule: response })
  return true
}

export default fetchOne
