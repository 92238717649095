import { appActions } from '../AppActions'
import accommodationInvoiceRepository from '../../infra/AccommodationInvoiceRepository'

const onRequestCreate = async (reservation_id, invoice) => {
  const param = invoice.set('reservation_id', reservation_id)
  const response = await accommodationInvoiceRepository.requestCreate(param).catch(e => e)

  if (response instanceof Error) {
    appActions.showAlert(response, '作成できませんでした')
    return false
  }

  appActions.snackbar('請求書を作成しました', 'success', true)

  return true
}

export default onRequestCreate
