import RoomEvent from '../../event/RoomEvent'
import AppDispatcher from '../../core/AppDispatcher'
import roomRepository from '../../infra/RoomRepository'
import { appActions } from '../AppActions'

const fetchOne = async roomId => {
  const response = await roomRepository.fetchOne(roomId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(RoomEvent.fetchOne, { room: response })
  return true
}

export default fetchOne
