/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import DailyClosingReservation from './DailyClosingReservation'
import Reservation from './Reservation'

/**
 * The DailyClosingHead model module.
 * @module model/DailyClosingHead
 * @version 1.0.0
 */
export default class DailyClosingHead {
  /**
   * Constructs a new <code>DailyClosingHead</code>.
   * @alias module:model/DailyClosingHead
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>DailyClosingHead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DailyClosingHead} obj Optional instance to populate.
   * @return {module:model/DailyClosingHead} The populated <code>DailyClosingHead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyClosingHead()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('room_id')) {
        obj['room_id'] = ApiClient.convertToType(data['room_id'], 'Number')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('close_date')) {
        obj['close_date'] = ApiClient.convertToType(data['close_date'], 'Date')
      }
      if (data.hasOwnProperty('monthly_close_date')) {
        obj['monthly_close_date'] = ApiClient.convertToType(data['monthly_close_date'], 'Date')
      }
      if (data.hasOwnProperty('rsvn_sales')) {
        obj['rsvn_sales'] = ApiClient.convertToType(data['rsvn_sales'], 'Number')
      }
      if (data.hasOwnProperty('ota_comsn')) {
        obj['ota_comsn'] = ApiClient.convertToType(data['ota_comsn'], 'Number')
      }
      if (data.hasOwnProperty('rsvn_pay_comsn')) {
        obj['rsvn_pay_comsn'] = ApiClient.convertToType(data['rsvn_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('rsvn_pay_code')) {
        obj['rsvn_pay_code'] = ApiClient.convertToType(data['rsvn_pay_code'], 'String')
      }
      if (data.hasOwnProperty('site_referrer_id')) {
        obj['site_referrer_id'] = ApiClient.convertToType(data['site_referrer_id'], 'Number')
      }
      if (data.hasOwnProperty('sales')) {
        obj['sales'] = ApiClient.convertToType(data['sales'], 'Number')
      }
      if (data.hasOwnProperty('variable_costs')) {
        obj['variable_costs'] = ApiClient.convertToType(data['variable_costs'], 'Number')
      }
      if (data.hasOwnProperty('fixed_costs')) {
        obj['fixed_costs'] = ApiClient.convertToType(data['fixed_costs'], 'Number')
      }
      if (data.hasOwnProperty('variable_expenses')) {
        obj['variable_expenses'] = ApiClient.convertToType(data['variable_expenses'], 'Number')
      }
      if (data.hasOwnProperty('fixed_expenses')) {
        obj['fixed_expenses'] = ApiClient.convertToType(data['fixed_expenses'], 'Number')
      }
      if (data.hasOwnProperty('accom_pay_comsn')) {
        obj['accom_pay_comsn'] = ApiClient.convertToType(data['accom_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('memo')) {
        obj['memo'] = ApiClient.convertToType(data['memo'], 'String')
      }
      if (data.hasOwnProperty('user_name')) {
        obj['user_name'] = ApiClient.convertToType(data['user_name'], 'String')
      }
      if (data.hasOwnProperty('reservations')) {
        obj['reservations'] = ApiClient.convertToType(data['reservations'], [Reservation])
      }
      if (data.hasOwnProperty('daily_closing_reservations')) {
        obj['daily_closing_reservations'] = ApiClient.convertToType(data['daily_closing_reservations'], [DailyClosingReservation])
      }
      if (data.hasOwnProperty('room_name')) {
        obj['room_name'] = ApiClient.convertToType(data['room_name'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} room_id
   */
  room_id = undefined
  /**
   * @member {module:model/DailyClosingHead.StatusEnum} status
   */
  status = undefined
  /**
   * @member {Date} close_date
   */
  close_date = undefined
  /**
   * @member {Date} monthly_close_date
   */
  monthly_close_date = undefined
  /**
   * @member {Number} rsvn_sales
   */
  rsvn_sales = undefined
  /**
   * @member {Number} ota_comsn
   */
  ota_comsn = undefined
  /**
   * @member {Number} rsvn_pay_comsn
   */
  rsvn_pay_comsn = undefined
  /**
   * @member {String} rsvn_pay_code
   */
  rsvn_pay_code = undefined
  /**
   * @member {Number} site_referrer_id
   */
  site_referrer_id = undefined
  /**
   * @member {Number} sales
   */
  sales = undefined
  /**
   * @member {Number} variable_costs
   */
  variable_costs = undefined
  /**
   * @member {Number} fixed_costs
   */
  fixed_costs = undefined
  /**
   * @member {Number} variable_expenses
   */
  variable_expenses = undefined
  /**
   * @member {Number} fixed_expenses
   */
  fixed_expenses = undefined
  /**
   * @member {Number} accom_pay_comsn
   */
  accom_pay_comsn = undefined
  /**
   * @member {String} memo
   */
  memo = undefined
  /**
   * @member {String} user_name
   */
  user_name = undefined
  /**
   * @member {Array.<module:model/Reservation>} reservations
   */
  reservations = undefined
  /**
   * @member {Array.<module:model/DailyClosingReservation>} daily_closing_reservations
   */
  daily_closing_reservations = undefined
  /**
   * @member {String} room_name
   */
  room_name = undefined

  /**
   * Allowed values for the <code>status</code> property.
   * @enum {String}
   * @readonly
   */
  static StatusEnum = {
    /**
     * value: "saved"
     * @const
     */
    saved: 'saved',

    /**
     * value: "closed"
     * @const
     */
    closed: 'closed'
  }
}
