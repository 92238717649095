import ApiClient from '../ApiClient'
import LoginApi from '../api/LoginApi'
import SiteReferrerApi from '../api/SiteReferrerApi'

export class SiteReferrerRepository {
  constructor() {
    this._loginApi = new LoginApi(ApiClient.instance)
    this._siteReferrerApi = new SiteReferrerApi(ApiClient.instance)
  }

  fetchByType(type) {
    return this._loginApi.getSiteReferrers({ site_referrers: { available_type: type } })
  }

  fetchAll() {
    return this._siteReferrerApi.searchSiteReferrers()
  }
}

// singleton
export default new SiteReferrerRepository()
