import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import SelectOptionEvent from '../../event/SelectOptionEvent'
import HotelRepository from '../../infra/HotelRepository'

const fetchRoomsByHotelId = async hotelId => {
  const response = await HotelRepository.fetchRoomsById(hotelId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(SelectOptionEvent.fetchRooms, { rooms: response })
  return true
}

export default fetchRoomsByHotelId
