import './AutoActionConfigView.scss'
import React from 'react'
import { Dropdown, RadioGroup, RadioInput } from '../../atoms'
import { trigger_events, trigger_times, retry_periods } from '../../organisms/auto_action/AutoActionConfigOptions'

/**
 * 自動アクション（メール・タスクの共通入力項目）
 */
function AutoActionConfigView(props) {
  const config = props.config

  return (
    <div id="AutoActionConfig">
      <div className="EditArea__row">
        <div className="EditArea__row--full">
          <div className="EditArea__column">
            <div className="textInput__label textInput__label- textInput__label-required">
              ステータス
              <div className="textInput__optionIcon" />
            </div>
            <p className="status_list">
              <RadioGroup name="status" value={config.status}>
                <RadioInput id="status_auto" value="auto" onChange={props.onEdit} />
                <label htmlFor="status_auto">自動</label>
                <RadioInput id="status_manual" value="manual" onChange={props.onEdit} />
                <label htmlFor="status_manual">手動</label>
                <RadioInput id="status_disable" value="disabled" onChange={props.onEdit} />
                <label htmlFor="status_disable">無効</label>
              </RadioGroup>
            </p>
          </div>
        </div>
      </div>

      <div className="EditArea__row">
        <div className="EditArea__row--full">
          <div className="EditArea__column">
            <p>宿泊施設</p>
            <Dropdown className="Dropdown--wide" name="room_ids" options={props.rooms} value={props.room_ids} onChange={props.onEdit} placeholder="すべての宿泊施設" searchable={true} isMulti={true} />
          </div>
        </div>
      </div>

      <div className="EditArea__row">
        <div className="EditArea__row--half">
          <div className="EditArea__column">
            <div className="textInput__label textInput__label- textInput__label-required">
              トリガー
              <div className="textInput__optionIcon" />
            </div>
            <Dropdown name="trigger_event" value={config.trigger_event} options={trigger_events} onChange={props.onEdit} searchable={true} />
          </div>
        </div>
        <div className="EditArea__row--half">
          <div className="EditArea__column">
            <div className="textInput__label textInput__label- textInput__label-required">
              トリガータイム
              <div className="textInput__optionIcon" />
            </div>
            <Dropdown name="trigger_time" value={config.trigger_time} options={trigger_times} onChange={props.onEdit} searchable={true} />
          </div>
        </div>
      </div>

      <div className="EditArea__row">
        <div className="EditArea__row--full">
          <div className="EditArea__column">
            <div className="textInput__label textInput__label- textInput__label-required">
              リトライ期間
              <div className="textInput__optionIcon" />
            </div>
            <Dropdown name="retry_period" value={config.retry_period} options={retry_periods} onChange={props.onEdit} searchable={true} />
          </div>
        </div>
      </div>

      <div className="EditArea__row">
        <div className="EditArea__row--full">
          <div className="EditArea__column">
            <p>予約元</p>
            <Dropdown
              className="Dropdown--wide"
              name="site_referrer_ids"
              options={props.site_referrers}
              value={props.site_referrer_ids}
              onChange={props.onEdit}
              placeholder="すべての予約元"
              searchable={true}
              isMulti={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AutoActionConfigView
