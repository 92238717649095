import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onEdit = event => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.onEdit, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEdit
