import AppDispatcher from '../../core/AppDispatcher'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onUpdateAccommodationTax = total => {
  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onUpdateAccommodationTax, {
    value: total
  })

  return true
}

export default onUpdateAccommodationTax
