/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body24 from '../model/Body24'
import Body25 from '../model/Body25'
import Body26 from '../model/Body26'
import InlineResponse20024 from '../model/InlineResponse20024'
import InlineResponse201 from '../model/InlineResponse201'
import MonthlyClosingBody from '../model/MonthlyClosingBody'
import MonthlyClosingHead from '../model/MonthlyClosingHead'

/**
 * MonthlyClosing service.
 * @module api/MonthlyClosingApi
 * @version 1.0.0
 */
export default class MonthlyClosingApi {
  /**
   * Constructs a new MonthlyClosingApi.
   * @alias module:api/MonthlyClosingApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * create monthly closing
   * @param {module:model/Body24} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
   */
  createMonthlyClosingWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createMonthlyClosing")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse201

    return this.apiClient.callApi('/monthly/closings', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * create monthly closing
   * @param {module:model/Body24} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
   */
  createMonthlyClosing(body) {
    return this.createMonthlyClosingWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 月次締めデータを削除する
   * @param {Number} monthlyClosingHeadId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteMonthlyClosingWithHttpInfo(monthlyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'monthlyClosingHeadId' is set
    if (monthlyClosingHeadId === undefined || monthlyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'monthlyClosingHeadId' when calling deleteMonthlyClosing")
    }

    let pathParams = {
      monthly_closing_head_id: monthlyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/monthly/closings/{monthly_closing_head_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 月次締めデータを削除する
   * @param {Number} monthlyClosingHeadId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteMonthlyClosing(monthlyClosingHeadId) {
    return this.deleteMonthlyClosingWithHttpInfo(monthlyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 締め月とホテルIDに紐づく日締めデータを取得する
   * @param {Number} hotelId Target hotel_id
   * @param {Date} monthlyCloseDate Target monthly close date
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20024} and HTTP response
   */
  fetchDailySummaryByHotelIdWithHttpInfo(hotelId, monthlyCloseDate) {
    let postBody = null

    // verify the required parameter 'hotelId' is set
    if (hotelId === undefined || hotelId === null) {
      throw new Error("Missing the required parameter 'hotelId' when calling fetchDailySummaryByHotelId")
    }

    // verify the required parameter 'monthlyCloseDate' is set
    if (monthlyCloseDate === undefined || monthlyCloseDate === null) {
      throw new Error("Missing the required parameter 'monthlyCloseDate' when calling fetchDailySummaryByHotelId")
    }

    let pathParams = {
      hotel_id: hotelId,
      monthly_close_date: monthlyCloseDate
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20024

    return this.apiClient.callApi(
      '/monthly/closings/date/{monthly_close_date}/hotel/{hotel_id}/daily_closings',
      'GET',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 締め月とホテルIDに紐づく日締めデータを取得する
   * @param {Number} hotelId Target hotel_id
   * @param {Date} monthlyCloseDate Target monthly close date
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20024}
   */
  fetchDailySummaryByHotelId(hotelId, monthlyCloseDate) {
    return this.fetchDailySummaryByHotelIdWithHttpInfo(hotelId, monthlyCloseDate).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get daily closings summary
   * @param {module:model/Body26} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
   */
  getDailyClosingSummaryWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling getDailyClosingSummary")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse201

    return this.apiClient.callApi('/monthly/closings/new', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get daily closings summary
   * @param {module:model/Body26} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
   */
  getDailyClosingSummary(body) {
    return this.getDailyClosingSummaryWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 月次締めIDに紐づく日締めデータを取得する
   * @param {Number} monthlyClosingHeadId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20024} and HTTP response
   */
  getDailyDlosingsOfMonthlyWithHttpInfo(monthlyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'monthlyClosingHeadId' is set
    if (monthlyClosingHeadId === undefined || monthlyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'monthlyClosingHeadId' when calling getDailyDlosingsOfMonthly")
    }

    let pathParams = {
      monthly_closing_head_id: monthlyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20024

    return this.apiClient.callApi(
      '/monthly/closings/{monthly_closing_head_id}/daily_closings',
      'GET',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 月次締めIDに紐づく日締めデータを取得する
   * @param {Number} monthlyClosingHeadId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20024}
   */
  getDailyDlosingsOfMonthly(monthlyClosingHeadId) {
    return this.getDailyDlosingsOfMonthlyWithHttpInfo(monthlyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 月次締めデータを1件取得する
   * @param {Number} monthlyClosingHeadId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
   */
  getMonthlyClosingWithHttpInfo(monthlyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'monthlyClosingHeadId' is set
    if (monthlyClosingHeadId === undefined || monthlyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'monthlyClosingHeadId' when calling getMonthlyClosing")
    }

    let pathParams = {
      monthly_closing_head_id: monthlyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse201

    return this.apiClient.callApi('/monthly/closings/{monthly_closing_head_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 月次締めデータを1件取得する
   * @param {Number} monthlyClosingHeadId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
   */
  getMonthlyClosing(monthlyClosingHeadId) {
    return this.getMonthlyClosingWithHttpInfo(monthlyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ge monthly closing heads
   * @param {module:model/Body25} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MonthlyClosingHead>} and HTTP response
   */
  getMonthlyClosingHeadsWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling getMonthlyClosingHeads")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [MonthlyClosingHead]

    return this.apiClient.callApi('/monthly/closings/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ge monthly closing heads
   * @param {module:model/Body25} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MonthlyClosingHead>}
   */
  getMonthlyClosingHeads(body) {
    return this.getMonthlyClosingHeadsWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 月次締めデータを更新する
   * @param {Number} monthlyClosingHeadId Target monthly closing head id
   * @param {module:model/MonthlyClosingBody} monthlyClosingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
   */
  updateMonthlyClosingWithHttpInfo(monthlyClosingHeadId, monthlyClosingBody) {
    let postBody = monthlyClosingBody

    // verify the required parameter 'monthlyClosingHeadId' is set
    if (monthlyClosingHeadId === undefined || monthlyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'monthlyClosingHeadId' when calling updateMonthlyClosing")
    }

    // verify the required parameter 'monthlyClosingBody' is set
    if (monthlyClosingBody === undefined || monthlyClosingBody === null) {
      throw new Error("Missing the required parameter 'monthlyClosingBody' when calling updateMonthlyClosing")
    }

    let pathParams = {
      monthly_closing_head_id: monthlyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse201

    return this.apiClient.callApi('/monthly/closings/{monthly_closing_head_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 月次締めデータを更新する
   * @param {Number} monthlyClosingHeadId Target monthly closing head id
   * @param {module:model/MonthlyClosingBody} monthlyClosingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
   */
  updateMonthlyClosing(monthlyClosingHeadId, monthlyClosingBody) {
    return this.updateMonthlyClosingWithHttpInfo(monthlyClosingHeadId, monthlyClosingBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
