import AppDispatcher from '../../core/AppDispatcher'
import SegmentationEvent from '../../event/SegmentationEvent'
import SegmentationRepository from '../../infra/SegmentationRepository'
import { appActions } from '../AppActions'

const fetchAll = async () => {
  const res = await SegmentationRepository.fetchAll().catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(SegmentationEvent.fetchAll, res)

  return true
}

export default fetchAll
