import MonthlyReportEvent from '../../../event/MonthlyReportEvent'
import AppDispatcher from '../../../core/AppDispatcher'

const onEditSearchCondition = event => {
  AppDispatcher.dispatchWithType(MonthlyReportEvent.onEditMonthlySalesSearchCondition, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEditSearchCondition
