import AppDispatcher from '../../core/AppDispatcher'
import LoginEvent from '../../event/LoginEvent'

const onChange = event => {
  AppDispatcher.dispatchWithType(LoginEvent.onChange, {
    name: event.target.name,
    value: event.target.value
  })
}

export default onChange
