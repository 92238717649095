import history from '../../util/history'
import { appActions } from '../AppActions'
import taskRepository from '../../infra/TaskRepository'

const onRequestSave = async (reservationId, task) => {
  const res = await taskRepository.requestSave(reservationId, task).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '保存に失敗しました')
    return false
  }

  history.goBack()
  appActions.snackbar('保存しました', 'success', true)
  return true
}

export default onRequestSave
