/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The ValidationError model module.
 * @module model/ValidationError
 * @version 1.0.0
 */
export default class ValidationError {
  /**
   * Constructs a new <code>ValidationError</code>.
   * @alias module:model/ValidationError
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>ValidationError</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ValidationError} obj Optional instance to populate.
   * @return {module:model/ValidationError} The populated <code>ValidationError</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ValidationError()

      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String')
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = ApiClient.convertToType(data['message'], 'String')
      }
      if (data.hasOwnProperty('columns')) {
        obj['columns'] = ApiClient.convertToType(data['columns'], ['String'])
      }
    }
    return obj
  }

  /**
   * @member {module:model/ValidationError.TypeEnum} type
   */
  type = undefined
  /**
   * @member {String} message
   */
  message = undefined
  /**
   * @member {Array.<String>} columns
   */
  columns = undefined

  /**
   * Allowed values for the <code>type</code> property.
   * @enum {String}
   * @readonly
   */
  static TypeEnum = {
    /**
     * value: "validation_error"
     * @const
     */
    validation_error: 'validation_error'
  }
}
