import CheckInRepository from '../../infra/CheckInRepository'
import { appActions } from '../AppActions'
import history from '../../util/history'

const onRequestDelete = async reservationId => {
  const response = await CheckInRepository.requestDelete(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '削除に失敗しました')
    return false
  }

  history.push(`/check_in`)
  appActions.snackbar('削除に成功しました', 'success', true)
  return true
}

export default onRequestDelete
