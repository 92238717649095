/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Reservation from './Reservation'

/**
 * The DailyClosingReservation model module.
 * @module model/DailyClosingReservation
 * @version 1.0.0
 */
export default class DailyClosingReservation {
  /**
   * Constructs a new <code>DailyClosingReservation</code>.
   * @alias module:model/DailyClosingReservation
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>DailyClosingReservation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DailyClosingReservation} obj Optional instance to populate.
   * @return {module:model/DailyClosingReservation} The populated <code>DailyClosingReservation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyClosingReservation()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('daily_closing_head_id')) {
        obj['daily_closing_head_id'] = ApiClient.convertToType(data['daily_closing_head_id'], 'Number')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
      if (data.hasOwnProperty('rsvn_sales')) {
        obj['rsvn_sales'] = ApiClient.convertToType(data['rsvn_sales'], 'Number')
      }
      if (data.hasOwnProperty('ota_comsn')) {
        obj['ota_comsn'] = ApiClient.convertToType(data['ota_comsn'], 'Number')
      }
      if (data.hasOwnProperty('rsvn_pay_comsn')) {
        obj['rsvn_pay_comsn'] = ApiClient.convertToType(data['rsvn_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('rsvn_pay_code')) {
        obj['rsvn_pay_code'] = ApiClient.convertToType(data['rsvn_pay_code'], 'String')
      }
      if (data.hasOwnProperty('site_referrer_id')) {
        obj['site_referrer_id'] = ApiClient.convertToType(data['site_referrer_id'], 'Number')
      }
      if (data.hasOwnProperty('accom_pay_comsn')) {
        obj['accom_pay_comsn'] = ApiClient.convertToType(data['accom_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('accom_pay_code')) {
        obj['accom_pay_code'] = ApiClient.convertToType(data['accom_pay_code'], 'String')
      }
      if (data.hasOwnProperty('reservation')) {
        obj['reservation'] = Reservation.constructFromObject(data['reservation'])
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} daily_closing_head_id
   */
  daily_closing_head_id = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
  /**
   * @member {Number} rsvn_sales
   */
  rsvn_sales = undefined
  /**
   * @member {Number} ota_comsn
   */
  ota_comsn = undefined
  /**
   * @member {Number} rsvn_pay_comsn
   */
  rsvn_pay_comsn = undefined
  /**
   * @member {String} rsvn_pay_code
   */
  rsvn_pay_code = undefined
  /**
   * @member {Number} site_referrer_id
   */
  site_referrer_id = undefined
  /**
   * @member {Number} accom_pay_comsn
   */
  accom_pay_comsn = undefined
  /**
   * @member {String} accom_pay_code
   */
  accom_pay_code = undefined
  /**
   * @member {module:model/Reservation} reservation
   */
  reservation = undefined
}
