import React from 'react'

function PaymentPaid(props) {
  const paid = props.paid
  const prefix = props.prefix || ''
  const color = paid ? '#4DB5F0' : '#c00'
  const label = paid ? '受領済' : '未受領'
  return (
    <span
      style={{
        borderRadius: '2px',
        backgroundColor: color,
        marginRight: '8px',
        padding: '0 4px 2px 4px'
      }}
    >
      <span
        style={{
          margin: 'auto',
          fontFamily: 'Lato',
          fontSize: '0.7rem',
          fontWeight: 'bold',
          fontStyle: 'normal',
          fontStretch: 'normal',
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#ffffff'
        }}
      >
        {prefix}
        {label}
      </span>
    </span>
  )
}

export { PaymentPaid }
