import AppDispatcher from '../../core/AppDispatcher'
import TaskEvent from '../../event/TaskEvent'

const onEditSearchCondition = event => {
  AppDispatcher.dispatchWithType(TaskEvent.onEditSearchCondition, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEditSearchCondition
