import AppRepository from './AppRepository'
import ApiClient from '../ApiClient'
import LoginApi from '../api/LoginApi'

export class LoginRepository extends AppRepository {
  constructor() {
    super()
    this._api = new LoginApi(ApiClient.instance)
  }

  requestLogin(email, password) {
    const body = {
      user: {
        email: email,
        password: password
      }
    }

    return this._api.login({ body: body })
  }

  checkLoginStatus() {
    return this._api.checkLoginStatus()
  }
}

// singleton
export default new LoginRepository()
