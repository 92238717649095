import fetchAccountTitle from './fetchAccountTitle'
import fetchAccountTitles from './fetchAccountTitles'
import onCancelEditView from './onCancelEditView'
import onEdit from './onEdit'
import onRequestCreate from './onRequestCreate'
import onRequestDelete from './onRequestDelete'
import onRequestUpdate from './onRequestUpdate'
import fetchAccountTitleGroups from './fetchAccountTitleGroups'

const AccountTitleAction = {
  fetchAccountTitleGroups,
  fetchAccountTitle,
  fetchAccountTitles,
  onCancelEditView,
  onEdit,
  onRequestCreate,
  onRequestUpdate,
  onRequestDelete
}

export default AccountTitleAction
