class Price {
  constructor(value) {
    if (!value) {
      this.value = value
    } else {
      const validity = /^[-]?\d+$/.test(value)
      const valid_value = validity ? value : value.replace(/[^0-9-]/g, '')
      const parsed_value = parseInt(valid_value)
      const before_check_range_str = parsed_value ? String(parsed_value) : String(valid_value)
      const ranged_value = before_check_range_str.length > 9 ? parseInt(before_check_range_str.substring(0, 9)) : parseInt(before_check_range_str)
      this.value = ranged_value
    }
  }

  val() {
    return this.value
  }
}

export default Price
