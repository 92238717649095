import './AccountTitleView.scss'
import React from 'react'
import { NavigationDrawer, NormalButton } from 'ui-library/dist/external/components'
import { ListGroup } from '../../molecules'
import { ListItem } from '../../atoms'
import Cell from '../../templates/Cell'
import r from '../../../util/Renderer'
import LocalArea from '../../templates/LocalArea'
import toAccountTitleEditView from '../../../action/account_title/toAccountTitleEditView'

function AccountTitleListView(props) {
  const toolbar = { label: '勘定科目管理', items: props.accountMenu }
  const titles = props.titles ? props.titles : []

  return (
    <div id="AccountTitles">
      <NavigationDrawer toolbar={toolbar} navigations={props.navigations} />
      <LocalArea>
        <div className="LocalArea--right">
          <NormalButton onClick={e => toAccountTitleEditView()}>新規作成</NormalButton>
        </div>
      </LocalArea>
      <div className="AccountTitle">
        <div className="AccountTitles__List">
          {r.renderIfOrElse(titles.size > 0)(
            <ListGroup border="underline" className="ItemGroup">
              {titles.map((item, id) => (
                <ListItem key={id} className="ListItem">
                  <Cell className="position">{item.position}</Cell>
                  <Cell className="titleGroupName">{item.account_title_group_name}</Cell>
                  <Cell className="name">{item.name}</Cell>
                  <Cell className="action">
                    <NormalButton onClick={e => toAccountTitleEditView(item.id)}>編集</NormalButton>
                  </Cell>
                </ListItem>
              ))}
            </ListGroup>
          )(
            <div className="row">
              <div className="col-12">
                <div className="AccountTitles__List--empty">
                  <div className="inner">
                    <div className="message">登録されている勘定科目はありません</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default AccountTitleListView
