import React from 'react'
import { IconMail, IconTask } from '../../../atoms/icon'
import { ListItem, Tag } from '../../../atoms'
import { ListGroup } from '../../../molecules'
import Cell from '../../../templates/Cell'
import r from '../../../../util/Renderer'
import { dateToString } from '../../../../util/DateString'
import AutoLogActions from '../../../../action/auto_action/AutoLogActions'
import AutoMailContentActions from '../../../../action/auto_action/AutoMailContentActions'
import { NormalButton } from 'ui-library/dist/external/components'

export default function AutoActionLog(props) {
  if (!props.tab || props.tab !== 'auto_action_log') {
    return null
  }

  if (!props.auto_action_logs || props.auto_action_logs.size === 0) {
    return null
  }

  return (
    <div className="reservationDetail">
      <ListGroup border="surround" className="autoActionLogItems">
        {props.auto_action_logs.map((auto_action_log, idx) => {
          const action_id = auto_action_log.auto_action_mail_id ? auto_action_log.auto_action_mail_id : auto_action_log.auto_action_task_id

          return (
            <ListItem key={idx} className="autoActionLogItem">
              <div className="col-12">
                <div className="row">
                  <div className="col-8 col-lg-8 align-self-center">
                    <Cell className="autoActionLogItem__Content">
                      <p>
                        <AutoActionLogIcon auto_action_log={auto_action_log} />
                        <span className="date">{dateToString(auto_action_log.execute_at)}</span>
                        <AutoActionLogStatus status={auto_action_log.status} />
                      </p>
                      <p className="autoActionLogItem__Content__title">
                        <a href={`/auto_action/mails/${action_id}/edit`} target="_blank" rel="noopener noreferrer">
                          {auto_action_log.title}
                        </a>
                      </p>
                      {r.renderIf(auto_action_log.auto_action_mail_id)(
                        <div className="autoActionLogItem__Content__mailDetail">
                          <p>TO: {auto_action_log.mail_to}</p>
                          <p>CC: {auto_action_log.mail_cc}</p>
                          <p>BCC: {auto_action_log.mail_bcc}</p>
                        </div>
                      )}
                    </Cell>
                  </div>
                  <div className="ml-auto col-auto align-self-center">
                    <Cell className="autoActionLogItem__Action">
                      <AutoActionLogButton auto_action_log={auto_action_log} />
                    </Cell>
                  </div>
                </div>
              </div>
            </ListItem>
          )
        })}
      </ListGroup>
    </div>
  )
}

function AutoActionLogButton(props) {
  const id = props.auto_action_log.id
  const status = props.auto_action_log.status

  let label = '送信'
  let onCreateClick = id => AutoLogActions.onRequestCreate(id)
  let onUpdateClick = id => AutoLogActions.onRequestUpdate(id)

  if (props.auto_action_log.auto_action_task_id) {
    label = '登録'
  } else {
    onCreateClick = id => AutoMailContentActions.toEditView(id)
    onUpdateClick = id => AutoMailContentActions.toEditView(id)
  }

  const buttons = {
    failed: {
      show: true,
      label: `再${label}する`,
      onClick: () => onCreateClick(id)
    },
    succeeded: {
      show: true,
      label: `再${label}する`,
      onClick: () => onCreateClick(id)
    },
    manual: {
      show: true,
      label: `手動${label}する`,
      onClick: () => onUpdateClick(id)
    }
  }
  const button = buttons[status] || {
    show: false,
    label: null,
    onClick: () => {}
  }

  // 有効期限が設定されているログは実行中か失敗して自動的に再実行されるので表示しないようにする
  if (status != 'manual' && props.auto_action_log.expire_at) return <div />

  if (!button.show) return <div />

  return <NormalButton onClick={button.onClick}>{button.label}</NormalButton>
}

function AutoActionLogIcon(props) {
  const auto_action_log = props.auto_action_log

  if (auto_action_log.auto_action_mail_id) {
    return <IconMail />
  }

  if (auto_action_log.auto_action_task_id) {
    return <IconTask />
  }

  return null
}

function AutoActionLogStatus(props) {
  const status = {
    failed: { id: 1, label: '失敗' },
    succeeded: { id: 2, label: '成功' },
    in_progress: { id: 3, label: '処理中' },
    manual: { id: 3, label: '手動' }
  }
  const action_status = status[props.status] || { id: 0, label: '' }

  return <Tag id={action_status.id}>{action_status.label}</Tag>
}
