import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import loginRepository from '../../infra/LoginRepository'
import LoginEvent from '../../event/LoginEvent'
import SessionEvent from '../../event/SessionEvent'
import updateReferrer from './updateReferrer'

const onProcessRequestLogin = async form => {
  const response = await loginRepository.requestLogin(form.email, form.password).catch(() => false)
  if (response === false) {
    AppDispatcher.dispatchWithType(LoginEvent.failed, { error: true })
    return false
  }

  AppDispatcher.dispatchWithType(SessionEvent.loginSuccess, {
    token: response.token
  })
  // ログイン後にリダイレクト
  if (form.referrer) {
    history.replace(form.referrer)
  }

  return true
}

const onRequestLogin = form => {
  const result = onProcessRequestLogin(form)
  if (result) {
    // ログイン成功後はクリアしておく
    updateReferrer(null)
  }
}
export default onRequestLogin
