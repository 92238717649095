import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import TaskRepository from '../../infra/TaskRepository'
import TaskEvent from '../../event/TaskEvent'

const onSearch = async (condition, pagination) => {
  const response = await TaskRepository.fetchBySearchCondition(condition, pagination).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, 'タスクの検索に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(TaskEvent.onSearch, {
    tasks: response.tasks,
    pagination: response.pagination
  })

  return true
}

export default onSearch
