import cancelEditView from './cancelEditView'
import fetch from './fetch'
import onEdit from './onEdit'
import onRequestSave from './onRequestSave'

const ReservationSegmentAction = {
  cancelEditView,
  fetch,
  onEdit,
  onRequestSave
}

export default ReservationSegmentAction
