/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationInvoice from './AccommodationInvoice'
import Pagination from './Pagination'

/**
 * The InlineResponse20017 model module.
 * @module model/InlineResponse20017
 * @version 1.0.0
 */
export default class InlineResponse20017 {
  /**
   * Constructs a new <code>InlineResponse20017</code>.
   * @alias module:model/InlineResponse20017
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20017</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20017} obj Optional instance to populate.
   * @return {module:model/InlineResponse20017} The populated <code>InlineResponse20017</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20017()

      if (data.hasOwnProperty('invoices')) {
        obj['invoices'] = ApiClient.convertToType(data['invoices'], [AccommodationInvoice])
      }
      if (data.hasOwnProperty('pagination')) {
        obj['pagination'] = Pagination.constructFromObject(data['pagination'])
      }
    }
    return obj
  }

  /**
   * @member {Array.<module:model/AccommodationInvoice>} invoices
   */
  invoices = undefined
  /**
   * @member {module:model/Pagination} pagination
   */
  pagination = undefined
}
