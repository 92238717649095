import { Record } from 'immutable'

/**
 * `auto_action_configs` と `auto_action_tasks` の2テーブルを表現したステートクラス
 *  自動登録タスクの設定ではこの2テーブルを1画面で同時に扱う
 */
const _AutoActionTaskConfigState = {
  // config
  auto_action_config_id: null,
  user_id: null,
  status: null,
  action_type: 'task',
  trigger_event: '', // {'cancelled', 'after_booking', 'check_in', 'check_out'}
  trigger_time: 0,
  retry_period: 0,
  // task
  auto_action_task_id: null,
  name: '',
  title: '',
  body: null,
  // rooms
  room_ids: [],
  // site_referrers
  site_referrer_ids: []
}

const AUTO_ACTION_TYPE_TASK = 'task'

class AutoActionTaskConfigState extends Record(_AutoActionTaskConfigState) {
  mergeModel(model) {
    const config = model.config
    const task = model.task

    return this.merge({
      // config
      auto_action_config_id: config.id,
      user_id: config.user_id,
      status: config.status,
      action_type: AUTO_ACTION_TYPE_TASK,
      trigger_event: config.trigger_event,
      trigger_time: config.trigger_time,
      retry_period: config.retry_period,
      // task
      auto_action_task_id: task.id,
      name: task.name,
      title: task.title,
      body: task.body,

      room_ids: model.rooms.map(_ => _.room_id),
      site_referrer_ids: model.site_referrers.map(_ => _.site_referrer_id)
    })
  }

  updateValue(key, value) {
    return this.set(key, value)
  }

  getConfig() {
    return {
      id: this.auto_action_config_id,
      user_id: this.user_id,
      status: this.status,
      action_type: AUTO_ACTION_TYPE_TASK,
      trigger_event: this.trigger_event,
      trigger_time: this.trigger_time,
      retry_period: this.retry_period
    }
  }

  getApiParameters() {
    return {
      config: {
        status: this.status,
        action_type: AUTO_ACTION_TYPE_TASK,
        trigger_event: this.trigger_event,
        trigger_time: this.trigger_time,
        retry_period: this.retry_period
      },
      task: {
        name: this.name,
        title: this.title,
        body: this.body
      },
      room_ids: this.room_ids,
      site_referrer_ids: this.site_referrer_ids
    }
  }
}

export { AutoActionTaskConfigState }
