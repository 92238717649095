import history from '../../../util/history'

const onMonthChanged = (month, hotel_id) => {
  if (month) {
    history.push(`/report/check_in/date/${month}`)
  } else {
    history.push(`/report/check_in`)
  }

  return true
}

export default onMonthChanged
