import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import ReservationEvent from '../../event/ReservationEvent'

const toCreationView = () => {
  AppDispatcher.dispatchWithType(ReservationEvent.startCreation)
  history.push('/reservation/new')

  return true
}

export default toCreationView
