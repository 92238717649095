import AppDispatcher from '../../core/AppDispatcher'
import TransactionEvent from '../../event/TransactionEvent'
import { ReservationState } from '../../store/state/ReservationState'
import { AccommodationTaxState } from '../../store/state/AccommodationTaxState'

const onEdit = (reservation, name, value) => {
  let totalTax = null
  if (name === 'accommodation_tax_paid') {
    const reservationState = new ReservationState().mergeModel(reservation)
    const accommodationTaxState = new AccommodationTaxState().buildBy(reservationState)
    totalTax = accommodationTaxState.calculate()
  }

  AppDispatcher.dispatchWithType(TransactionEvent.onEdit, {
    reservation: reservation,
    name: name,
    value: value,
    total_tax: totalTax
  })

  return true
}

export default onEdit
