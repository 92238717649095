import ApiClient from '../ApiClient'
import MonthlyClosingApi from '../api/MonthlyClosingApi'

export class MonthlyClosingRepository {
  constructor() {
    this._api = new MonthlyClosingApi(ApiClient.instance)
  }

  fetchByDate(date, hotel_ids) {
    const body = { monthly_close_date: date.beginningOfMonth().toISODate(), hotel_ids: hotel_ids }
    return this._api.getMonthlyClosingHeads({ monthly_closing_head: body })
  }

  fetchDailySummaryById(monthlyClosingHeadId) {
    return this._api.getDailyDlosingsOfMonthly(monthlyClosingHeadId)
  }

  fetchDailySummaryByHotelId(hotelId, monthlyCloseDate) {
    return this._api.fetchDailySummaryByHotelId(hotelId, monthlyCloseDate.toISODate())
  }

  fetchOne(monthlyClosingHeadId) {
    return this._api.getMonthlyClosing(monthlyClosingHeadId)
  }

  fetchSummary(hotelId, monthlyCloseDate) {
    const body = { hotel_id: hotelId, monthly_close_date: monthlyCloseDate.beginningOfMonth().toISODate() }
    return this._api.getDailyClosingSummary({ monthly_closing_head: body })
  }

  requestCreate(head, details) {
    const _head = head.toRequestBody()
    const body = { monthly_closing_head: _head, monthly_closing_details: details }
    return this._api.createMonthlyClosing(body)
  }

  requestUpdate(id, head, details, deleted_detail_ids) {
    const _head = head.toRequestBody()
    const body = { monthly_closing_head: _head, monthly_closing_details: details, deleted_detail_ids: deleted_detail_ids }
    return this._api.updateMonthlyClosing(id, body)
  }
}

// singleton
export default new MonthlyClosingRepository()
