/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import PlanBreakfasts from './PlanBreakfasts'

/**
 * The Plan model module.
 * @module model/Plan
 * @version 1.0.0
 */
export default class Plan {
  /**
   * Constructs a new <code>Plan</code>.
   * @alias module:model/Plan
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>Plan</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Plan} obj Optional instance to populate.
   * @return {module:model/Plan} The populated <code>Plan</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Plan()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('hotel_id')) {
        obj['hotel_id'] = ApiClient.convertToType(data['hotel_id'], 'Number')
      }
      if (data.hasOwnProperty('hotel_name')) {
        obj['hotel_name'] = ApiClient.convertToType(data['hotel_name'], 'String')
      }
      if (data.hasOwnProperty('plan_code')) {
        obj['plan_code'] = ApiClient.convertToType(data['plan_code'], 'String')
      }
      if (data.hasOwnProperty('pms_plan_code')) {
        obj['pms_plan_code'] = ApiClient.convertToType(data['pms_plan_code'], 'String')
      }
      if (data.hasOwnProperty('breakfasts')) {
        obj['breakfasts'] = ApiClient.convertToType(data['breakfasts'], [PlanBreakfasts])
      }
      if (data.hasOwnProperty('lunches')) {
        obj['lunches'] = ApiClient.convertToType(data['lunches'], [PlanBreakfasts])
      }
      if (data.hasOwnProperty('dinners')) {
        obj['dinners'] = ApiClient.convertToType(data['dinners'], [PlanBreakfasts])
      }
      if (data.hasOwnProperty('activities')) {
        obj['activities'] = ApiClient.convertToType(data['activities'], [PlanBreakfasts])
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {Number} hotel_id
   */
  hotel_id = undefined
  /**
   * @member {String} hotel_name
   */
  hotel_name = undefined
  /**
   * @member {String} plan_code
   */
  plan_code = undefined
  /**
   * @member {String} pms_plan_code
   */
  pms_plan_code = undefined
  /**
   * @member {Array.<module:model/PlanBreakfasts>} breakfasts
   */
  breakfasts = undefined
  /**
   * @member {Array.<module:model/PlanBreakfasts>} lunches
   */
  lunches = undefined
  /**
   * @member {Array.<module:model/PlanBreakfasts>} dinners
   */
  dinners = undefined
  /**
   * @member {Array.<module:model/PlanBreakfasts>} activities
   */
  activities = undefined
}
