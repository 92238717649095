import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'

// 削除確認ダイアログの表示
const confirmDeletion = (event, index) => {
  event.stopPropagation()
  AppDispatcher.dispatchWithType(CheckInEvent.confirmDeletion, { index: index })
  return true
}

export default confirmDeletion
