import AppDispatcher from '../../core/AppDispatcher'
import ApiHandle from '../../util/ApiHandle'
import { appActions } from '../AppActions'
import ReservationApi from '../../api/ReservationApi'
import ApiClient from '../../ApiClient'
import ReservationEvent from '../../event/ReservationEvent'

const reservationApi = new ReservationApi(ApiClient.instance)

const fetchAutoActionLog = reservationId => {
  const apiHandleOpts = {
    apiCall: () => reservationApi.getReservationAutoActionLogs(reservationId),
    onSucceded: response => AppDispatcher.dispatchWithType(ReservationEvent.fetchAutoActionLog, { auto_action_logs: response }),
    onFailed: response => appActions.snackbar('自動アクション履歴が取得できませんした', 'error', true),
    onErrored: error => appActions.snackbar('自動アクション履歴が取得できませんした', 'error', true)
  }
  ApiHandle(apiHandleOpts)
}

export default fetchAutoActionLog
