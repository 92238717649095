/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AutoActionConfig model module.
 * @module model/AutoActionConfig
 * @version 1.0.0
 */
export default class AutoActionConfig {
  /**
   * Constructs a new <code>AutoActionConfig</code>.
   * @alias module:model/AutoActionConfig
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AutoActionConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutoActionConfig} obj Optional instance to populate.
   * @return {module:model/AutoActionConfig} The populated <code>AutoActionConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutoActionConfig()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('action_type')) {
        obj['action_type'] = ApiClient.convertToType(data['action_type'], 'String')
      }
      if (data.hasOwnProperty('trigger_event')) {
        obj['trigger_event'] = ApiClient.convertToType(data['trigger_event'], 'String')
      }
      if (data.hasOwnProperty('trigger_time')) {
        obj['trigger_time'] = ApiClient.convertToType(data['trigger_time'], 'Number')
      }
      if (data.hasOwnProperty('retry_period')) {
        obj['retry_period'] = ApiClient.convertToType(data['retry_period'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} user_id
   */
  user_id = undefined
  /**
   * @member {module:model/AutoActionConfig.StatusEnum} status
   */
  status = undefined
  /**
   * @member {module:model/AutoActionConfig.ActionTypeEnum} action_type
   */
  action_type = undefined
  /**
   * @member {module:model/AutoActionConfig.TriggerEventEnum} trigger_event
   */
  trigger_event = undefined
  /**
   * @member {Number} trigger_time
   */
  trigger_time = undefined
  /**
   * @member {Number} retry_period
   */
  retry_period = undefined

  /**
   * Allowed values for the <code>status</code> property.
   * @enum {String}
   * @readonly
   */
  static StatusEnum = {
    /**
     * value: "disabled"
     * @const
     */
    disabled: 'disabled',

    /**
     * value: "auto"
     * @const
     */
    auto: 'auto',

    /**
     * value: "manual"
     * @const
     */
    manual: 'manual'
  }

  /**
   * Allowed values for the <code>action_type</code> property.
   * @enum {String}
   * @readonly
   */
  static ActionTypeEnum = {
    /**
     * value: "mail"
     * @const
     */
    mail: 'mail',

    /**
     * value: "task"
     * @const
     */
    task: 'task'
  }

  /**
   * Allowed values for the <code>trigger_event</code> property.
   * @enum {String}
   * @readonly
   */
  static TriggerEventEnum = {
    /**
     * value: "cancelled"
     * @const
     */
    cancelled: 'cancelled',

    /**
     * value: "after_booking"
     * @const
     */
    after_booking: 'after_booking',

    /**
     * value: "check_in"
     * @const
     */
    check_in: 'check_in',

    /**
     * value: "check_out"
     * @const
     */
    check_out: 'check_out'
  }
}
