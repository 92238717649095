import React from 'react'
import { BaseLayout, ListViewLayout } from 'ui-library/dist/external/layouts'
import { DataTable, DataTableColumn as TD, DataTableHeader as TH, DataTableRow as TR, NavigationDrawer } from 'ui-library/dist/external/components'

const notations = records => records.join(', ')
const replaceType = type => (type === 'closing' ? '締め処理' : type === 'reservation' ? '宿泊予約' : type === 'auto_action' ? '自動アクション' : type)
const availableTypes = records => records.map(r => replaceType(r)).join(', ')

export default props => {
  const toolbar = { label: `流入元チャネル設定`, items: props.accountMenu }
  return (
    <React.Fragment>
      <NavigationDrawer toolbar={toolbar} navigations={props.navigations} />

      <BaseLayout>
        <ListViewLayout>
          <DataTable
            headers={[
              <TH key={1} width="55px" isNumber>
                ID
              </TH>,
              <TH key={2} width="20%">
                流入元チャネル
              </TH>,
              <TH key={3} width="40%">
                表記
              </TH>,
              <TH key={4} width="30%">
                利用可能
              </TH>
            ]}
            rows={props.site_referrers.map(r => (
              <TR key={1}>
                <TD isNumber>{r.id}</TD>
                <TD>{r.name}</TD>
                <TD>{notations(r.notations)}</TD>
                <TD>{availableTypes(r.available_types)}</TD>
                {/*<TD><OutlinedButton onClick={() => {}}>編集</OutlinedButton></TD>*/}
              </TR>
            ))}
          />
        </ListViewLayout>
      </BaseLayout>
    </React.Fragment>
  )
}
