import history from '../../util/history'
import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'

const onRequestDelete = async invoiceId => {
  const response = await repository.requestDelete(invoiceId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '削除に失敗しました')
    return false
  }

  history.goBack()
  appActions.snackbar('削除しました', 'success', true)
  return true
}

export default onRequestDelete
