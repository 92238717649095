import addRow from './addRow'
import cancelCreateView from './cancelCreateView'
import fetchAll from './fetchAll'
import fetchOne from './fetchOne'
import hideDetailView from './hideDetailView'
import onEdit from './onEdit'
import onEditValue from './onEditValue'
import onRequestCreate from './onRequestCreate'
import onRequestUpdate from './onRequestUpdate'
import toCreateView from './toCreateView'
import toDetailView from './toDetailView'
import toEditView from './toEditView'

const SegmentationAction = {
  addRow,
  cancelCreateView,
  fetchAll,
  fetchOne,
  hideDetailView,
  onEdit,
  onEditValue,
  onRequestCreate,
  onRequestUpdate,
  toCreateView,
  toDetailView,
  toEditView
}

export default SegmentationAction
