import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleDetailEvent from '../../event/AccountTitleDetailEvent'

const fetchAccountTitleDetail = async detailId => {
  const response = await AccountTitleRepository.getAccountTitleDetail(detailId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleDetailEvent.fetchOne, { detail: response })
  return true
}

export default fetchAccountTitleDetail
