import onMonthChanged from './onMonthChanged'
import onEdit from './onEdit'
import requestSearch from './requestSearch'

const ReportCheckInAction = {
  onEdit,
  onMonthChanged,
  requestSearch
}

export default ReportCheckInAction
