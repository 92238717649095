import _ from 'lodash'

/**
 * 宿泊予約を使いやすいデータ構造にする
 *
 * @params
 *   [Reservation, ..]
 * @return
 *   [roomId] => { from: number, to: number, rowIndex: number, reservation: Reservation} // ordered by 'from'
 *       from ... その月のチェックイン日。前月またぎは 0 になる
 *       to ... その月のチェックアウト日。翌月またぎは末日+1になる
 */
function recomposeReservations(reservationArray, eventScheduleArray, targetDate) {
  if (_.isEmpty(reservationArray) && _.isEmpty(eventScheduleArray)) return []

  const targetYear = targetDate.getFullYear()
  const targetMonth = targetDate.getMonth()
  const getDate = d => {
    if (!d) return -1

    if ((d.getFullYear() === targetYear && d.getMonth() < targetMonth) || d.getFullYear() < targetYear) {
      // 前月からの月をまたいだ宿泊予約
      return 0
    }

    if ((d.getFullYear() === targetYear && d.getMonth() > targetMonth) || d.getFullYear() > targetYear) {
      // 翌月へまたいだ宿泊予約
      const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0)
      return lastDayOfMonth.getDate() + 1
    }

    return d.getDate()
  }
  const getDate4Schedule = d => {
    if (!d) return -1

    if ((d.getFullYear() === targetYear && d.getMonth() < targetMonth) || d.getFullYear() < targetYear) {
      // 前月からの月をまたいだ宿泊予約
      return 1
    }

    if ((d.getFullYear() === targetYear && d.getMonth() > targetMonth) || d.getFullYear() > targetYear) {
      // 翌月へまたいだ宿泊予約
      const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0)
      return lastDayOfMonth.getDate()
    }

    return d.getDate()
  }

  // 前月からの宿泊、翌月までの宿泊があるかチェックして日付を更新する
  const beforeOrderByDate1 = reservationArray.map(schedule => {
    const merged = { ...schedule, is_reservation: true }
    return { from: getDate(schedule.check_in), schedule: merged }
  })
  const beforeOrderByDate2 = eventScheduleArray.map(schedule => {
    const merged = { ...schedule, is_reservation: false }
    return { from: getDate4Schedule(schedule.start_date), schedule: merged }
  })
  const beforeOrderByDate = _.concat(beforeOrderByDate1, beforeOrderByDate2)

  // チェックイン日付順に並べる
  const ordered = _.sortBy(beforeOrderByDate, ['from'])
  const mapped = _.map(ordered, 'schedule')

  const arr = mapped.map(s => {
    const from = s.is_reservation ? getDate(s.check_in) : getDate4Schedule(s.start_date)
    const to = s.is_reservation ? getDate(s.check_out) : getDate4Schedule(s.end_date)

    return { from: from, to: to, room: s.room_id, schedule: s }
  })
  const grouped = _.groupBy(arr, 'room')

  // rowIndexを設定する
  const aligned = _.mapValues(grouped, (schedules, roomId) => {
    let context = []
    return _.map(schedules, (schedule, index) => {
      if (index === 0) {
        context[0] = schedule
        schedule['rowIndex'] = 0
        return schedule
      }
      // 2行目以降
      const lastRowIndex = context.length - 1
      const last = _.last(context)

      if (schedule.from < last.to) {
        // Collision detected
        let flag = false
        if (lastRowIndex > 0) {
          // 適切なrowIndexを設定する
          for (let i = 0; i <= lastRowIndex; i++) {
            const ctxr = context[i]
            if (schedule.from < ctxr.to) {
              continue
            }
            context[i] = schedule
            schedule['rowIndex'] = i
            flag = true
            break
          }
        }
        if (flag === false) {
          // 単純に1行ずらす
          const newRowIndex = lastRowIndex + 1
          context[newRowIndex] = schedule
          schedule['rowIndex'] = newRowIndex
        }

        return schedule
      }
      // No collision detected
      // 適切なrowIndexを設定する
      for (let i = 0; i <= lastRowIndex; i++) {
        const ctxr = context[i]
        if (schedule.from < ctxr.to) {
          continue
        }
        context[i] = schedule
        schedule['rowIndex'] = i
        break
      }

      return schedule
    })
  })

  return aligned
}

/**
 * 最大行数を計算する。
 * CheckIn/CheckOutの日程がかぶると同一の行に表示できないのでずらさないといけない
 *
 * @param reservations
 * @returns {*}
 */
function calculateMaxDuplicationCount(reservations) {
  if (_.isEmpty(reservations)) return 0

  const maxRowIndex = reservations.reduce((acc, cur) => {
    return acc > cur.rowIndex ? acc : cur.rowIndex
  }, 0)

  return maxRowIndex + 1
}

/**
 * 日付に一致する宿泊予約および前月からの宿泊予約と次月への宿泊予約を取得する
 */
function getReservationsByDate(numberOfDate, reservations, endOfMonth) {
  return _.filter(reservations, rsv => numberOfDate === rsv.from || (numberOfDate === 1 && rsv.from === 0) || (numberOfDate === endOfMonth && rsv.from === endOfMonth + 1))
}

/**
 * 同じ日付かどうかチェックする
 *
 * @param datetime1
 * @param datetime2
 * @returns {boolean}
 */
function isSameDate(datetime1, datetime2) {
  const year1 = datetime1.getFullYear()
  const month1 = datetime1.getMonth() + 1
  const date1 = datetime1.getDate()
  const datefmt1 = `${year1}/${month1}/${date1}`

  const year2 = datetime2.getFullYear()
  const month2 = datetime2.getMonth() + 1
  const date2 = datetime2.getDate()
  const datefmt2 = `${year2}/${month2}/${date2}`

  return datefmt1 === datefmt2
}

export { recomposeReservations, calculateMaxDuplicationCount, getReservationsByDate, isSameDate }
