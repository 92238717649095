import ApiHandle from '../../util/ApiHandle'
import HotelManualEvent from '../../event/HotelManualEvent'
import history from '../../util/history'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import hotelManualRepository from '../../infra/HotelManualRepository'
import fetchAll from './fetchAll'

const onUpdate = async (id, manual, roomId, name) => {
  const data = manual
    .setTitle()
    .setKeywords()
    .merge({ manual_url: manual.manual })

  const res = await hotelManualRepository.requestUpdate(id, data).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '更新に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(HotelManualEvent.updated)
  fetchAll(roomId)
  history.push(`/setting/${roomId}/tablet`)
  appActions.snackbar('更新しました', 'success', true)
  return true
}

export default onUpdate
