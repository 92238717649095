import RoomEvent from '../../event/RoomEvent'
import AppDispatcher from '../../core/AppDispatcher'
import roomRepository from '../../infra/RoomRepository'
import { appActions } from '../AppActions'

const fetchAll = async () => {
  const response = await roomRepository.fetchAll().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(RoomEvent.fetchAll, { rooms: response })
  return true
}

export default fetchAll
