import NProgress from 'nprogress'
import AppDispatcher from '../../../core/AppDispatcher'
import { appActions } from '../../AppActions'
import monthlyReportRepository from '../../../infra/MonthlyReportRepository'
import ReportCheckInEvent from '../../../event/MonthlyReportEvent'

const requestSearch = async (state, target_date) => {
  if (!state.search_hotel_id) {
    appActions.snackbar('宿泊施設を設定してください')
    return false
  }

  NProgress.start()

  const response = await monthlyReportRepository.fetchCheckIn(target_date, state.search_hotel_id).catch(e => e)
  if (response instanceof Error) {
    AppDispatcher.dispatchWithType(ReportCheckInEvent.onEditCheckIn, [])
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(ReportCheckInEvent.checkInMonthChanged, response)

  NProgress.done()

  return true
}

export default requestSearch
