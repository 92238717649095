/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AccountTitleGroup model module.
 * @module model/AccountTitleGroup
 * @version 1.0.0
 */
export default class AccountTitleGroup {
  /**
   * Constructs a new <code>AccountTitleGroup</code>.
   * @alias module:model/AccountTitleGroup
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AccountTitleGroup</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccountTitleGroup} obj Optional instance to populate.
   * @return {module:model/AccountTitleGroup} The populated <code>AccountTitleGroup</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccountTitleGroup()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {module:model/AccountTitleGroup.CodeEnum} code
   */
  code = undefined

  /**
   * Allowed values for the <code>code</code> property.
   * @enum {String}
   * @readonly
   */
  static CodeEnum = {
    /**
     * value: "sales"
     * @const
     */
    sales: 'sales',

    /**
     * value: "cost"
     * @const
     */
    cost: 'cost',

    /**
     * value: "expense"
     * @const
     */
    expense: 'expense'
  }
}
