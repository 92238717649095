import AppDispatcher from '../../core/AppDispatcher'
import reservationRepository from '../../infra/ReservationRepository'
import ReservationEvent from '../../event/ReservationEvent'
import { appActions } from '../AppActions'

const onUpdateReservationPay = async (reservationId, paidFee, paidTax) => {
  const response = await reservationRepository.updateReservationPay(reservationId, paidFee, paidTax).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }

  appActions.snackbar('更新しました', 'success', true)

  AppDispatcher.dispatchWithType(ReservationEvent.onUpdateReservationPay, { reservation_pay: response.reservation_pay })

  return true
}

export default onUpdateReservationPay
