/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AccommodationInvoiceDetail model module.
 * @module model/AccommodationInvoiceDetail
 * @version 1.0.0
 */
export default class AccommodationInvoiceDetail {
  /**
   * Constructs a new <code>AccommodationInvoiceDetail</code>.
   * @alias module:model/AccommodationInvoiceDetail
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AccommodationInvoiceDetail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccommodationInvoiceDetail} obj Optional instance to populate.
   * @return {module:model/AccommodationInvoiceDetail} The populated <code>AccommodationInvoiceDetail</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccommodationInvoiceDetail()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('accommodation_invoice_id')) {
        obj['accommodation_invoice_id'] = ApiClient.convertToType(data['accommodation_invoice_id'], 'Number')
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String')
      }
      if (data.hasOwnProperty('unit_price')) {
        obj['unit_price'] = ApiClient.convertToType(data['unit_price'], 'Number')
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number')
      }
      if (data.hasOwnProperty('total')) {
        obj['total'] = ApiClient.convertToType(data['total'], 'Number')
      }
      if (data.hasOwnProperty('invoice_type')) {
        obj['invoice_type'] = ApiClient.convertToType(data['invoice_type'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} accommodation_invoice_id
   */
  accommodation_invoice_id = undefined
  /**
   * @member {String} title
   */
  title = undefined
  /**
   * @member {Number} unit_price
   */
  unit_price = undefined
  /**
   * @member {Number} quantity
   */
  quantity = undefined
  /**
   * @member {Number} total
   */
  total = undefined
  /**
   * @member {String} invoice_type
   */
  invoice_type = undefined
}
