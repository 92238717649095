import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const addDetail = event => {
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.addDetail)

  return true
}

export default addDetail
