import AppDispatcher from '../../core/AppDispatcher'
import TransactionEvent from '../../event/TransactionEvent'

const onEditDetail = (index, name, value) => {
  AppDispatcher.dispatchWithType(TransactionEvent.onEditDetail, {
    index: index,
    name: name,
    value: value
  })

  return true
}

export default onEditDetail
