import ApiClient from '../ApiClient'
import TaskApi from '../api/TaskApi'

export class TaskRepository {
  constructor() {
    this._api = new TaskApi(ApiClient.instance)
  }

  fetchOne(taskId) {
    return this._api.getTask(taskId)
  }

  fetchAll() {
    return this._api.getTasks()
  }

  fetchBySearchCondition(condition, pagination) {
    const body = { task: condition.toObject(), pagination: pagination.toObject() }
    return this._api.searchTask(body)
  }

  requestSave(reservationId, task) {
    const taskId = task.id
    const body = { task: task.merge({ reservation_id: reservationId }) }
    if (taskId) {
      return this._api.updateTask(taskId, body)
    }

    return this._api.newTask(body)
  }

  requestUpdateStatus(taskId, task) {
    const body = { task: task }

    if (taskId) {
      return this._api.updateTask(taskId, body)
    }

    return this._api.this._api.newTask(body)
  }

  requestDelete(taskId) {
    return this._api.deleteTask(taskId)
  }
}

// singleton
export default new TaskRepository()
