import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'

const requestSave = async (reservationId, accommodationTax, view) => {
  const response = await repository.requestSave(reservationId, accommodationTax, view).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '登録できませんした')
    return false
  }
  appActions.snackbar('請求書を作成しました', 'success', true)

  return true
}

export default requestSave
