const MonthlyReportEvent = {
  accommodationTaxMonthChanged: 'monthly-report/accommodation-tax/month-changed',
  onEditAccommodationTaxSearchCondition: 'monthly-report/accommodation-tax/on-edit-search-condition',
  fetchAccommodationTaxDetail: 'monthly-report/accommodation-tax/fetchDetail',
  monthlySalesMonthChanged: 'monthly-report/monthly-sales/month-changed',
  onEditMonthlySalesSearchCondition: 'monthly-report/monthly-sales/search-condition',
  onEditCheckIn: 'monthly-report/check-in/on-edit',
  checkInMonthChanged: 'monthly-report/check-in/month-changed'
}

export default MonthlyReportEvent
