const ReceptionNav = [
  {
    path: null,
    label: '宿泊予約',
    icon: 'check_in_today',
    items: [
      {
        path: '/receptionist',
        label: '本日の宿泊予定'
      },
      {
        path: '/calendar',
        label: '宿泊予約カレンダー'
      },
      {
        path: '/reservation/search',
        label: '宿泊予約を検索'
      },
      {
        path: '/reservation/new',
        label: '宿泊予約の作成'
      },
      {
        path: '/event_schedule/new',
        label: 'イベントや予定の作成'
      },
      {
        path: '/check_in',
        label: '宿泊者名簿',
        icon: 'assignment'
      }

      // {
      //   path: '/plan/new',
      //   label: 'プランを作成'
      // }
    ]
  },
  {
    label: 'プラン',
    icon: 'assignment',
    path: null,
    items: [
      {
        path: '/plan',
        label: 'プラン一覧'
      },
      {
        path: '/meal',
        label: '食事'
      },
      {
        path: '/activity',
        label: 'アクティビティ'
      }
    ]
  },
  // {
  //   path: '/task/search',
  //   label: 'タスク',
  //   icon: 'task_manage'
  // },
  {
    path: null,
    label: '請求',
    icon: 'payment',
    items: [
      {
        path: '/invoice',
        label: '請求一覧'
      },
      {
        path: '/stripe_keys',
        label: 'Stripeキー設定'
      }
    ]
  },
  // {
  //   path: null,
  //   label: '売上',
  //   icon: null,
  //   items: [
  //     {
  //       path: '/transaction',
  //       label: '売上一覧'
  //     },
  //     {
  //       path: '/transaction/monthly',
  //       label: '月別売上'
  //     },
  //     {
  //       path: '',
  //       label: '未入金管理'
  //     }
  //   ]
  // },
  {
    path: null,
    label: '締め処理',
    icon: 'procedure',
    items: [
      {
        path: '/daily',
        label: '日次締め'
      },
      {
        path: '/monthly',
        label: '月次処理'
      },
      {
        path: '/monthly/accommodation_tax',
        label: '宿泊税一覧'
      },
      {
        path: '/account_titles',
        label: '勘定科目の設定'
      },
      {
        path: '/account_titles/details',
        label: '補助科目の設定'
      }
    ]
  },
  // オーナー向けレポート
  {
    path: null,
    label: 'レポート',
    icon: 'sales',
    items: [
      {
        path: '/report/monthly_sales',
        label: '売上レポート'
      },
      {
        path: '/report/accommodation_tax',
        label: '宿泊税レポート'
      },
      {
        path: '/report/check_in',
        label: '宿泊者レポート'
      }
    ]
  },
  {
    path: null,
    label: '宿泊施設',
    icon: 'hotel',
    items: [
      // {
      //   path: '/status',
      //   label: '宿の情報'
      // },
      {
        path: '/hotels',
        label: '宿泊施設管理'
      }
    ]
  },
  {
    path: null,
    label: '設定',
    icon: 'settings',
    items: [
      {
        path: '/segment_settings',
        label: '客層マスタの設定'
      },
      // {
      //   path: '/site_referers',
      //   label: '流入元チャネル設定'
      // },
      {
        path: '/auto_action/mails',
        label: '自動送信メール'
      },
      {
        path: '/mail/smtp_settings',
        label: 'メール設定'
      }
    ]
  }
]

const OwnerNav = [
  {
    path: '/receptionist',
    label: '本日の宿泊予定',
    icon: 'check_in_today'
  },
  {
    path: '/calendar',
    label: '宿泊予約カレンダー',
    icon: 'reservation_manage'
  },
  {
    path: '/reservation/search',
    label: '宿泊予約一覧',
    icon: 'reservation_manage'
  },
  {
    path: '/reservation/new',
    label: '宿泊予約の新規作成'
  },
  {
    path: '/check_in',
    label: '宿泊者名簿',
    icon: 'check_in_manage'
  },
  // オーナー向けレポート
  {
    path: null,
    label: 'レポート',
    icon: 'sales',
    items: [
      {
        path: '/report/monthly_sales',
        label: '売上レポート'
      },
      {
        path: '/report/accommodation_tax',
        label: '宿泊税レポート'
      },
      {
        path: '/report/check_in',
        label: '宿泊者レポート'
      }
    ]
  }
]

function Navigations(isOwner) {
  if (isOwner === true) {
    return OwnerNav
  } else {
    return ReceptionNav
  }
}

export default Navigations
