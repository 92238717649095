import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'

const fetchAll = async () => {
  const res = await dailyClosingRepository.getDailyClosingHeads().catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(DailyClosingEvent.fetchAll, res)

  return true
}

export default fetchAll
