/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The SmtpSetting model module.
 * @module model/SmtpSetting
 * @version 1.0.0
 */
export default class SmtpSetting {
  /**
   * Constructs a new <code>SmtpSetting</code>.
   * @alias module:model/SmtpSetting
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>SmtpSetting</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SmtpSetting} obj Optional instance to populate.
   * @return {module:model/SmtpSetting} The populated <code>SmtpSetting</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SmtpSetting()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = ApiClient.convertToType(data['address'], 'String')
      }
      if (data.hasOwnProperty('port')) {
        obj['port'] = ApiClient.convertToType(data['port'], 'Number')
      }
      if (data.hasOwnProperty('domain')) {
        obj['domain'] = ApiClient.convertToType(data['domain'], 'String')
      }
      if (data.hasOwnProperty('user_name')) {
        obj['user_name'] = ApiClient.convertToType(data['user_name'], 'String')
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = ApiClient.convertToType(data['password'], 'String')
      }
      if (data.hasOwnProperty('authentication')) {
        obj['authentication'] = ApiClient.convertToType(data['authentication'], 'String')
      }
      if (data.hasOwnProperty('enable_starttls_auto')) {
        obj['enable_starttls_auto'] = ApiClient.convertToType(data['enable_starttls_auto'], 'Boolean')
      }
      if (data.hasOwnProperty('openssl_verify_mode')) {
        obj['openssl_verify_mode'] = ApiClient.convertToType(data['openssl_verify_mode'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} address
   */
  address = undefined
  /**
   * @member {Number} port
   */
  port = undefined
  /**
   * @member {String} domain
   */
  domain = undefined
  /**
   * @member {String} user_name
   */
  user_name = undefined
  /**
   * @member {String} password
   */
  password = undefined
  /**
   * @member {String} authentication
   */
  authentication = undefined
  /**
   * @member {Boolean} enable_starttls_auto
   */
  enable_starttls_auto = undefined
  /**
   * @member {String} openssl_verify_mode
   */
  openssl_verify_mode = undefined
}
