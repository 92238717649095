function estimateAccommodationFee(accommodationFee, commissionRateSetting) {
  if (!commissionRateSetting) {
    return accommodationFee
  }

  if (commissionRateSetting.hasOwnProperty('include') && commissionRateSetting['include']) {
    return calculateRounding((accommodationFee * 100) / (100 - commissionRateSetting['rate']), commissionRateSetting['rounding'])
  }

  const rounding = commissionRateSetting.hasOwnProperty('rounding') ? commissionRateSetting['rounding'] : 'round'
  return calculateRounding(accommodationFee, rounding)
}

function estimateCommissionFee(accommodationFee, commissionRateSetting) {
  if (!commissionRateSetting) {
    return 0
  }

  const fee = estimateAccommodationFee(accommodationFee, commissionRateSetting)

  if (!commissionRateSetting.hasOwnProperty('rounding') || !commissionRateSetting.hasOwnProperty('rate')) {
    return 0
  }

  return calculateRounding(fee * (commissionRateSetting['rate'] / 100), commissionRateSetting['rounding'])
}

function calculateRounding(price, rounding) {
  switch (rounding) {
    case 'round':
      return Math.round(price)
    case 'floor':
      return Math.floor(price)
    case 'ceil':
      return Math.ceil(price)
    default:
      return Math.round(price)
  }
}

export { estimateAccommodationFee, estimateCommissionFee }
