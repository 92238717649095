import history from '../../util/history'
import AccommodationInvoiceAction from './index'

const onDialogClose = async (action, invoice, accommodationInvoiceRefund) => {
  if (action === 'cancel') {
    history.push(`/invoice/${invoice.id}/detail`)
    return true
  }

  if (action === 'refund') {
    if (accommodationInvoiceRefund.amount > invoice.total) {
      alert('返金時に請求金額以上の金額を返金することはできません')
      history.replace(`/invoice/${invoice.id}/refund`)
      return false
    }

    if (window.confirm('返金処理を行います。よろしいですか？')) {
      await AccommodationInvoiceAction.onRequestRefund(invoice.id, accommodationInvoiceRefund)
      history.push(`/invoice/${invoice.id}/detail`)
    }
  }

  return true
}

export default onDialogClose
