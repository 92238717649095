import { Record } from 'immutable'

const AutoActionMailState = Record({
  id: null,
  auto_action_config_id: null,
  name: null,
  title: null,
  body: null,
  mail_to: null,
  mail_from: null,
  mail_reply_to: null,
  mail_cc: null,
  mail_bcc: null
})

export { AutoActionMailState }
