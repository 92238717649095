import ApiClient from '../ApiClient'
import AppRepository from './AppRepository'
import CheckInApi from '../api/CheckInApi'

export class CheckInRepository extends AppRepository {
  constructor() {
    super()
    this._api = new CheckInApi(ApiClient.instance)
  }

  fetchAll() {
    return this._api.getCheckIns()
  }

  onSearch(condition, pagination) {
    const checkin = condition.toObject()
    delete checkin.loading_room_option
    delete checkin.search_room_options
    const body = { check_in: checkin, pagination: pagination.toObject() }
    return this._api.searchCheckIn(body)
  }

  requestDelete(reservationId) {
    return this._api.deleteCheckIn(reservationId)
  }
}

// singleton
export default new CheckInRepository()
