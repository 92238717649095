/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AutoActionMailContent model module.
 * @module model/AutoActionMailContent
 * @version 1.0.0
 */
export default class AutoActionMailContent {
  /**
   * Constructs a new <code>AutoActionMailContent</code>.
   * @alias module:model/AutoActionMailContent
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AutoActionMailContent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutoActionMailContent} obj Optional instance to populate.
   * @return {module:model/AutoActionMailContent} The populated <code>AutoActionMailContent</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutoActionMailContent()

      if (data.hasOwnProperty('smtp_setting_id')) {
        obj['smtp_setting_id'] = ApiClient.convertToType(data['smtp_setting_id'], 'Number')
      }
      if (data.hasOwnProperty('subject')) {
        obj['subject'] = ApiClient.convertToType(data['subject'], 'String')
      }
      if (data.hasOwnProperty('body')) {
        obj['body'] = ApiClient.convertToType(data['body'], 'String')
      }
      if (data.hasOwnProperty('from')) {
        obj['from'] = ApiClient.convertToType(data['from'], 'String')
      }
      if (data.hasOwnProperty('to')) {
        obj['to'] = ApiClient.convertToType(data['to'], 'String')
      }
      if (data.hasOwnProperty('reply_to')) {
        obj['reply_to'] = ApiClient.convertToType(data['reply_to'], 'String')
      }
      if (data.hasOwnProperty('cc')) {
        obj['cc'] = ApiClient.convertToType(data['cc'], 'String')
      }
      if (data.hasOwnProperty('bcc')) {
        obj['bcc'] = ApiClient.convertToType(data['bcc'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} smtp_setting_id
   */
  smtp_setting_id = undefined
  /**
   * @member {String} subject
   */
  subject = undefined
  /**
   * @member {String} body
   */
  body = undefined
  /**
   * @member {String} from
   */
  from = undefined
  /**
   * @member {String} to
   */
  to = undefined
  /**
   * @member {String} reply_to
   */
  reply_to = undefined
  /**
   * @member {String} cc
   */
  cc = undefined
  /**
   * @member {String} bcc
   */
  bcc = undefined
}
