const CheckInEvent = {
  initialize: 'checkin/initialize',
  fetchOne: 'checkin/fetch-one',
  fetchAll: 'checkin/fetch-all',
  addGuest: 'checkin/add-guest',
  confirmDeletion: 'checkin/confirm-deletion',
  closeDeletionDialog: 'checkin/close-confirm-deletion',
  deleteForm: 'checkin/form-deletion',
  onEdit: 'checkin/value-changed',
  disposePassport: 'checkin/dispose-passport',
  complete: 'checkin/complete',
  onEditSearchCondition: 'checkin-search/on-edit'
}

export default CheckInEvent
