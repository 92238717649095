import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'
import { Record } from 'immutable'
import LocalDate from '../../domain/type/Date'

const _DailyProcessListSearchState = {
  search_keywords: '',
  search_room_ids: [],
  search_status_cancelled: true,
  search_status_booked: true,
  search_status_not_a_reservation: false
}

class DailyProcessListSearchState extends Record(_DailyProcessListSearchState) {
  setValue(key, value) {
    if (key === 'search_start_date' || key === 'search_end_date') {
      value = new LocalDate(value)
    }

    return this.set(key, value)
  }
}

class DailyProcessListSearchStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new DailyProcessListSearchState()
  }

  reduce(state, action) {
    const payload = action.payload

    switch (action.type) {
      case DailyClosingEvent.dateChange:
        return state.setValue('search_room_ids', payload.room_ids)

      case DailyClosingEvent.onEditSearchCondition:
        return state.setValue(payload.name, payload.value)

      default:
        return state
    }
  }
}

export default new DailyProcessListSearchStore()
