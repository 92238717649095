import HotelManualEvent from '../../event/HotelManualEvent'
import history from '../../util/history'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import hotelManualRepository from '../../infra/HotelManualRepository'
import fetchAll from './fetchAll'

const onCreate = async (roomId, manual, name) => {
  const data = manual
    .setTitle()
    .setKeywords()
    .merge({ id: null, position: manual.position, manual_url: manual.manual })

  const res = await hotelManualRepository.requestCreate(roomId, data).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '登録に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(HotelManualEvent.created)

  fetchAll(roomId)
  history.push(`/setting/${roomId}/tablet`)
  appActions.snackbar('追加しました', 'success', true)
  return true
}

export default onCreate
