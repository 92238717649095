/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AutoActionConfig from './AutoActionConfig'
import AutoActionConfigHouse from './AutoActionConfigHouse'
import AutoActionConfigSiteReferrer from './AutoActionConfigSiteReferrer'
import AutoActionMail from './AutoActionMail'

/**
 * The InlineResponse2011 model module.
 * @module model/InlineResponse2011
 * @version 1.0.0
 */
export default class InlineResponse2011 {
  /**
   * Constructs a new <code>InlineResponse2011</code>.
   * @alias module:model/InlineResponse2011
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse2011</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2011} obj Optional instance to populate.
   * @return {module:model/InlineResponse2011} The populated <code>InlineResponse2011</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2011()

      if (data.hasOwnProperty('config')) {
        obj['config'] = AutoActionConfig.constructFromObject(data['config'])
      }
      if (data.hasOwnProperty('mail')) {
        obj['mail'] = AutoActionMail.constructFromObject(data['mail'])
      }
      if (data.hasOwnProperty('rooms')) {
        obj['rooms'] = ApiClient.convertToType(data['rooms'], [AutoActionConfigHouse])
      }
      if (data.hasOwnProperty('site_referrers')) {
        obj['site_referrers'] = ApiClient.convertToType(data['site_referrers'], [AutoActionConfigSiteReferrer])
      }
    }
    return obj
  }

  /**
   * @member {module:model/AutoActionConfig} config
   */
  config = undefined
  /**
   * @member {module:model/AutoActionMail} mail
   */
  mail = undefined
  /**
   * @member {Array.<module:model/AutoActionConfigHouse>} rooms
   */
  rooms = undefined
  /**
   * @member {Array.<module:model/AutoActionConfigSiteReferrer>} site_referrers
   */
  site_referrers = undefined
}
