import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import AccountTitleDetailEvent from '../../event/AccountTitleDetailEvent'

const onCancelDetailEditView = async () => {
  AppDispatcher.dispatchWithType(AccountTitleDetailEvent.onClear)
  history.goBack()

  return true
}

export default onCancelDetailEditView
