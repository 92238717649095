import ReservationApi from '../api/ReservationApi'
import ApiClient from '../ApiClient'
import AppDispatcher from '../core/AppDispatcher'
import ApiHandle from '../util/ApiHandle'
import ReservationEvent from '../event/ReservationEvent'
import { appActions } from '../action/AppActions'
import LoginApi from '../api/LoginApi'
import ConfigApi from '../api/ConfigApi'
import StatusApi from '../api/StatusApi'

export class HotelManualRepository {
  constructor() {
    this._configApi = new ConfigApi(ApiClient.instance)
  }

  fetchByRoomId(roomId) {
    return this._configApi.getConfigHouseManuals(roomId)
  }

  fetchOne(id) {
    return this._configApi.getConfigHouseManual(id)
  }

  requestCreate(roomId, manual) {
    return this._configApi.newConfigHouseManual(roomId, { house_manual: manual })
  }

  requestUpdate(id, manual) {
    return this._configApi.updateConfigHouseManual(id, { house_manual: manual })
  }

  requestUpdatePosition(id, position) {
    return this._configApi.updateConfigHouseManualPosition(id, { position: position })
  }

  requestDelete(id) {
    return this._configApi.deleteConfigHouseManual(id)
  }
}

// singleton
export default new HotelManualRepository()
