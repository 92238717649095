/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The MinutPoint model module.
 * @module model/MinutPoint
 * @version 1.0.0
 */
export default class MinutPoint {
  /**
   * Constructs a new <code>MinutPoint</code>.
   * @alias module:model/MinutPoint
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>MinutPoint</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MinutPoint} obj Optional instance to populate.
   * @return {module:model/MinutPoint} The populated <code>MinutPoint</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MinutPoint()

      if (data.hasOwnProperty('devieceid')) {
        obj['devieceid'] = ApiClient.convertToType(data['devieceid'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {String} devieceid
   */
  devieceid = undefined
}
