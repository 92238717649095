import ApiClient from '../ApiClient'
import AccountTitleApi from '../api/AccountTitleApi'

export class AccountTitleRepository {
  constructor() {
    this._accountTitleApi = new AccountTitleApi(ApiClient.instance)
  }

  getAccountTitleGroups() {
    return this._accountTitleApi.getAccountTitleGroups()
  }

  getAccountTitle(titleId) {
    return this._accountTitleApi.getAccountTitle(titleId)
  }

  getAccoountTitles() {
    return this._accountTitleApi.getAccountTitles()
  }

  requestCreateByTitle(title) {
    const body = { account_title: title }
    return this._accountTitleApi.createAccountTitle(body)
  }

  requestUpdateByTitle(titleId, title) {
    const body = { account_title: title }
    return this._accountTitleApi.updateAccountTitle(titleId, body)
  }

  requestDeleteByTitleId(titleId) {
    return this._accountTitleApi.deleteAccountTitle(titleId)
  }

  getAccountTitleDetail(detailId) {
    return this._accountTitleApi.getAccountTitleDetail(detailId)
  }

  getAccountTitleDetails() {
    return this._accountTitleApi.getAccountTitleDetails()
  }

  requestCreateByDetail(detail) {
    const body = { account_title_detail: detail }
    return this._accountTitleApi.createAccountTitleDetail(body)
  }

  requestUpdateByDetail(detailId, detail) {
    const body = { account_title_detail: detail }
    return this._accountTitleApi.updateAccountTitleDetail(detailId, body)
  }

  requestDeleteByDetailId(detailId) {
    return this._accountTitleApi.deleteAccountTitleDetail(detailId)
  }
}

// singleton
export default new AccountTitleRepository()
