import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import siteReferrerRepository from '../../infra/SiteReferrerRepository'
import SiteReferrerEvent from '../../event/SiteReferrerEvent'

const fetchAll = async () => {
  const res = await siteReferrerRepository.fetchAll().catch(e => e)
  if (res instanceof Error) {
    appActions.showError(res.response.body, 'OTA情報の一覧取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(SiteReferrerEvent.fetchAll, { site_referrers: res })

  return true
}

export default fetchAll
