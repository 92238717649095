import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import monthlyClosingRepository from '../../infra/MonthlyClosingRepository'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const fetchOne = async headId => {
  const res = await monthlyClosingRepository.fetchOne(headId).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyClosingEvent.fetchOne, res)
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.fetchSummary, res)

  return res
}

export default fetchOne
