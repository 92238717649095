import ApiClient from '../ApiClient'
import LoginApi from '../api/LoginApi'
import ConfigApi from '../api/ConfigApi'
import StatusApi from '../api/StatusApi'

export class RoomRepository {
  constructor() {
    this._loginApi = new LoginApi(ApiClient.instance)
    this._configApi = new ConfigApi(ApiClient.instance)
    this._statusApi = new StatusApi(ApiClient.instance)
  }

  fetchAll() {
    return this._configApi.getConfigRooms()
  }

  fetchByUser() {
    return this._loginApi.getUserRooms()
  }

  fetchUserRoomById(roomId) {
    return this._loginApi.getUserRoomById(roomId)
  }

  fetchUserRoomByHotelId(hotelId) {
    return this._loginApi.getRoomsByHotelId(hotelId)
  }

  fetchOne(roomId) {
    return this._configApi.getConfigRoom(roomId)
  }

  fetchStatus() {
    return this._statusApi.getStatusRooms()
  }

  requestCreate(room) {
    return this._configApi.newConfigRoom({ room: room.toObject() })
  }

  requestUpdate(room) {
    return this._configApi.updateConfigRoom(room.id, { room: room.toObject() })
  }

  requestDelete(roomId) {
    return this._configApi.deleteConfigRoom(roomId)
  }
}

// singleton
export default new RoomRepository()
