import './AccountTitleDetailView.scss'
import React from 'react'
import { Edit } from '../../molecules'
import r from '../../../util/Renderer'
import { DeleteButton, Dropdown } from '../../atoms'
import TextInput from '../../atoms/input/TextInput'
import AccountTitleDetailAction from '../../../action/account_title_detail'
import AccountTitleGroupCode from '../../../domain/model/account_title/GroupCode'

function AccountTitleDetailEditView(props) {
  const detail = props.detail ? props.detail : {}
  const id = detail.id ? detail.id : null
  const label = id ? '更新' : '新規作成'
  const onRequestSave = id ? d => AccountTitleDetailAction.onRequestUpdateDetail(d) : d => AccountTitleDetailAction.onRequestCreateDetail(d)
  const onCancel = e => AccountTitleDetailAction.onCancelDetailEditView()
  const onRequestDelete = detail => AccountTitleDetailAction.onRequestDeleteDetail(detail)
  const onEditDetail = AccountTitleDetailAction.onEditDetail

  const costTypeOptions = () => {
    const variable = { value: 'variable', label: '変動費' }
    const fixed = { value: 'fixed', label: '固定費' }
    return [variable, fixed]
  }
  const titles = props.title_options || []
  const selectedGroup = titles.find(option => option.value == detail.account_title_id, null, { parent_id: null, parent_name: '(未選択)' })

  return (
    <Edit title={`補助科目管理[${label}]`} buttonLabel={label} onCancel={onCancel} onSubmit={e => onRequestSave(detail)}>
      <div>
        <div>
          <div className="EditArea__row">
            <div className="EditArea__row--full">
              <div className="EditArea__column">
                <p className="input">分類名</p>
                <p>{selectedGroup.parent_name}</p>
              </div>
            </div>
          </div>
          <div className="EditArea__row">
            <div className="EditArea__row--full">
              <div className="EditArea__column">
                <p className="input-required">勘定科目名</p>
                <Dropdown name="account_title_id" options={titles} value={detail.account_title_id} onChange={onEditDetail} placeholder="選択" searchable={true} isMulti={false} />
              </div>
            </div>
          </div>
          <div className="EditArea__row">
            <div className="EditArea__row--full">
              <div className="EditArea__column">
                <p className="input-required">補助科目名</p>
                <TextInput name="name" value={detail.name} placeholder="補助科目名" onChange={onEditDetail} />
              </div>
            </div>
          </div>
          {r.renderIf(selectedGroup.parent_id == AccountTitleGroupCode.COST || selectedGroup.parent_id == AccountTitleGroupCode.EXPENSE)(
            <div className="EditArea__row">
              <div className="EditArea__row--full">
                <div className="EditArea__column">
                  <p className="input-required">固定/変動</p>
                  <Dropdown name="cost_type" options={costTypeOptions()} value={detail.cost_type} onChange={onEditDetail} placeholder="未選択" isMulti={false} />
                </div>
              </div>
            </div>
          )}
          <div className="EditArea__row">
            <div className="EditArea__row--full">
              <div className="EditArea__column">
                <p className="input-required">並び順</p>
                <TextInput name="position" value={detail.position} placeholder="数値で入力してください" onChange={onEditDetail} />
              </div>
            </div>
          </div>
        </div>
        {r.renderIf(id)(
          <div className="EditArea__Deletion">
            <DeleteButton type="danger" size="lg" onClick={e => onRequestDelete(detail)}>
              削除
            </DeleteButton>
          </div>
        )}
      </div>
    </Edit>
  )
}
export default AccountTitleDetailEditView
