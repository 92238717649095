import AppDispatcher from '../../core/AppDispatcher'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onEditSearchCondition = event => {
  const name = event.target.name
  if (name === 'before_invoice' || name === 'invoicing' || name === 'waiting' || name === 'uncollectible' || name === 'paid' || name === 'refunded') {
    AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onEditSearchCondition, {
      name: name,
      value: event.target.checked
    })
    return true
  }

  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onEditSearchCondition, {
    name: name,
    value: event.target.value
  })

  return true
}

export default onEditSearchCondition
