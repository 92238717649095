import checkStatus from './checkStatus'
import onChange from './onChange'
import onRequestLogin from './onRequestLogin'
import onRequestLogout from './onRequestLogout'
import updateReferrer from './updateReferrer'

const LoginAction = {
  checkStatus,
  onChange,
  onRequestLogin,
  onRequestLogout,
  updateReferrer
}

export default LoginAction
