/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import InlineResponse20037 from '../model/InlineResponse20037'
import InlineResponse20038 from '../model/InlineResponse20038'

/**
 * Status service.
 * @module api/StatusApi
 * @version 1.0.0
 */
export default class StatusApi {
  /**
   * Constructs a new StatusApi.
   * @alias module:api/StatusApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * Sensorの値を取得する
   * @param {Number} sensorId Sensor id
   * @param {Number} from 取得するデータの開始時刻のUnixTime(1週間前など)
   * @param {Number} to 取得するデータの終了時刻のUnixTime(現在時刻など)
   * @param {Number} n データの分割数
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20038} and HTTP response
   */
  getSensorValuesWithHttpInfo(sensorId, from, to, n) {
    let postBody = null

    // verify the required parameter 'sensorId' is set
    if (sensorId === undefined || sensorId === null) {
      throw new Error("Missing the required parameter 'sensorId' when calling getSensorValues")
    }

    // verify the required parameter 'from' is set
    if (from === undefined || from === null) {
      throw new Error("Missing the required parameter 'from' when calling getSensorValues")
    }

    // verify the required parameter 'to' is set
    if (to === undefined || to === null) {
      throw new Error("Missing the required parameter 'to' when calling getSensorValues")
    }

    // verify the required parameter 'n' is set
    if (n === undefined || n === null) {
      throw new Error("Missing the required parameter 'n' when calling getSensorValues")
    }

    let pathParams = {
      sensor_id: sensorId
    }
    let queryParams = {
      from: from,
      to: to,
      n: n
    }
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20038

    return this.apiClient.callApi('/status/sensors/{sensor_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Sensorの値を取得する
   * @param {Number} sensorId Sensor id
   * @param {Number} from 取得するデータの開始時刻のUnixTime(1週間前など)
   * @param {Number} to 取得するデータの終了時刻のUnixTime(現在時刻など)
   * @param {Number} n データの分割数
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20038}
   */
  getSensorValues(sensorId, from, to, n) {
    return this.getSensorValuesWithHttpInfo(sensorId, from, to, n).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ステータスを確認可能なRoomの一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/InlineResponse20037>} and HTTP response
   */
  getStatusRoomsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [InlineResponse20037]

    return this.apiClient.callApi('/status/rooms', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ステータスを確認可能なRoomの一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/InlineResponse20037>}
   */
  getStatusRooms() {
    return this.getStatusRoomsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
