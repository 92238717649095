import AppDispatcher from '../../core/AppDispatcher'
import TransactionEvent from '../../event/TransactionEvent'

const onUpdateAccommodationTax = total => {
  AppDispatcher.dispatchWithType(TransactionEvent.onUpdateAccommodationTax, {
    value: total
  })

  return true
}

export default onUpdateAccommodationTax
