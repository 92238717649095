import history from '../../util/history'

const showDetailView = (reservationId, path) => {
  if (!path) {
    history.push(`/reservation/${reservationId}/detail`)
  } else {
    history.push(path)
  }

  return true
}

export default showDetailView
