/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The CheckIn model module.
 * @module model/CheckIn
 * @version 1.0.0
 */
export default class CheckIn {
  /**
   * Constructs a new <code>CheckIn</code>.
   * @alias module:model/CheckIn
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>CheckIn</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CheckIn} obj Optional instance to populate.
   * @return {module:model/CheckIn} The populated <code>CheckIn</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CheckIn()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('gender')) {
        obj['gender'] = ApiClient.convertToType(data['gender'], 'String')
      }
      if (data.hasOwnProperty('birthmonth')) {
        obj['birthmonth'] = ApiClient.convertToType(data['birthmonth'], 'String')
      }
      if (data.hasOwnProperty('birthday')) {
        obj['birthday'] = ApiClient.convertToType(data['birthday'], 'String')
      }
      if (data.hasOwnProperty('age')) {
        obj['age'] = ApiClient.convertToType(data['age'], 'Number')
      }
      if (data.hasOwnProperty('phone')) {
        obj['phone'] = ApiClient.convertToType(data['phone'], 'String')
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String')
      }
      if (data.hasOwnProperty('prefecture_id')) {
        obj['prefecture_id'] = ApiClient.convertToType(data['prefecture_id'], 'String')
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = ApiClient.convertToType(data['address'], 'String')
      }
      if (data.hasOwnProperty('business')) {
        obj['business'] = ApiClient.convertToType(data['business'], 'String')
      }
      if (data.hasOwnProperty('nation')) {
        obj['nation'] = ApiClient.convertToType(data['nation'], 'String')
      }
      if (data.hasOwnProperty('arrival')) {
        obj['arrival'] = ApiClient.convertToType(data['arrival'], 'String')
      }
      if (data.hasOwnProperty('departure')) {
        obj['departure'] = ApiClient.convertToType(data['departure'], 'String')
      }
      if (data.hasOwnProperty('passport_url')) {
        obj['passport_url'] = ApiClient.convertToType(data['passport_url'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {String} gender
   */
  gender = undefined
  /**
   * @member {String} birthmonth
   */
  birthmonth = undefined
  /**
   * @member {String} birthday
   */
  birthday = undefined
  /**
   * @member {Number} age
   */
  age = undefined
  /**
   * @member {String} phone
   */
  phone = undefined
  /**
   * @member {String} email
   */
  email = undefined
  /**
   * @member {String} prefecture_id
   */
  prefecture_id = undefined
  /**
   * @member {String} address
   */
  address = undefined
  /**
   * @member {String} business
   */
  business = undefined
  /**
   * @member {String} nation
   */
  nation = undefined
  /**
   * @member {String} arrival
   */
  arrival = undefined
  /**
   * @member {String} departure
   */
  departure = undefined
  /**
   * @member {String} passport_url
   */
  passport_url = undefined
}
