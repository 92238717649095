import { appActions } from '../AppActions'
import StripeApiKeyRepository from '../../infra/StripeApiKeyRepository'

const onRequestUpdate = async state => {
  const res = await StripeApiKeyRepository.requestUpdate(state).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '更新に失敗しました')
    return false
  }

  appActions.snackbar('更新しました', 'success', true)
  return true
}

export default onRequestUpdate
