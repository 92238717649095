import { appActions } from '../AppActions'
import reservationRepository from '../../infra/ReservationRepository'
import ReservationEvent from '../../event/ReservationEvent'

const onRequestUpdate = async reservation => {
  const result = await reservationRepository.updateRequest(ReservationEvent.requestSave, () => reservationRepository.requestUpdate(reservation), '更新に失敗しました')

  if (result) appActions.snackbar('更新しました', 'success', true)

  return true
}

export default onRequestUpdate
