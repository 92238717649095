import AppDispatcher from '../../core/AppDispatcher'
import HotelRepository from '../../infra/HotelRepository'
import { appActions } from '../AppActions'
import RoomEvent from '../../event/RoomEvent'

export default async hotelId => {
  const response = await HotelRepository.fetchRoomsById(hotelId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(RoomEvent.fetchAll, { rooms: response })
  return true
}
