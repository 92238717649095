/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import SmtpSetting from '../model/SmtpSetting'
import SmtpSettingBody from '../model/SmtpSettingBody'
import SmtpSettingBody1 from '../model/SmtpSettingBody1'

/**
 * Mail service.
 * @module api/MailApi
 * @version 1.0.0
 */
export default class MailApi {
  /**
   * Constructs a new MailApi.
   * @alias module:api/MailApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * SMTP設定を新規登録する
   * @param {module:model/SmtpSettingBody} smtpSettingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SmtpSetting} and HTTP response
   */
  createSmtpSettingWithHttpInfo(smtpSettingBody) {
    let postBody = smtpSettingBody

    // verify the required parameter 'smtpSettingBody' is set
    if (smtpSettingBody === undefined || smtpSettingBody === null) {
      throw new Error("Missing the required parameter 'smtpSettingBody' when calling createSmtpSetting")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = SmtpSetting

    return this.apiClient.callApi('/mail/smtp_settings', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * SMTP設定を新規登録する
   * @param {module:model/SmtpSettingBody} smtpSettingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SmtpSetting}
   */
  createSmtpSetting(smtpSettingBody) {
    return this.createSmtpSettingWithHttpInfo(smtpSettingBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * SMTP設定を削除する
   * @param {Number} smtpSettingId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteSmtpSettingWithHttpInfo(smtpSettingId) {
    let postBody = null

    // verify the required parameter 'smtpSettingId' is set
    if (smtpSettingId === undefined || smtpSettingId === null) {
      throw new Error("Missing the required parameter 'smtpSettingId' when calling deleteSmtpSetting")
    }

    let pathParams = {
      smtp_setting_id: smtpSettingId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/mail/smtp_settings/{smtp_setting_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * SMTP設定を削除する
   * @param {Number} smtpSettingId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteSmtpSetting(smtpSettingId) {
    return this.deleteSmtpSettingWithHttpInfo(smtpSettingId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * SMTP詳細を取得する
   * @param {Number} smtpSettingId Target Smtp Setting id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SmtpSetting} and HTTP response
   */
  getSmtpSettingWithHttpInfo(smtpSettingId) {
    let postBody = null

    // verify the required parameter 'smtpSettingId' is set
    if (smtpSettingId === undefined || smtpSettingId === null) {
      throw new Error("Missing the required parameter 'smtpSettingId' when calling getSmtpSetting")
    }

    let pathParams = {
      smtp_setting_id: smtpSettingId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = SmtpSetting

    return this.apiClient.callApi('/mail/smtp_settings/{smtp_setting_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * SMTP詳細を取得する
   * @param {Number} smtpSettingId Target Smtp Setting id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SmtpSetting}
   */
  getSmtpSetting(smtpSettingId) {
    return this.getSmtpSettingWithHttpInfo(smtpSettingId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * SMTP設定一覧を返す
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SmtpSetting>} and HTTP response
   */
  getSmtpSettingsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [SmtpSetting]

    return this.apiClient.callApi('/mail/smtp_settings', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * SMTP設定一覧を返す
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SmtpSetting>}
   */
  getSmtpSettings() {
    return this.getSmtpSettingsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * SMTP設定を更新する
   * @param {Number} smtpSettingId Target Smtp Setting id
   * @param {module:model/SmtpSettingBody1} smtpSettingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateSmtpSettingWithHttpInfo(smtpSettingId, smtpSettingBody) {
    let postBody = smtpSettingBody

    // verify the required parameter 'smtpSettingId' is set
    if (smtpSettingId === undefined || smtpSettingId === null) {
      throw new Error("Missing the required parameter 'smtpSettingId' when calling updateSmtpSetting")
    }

    // verify the required parameter 'smtpSettingBody' is set
    if (smtpSettingBody === undefined || smtpSettingBody === null) {
      throw new Error("Missing the required parameter 'smtpSettingBody' when calling updateSmtpSetting")
    }

    let pathParams = {
      smtp_setting_id: smtpSettingId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/mail/smtp_settings/{smtp_setting_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * SMTP設定を更新する
   * @param {Number} smtpSettingId Target Smtp Setting id
   * @param {module:model/SmtpSettingBody1} smtpSettingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updateSmtpSetting(smtpSettingId, smtpSettingBody) {
    return this.updateSmtpSettingWithHttpInfo(smtpSettingId, smtpSettingBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
