import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'

// 削除確認ダイアログを閉じる
const closeDeletionDialog = (event, index) => {
  event.stopPropagation()
  AppDispatcher.dispatchWithType(CheckInEvent.closeDeletionDialog, {
    index: index
  })
  return true
}

export default closeDeletionDialog
