import history from '../../../util/history'

const hideDetailView = date => {
  if (!history.canGoBack()) {
    if (date) {
      history.push(`/monthly/accommodation_tax/date/${date}`)
      return true
    }

    history.push('/monthly/accommodation_tax')
    return false
  }

  history.goBack()

  return true
}

export default hideDetailView
