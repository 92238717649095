/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The TransactionCommission model module.
 * @module model/TransactionCommission
 * @version 1.0.0
 */
export default class TransactionCommission {
  /**
   * Constructs a new <code>TransactionCommission</code>.
   * @alias module:model/TransactionCommission
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>TransactionCommission</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TransactionCommission} obj Optional instance to populate.
   * @return {module:model/TransactionCommission} The populated <code>TransactionCommission</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TransactionCommission()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('transaction_head_id')) {
        obj['transaction_head_id'] = ApiClient.convertToType(data['transaction_head_id'], 'Number')
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = ApiClient.convertToType(data['amount'], 'Number')
      }
      if (data.hasOwnProperty('currency')) {
        obj['currency'] = ApiClient.convertToType(data['currency'], 'String')
      }
      if (data.hasOwnProperty('payment_code')) {
        obj['payment_code'] = ApiClient.convertToType(data['payment_code'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} transaction_head_id
   */
  transaction_head_id = undefined
  /**
   * @member {Number} amount
   */
  amount = undefined
  /**
   * @member {String} currency
   */
  currency = undefined
  /**
   * @member {String} payment_code
   */
  payment_code = undefined
}
