/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The DailyClosingSummaryOtaComsnOta model module.
 * @module model/DailyClosingSummaryOtaComsnOta
 * @version 1.0.0
 */
export default class DailyClosingSummaryOtaComsnOta {
  /**
   * Constructs a new <code>DailyClosingSummaryOtaComsnOta</code>.
   * @alias module:model/DailyClosingSummaryOtaComsnOta
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>DailyClosingSummaryOtaComsnOta</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DailyClosingSummaryOtaComsnOta} obj Optional instance to populate.
   * @return {module:model/DailyClosingSummaryOtaComsnOta} The populated <code>DailyClosingSummaryOtaComsnOta</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyClosingSummaryOtaComsnOta()

      if (data.hasOwnProperty('site_referrer_id')) {
        obj['site_referrer_id'] = ApiClient.convertToType(data['site_referrer_id'], 'Number')
      }
      if (data.hasOwnProperty('label')) {
        obj['label'] = ApiClient.convertToType(data['label'], 'String')
      }
      if (data.hasOwnProperty('ota_comsn')) {
        obj['ota_comsn'] = ApiClient.convertToType(data['ota_comsn'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} site_referrer_id
   */
  site_referrer_id = undefined
  /**
   * @member {String} label
   */
  label = undefined
  /**
   * @member {Number} ota_comsn
   */
  ota_comsn = undefined
}
