/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The SiteReferrersSiteReferrers model module.
 * @module model/SiteReferrersSiteReferrers
 * @version 1.0.0
 */
export default class SiteReferrersSiteReferrers {
  /**
   * Constructs a new <code>SiteReferrersSiteReferrers</code>.
   * @alias module:model/SiteReferrersSiteReferrers
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>SiteReferrersSiteReferrers</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SiteReferrersSiteReferrers} obj Optional instance to populate.
   * @return {module:model/SiteReferrersSiteReferrers} The populated <code>SiteReferrersSiteReferrers</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SiteReferrersSiteReferrers()

      if (data.hasOwnProperty('available_type')) {
        obj['available_type'] = ApiClient.convertToType(data['available_type'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {module:model/SiteReferrersSiteReferrers.AvailableTypeEnum} available_type
   */
  available_type = undefined

  /**
   * Allowed values for the <code>available_type</code> property.
   * @enum {String}
   * @readonly
   */
  static AvailableTypeEnum = {
    /**
     * value: "closing"
     * @const
     */
    closing: 'closing',

    /**
     * value: "auto_action"
     * @const
     */
    auto_action: 'auto_action',

    /**
     * value: "reservation"
     * @const
     */
    reservation: 'reservation'
  }
}
