import { appActions } from '../AppActions'
import accommodationTaxRepository from '../../infra/AccommodationTaxRepository'
import ReservationAction from '../reservation'

const onRequestDelete = async (reservationId, accommodationTaxId) => {
  const response = await accommodationTaxRepository.requestDelete(reservationId, accommodationTaxId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '削除できませんでした')
    return false
  }

  appActions.snackbar('削除しました', 'success', true)
  ReservationAction.fetchOne(reservationId)
  window.location.reload()
  return true
}

export default onRequestDelete
