import fetchAll from './fetchAll'
import fetchOne from './fetchOne'
import fetchRoomsById from './fetchRoomsById'
import onEdit from './onEdit'
import onRequestDelete from './onRequestDelete'
import onRequestUpdate from './onRequestUpdate'
import onUpload from './onUpload'
import showDetailView from './showDetailView'
import hideDetailView from './hideDetailView'
import toRoomEditView from './toRoomEditView'
import toEditView from './toEditView'
import onClear from './onClear'
import onCancel from './onCancel'

const HotelAction = {
  fetchAll,
  fetchOne,
  fetchRoomsById,
  onEdit,
  toEditView,
  toRoomEditView,
  hideDetailView,
  showDetailView,
  onRequestDelete,
  onRequestUpdate,
  onUpload,
  onClear,
  onCancel
}

export default HotelAction
