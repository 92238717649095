/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationTax from './AccommodationTax'
import Reservation from './Reservation'

/**
 * The InlineResponse20026 model module.
 * @module model/InlineResponse20026
 * @version 1.0.0
 */
export default class InlineResponse20026 {
  /**
   * Constructs a new <code>InlineResponse20026</code>.
   * @alias module:model/InlineResponse20026
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20026</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20026} obj Optional instance to populate.
   * @return {module:model/InlineResponse20026} The populated <code>InlineResponse20026</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20026()

      if (data.hasOwnProperty('daily_closing_head')) {
        obj['daily_closing_head'] = AccommodationTax.constructFromObject(data['daily_closing_head'])
      }
      if (data.hasOwnProperty('reservation')) {
        obj['reservation'] = Reservation.constructFromObject(data['reservation'])
      }
    }
    return obj
  }

  /**
   * @member {module:model/AccommodationTax} daily_closing_head
   */
  daily_closing_head = undefined
  /**
   * @member {module:model/Reservation} reservation
   */
  reservation = undefined
}
