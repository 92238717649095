const MonthlyClosingEvent = {
  addDetail: 'monthly-closing/add-detail',
  fetchAll: 'monthly-closing/fetch-all',
  fetchOne: 'monthly-closing/fetch-one',
  fetchSummary: 'monthly-closing/fetch-summary',
  onClear: 'monthly-closing/on-clear',
  onDeleteDetail: 'monthly-closing/on-delete-detail',
  onEdit: 'monthly-closing/on-edit',
  onEditDetail: 'monthly-closing/on-edit-detail',
  showDetail: 'monthly-closing/show-detail',
  monthChanged: 'monthly-closing/month-changed'
}

export default MonthlyClosingEvent
