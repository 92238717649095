import AppDispatcher from '../../core/AppDispatcher'
import ReservationEvent from '../../event/ReservationEvent'

const onEditSearchCondition = event => {
  const name = event.target.name
  let value = event.target.value

  switch (name) {
    case 'search_status_booked':
    case 'search_status_cancelled':
    case 'search_status_not_a_reservation':
    case 'search_noshow':
      value = event.target.checked
      break
    default:
      break
  }

  AppDispatcher.dispatchWithType(ReservationEvent.onEditSearchCondition, { name: name, value: value })

  return true
}

export default onEditSearchCondition
