import AppDispatcher from '../../core/AppDispatcher'
import HotelRepository from '../../infra/HotelRepository'
import { appActions } from '../AppActions'
import HotelEvent from '../../event/HotelEvent'

const fetchOne = async hotelId => {
  const response = await HotelRepository.fetchOne(hotelId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(HotelEvent.fetchOne, { hotel: response })
  return true
}

export default fetchOne
