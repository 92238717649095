import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import { PlanSearchState } from '../state/PlanSearchState'
import PlanEvent from '../../event/PlanEvent'

class PlanSearchStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new PlanSearchState()
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case PlanEvent.onEditSearchCondition:
        return state.set(payload.name, payload.value)
      default:
        return state
    }
  }
}

export default new PlanSearchStore()
