/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import SegmentCategoryOptionInner from './SegmentCategoryOptionInner'

/**
 * The SegmentCategoryOption model module.
 * @module model/SegmentCategoryOption
 * @version 1.0.0
 */
export default class SegmentCategoryOption extends Array {
  /**
   * Constructs a new <code>SegmentCategoryOption</code>.
   * @alias module:model/SegmentCategoryOption
   * @class
   * @extends Array
   */

  constructor() {
    super()

    return this
  }

  /**
   * Constructs a <code>SegmentCategoryOption</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SegmentCategoryOption} obj Optional instance to populate.
   * @return {module:model/SegmentCategoryOption} The populated <code>SegmentCategoryOption</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SegmentCategoryOption()

      ApiClient.constructFromObject(data, obj, 'SegmentCategoryOptionInner')
    }
    return obj
  }
}
