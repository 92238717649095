import AppDispatcher from '../../../core/AppDispatcher'
import history from '../../../util/history'
import ReportCheckInEvent from '../../../event/MonthlyReportEvent'

const onEdit = (month, hotel_id) => {
  AppDispatcher.dispatchWithType(ReportCheckInEvent.onEditCheckIn, {
    name: 'search_hotel_id',
    value: hotel_id
  })

  if (month) {
    history.push(`/report/check_in/date/${month}`)
  } else {
    history.push(`/report/check_in`)
  }

  return true
}

export default onEdit
