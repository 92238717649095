import history from '../../util/history'
import { dateToParam } from '../../util/DateString'

const monthChange = date => {
  history.push('/calendar/' + dateToParam(date))

  return true
}

export default monthChange
