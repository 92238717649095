import fetchOne from './fetchOne'
import onCancelCreation from './onCancelCreation'
import onEdit from './onEdit'
import onInit from './onInit'
import onRequestDelete from './onRequestDelete'
import onRequestSave from './onRequestSave'
import onRequestUpdateStatus from './onRequestUpdateStatus'
import onSearch from './onSearch'
import onEditSearchCondition from './onEditSearchCondition'
import showDetail from './showDetail'
import showNew from './showNew'

const TaskAction = {
  fetchOne,
  onCancelCreation,
  onEdit,
  onInit,
  onRequestDelete,
  onRequestSave,
  onRequestUpdateStatus,
  onSearch,
  onEditSearchCondition,
  showNew,
  showDetail
}

export default TaskAction
