import React from 'react'

function Status(props) {
  const { type } = props

  let style = {
    display: 'inline',
    minWidth: '37px',
    height: '19px',
    borderRadius: '15px',
    padding: '2px 6px',
    fontFamily: 'NotoSansCJKjp, Lato, "Hiragino Kaku Gothic ProN", Meiryo, sans - serif',
    fontSize: '12px',
    textAlign: 'left'
  }
  switch (type) {
    case 'cancelled':
      style = { ...style, backgroundColor: '#EDEEEF', color: '#475460' }
      break
    case 'booked':
      style = { ...style, backgroundColor: '#E1F2FE', color: '#0072DD' }
      break
    case 'not_a_reservation':
      style = { ...style, backgroundColor: '#475460', color: '#FFFFFF' }
      break
  }

  return <span style={style}>{props.children}</span>
}

function NoShow(props) {
  let style = {
    display: 'inline',
    minWidth: '37px',
    height: '19px',
    borderRadius: '15px',
    padding: '2px 6px',
    fontFamily: 'NotoSansCJKjp, Lato, "Hiragino Kaku Gothic ProN", Meiryo, sans - serif',
    fontSize: '12px',
    textAlign: 'left',
    backgroundColor: '#475460',
    color: '#FFFFFF'
  }

  return <span style={style}>{props.children}</span>
}

export { Status, NoShow }
