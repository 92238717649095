import React from 'react'
import './OtaLogo.scss'

function OtaLogo(props) {
  const value = props.value

  const siteRefererToOta = {
    'Booking.com': 'booking',
    Expedia: 'expedia',
    'Airbnb.com': 'airbnb',
    'Hotels.com': 'hotels',
    Ctrip: 'ctrip',
    '一休.com': 'ikyu',
    一休com: 'ikyu',
    一休: 'ikyu',
    ikyu: 'ikyu',
    楽天トラベル: 'rakuten',
    Rakuten: 'rakuten',
    rakuten: 'rakuten',
    じゃらん: 'jalan',
    jalan: 'jalan',
    Hafh: 'hafh'
  }
  const ota = siteRefererToOta[value] || 'ota'
  const className = props.className || ''
  return <span className={`OtaLogo OtaLogo-${ota} ${className}`} />
}

export default OtaLogo
