import RoomEvent from '../../event/RoomEvent'
import AppDispatcher from '../../core/AppDispatcher'
import roomRepository from '../../infra/RoomRepository'
import { appActions } from '../AppActions'

const fetchStatus = async () => {
  const response = await roomRepository.fetchStatus().catch(e => e)
  if (response === false) {
    appActions.snackbar('取得できませんした', 'error', true)
    return false
  }

  AppDispatcher.dispatchWithType(RoomEvent.getStatus, response)
  return true
}

export default fetchStatus
