import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import AccountTitleEvent from '../../event/AccountTitleEvent'

const onCancelEditView = async () => {
  AppDispatcher.dispatchWithType(AccountTitleEvent.onClear)
  history.goBack()

  return true
}

export default onCancelEditView
