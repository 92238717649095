import fetchAll from './fetchAll'
import fetchOne from './fetchOne'
import onCancel from './onCancel'
import onClear from './onClear'
import onCreate from './onCreate'
import onDelete from './onDelete'
import onDragItem from './onDragItem'
import onDropItem from './onDropItem'
import onEdit from './onEdit'
import onUpdate from './onUpdate'

const RoomManualAction = {
  fetchAll,
  fetchOne,
  onCancel,
  onClear,
  onCreate,
  onDelete,
  onDragItem,
  onDropItem,
  onEdit,
  onUpdate
}

export default RoomManualAction
