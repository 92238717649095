import ApiClient from '../ApiClient'
import MonthlyReportApi from '../api/MonthlyReportApi'

export class MonthlyReportRepository {
  constructor() {
    this._api = new MonthlyReportApi(ApiClient.instance)
  }

  fetchAccommodationTax(date, hotel_ids) {
    const body = { search_check_in_date: date.beginningOfMonth().toISODate(), search_hotel_ids: hotel_ids }
    return this._api.searchAccommodationTax({ monthly_report: body })
  }

  fetchAccommodationTaxDetail(accommodation_tax_id) {
    return this._api.getDailyDlosingsOfAccommodationTax(accommodation_tax_id)
  }

  fetchMonthlySales(date, hotel_id) {
    const body = { search_date: date.beginningOfMonth().toISODate(), search_hotel_id: hotel_id }
    return this._api.searchMonthlySales({ monthly_sales: body })
  }

  fetchCheckIn(date, hotel_id) {
    const body = { search_date: date.beginningOfMonth().toISODate(), search_hotel_id: hotel_id }
    return this._api.searchMonthlyCheckIn({ search_condition: body })
  }
}

// singleton
export default new MonthlyReportRepository()
