import ApiClient from '../ApiClient'
import ActivityApi from '../api/ActivityApi'

export class ActivityRepository {
  constructor() {
    this._api = new ActivityApi(ApiClient.instance)
  }

  onSearch(pagination) {
    const body = { pagination: pagination }
    return this._api.searchActivity(body)
  }
}

// singleton
export default new ActivityRepository()
