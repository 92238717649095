import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onSearch = async (condition, pagination) => {
  const response = await repository.fetchBySearchCondition(condition, pagination).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '検索に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onSearch, {
    invoices: response.invoices,
    pagination: response.pagination
  })

  return true
}

export default onSearch
