import AppDispatcher from '../../core/AppDispatcher'
import ReservationSegmentEvent from '../../event/ReservationSegmentEvent'

const onEdit = (segment_category_id, segment_value_id) => {
  AppDispatcher.dispatchWithType(ReservationSegmentEvent.onEdit, {
    segment_category_id: segment_category_id,
    segment_value_id: segment_value_id
  })
}

export default onEdit
