class Status {
  constructor() {
    this.NONE = 'none'
    this.SAVED = 'saved'
    this.CLOSED = 'closed'
  }
  statusLabels = {
    none: '未処理',
    saved: '保存済',
    closed: '締め済'
  }

  getCode = status => {
    if (!status) {
      return this.NONE
    }

    return status
  }

  getLabel = status => {
    return this.statusLabels[status] || '未処理'
  }

  markAsClosed = record => {
    return record.set('status', this.CLOSED)
  }

  markAsSaved = record => {
    return record.set('status', this.SAVED)
  }

  isClosed = status => {
    return this.CLOSED === status
  }

  isNone = status => {
    return !status || this.NONE === status
  }
}

export default new Status()
