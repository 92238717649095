import ApiHandle from '../../util/ApiHandle'
import history from '../../util/history'
import { appActions } from '../AppActions'
import roomRepository from '../../infra/RoomRepository'

const onRequestDelete = async roomId => {
  const response = await roomRepository.requestDelete(roomId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '宿の削除に失敗しました')
    return false
  }

  history.push('/setting')
  appActions.snackbar('宿を削除しました', 'success', true)
  return true
}

export default onRequestDelete
