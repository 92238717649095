import { appActions } from '../AppActions'
import ReservationRepository from '../../infra/ReservationRepository'

const onRequestSave = async (reservation_id, reservation_segments) => {
  const res = await ReservationRepository.requestSaveSegment(reservation_id, reservation_segments).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '更新に失敗しました')
    return false
  }

  appActions.snackbar('更新しました', 'success', true)
  return true
}

export default onRequestSave
