import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const setDailyClosingReservations = async daily_closing_reservation => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.setDailyClosingReservations, { daily_closing_reservation: daily_closing_reservation })

  return true
}

export default setDailyClosingReservations
