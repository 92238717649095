import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'
import ClosingStatus from '../../domain/model/closing/Status'

const onRequestUpdate = async (id, nextStatus, head, details, deletedDetailIds, dailyClosingReservations, deletedReservationIds) => {
  const _head = ClosingStatus.CLOSED === nextStatus ? ClosingStatus.markAsClosed(head) : ClosingStatus.markAsSaved(head)

  const res = await dailyClosingRepository.requestUpdate(id, _head, details, deletedDetailIds, dailyClosingReservations, deletedReservationIds).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '更新できませんでした')
    return false
  }

  appActions.snackbar('更新しました', 'success', true)
  AppDispatcher.dispatchWithType(DailyClosingEvent.onClear)

  history.replace(`/daily/id/${id}/edit`)

  return true
}

export default onRequestUpdate
