import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import { dateToParam } from '../../util/DateString'
import ReservationEvent from '../../event/ReservationEvent'

const dateChange = date => {
  AppDispatcher.dispatchWithType(ReservationEvent.dateChange, {
    date: date
  })
  history.push('/receptionist/' + dateToParam(date))

  return true
}

export default dateChange
