import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import AccountTitleDetailState from '../state/AccountTitleDetailState'
import AccountTitleDetailEvent from '../../event/AccountTitleDetailEvent'
import { List } from 'immutable'

class AccountTitleDetailStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return List.of([])
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case AccountTitleDetailEvent.fetchDetails:
        const details = payload.details.map(detail => new AccountTitleDetailState(detail))
        return List(details)
      default:
        return state
    }
  }
}

export default new AccountTitleDetailStore()
