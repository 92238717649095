import history from '../../util/history'

const toEditView = (head_id, hotel_id, date) => {
  if (head_id) {
    history.push(`/monthly/${head_id}/edit`)
  } else {
    history.push(`/monthly/new/${hotel_id}/${date}`)
  }

  return true
}

export default toEditView
