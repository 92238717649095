import monthlyClosingRepository from '../../infra/MonthlyClosingRepository'
import { appActions } from '../AppActions'
import history from '../../util/history'

const onRequestCreate = async (status, head, details) => {
  const _head = head.set('status', status)
  const res = await monthlyClosingRepository.requestCreate(_head, details).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '登録できませんでした')
    return false
  }

  appActions.snackbar('作成しました', 'success', true)

  history.replace(`/monthly/${res.monthly_closing_head.id}/edit`)

  return true
}

export default onRequestCreate
