import history from '../../util/history'
import { appActions } from '../AppActions'
import EventScheduleRepository from '../../infra/EventScheduleRepository'

const onRequestDelete = async eventScheduleId => {
  const res = await EventScheduleRepository.requestDelete(eventScheduleId).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '削除に失敗しました')
    return false
  }

  appActions.snackbar('削除しました', 'success', true)

  if (!history.canGoBack()) {
    history.push('/')
    return false
  }
  history.goBack()

  return true
}

export default onRequestDelete
