import { appActions } from '../AppActions'
import ReservationAction from '../reservation'
import accommodationTaxRepository from '../../infra/AccommodationTaxRepository'

const onRequestCreate = async (reservationId, accommodationTax) => {
  const response = await accommodationTaxRepository.requestCreate(reservationId, accommodationTax).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }
  appActions.snackbar('更新しました', 'success', true)
  ReservationAction.fetchOne(reservationId)
  window.location.reload()

  return true
}

export default onRequestCreate
