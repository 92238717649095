const RoomEvent = {
  getStatus: 'rooms/fetch-status',
  fetchOne: 'room/fetch-room',
  fetchAll: 'rooms/fetch-all',
  fetchByUser: 'rooms/fetch-by-user',
  onCreate: 'room/on-create',
  onEdit: 'room/on-edit',
  onEditChannelManager: 'settingInfo/edit-channel-manager'
}

export default RoomEvent
