import ApiHandle from '../../util/ApiHandle'
import TabletEvent from '../../event/TabletEvent'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import tabletRepository from '../../infra/TabletRepository'
import TaskEvent from '../../event/TaskEvent'

const fetchAll = async roomId => {
  const response = await tabletRepository.fetchByRoomId(roomId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(TabletEvent.fetchAll, response)
  return true
}

export default fetchAll
