import ApiClient from '../ApiClient'
import AppRepository from './AppRepository'
import AccommodationInvoiceApi from '../api/AccommodationInvoiceApi'

class AccommodationInvoiceRepository extends AppRepository {
  constructor() {
    super()
    this._api = new AccommodationInvoiceApi(ApiClient.instance)
  }

  fetchOne(id) {
    return this._api.getAccommodationInvoice(id)
  }

  fetchBySearchCondition(condition, pagination) {
    const body = { accommodation_invoice: condition.toObject(), pagination: pagination.toObject() }
    return this._api.searchAccommodationInvoices(body)
  }

  updatePaid(invoiceId) {
    return this._api.updatePaid(invoiceId)
  }

  updateUncollectible(invoiceId) {
    return this._api.updateUncollectible(invoiceId)
  }

  updateInvoicing(invoiceId) {
    return this._api.updateInvoicing(invoiceId)
  }

  requestDelete(invoiceId) {
    return this._api.deleteAccommodationInvoice(invoiceId)
  }

  requestSave(reservationId, accommodationTax, accommodationInvoiceCreate) {
    return this._api.createAccommodationInvoice({
      reservation_id: reservationId,
      accommodation_tax: accommodationTax.toObject(),
      accommodation_invoice_create: accommodationInvoiceCreate.toObject()
    })
  }

  requestRefund(invoiceId, accommodationInvoiceRefund) {
    return this._api.refund(invoiceId, { accommodation_invoice_refund: accommodationInvoiceRefund.toObject() })
  }
}

// singleton
export default new AccommodationInvoiceRepository()
