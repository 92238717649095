import getPassport from './passport/getPassport'
import { appActions } from '../AppActions'

const getPassports = (token, checkIns) => {
  // When target is passport, upload passport datafile to S3 and receive URL, and dispatch this url
  if (!token) {
    appActions.snackbar('Error: Unable to send check-in form to the server. Please ask the staff to solve this problem.', 'error', true)
    return false
  }

  checkIns.map((c, index) => {
    if (c.passport_url) {
      const result = getPassport(token, c.passport_url, index + 1)
      if (result === false) {
        appActions.snackbar('Failed to get a passport file. パスポート画像の取得に失敗しました。', 'error', false)
      }
    }
  })
  return true
}

export default getPassports
