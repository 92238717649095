import ApiClient from '../ApiClient'
import ConfigApi from '../api/ConfigApi'

class TabletRepository {
  constructor() {
    this._api = new ConfigApi(ApiClient.instance)
  }

  fetchByRoomId(roomId) {
    return this._api.getConfigRoomTabletsDevice(roomId)
  }

  requestDeleteByUuid(uuid) {
    return this._api.deleteConfigRoomTabletsDevice(uuid)
  }
}

// singleton
export default new TabletRepository()
