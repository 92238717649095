const commissionRateSettings = {
  'Booking.com': { include: false, rate: 15, rounding: 'round' },
  Expedia: { include: true, rate: 15, rounding: 'round' },
  'Airbnb.com': { include: true, rate: 15, rounding: 'round' },
  'Hotels.com': { include: true, rate: 15, rounding: 'round' },
  Ctrip: { include: true, rate: 12, rounding: 'round' },
  '一休.com': { include: false, rate: 13.5, rounding: 'floor' },
  一休com: { include: false, rate: 13.5, rounding: 'floor' }
}

export { commissionRateSettings }
