/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import ReservationSale from './ReservationSale'
import TransactionCommission from './TransactionCommission'
import TransactionHead from './TransactionHead'

/**
 * The InlineResponse2007 model module.
 * @module model/InlineResponse2007
 * @version 1.0.0
 */
export default class InlineResponse2007 {
  /**
   * Constructs a new <code>InlineResponse2007</code>.
   * @alias module:model/InlineResponse2007
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse2007</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2007} obj Optional instance to populate.
   * @return {module:model/InlineResponse2007} The populated <code>InlineResponse2007</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2007()

      if (data.hasOwnProperty('reservation_sales')) {
        obj['reservation_sales'] = ApiClient.convertToType(data['reservation_sales'], [ReservationSale])
      }
      if (data.hasOwnProperty('transaction_heads')) {
        obj['transaction_heads'] = ApiClient.convertToType(data['transaction_heads'], [TransactionHead])
      }
      if (data.hasOwnProperty('transaction_commissions')) {
        obj['transaction_commissions'] = ApiClient.convertToType(data['transaction_commissions'], [TransactionCommission])
      }
    }
    return obj
  }

  /**
   * @member {Array.<module:model/ReservationSale>} reservation_sales
   */
  reservation_sales = undefined
  /**
   * @member {Array.<module:model/TransactionHead>} transaction_heads
   */
  transaction_heads = undefined
  /**
   * @member {Array.<module:model/TransactionCommission>} transaction_commissions
   */
  transaction_commissions = undefined
}
