import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onDeleteDetail = (event, reservation_id) => {
  event.preventDefault()

  if (window.confirm(`この日次締めデータから宿泊予約ID[${reservation_id}]を削除します。よろしいですか？`))
    AppDispatcher.dispatchWithType(DailyClosingEvent.onDeleteReservation, { reservation_id: reservation_id })

  return true
}

export default onDeleteDetail
