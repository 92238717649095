import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../../action/AppActions'

const fetchDate = async (date, state) => {
  const res = await dailyClosingRepository.fetchBySearchCondition(date, state).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(DailyClosingEvent.dateChange, { date: date, room_ids: state.search_room_ids, result: res })

  return true
}

export default fetchDate
