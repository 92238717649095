import AppDispatcher from '../../../core/AppDispatcher'
import ReservationEvent from '../../../event/ReservationEvent'

const onEditSearchCondition = (name, value) => {
  AppDispatcher.dispatchWithType(ReservationEvent.receptionistOnEditSearch, {
    name: name,
    value: value
  })

  return true
}

export default onEditSearchCondition
