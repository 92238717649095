import AppDispatcher from '../../core/AppDispatcher'
import reservationRepository from '../../infra/ReservationRepository'
import { appActions } from '../AppActions'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const getTransactionReservation = async (reservation, site_referrer_options) => {
  const response = await reservationRepository.fetchTransaction(reservation.id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    AppDispatcher.dispatchWithType(DailyClosingEvent.setReservation, {
      reservation: reservation,
      site_referrer_options: site_referrer_options,
      reservation_sales: null,
      transaction_heads: null,
      transaction_commissions: null
    })
    return false
  }

  AppDispatcher.dispatchWithType(DailyClosingEvent.setReservation, {
    reservation: reservation,
    site_referrer_options: site_referrer_options,
    reservation_sales: response.reservation_sales,
    transaction_heads: response.transaction_heads,
    transaction_commissions: response.transaction_commissions
  })
  return response
}

export default getTransactionReservation
