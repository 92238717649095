import 'react-app-polyfill/ie9'
import 'nprogress/nprogress.css'
import 'ui-library/dist/external/styles.css'
import './index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import AppContainer from './container/AppContainer'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<AppContainer />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
const config = {
  onUpdate: registration => {
    registration.update()
    window.location.reload(true)
  },
  onSuccess: registration => {
    registration.update()
    window.location.reload(true)
  }
}
// serviceWorker.register(config)
serviceWorker.unregister()
