import AppDispatcher from '../../core/AppDispatcher'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onEditAccommodationTax = (reservation, name, value) => {
  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onEditAccommodationTax, {
    reservation: reservation,
    name: name,
    value: value
  })

  return true
}

export default onEditAccommodationTax
