import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchPayments = async reservationId => {
  const response = await reservationRepository.fetchPayments(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '決済情報が取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchPayments, {
    payments: response
  })

  return true
}

export default fetchPayments
