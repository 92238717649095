import ApiClient from '../ApiClient'
import PlanApi from '../api/PlanApi'

export class PlanRepository {
  constructor() {
    this._api = new PlanApi(ApiClient.instance)
  }
  fetchOne(planId) {
    return this._api.getPlan(planId)
  }

  onSearch(pagination) {
    const body = { pagination: pagination }
    return this._api.searchPlan(body)
  }
}

// singleton
export default new PlanRepository()
