/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import ReservationAccommodationTax from './ReservationAccommodationTax'

/**
 * The Reservation model module.
 * @module model/Reservation
 * @version 1.0.0
 */
export default class Reservation {
  /**
   * Constructs a new <code>Reservation</code>.
   * @alias module:model/Reservation
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>Reservation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Reservation} obj Optional instance to populate.
   * @return {module:model/Reservation} The populated <code>Reservation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Reservation()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('room_id')) {
        obj['room_id'] = ApiClient.convertToType(data['room_id'], 'Number')
      }
      if (data.hasOwnProperty('hotel_id')) {
        obj['hotel_id'] = ApiClient.convertToType(data['hotel_id'], 'Number')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('stay')) {
        obj['stay'] = ApiClient.convertToType(data['stay'], 'String')
      }
      if (data.hasOwnProperty('key_code')) {
        obj['key_code'] = ApiClient.convertToType(data['key_code'], 'String')
      }
      if (data.hasOwnProperty('check_in')) {
        obj['check_in'] = ApiClient.convertToType(data['check_in'], 'Date')
      }
      if (data.hasOwnProperty('check_out')) {
        obj['check_out'] = ApiClient.convertToType(data['check_out'], 'Date')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('first_name')) {
        obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String')
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String')
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String')
      }
      if (data.hasOwnProperty('phone_number')) {
        obj['phone_number'] = ApiClient.convertToType(data['phone_number'], 'String')
      }
      if (data.hasOwnProperty('mobile_number')) {
        obj['mobile_number'] = ApiClient.convertToType(data['mobile_number'], 'String')
      }
      if (data.hasOwnProperty('num_adult')) {
        obj['num_adult'] = ApiClient.convertToType(data['num_adult'], 'Number')
      }
      if (data.hasOwnProperty('num_child')) {
        obj['num_child'] = ApiClient.convertToType(data['num_child'], 'Number')
      }
      if (data.hasOwnProperty('num_infant')) {
        obj['num_infant'] = ApiClient.convertToType(data['num_infant'], 'Number')
      }
      if (data.hasOwnProperty('accommodation_fee')) {
        obj['accommodation_fee'] = ApiClient.convertToType(data['accommodation_fee'], 'Number')
      }
      if (data.hasOwnProperty('commission')) {
        obj['commission'] = ApiClient.convertToType(data['commission'], 'Number')
      }
      if (data.hasOwnProperty('accommodation_tax')) {
        obj['accommodation_tax'] = ReservationAccommodationTax.constructFromObject(data['accommodation_tax'])
      }
      if (data.hasOwnProperty('guest_arrival_time')) {
        obj['guest_arrival_time'] = ApiClient.convertToType(data['guest_arrival_time'], 'String')
      }
      if (data.hasOwnProperty('site_referer')) {
        obj['site_referer'] = ApiClient.convertToType(data['site_referer'], 'String')
      }
      if (data.hasOwnProperty('reservation_code')) {
        obj['reservation_code'] = ApiClient.convertToType(data['reservation_code'], 'String')
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String')
      }
      if (data.hasOwnProperty('api_message')) {
        obj['api_message'] = ApiClient.convertToType(data['api_message'], 'String')
      }
      if (data.hasOwnProperty('guest_comment')) {
        obj['guest_comment'] = ApiClient.convertToType(data['guest_comment'], 'String')
      }
      if (data.hasOwnProperty('notes')) {
        obj['notes'] = ApiClient.convertToType(data['notes'], 'String')
      }
      if (data.hasOwnProperty('shared_notes')) {
        obj['shared_notes'] = ApiClient.convertToType(data['shared_notes'], 'String')
      }
      if (data.hasOwnProperty('checkin_status')) {
        obj['checkin_status'] = ApiClient.convertToType(data['checkin_status'], 'String')
      }
      if (data.hasOwnProperty('arrived_at')) {
        obj['arrived_at'] = ApiClient.convertToType(data['arrived_at'], 'Date')
      }
      if (data.hasOwnProperty('daily_closing_status')) {
        obj['daily_closing_status'] = ApiClient.convertToType(data['daily_closing_status'], 'String')
      }
      if (data.hasOwnProperty('bookid')) {
        obj['bookid'] = ApiClient.convertToType(data['bookid'], 'String')
      }
      if (data.hasOwnProperty('is_paid_accommodation_fee')) {
        obj['is_paid_accommodation_fee'] = ApiClient.convertToType(data['is_paid_accommodation_fee'], 'Boolean')
      }
      if (data.hasOwnProperty('is_paid_accommodation_tax')) {
        obj['is_paid_accommodation_tax'] = ApiClient.convertToType(data['is_paid_accommodation_tax'], 'Boolean')
      }
      if (data.hasOwnProperty('rate_description')) {
        obj['rate_description'] = ApiClient.convertToType(data['rate_description'], 'String')
      }
      if (data.hasOwnProperty('execute_invoice_automation')) {
        obj['execute_invoice_automation'] = ApiClient.convertToType(data['execute_invoice_automation'], 'String')
      }
      if (data.hasOwnProperty('form_count')) {
        obj['form_count'] = ApiClient.convertToType(data['form_count'], 'Number')
      }
      if (data.hasOwnProperty('channel_manager')) {
        obj['channel_manager'] = ApiClient.convertToType(data['channel_manager'], 'String')
      }
      if (data.hasOwnProperty('plan_code')) {
        obj['plan_code'] = ApiClient.convertToType(data['plan_code'], 'String')
      }
      if (data.hasOwnProperty('plan_name')) {
        obj['plan_name'] = ApiClient.convertToType(data['plan_name'], 'String')
      }
      if (data.hasOwnProperty('meal_condition')) {
        obj['meal_condition'] = ApiClient.convertToType(data['meal_condition'], 'String')
      }
      if (data.hasOwnProperty('meal_condition_pms')) {
        obj['meal_condition_pms'] = ApiClient.convertToType(data['meal_condition_pms'], 'String')
      }
      if (data.hasOwnProperty('meal_delivery')) {
        obj['meal_delivery'] = ApiClient.convertToType(data['meal_delivery'], 'String')
      }
      if (data.hasOwnProperty('noshow')) {
        obj['noshow'] = ApiClient.convertToType(data['noshow'], 'Boolean')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} room_id
   */
  room_id = undefined
  /**
   * @member {Number} hotel_id
   */
  hotel_id = undefined
  /**
   * @member {module:model/Reservation.StatusEnum} status
   */
  status = undefined
  /**
   * @member {String} stay
   */
  stay = undefined
  /**
   * @member {String} key_code
   */
  key_code = undefined
  /**
   * @member {Date} check_in
   */
  check_in = undefined
  /**
   * @member {Date} check_out
   */
  check_out = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {String} first_name
   */
  first_name = undefined
  /**
   * @member {String} last_name
   */
  last_name = undefined
  /**
   * @member {String} email
   */
  email = undefined
  /**
   * @member {String} phone_number
   */
  phone_number = undefined
  /**
   * @member {String} mobile_number
   */
  mobile_number = undefined
  /**
   * @member {Number} num_adult
   */
  num_adult = undefined
  /**
   * @member {Number} num_child
   */
  num_child = undefined
  /**
   * @member {Number} num_infant
   */
  num_infant = undefined
  /**
   * @member {Number} accommodation_fee
   */
  accommodation_fee = undefined
  /**
   * @member {Number} commission
   */
  commission = undefined
  /**
   * @member {module:model/ReservationAccommodationTax} accommodation_tax
   */
  accommodation_tax = undefined
  /**
   * @member {String} guest_arrival_time
   */
  guest_arrival_time = undefined
  /**
   * @member {String} site_referer
   */
  site_referer = undefined
  /**
   * @member {String} reservation_code
   */
  reservation_code = undefined
  /**
   * @member {String} country
   */
  country = undefined
  /**
   * @member {String} api_message
   */
  api_message = undefined
  /**
   * @member {String} guest_comment
   */
  guest_comment = undefined
  /**
   * @member {String} notes
   */
  notes = undefined
  /**
   * @member {String} shared_notes
   */
  shared_notes = undefined
  /**
   * @member {module:model/Reservation.CheckinStatusEnum} checkin_status
   */
  checkin_status = undefined
  /**
   * @member {Date} arrived_at
   */
  arrived_at = undefined
  /**
   * @member {module:model/Reservation.DailyClosingStatusEnum} daily_closing_status
   */
  daily_closing_status = undefined
  /**
   * @member {String} bookid
   */
  bookid = undefined
  /**
   * @member {Boolean} is_paid_accommodation_fee
   */
  is_paid_accommodation_fee = undefined
  /**
   * @member {Boolean} is_paid_accommodation_tax
   */
  is_paid_accommodation_tax = undefined
  /**
   * @member {String} rate_description
   */
  rate_description = undefined
  /**
   * @member {String} execute_invoice_automation
   */
  execute_invoice_automation = undefined
  /**
   * @member {Number} form_count
   */
  form_count = undefined
  /**
   * @member {String} channel_manager
   */
  channel_manager = undefined
  /**
   * @member {String} plan_code
   */
  plan_code = undefined
  /**
   * @member {String} plan_name
   */
  plan_name = undefined
  /**
   * @member {String} meal_condition
   */
  meal_condition = undefined
  /**
   * @member {String} meal_condition_pms
   */
  meal_condition_pms = undefined
  /**
   * @member {String} meal_delivery
   */
  meal_delivery = undefined
  /**
   * @member {Boolean} noshow
   */
  noshow = undefined

  /**
   * Allowed values for the <code>status</code> property.
   * @enum {String}
   * @readonly
   */
  static StatusEnum = {
    /**
     * value: "cancelled"
     * @const
     */
    cancelled: 'cancelled',

    /**
     * value: "booked"
     * @const
     */
    booked: 'booked',

    /**
     * value: "not_a_reservation"
     * @const
     */
    not_a_reservation: 'not_a_reservation'
  }

  /**
   * Allowed values for the <code>checkin_status</code> property.
   * @enum {String}
   * @readonly
   */
  static CheckinStatusEnum = {
    /**
     * value: "not_yet"
     * @const
     */
    not_yet: 'not_yet',

    /**
     * value: "only_checkin"
     * @const
     */
    only_checkin: 'only_checkin',

    /**
     * value: "form_wrote"
     * @const
     */
    form_wrote: 'form_wrote',

    /**
     * value: "no_show"
     * @const
     */
    no_show: 'no_show',

    /**
     * value: "checkin_completed"
     * @const
     */
    checkin_completed: 'checkin_completed'
  }

  /**
   * Allowed values for the <code>daily_closing_status</code> property.
   * @enum {String}
   * @readonly
   */
  static DailyClosingStatusEnum = {
    /**
     * value: "saved"
     * @const
     */
    saved: 'saved',

    /**
     * value: "closed"
     * @const
     */
    closed: 'closed'
  }
}
