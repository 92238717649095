/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationTax from './AccommodationTax'
import Reservation from './Reservation'

/**
 * The InlineResponse20025 model module.
 * @module model/InlineResponse20025
 * @version 1.0.0
 */
export default class InlineResponse20025 {
  /**
   * Constructs a new <code>InlineResponse20025</code>.
   * @alias module:model/InlineResponse20025
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20025</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20025} obj Optional instance to populate.
   * @return {module:model/InlineResponse20025} The populated <code>InlineResponse20025</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20025()

      if (data.hasOwnProperty('accommodation_tax')) {
        obj['accommodation_tax'] = AccommodationTax.constructFromObject(data['accommodation_tax'])
      }
      if (data.hasOwnProperty('reservation')) {
        obj['reservation'] = Reservation.constructFromObject(data['reservation'])
      }
    }
    return obj
  }

  /**
   * @member {module:model/AccommodationTax} accommodation_tax
   */
  accommodation_tax = undefined
  /**
   * @member {module:model/Reservation} reservation
   */
  reservation = undefined
}
