import fetchAll from './fetchAll'
import fetchOne from './fetchOne'
import fetchStatus from './fetchStatus'
import fetchByUser from './fetchByUser'
import onClear from './onClear'
import onCreate from './onCreate'
import onEdit from './onEdit'
import onEditChannelManager from './onEditChannelManager'
import onRequestCreate from './onRequestCreate'
import onRequestDelete from './onRequestDelete'
import onRequestUpdate from './onRequestUpdate'
import onRequestSyncInventory from './onRequestSyncInventory'
import onUpdate from './onUpdate'
import onUpload from './onUpload'

const RoomAction = {
  fetchAll,
  fetchOne,
  fetchStatus,
  fetchByUser,
  onClear,
  onCreate,
  onEdit,
  onEditChannelManager,
  onRequestCreate,
  onRequestDelete,
  onRequestUpdate,
  onRequestSyncInventory,
  onUpdate,
  onUpload
}

export default RoomAction
