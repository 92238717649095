import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchOne = async reservationId => {
  const response = await reservationRepository.fetchOne(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchOne, {
    reservation: response
  })
  return true
}

export default fetchOne
