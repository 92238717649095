import React from 'react'
import { BaseLayout, LocalAreaLayout, ListViewLayout } from 'ui-library/dist/external/layouts'
import { DataTable, DataTableColumn as TD, DataTableHeader as TH, DataTableRow as TR, NavigationDrawer, NormalButton } from 'ui-library/dist/external/components'
import SegmentationAction from '../../../action/segmentation'

export default props => {
  const toolbar = { label: `客層マスタの設定`, items: props.accountMenu }
  return (
    <React.Fragment>
      <NavigationDrawer toolbar={toolbar} navigations={props.navigations} />

      <BaseLayout>
        <LocalAreaLayout right={<NormalButton onClick={SegmentationAction.toCreateView}>新規作成</NormalButton>} />
        <ListViewLayout>
          <DataTable
            headers={[
              <TH key={1} width="5%" isNumber>
                #
              </TH>,
              <TH key={4} width="10%">
                表示
              </TH>,
              <TH key={2} width="20%">
                分類名
              </TH>,
              <TH key={3} width="40%">
                選択項目
              </TH>
            ]}
            rows={
              props.segment_categories &&
              props.segment_categories.map(segment_category => (
                <TR key={1} onClick={() => SegmentationAction.toEditView(segment_category.id)}>
                  <TD isNumber>{segment_category.priority}</TD>
                  <TD>
                    <Status status={segment_category.status} />
                  </TD>
                  <TD>{segment_category.name}</TD>
                  <TD>{segment_category.segmentValues.join(', ')}</TD>
                </TR>
              ))
            }
          />
        </ListViewLayout>
      </BaseLayout>
    </React.Fragment>
  )
}

function Status(props) {
  const { status } = props

  let style = {
    display: 'inline',
    minWidth: '37px',
    height: '19px',
    borderRadius: '15px',
    padding: '2px 6px',
    fontSize: '12px',
    textAlign: 'left'
  }
  switch (status) {
    case 'hide':
      style = { ...style, backgroundColor: '#EDEEEF', color: '#475460' }
      break
    case 'show':
      style = { ...style, backgroundColor: '#E1F2FE', color: '#0072DD' }
      break
  }

  return <span style={style}>{getStatusLabel(status)}</span>
}

function getStatusLabel(status) {
  return status === 'show' ? '表示' : '非表示'
}
