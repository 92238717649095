import reservationRepository from '../../infra/ReservationRepository'
import { appActions } from '../AppActions'
import history from '../../util/history'

const confirmCheckIn = async (event, reservationId, guestName, formCount, guestCount) => {
  event.stopPropagation()

  let confirmMessage = null
  if (formCount == guestCount) {
    confirmMessage = `${guestName}様の予約をチェックイン済みにします。よろしいですか？`
  } else {
    confirmMessage = `${guestName}様の宿泊台帳記入数が予約情報の人数と異なります(${formCount}/${guestCount})\nこの予約チェックイン済みにしますがよろしいですか？`
  }

  if (window.confirm(confirmMessage)) {
    const response = await reservationRepository.requestCheckin(reservationId).catch(e => e)
    if (response instanceof Error) {
      appActions.showAlert(response, 'チェックインに失敗しました')
      return false
    }
    history.push(`/reservation/${reservationId}/segment`)
  }

  return true
}

export default confirmCheckIn
