import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import repository from '../../infra/ReservationRepository'
import TransactionEvent from '../../event/TransactionEvent'

const fetchReservation = async reservationId => {
  const response = await repository.fetchOne(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(TransactionEvent.fetchReservation, response)

  return true
}

export default fetchReservation
