import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const toEditView = id => {
  if (id) {
    history.push(`/stripe_keys/${id}`)
  } else {
    AppDispatcher.dispatchWithType(StripeKeyEvent.onClear)
    history.push(`/stripe_keys/new`)
  }

  return true
}

export default toEditView
