/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import InlineResponse20020 from '../model/InlineResponse20020'

/**
 * InvoiceAutomation service.
 * @module api/InvoiceAutomationApi
 * @version 1.0.0
 */
export default class InvoiceAutomationApi {
  /**
   * Constructs a new InvoiceAutomationApi.
   * @alias module:api/InvoiceAutomationApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * get a invoice automation setting
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20020} and HTTP response
   */
  getInvoiceAutomationWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20020

    return this.apiClient.callApi('/invoice_automation/1', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get a invoice automation setting
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20020}
   */
  getInvoiceAutomation() {
    return this.getInvoiceAutomationWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
