import AppDispatcher from '../../../core/AppDispatcher'
import CheckInEvent from '../../../event/CheckInEvent'
import AWS from 'aws-sdk'
import SelfCheckInRepository from '../../../infra/SelfCheckInRepository'
import arrayBufferToDataURI from '../../../util/DataURI'

async function getPassport(token, filepath, index) {
  const repository = new SelfCheckInRepository(token)
  const response = await repository.selfCheckInRequest().catch(() => false)
  if (response === false || !response.aws) {
    return false
  }

  const aws = response.aws
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    accessKeyId: aws.access_key,
    secretAccessKey: aws.secret_key,
    sessionToken: aws.session_token,
    region: 'ap-northeast-1'
  })

  const filename = filepath.split('/').pop()
  const params = {
    Bucket: aws.bucket_name,
    Key: `${aws.bucket_dir}/${filename}`
  }
  return getObject(s3, params, index)
}

async function getObject(s3, params, index) {
  const response = await s3
    .getObject(params)
    .promise()
    .catch(() => false)
  if (response === false) {
    return false
  }

  const dataURI = arrayBufferToDataURI(response.Body)
  AppDispatcher.dispatchWithType(CheckInEvent.onEdit, {
    index: index,
    name: 'passport_image',
    value: dataURI
  })
  return true
}

export default getPassport
