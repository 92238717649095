import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import { PlanState } from '../state/PlanState'
import PlanEvent from '../../event/PlanEvent'

class PlanStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new PlanState()
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case PlanEvent.fetchOne:
        return state.mergeModel(payload.plan)
      case PlanEvent.onEdit:
        return state.set(payload.name, payload.value)
      case PlanEvent.onClear:
        return state.clear()
      default:
        return state
    }
  }
}

export default new PlanStore()
