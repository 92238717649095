import { commissionRateSettings } from '../site_referrer/CommissionRateSetting'
class AccommodationTax {
  calculate(accommodation_fee_excluding_tax, stay, people) {
    const tax_price = this.calculateAccomodationTax(accommodation_fee_excluding_tax, stay, people)

    return {
      tax_price: tax_price,
      total_tax_price: tax_price * people * stay
    }
  }

  /**
   * 予約総額の計算
   * ExpediaなどのOTAからの予約の場合、OTA手数料が差し引かれて予約料金が登録される場合がある
   * その場合、OTA手数料を付与した金額を知りたい場合がある
   * ※ このメソッドではOTA手数料は小数点切り捨てで計算している
   *
   * original_price * (100 - commission_rate) / 100 = price
   * original_price = price * 100 / (100 - commission_rate)
   */
  calculateTotalPrice(price, include_commission, commission_rate) {
    return include_commission ? Math.ceil((price * 100) / (100 - commission_rate)) : price
  }

  // 税抜価格の計算
  calculateExcludingPrice(price_include_tax) {
    if (price_include_tax === 0) return 0
    const TAX_RATE = 10 // 消費税率
    // 端数処理は切捨て
    return Math.ceil((price_include_tax * 100) / (100 + TAX_RATE))
  }

  /**
   * 1泊1人あたりの宿泊税額の計算
   *
   * @param accommodation_fee 宿泊費（税抜）
   * @param stay 泊数
   * @param people 人数
   * @returns {number} 1泊1人あたりの宿泊税額
   */
  calculateAccomodationTax(accommodation_fee, stay, people) {
    const price_per_day = accommodation_fee / stay
    const price_per_person = price_per_day / people

    // 宿泊者1人1泊につき，宿泊料金が
    let tax_price = null

    if (price_per_person >= 50000) {
      // 5万円以上のもの: 1,000円
      tax_price = 1000
    } else if (price_per_person < 50000 && price_per_person >= 20000) {
      // 2万円以上5万円未満のもの: 500円
      tax_price = 500
    } else if (price_per_person < 20000 && price_per_person > 0) {
      // 2万円未満のもの: 200円
      tax_price = 200
    } else {
      // 課金されない場合は税金がかからない
      tax_price = 0
    }

    return tax_price
  }

  getCommissionRate(site_referer) {
    return commissionRateSettings[site_referer] || { include: false, rate: 0 }
  }
}

export default new AccommodationTax()
