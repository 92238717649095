import { MealCondition } from './MealCondition'

/**
 * reservation
 * - stay 泊数
 * - people 人数
 */
class Plans {
  constructor() {
    this.plans = []
  }

  find(hotelId, planCode) {
    return this.plans.find(plan => plan && plan.matches(hotelId, planCode))
  }

  addPlan(plan) {
    this.plans.push(plan)
  }

  hasAnyPlan() {
    return this.plans.length > 0
  }
}

class Plan {
  constructor(name, hotelId, planCode) {
    this.name = name
    this.hotelId = hotelId
    this.planCode = planCode
    this.mealPlans = []
    this.activityPlans = []
  }

  matches(hotelId, planCode) {
    return this.hotelId === hotelId && this.planCode === planCode
  }

  /**
   * @param plan MealPlan
   */
  addMealPlan(plan) {
    this.mealPlans.push(plan)
    return this
  }
  getMealPlans() {
    return this.mealPlans
  }

  /**
   * @param plan ActivityPlan
   */
  addActivityPlan(plan) {
    this.activityPlans.push(plan)
    return this
  }
  getActivityPlans() {
    return this.activityPlans
  }

  breakfastPrice(reservation) {
    return this.mealPlans.reduce((acc, cur) => acc + cur.breakfastTotal(reservation), 0)
  }
  lunchPrice(reservation) {
    return this.mealPlans.reduce((acc, cur) => acc + cur.lunchTotal(reservation), 0)
  }
  dinnerPrice(reservation) {
    return this.mealPlans.reduce((acc, cur) => acc + cur.dinnerTotal(reservation), 0)
  }
  mealPlanTotal(reservation) {
    return this.mealPlans.reduce((acc, cur) => acc + cur.total(reservation), 0)
  }
  activityPrice() {}

  activityPlanTotal(reservation) {
    return this.activityPlans.reduce((acc, cur) => acc + cur.total(reservation), 0)
  }
  total(reservation) {
    return this.mealPlanTotal(reservation) + this.activityPlanTotal(reservation)
  }
  matches(hotelId, planCode) {
    return this.hotelId === hotelId && this.planCode === planCode
  }
}

class MealPlan {
  constructor(breakfast, lunch, dinner) {
    this.breakfast = breakfast
    this.lunch = lunch
    this.dinner = dinner
  }
  mealCondition() {
    const includeBreakfast = this.isIncludeBreakfast()
    const includeLunch = this.isIncludeLunch()
    const includeDinner = this.isIncludeDinner()
    if (includeBreakfast && includeLunch && includeDinner) return MealCondition.INCLUDING_BREAKFAST_AND_LUNCH_AND_DINNER
    if (includeBreakfast && includeDinner) return MealCondition.INCLUDING_BREAKFAST_AND_DINNER
    if (includeBreakfast && includeLunch) return MealCondition.INCLUDING_BREAKFAST_AND_LUNCH
    if (includeLunch && includeDinner) return MealCondition.INCLUDING_LUNCH_AND_DINNER
    if (includeBreakfast) return MealCondition.INCLUDING_BREAKFAST
    if (includeDinner) return MealCondition.INCLUDING_DINNER
    return MealCondition.NO_MEAL
  }
  breakfastTotal(reservation) {
    if (!this.breakfast) return 0
    return this.breakfast.total(reservation)
  }
  lunchTotal(reservation) {
    if (!this.lunch) return 0
    return this.lunch.total(reservation)
  }
  dinnerTotal(reservation) {
    if (!this.dinner) return 0
    return this.dinner.total(reservation)
  }
  total(reservation) {
    let total = 0
    const stay = reservation.stay
    const people = reservation.people
    if (this.isIncludeBreakfast()) {
      total += this.breakfast.total(reservation)
    }
    if (this.isIncludeLunch()) {
      total += this.lunch.total(reservation)
    }
    if (this.isIncludeDinner()) {
      total += this.dinner.total(reservation)
    }

    return total
  }
  isIncludeBreakfast() {
    return !!this.breakfast
  }
  isIncludeLunch() {
    return !!this.lunch
  }
  isIncludeDinner() {
    return !!this.dinner
  }
}

class ActivityPlan {
  constructor(activity) {
    this.activity = activity
  }
  total(reservation) {
    return this.activity.total(reservation)
  }
}

class Meal {
  constructor(name, saleUnit) {
    this.name = name
    this.saleUnit = saleUnit
  }
  total(reservation) {
    return this.saleUnit.total(reservation)
  }
}
class Activity {
  constructor(name, saleUnit) {
    this.name = name
    this.saleUnit = saleUnit
  }
  total(reservation) {
    return this.saleUnit.total(reservation)
  }
}
const MealTypeBreakfast = 'Meal_TYPE_BREAKFAST'
const MealTypeLunch = 'Meal_TYPE_LUNCH'
const MealTypeDinner = 'Meal_TYPE_DINNER'

/**
 * 人数 × 泊数
 */
class SaleUnitPeopleStay {
  constructor(unitPrice) {
    this.unitPrice = unitPrice
  }
  total(reservation) {
    return reservation.stay * reservation.people * this.unitPrice
  }
}
/**
 * 人数
 */
class SaleUnitPeople {
  constructor(unitPrice) {
    this.unitPrice = unitPrice
  }
  total(reservation) {
    return reservation.people * this.unitPrice
  }
}
/**
 * 泊数
 */
class SaleUnitStay {
  constructor(unitPrice) {
    this.unitPrice = unitPrice
  }
  total(reservation) {
    return reservation.stay * this.unitPrice
  }
}
/**
 * 一括
 */
class SaleUnitOnce {
  constructor(unitPrice) {
    this.unitPrice = unitPrice
  }
  total(reservation) {
    return this.unitPrice
  }
}

export { Plans, Plan, MealPlan, ActivityPlan, Meal, Activity, SaleUnitPeopleStay, SaleUnitPeople, SaleUnitStay, SaleUnitOnce }
