import React from 'react'

export default function IconSuccess(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
      <g fill="none" fill-rule="evenodd" stroke="#00BA8A" stroke-width="2">
        <circle cx="30" cy="30" r="29.5" />
        <path d="M16 30.412L26.875 41 45 21" />
      </g>
    </svg>
  )
}
