import history from '../../../util/history'

const onMonthChanged = (month, hotel_id) => {
  if (month && hotel_id) {
    history.push(`/report/monthly_sales/date/${month}/hotel/${hotel_id}`)
  } else if (month) {
    history.push(`/report/monthly_sales/date/${month}`)
  } else {
    history.push(`/report/monthly_sales`)
  }

  return true
}

export default onMonthChanged
