import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onCancelEditView = async () => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.onClear)

  if (!history.canGoBack()) {
    if (window.confirm('このウィンドウを閉じます。よろしいですか？')) {
      window.close()
    }
    return false
  }
  history.goBack()

  return true
}

export default onCancelEditView
