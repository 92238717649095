/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AccommodationTax model module.
 * @module model/AccommodationTax
 * @version 1.0.0
 */
export default class AccommodationTax {
  /**
   * Constructs a new <code>AccommodationTax</code>.
   * @alias module:model/AccommodationTax
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AccommodationTax</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccommodationTax} obj Optional instance to populate.
   * @return {module:model/AccommodationTax} The populated <code>AccommodationTax</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccommodationTax()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('booking_price')) {
        obj['booking_price'] = ApiClient.convertToType(data['booking_price'], 'Number')
      }
      if (data.hasOwnProperty('accommodation_fee_include_tax')) {
        obj['accommodation_fee_include_tax'] = ApiClient.convertToType(data['accommodation_fee_include_tax'], 'Number')
      }
      if (data.hasOwnProperty('accommodation_fee')) {
        obj['accommodation_fee'] = ApiClient.convertToType(data['accommodation_fee'], 'Number')
      }
      if (data.hasOwnProperty('ota_commission_include')) {
        obj['ota_commission_include'] = ApiClient.convertToType(data['ota_commission_include'], 'Boolean')
      }
      if (data.hasOwnProperty('ota_commission_rate')) {
        obj['ota_commission_rate'] = ApiClient.convertToType(data['ota_commission_rate'], 'Number')
      }
      if (data.hasOwnProperty('tax_per_day')) {
        obj['tax_per_day'] = ApiClient.convertToType(data['tax_per_day'], 'Number')
      }
      if (data.hasOwnProperty('number_of_guests')) {
        obj['number_of_guests'] = ApiClient.convertToType(data['number_of_guests'], 'Number')
      }
      if (data.hasOwnProperty('number_of_nights')) {
        obj['number_of_nights'] = ApiClient.convertToType(data['number_of_nights'], 'Number')
      }
      if (data.hasOwnProperty('payment_commission')) {
        obj['payment_commission'] = ApiClient.convertToType(data['payment_commission'], 'Number')
      }
      if (data.hasOwnProperty('payment_code')) {
        obj['payment_code'] = ApiClient.convertToType(data['payment_code'], 'String')
      }
      if (data.hasOwnProperty('memo')) {
        obj['memo'] = ApiClient.convertToType(data['memo'], 'String')
      }
      if (data.hasOwnProperty('room_name')) {
        obj['room_name'] = ApiClient.convertToType(data['room_name'], 'String')
      }
      if (data.hasOwnProperty('check_out')) {
        obj['check_out'] = ApiClient.convertToType(data['check_out'], 'Date')
      }
      if (data.hasOwnProperty('reserved_name')) {
        obj['reserved_name'] = ApiClient.convertToType(data['reserved_name'], 'String')
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date')
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} booking_price
   */
  booking_price = undefined
  /**
   * @member {Number} accommodation_fee_include_tax
   */
  accommodation_fee_include_tax = undefined
  /**
   * @member {Number} accommodation_fee
   */
  accommodation_fee = undefined
  /**
   * @member {Boolean} ota_commission_include
   */
  ota_commission_include = undefined
  /**
   * @member {Number} ota_commission_rate
   */
  ota_commission_rate = undefined
  /**
   * @member {Number} tax_per_day
   */
  tax_per_day = undefined
  /**
   * @member {Number} number_of_guests
   */
  number_of_guests = undefined
  /**
   * @member {Number} number_of_nights
   */
  number_of_nights = undefined
  /**
   * @member {Number} payment_commission
   */
  payment_commission = undefined
  /**
   * @member {String} payment_code
   */
  payment_code = undefined
  /**
   * @member {String} memo
   */
  memo = undefined
  /**
   * @member {String} room_name
   */
  room_name = undefined
  /**
   * @member {Date} check_out
   */
  check_out = undefined
  /**
   * @member {String} reserved_name
   */
  reserved_name = undefined
  /**
   * @member {Date} created_at
   */
  created_at = undefined
  /**
   * @member {Date} updated_at
   */
  updated_at = undefined
}
