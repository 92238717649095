import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import AccountTitleDetailState from '../state/AccountTitleDetailState'
import AccountTitleDetailEvent from '../../event/AccountTitleDetailEvent'

class AccountTitleDetailStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new AccountTitleDetailState()
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case AccountTitleDetailEvent.fetchOne:
        return new AccountTitleDetailState(payload.detail)
      case AccountTitleDetailEvent.onEdit:
        return state.set(payload.name, payload.value)
      case AccountTitleDetailEvent.onClear:
        return state.clear()
      default:
        return state
    }
  }
}

export default new AccountTitleDetailStore()
