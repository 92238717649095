import { Record } from 'immutable'

const CheckInState = Record({
  id: null,
  reservation_id: null,
  name: '',
  gender: '',
  birthmonth: '',
  birthday: '',
  age: null,
  mobile: '',
  phone: '',
  email: '',
  prefecture_id: '',
  address: '',
  business: '',
  nation: '',
  arrival: '',
  departure: '',
  with_passport: false,
  passport_url: '',
  passport_image: '',
  showConfirmDeletion: false
})

export { CheckInState }
