import NProgress from 'nprogress'
import MonthlyReportAction from '../index'
import { appActions } from '../../AppActions'

const onRequestSearch = async (state, target_date) => {
  if (!state.search_hotel_id) {
    appActions.snackbar('宿泊施設を設定してください')
    return false
  }

  NProgress.start()
  await MonthlyReportAction.fetchMonthlySalesByDate(target_date, state.search_hotel_id)
  NProgress.done()

  return true
}

export default onRequestSearch
