import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'
import NProgress from 'nprogress'

const onSearch = async (state, pagination) => {
  NProgress.start()
  const response = await reservationRepository.fetchBySearchCondition(state, pagination).catch(e => e)
  NProgress.done()
  if (response instanceof Error) {
    appActions.showAlert(response, '予約の検索に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.onSearch, response)

  return true
}

export default onSearch
