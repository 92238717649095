import history from '../../../util/history'
import { appActions } from '../../AppActions'

const showDetailView = (date, id) => {
  if (!id) {
    appActions.snackbar('保存されているデータはありません')
    return false
  }

  history.push(`/report/accommodation_tax/date/${date}/id/${id}/detail`)

  return true
}

export default showDetailView
