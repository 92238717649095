import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'
import monthlyClosingRepository from '../../infra/MonthlyClosingRepository'
import { appActions } from '../AppActions'

const fetchByDate = async (date, hotel_ids) => {
  const res = await monthlyClosingRepository.fetchByDate(date, hotel_ids).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyClosingEvent.monthChanged, { date: date, result: res })

  return true
}

export default fetchByDate
