import TaskAction from '../task'
import history from '../../util/history'

const showNew = reservationId => {
  TaskAction.onInit()
  history.push(`/tasks/new`, { reservationId: reservationId })
  return true
}

export default showNew
