import AppDispatcher from '../../core/AppDispatcher'
import AccountTitleEvent from '../../event/AccountTitleEvent'

const onEdit = event => {
  AppDispatcher.dispatchWithType(AccountTitleEvent.onEdit, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEdit
