function arrayBufferToDataURI(data) {
  const body = data instanceof Uint8Array ? data : new Uint8Array(data)
  const contentType = data.ContentType

  const str = body.reduce((a, b) => a + String.fromCharCode(b), '')
  // S3から取得したUint8ArrayをDataURIに変換
  return `data:${contentType};base64,` + btoa(str).replace(/.{76}(?=.)/g, '$&\n')
}

export default arrayBufferToDataURI
