/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The Audit model module.
 * @module model/Audit
 * @version 1.0.0
 */
export default class Audit {
  /**
   * Constructs a new <code>Audit</code>.
   * @alias module:model/Audit
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>Audit</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Audit} obj Optional instance to populate.
   * @return {module:model/Audit} The populated <code>Audit</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Audit()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
      if (data.hasOwnProperty('username')) {
        obj['username'] = ApiClient.convertToType(data['username'], 'String')
      }
      if (data.hasOwnProperty('column_name')) {
        obj['column_name'] = ApiClient.convertToType(data['column_name'], 'String')
      }
      if (data.hasOwnProperty('old_value')) {
        obj['old_value'] = ApiClient.convertToType(data['old_value'], 'String')
      }
      if (data.hasOwnProperty('new_value')) {
        obj['new_value'] = ApiClient.convertToType(data['new_value'], 'String')
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
  /**
   * @member {String} username
   */
  username = undefined
  /**
   * @member {String} column_name
   */
  column_name = undefined
  /**
   * @member {String} old_value
   */
  old_value = undefined
  /**
   * @member {String} new_value
   */
  new_value = undefined
  /**
   * @member {Date} updated_at
   */
  updated_at = undefined
}
