import HotelManualEvent from '../../event/HotelManualEvent'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import hotelManualRepository from '../../infra/HotelManualRepository'

const fetchAll = async roomId => {
  const response = await hotelManualRepository.fetchByRoomId(roomId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, 'データ取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(HotelManualEvent.fetchAll, response)
  return true
}

export default fetchAll
