/**
 * Stack (LIFO) 構造を持つ画面遷移フローの管理クラス
 */
class TransitionManager {
  constructor() {
    this._stack = []
    this.MAX_HISTORY_SIZE = 30
  }

  /**
   * Pushes an item onto the top of this stack.
   */
  push(location) {
    const prev = this.peek()
    if (location != null) {
      const prevPathname = prev ? prev.pathname : null
      if (location.pathname !== prevPathname) {
        this._stack.push(location)

        if (this._stack.length > this.MAX_HISTORY_SIZE) {
          const overSize = this._stack.length - this.MAX_HISTORY_SIZE
          // オーバーした数だけスタックの先頭から削除する
          this._stack.splice(0, overSize)
        }
      } else {
        // ダブルクリックで戻れなくなっている可能性があるので一つpopする
        return this._stack.pop()
      }
    }
  }

  /**
   * Removes the object at the top of this stack and returns that object as the value of this function.
   */
  pop() {
    if (this._stack.length > 0) {
      return this._stack.pop()
    }

    return null
  }

  /**
   * Looks at the object at the top of this stack without removing it from the stack.
   */
  peek() {
    if (this._stack.length > 0) {
      const topIndex = this._stack.length - 1
      return this._stack[topIndex]
    }

    return null
  }

  /**
   * Returns the number of components in the stack.
   */
  size() {
    return this._stack.length
  }

  /**
   * Tests if this stack is empty
   */
  isEmpty() {
    return this._stack.length === 0
  }

  toString() {
    return '[' + this._stack.map(it => (it ? it.pathname : '')).join(',') + ']'
  }
}

export default new TransitionManager()
