import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const addReservation = async (reservation, site_referrer_options) => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.addReservation, { reservation: reservation, site_referrer_options: site_referrer_options })

  return true
}

export default addReservation
