import './SensorValue.scss'
import React from 'react'

/**
 * type: string (sound[dB], temperature[℃], battery[%])
 * value: integer
 *
 * # Font Color
 * ## sound
 * - black  :  0 ~ 40
 * - yellow : 41 ~ 60
 * - red    : 61 ~
 *
 * ## temperature
 * - blue   :    ~ 14
 * - black  : 15 ~ 25
 * - yellow : 26 ~ 30
 * - red    : 31 ~
 *
 * ## battery
 * - red    :    ~ 10
 * - yellow : 11 ~ 30
 * - black  : 31 ~
 *
 */

export const SENSOR_TYPES = {
  SOUND: 'sound',
  TEMPERATURE: 'temperature',
  BATTERY: 'battery'
}

const colors = {
  black: '#0e141a',
  blue: '#4db5f0',
  yellow: '#ffc62b',
  red: '#db2d26'
}

const units = {
  sound: 'dB',
  temperature: '&#8451;',
  battery: '&#37;'
}

function SensorValue(props) {
  const type = props.type
  let value = props.value

  if (type === SENSOR_TYPES.BATTERY) {
    // バッテリーの最大値は6v、小数点第2位までを表示する
    value = Math.round((value * 10000) / 6) / 100
  }
  const className = props.className || ''

  return (
    <div className={`SensorValue ${className}`}>
      <div className="labels" style={colorComputed(type, value)}>
        <span className="value-label">{value}</span>
        <span className="unit-label" dangerouslySetInnerHTML={{ __html: units[type] }} />
      </div>
    </div>
  )
}

function colorComputed(type, value) {
  return { color: colors[resolveColorByValue(type, value)] }
}

function resolveColorByValue(type, value) {
  if (type === SENSOR_TYPES.SOUND) {
    if (value > 40 && value <= 60) return 'yellow'
    else if (value > 60) return 'red'
    return 'black'
  }
  if (type === SENSOR_TYPES.TEMPERATURE) {
    if (value <= 14) return 'blue'
    else if (value > 25 && value <= 30) return 'yellow'
    else if (value > 30) return 'red'
    return 'black'
  }
  if (type === SENSOR_TYPES.BATTERY) {
    if (value <= 10) return 'red'
    else if (value > 10 && value <= 30) return 'yellow'
    return 'black'
  }
}

export default SensorValue
