import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const onDeleteDetail = (event, index, id) => {
  event.preventDefault()
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.onDeleteDetail, { index: index, id: id })

  return true
}

export default onDeleteDetail
