import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchTasks = async reservationId => {
  const response = await reservationRepository.fetchTasks(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, 'タスクの取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchTasks, {
    tasks: response
  })
  return true
}

export default fetchTasks
