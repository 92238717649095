import ApiClient from '../ApiClient'
import AppRepository from './AppRepository'
import AccommodationTaxApi from '../api/AccommodationTaxApi'

class AccommodationTaxRepository extends AppRepository {
  constructor() {
    super()
    this._accommodationTaxApi = new AccommodationTaxApi(ApiClient.instance)
  }

  requestCreate(reservationId, accommodation_tax) {
    const body = {
      booking_price: accommodation_tax.booking_price,
      accommodation_fee_include_tax: accommodation_tax.accommodation_fee_include_tax,
      accommodation_fee: accommodation_tax.accommodation_fee,
      ota_commission_include: accommodation_tax.include_commission,
      ota_commission_rate: accommodation_tax.commission_rate,
      number_of_nights: accommodation_tax.stay,
      number_of_guests: accommodation_tax.people,
      tax_per_day: accommodation_tax.tax_price,
      memo: accommodation_tax.memo
    }
    return this._accommodationTaxApi.createAccommodationTax(reservationId, { accommodation_tax: body })
  }

  requestUpdate(reservationId, accommodation_tax) {
    const body = {
      booking_price: accommodation_tax.booking_price,
      accommodation_fee_include_tax: accommodation_tax.accommodation_fee_include_tax,
      accommodation_fee: accommodation_tax.accommodation_fee,
      ota_commission_include: accommodation_tax.include_commission,
      ota_commission_rate: accommodation_tax.commission_rate,
      number_of_nights: accommodation_tax.stay,
      number_of_guests: accommodation_tax.people,
      tax_per_day: accommodation_tax.tax_price,
      memo: accommodation_tax.memo
    }
    return this._accommodationTaxApi.updateAccommodationTax(reservationId, accommodation_tax.id, { accommodation_tax: body })
  }

  requestDelete(reservationId, accommodation_tax_id) {
    return this._accommodationTaxApi.deleteAccommodationTax(reservationId, accommodation_tax_id)
  }
}

// singleton
export default new AccommodationTaxRepository()
