/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body18 from '../model/Body18'
import Body19 from '../model/Body19'
import InlineResponse20021 from '../model/InlineResponse20021'
import InlineResponse20022 from '../model/InlineResponse20022'
import StripeApiKeyBody from '../model/StripeApiKeyBody'
import StripeApiKeyBody1 from '../model/StripeApiKeyBody1'

/**
 * Stripe service.
 * @module api/StripeApi
 * @version 1.0.0
 */
export default class StripeApi {
  /**
   * Constructs a new StripeApi.
   * @alias module:api/StripeApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * Stripe APIキーを新規登録する
   * @param {module:model/Body18} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createApiKeyWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createApiKey")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/stripe_api_key', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Stripe APIキーを新規登録する
   * @param {module:model/Body18} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createApiKey(body) {
    return this.createApiKeyWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Stripe APIキーを削除する
   * @param {Number} stripeApiKeyId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteApiKeyWithHttpInfo(stripeApiKeyId) {
    let postBody = null

    // verify the required parameter 'stripeApiKeyId' is set
    if (stripeApiKeyId === undefined || stripeApiKeyId === null) {
      throw new Error("Missing the required parameter 'stripeApiKeyId' when calling deleteApiKey")
    }

    let pathParams = {
      stripe_api_key_id: stripeApiKeyId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/stripe_api_key/{stripe_api_key_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Stripe APIキーを削除する
   * @param {Number} stripeApiKeyId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteApiKey(stripeApiKeyId) {
    return this.deleteApiKeyWithHttpInfo(stripeApiKeyId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Stripe APIキーを取得する
   * @param {Number} stripeApiKeyId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20022} and HTTP response
   */
  getApiKeyWithHttpInfo(stripeApiKeyId) {
    let postBody = null

    // verify the required parameter 'stripeApiKeyId' is set
    if (stripeApiKeyId === undefined || stripeApiKeyId === null) {
      throw new Error("Missing the required parameter 'stripeApiKeyId' when calling getApiKey")
    }

    let pathParams = {
      stripe_api_key_id: stripeApiKeyId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20022

    return this.apiClient.callApi('/stripe_api_key/{stripe_api_key_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Stripe APIキーを取得する
   * @param {Number} stripeApiKeyId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20022}
   */
  getApiKey(stripeApiKeyId) {
    return this.getApiKeyWithHttpInfo(stripeApiKeyId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Stripe APIキーを検索する
   * @param {module:model/Body19} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20021} and HTTP response
   */
  searchApiKeyWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchApiKey")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20021

    return this.apiClient.callApi('/stripe_api_key/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Stripe APIキーを検索する
   * @param {module:model/Body19} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20021}
   */
  searchApiKey(body) {
    return this.searchApiKeyWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Stripe APIキーの接続テストをする
   * @param {module:model/StripeApiKeyBody1} stripeApiKeyBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  testApiKeyWithHttpInfo(stripeApiKeyBody) {
    let postBody = stripeApiKeyBody

    // verify the required parameter 'stripeApiKeyBody' is set
    if (stripeApiKeyBody === undefined || stripeApiKeyBody === null) {
      throw new Error("Missing the required parameter 'stripeApiKeyBody' when calling testApiKey")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/stripe_api_key/connection_test', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Stripe APIキーの接続テストをする
   * @param {module:model/StripeApiKeyBody1} stripeApiKeyBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  testApiKey(stripeApiKeyBody) {
    return this.testApiKeyWithHttpInfo(stripeApiKeyBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Stripe APIキーを更新する
   * @param {Number} stripeApiKeyId Target id
   * @param {module:model/StripeApiKeyBody} stripeApiKeyBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateApiKeyWithHttpInfo(stripeApiKeyId, stripeApiKeyBody) {
    let postBody = stripeApiKeyBody

    // verify the required parameter 'stripeApiKeyId' is set
    if (stripeApiKeyId === undefined || stripeApiKeyId === null) {
      throw new Error("Missing the required parameter 'stripeApiKeyId' when calling updateApiKey")
    }

    // verify the required parameter 'stripeApiKeyBody' is set
    if (stripeApiKeyBody === undefined || stripeApiKeyBody === null) {
      throw new Error("Missing the required parameter 'stripeApiKeyBody' when calling updateApiKey")
    }

    let pathParams = {
      stripe_api_key_id: stripeApiKeyId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/stripe_api_key/{stripe_api_key_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Stripe APIキーを更新する
   * @param {Number} stripeApiKeyId Target id
   * @param {module:model/StripeApiKeyBody} stripeApiKeyBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updateApiKey(stripeApiKeyId, stripeApiKeyBody) {
    return this.updateApiKeyWithHttpInfo(stripeApiKeyId, stripeApiKeyBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
