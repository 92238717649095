import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const addDetail = event => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.addDetail)

  return true
}

export default addDetail
