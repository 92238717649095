import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onEditReservation = (event, index) => {
  AppDispatcher.dispatchWithType(DailyClosingEvent.onEditReservation, {
    index: index,
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEditReservation
