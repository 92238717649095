/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AuthenticationError from '../model/AuthenticationError'
import Body from '../model/Body'
import Body1 from '../model/Body1'
import Body2 from '../model/Body2'
import Body3 from '../model/Body3'
import Hotel from '../model/Hotel'
import InlineResponse200 from '../model/InlineResponse200'
import InlineResponse2001 from '../model/InlineResponse2001'
import Room from '../model/Room'
import SiteReferrerOption from '../model/SiteReferrerOption'
import ValidationError from '../model/ValidationError'

/**
 * Login service.
 * @module api/LoginApi
 * @version 1.0.0
 */
export default class LoginApi {
  /**
   * Constructs a new LoginApi.
   * @alias module:api/LoginApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * ログイン状態を確認する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
   */
  checkLoginStatusWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2001

    return this.apiClient.callApi('/sessions/check', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ログイン状態を確認する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
   */
  checkLoginStatus() {
    return this.checkLoginStatusWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * OTAオプションを取得する
   * @param {module:model/Body} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SiteReferrerOption>} and HTTP response
   */
  getSiteReferrersWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling getSiteReferrers")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [SiteReferrerOption]

    return this.apiClient.callApi('/site_referrers', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * OTAオプションを取得する
   * @param {module:model/Body} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SiteReferrerOption>}
   */
  getSiteReferrers(body) {
    return this.getSiteReferrersWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ログインユーザーに紐づいた宿泊施設一覧を返します
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Hotel>} and HTTP response
   */
  getUserHotelsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Hotel]

    return this.apiClient.callApi('/sessions/hotels', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ログインユーザーに紐づいた宿泊施設一覧を返します
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Hotel>}
   */
  getUserHotels() {
    return this.getUserHotelsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ログインユーザーがもつ宿一覧から指定されたIDの宿泊施設情報を返します
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Room} and HTTP response
   */
  getUserRoomByIdWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling getUserRoomById")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Room

    return this.apiClient.callApi('/sessions/rooms/{room_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ログインユーザーがもつ宿一覧から指定されたIDの宿泊施設情報を返します
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Room}
   */
  getUserRoomById(roomId) {
    return this.getUserRoomByIdWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ログインユーザーに紐づいた宿一覧を返します
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Room>} and HTTP response
   */
  getUserRoomsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Room]

    return this.apiClient.callApi('/sessions/rooms', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ログインユーザーに紐づいた宿一覧を返します
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Room>}
   */
  getUserRooms() {
    return this.getUserRoomsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ログイン処理(JWTを取得する)
   * @param {Object} opts Optional parameters
   * @param {module:model/Body1} opts.body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
   */
  loginWithHttpInfo(opts) {
    opts = opts || {}
    let postBody = opts['body']

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = []
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse200

    return this.apiClient.callApi('/sessions/login', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ログイン処理(JWTを取得する)
   * @param {Object} opts Optional parameters
   * @param {module:model/Body1} opts.body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
   */
  login(opts) {
    return this.loginWithHttpInfo(opts).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * パスワードリセットのためのメールを送信する
   * @param {Object} opts Optional parameters
   * @param {module:model/Body2} opts.body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  passwordResetRequestWithHttpInfo(opts) {
    opts = opts || {}
    let postBody = opts['body']

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = []
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/password/reset/request', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * パスワードリセットのためのメールを送信する
   * @param {Object} opts Optional parameters
   * @param {module:model/Body2} opts.body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  passwordResetRequest(opts) {
    return this.passwordResetRequestWithHttpInfo(opts).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * パスワードリセットを行う
   * @param {Object} opts Optional parameters
   * @param {module:model/Body3} opts.body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  passwordResetUpdateWithHttpInfo(opts) {
    opts = opts || {}
    let postBody = opts['body']

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = []
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/password/reset/update', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * パスワードリセットを行う
   * @param {Object} opts Optional parameters
   * @param {module:model/Body3} opts.body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  passwordResetUpdate(opts) {
    return this.passwordResetUpdateWithHttpInfo(opts).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
