import AppDispatcher from '../../core/AppDispatcher'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const onEditSearchCondition = event => {
  AppDispatcher.dispatchWithType(StripeKeyEvent.onEditSearchCondition, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEditSearchCondition
