import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import StripeApiKeyRepository from '../../infra/StripeApiKeyRepository'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const onRequestCreate = async state => {
  const res = await StripeApiKeyRepository.requestCreate(state).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '作成に失敗しました')
    return false
  }

  appActions.snackbar('作成しました', 'success', true)

  AppDispatcher.dispatchWithType(StripeKeyEvent.onClear)
  return true
}

export default onRequestCreate
