import ReservationApi from '../../api/ReservationApi'
import history from '../../util/history'
import ApiClient from '../../ApiClient'
import ApiHandle from '../../util/ApiHandle'
import { appActions } from '../AppActions'

const toCheckInView = (event, reservationId) => {
  event.stopPropagation()

  const reservationApi = new ReservationApi(ApiClient.instance)
  const apiHandleOpts = {
    apiCall: () => reservationApi.issueCheckInUrl(reservationId),
    onSucceded: response => {
      const url = '/check_in/' + reservationId + '/' + response.token
      history.push(url)
    },
    onFailed: response => {
      appActions.snackbar('宿泊者帳簿入力画面を表示できませんでした', 'error', true)
    },
    onErrored: error => {
      appActions.snackbar('宿泊者帳簿入力画面を表示できませんでした', 'error', true)
    }
  }
  ApiHandle(apiHandleOpts)
}

export default toCheckInView
