import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const showRefundDialog = invoiceId => {
  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.clearRefund)
  history.push(`/invoice/${invoiceId}/refund`)

  return true
}

export default showRefundDialog
