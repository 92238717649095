import AppDispatcher from '../../../core/AppDispatcher'
import { appActions } from '../../AppActions'
import MonthlyReportEvent from '../../../event/MonthlyReportEvent'
import accommodationTaxRepository from '../../../infra/ReportAccommodationTaxRepository'

const fetchByDate = async (date, hotel_id) => {
  const response = await accommodationTaxRepository.fetchAccommodationTax(date, hotel_id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    // 一覧を空で更新
    AppDispatcher.dispatchWithType(MonthlyReportEvent.accommodationTaxMonthChanged, { date: date, hotel_id: hotel_id, accommodation_tax: [] })
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyReportEvent.accommodationTaxMonthChanged, { date: date, hotel_id: hotel_id, accommodation_tax: response })

  return true
}

export default fetchByDate
