import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onDeleteDetail = (event, index, id) => {
  event.preventDefault()
  AppDispatcher.dispatchWithType(DailyClosingEvent.onDeleteDetail, { index: index, id: id })

  return true
}

export default onDeleteDetail
