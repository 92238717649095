import AppDispatcher from '../../core/AppDispatcher'
import LoginEvent from '../../event/LoginEvent'

const updateReferrer = path => {
  AppDispatcher.dispatchWithType(LoginEvent.updateReferrer, {
    path: path
  })
}

export default updateReferrer
