import history from '../../util/history'
import { appActions } from '../AppActions'
import HotelRepository from '../../infra/HotelRepository'

const onRequestDelete = async hotelId => {
  const response = await HotelRepository.requestDelete(hotelId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '宿の削除に失敗しました')
    return false
  }

  // TODO: 遷移先違うので変更
  history.push('/hotels')
  appActions.snackbar('宿を削除しました', 'success', true)
  return true
}

export default onRequestDelete
