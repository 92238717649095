import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleDetailEvent from '../../event/AccountTitleDetailEvent'

const onRequestUpdateDetail = async detail => {
  const response = await AccountTitleRepository.requestUpdateByDetail(detail.id, detail).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleDetailEvent.fetchOne, { detail: response })
  appActions.snackbar('更新しました', 'success', true)
  return true
}

export default onRequestUpdateDetail
