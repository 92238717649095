class MealCondition {
  static NO_MEAL = 'NoMeal'
  static INCLUDING_BREAKFAST = 'IncludingBreakfast'
  static INCLUDING_DINNER = 'IncludingDinner'
  static INCLUDING_BREAKFAST_AND_DINNER = 'IncludingBreakfastAndDinner'
  static INCLUDING_BREAKFAST_AND_LUNCH_AND_DINNER = 'IncludingBreakfastAndLunchAndDinner'
  static INCLUDING_BREAKFAST_AND_LUNCH = 'IncludingBreakfastAndLunch'
  static INCLUDING_LUNCH_AND_DINNER = 'IncludingLunchAndDinner'

  constructor() {
    this.NoMeal = MealCondition.NO_MEAL
    this.IncludingBreakfast = MealCondition.INCLUDING_BREAKFAST
    this.IncludingDinner = MealCondition.INCLUDING_DINNER
    this.IncludingBreakfastAndDinner = MealCondition.INCLUDING_BREAKFAST_AND_DINNER
    this.IncludingBreakfastAndLunchAndDinner = MealCondition.INCLUDING_BREAKFAST_AND_LUNCH_AND_DINNER
    this.IncludingBreakfastAndLunch = MealCondition.INCLUDING_BREAKFAST_AND_LUNCH
    this.IncludingLunchAndDinner = MealCondition.INCLUDING_LUNCH_AND_DINNER
  }

  labels = {
    NoMeal: '食事なし',
    IncludingBreakfast: '朝食付き',
    IncludingDinner: '夕食付き',
    IncludingBreakfastAndDinner: '朝夕食付き',
    IncludingBreakfastAndLunchAndDinner: '3食付き',
    IncludingBreakfastAndLunch: '朝昼食付き',
    IncludingLunchAndDinner: '昼夕食付き'
  }

  getLabel = code => {
    return this.labels[code] || code
  }

  options = Object.keys(this.labels).map(k => {
    return {
      value: k,
      label: this.labels[k]
    }
  })
}
const MealConditionInstance = new MealCondition()
export { MealCondition, MealConditionInstance }
