import NProgress from 'nprogress'
import CheckInRepository from '../../infra/CheckInRepository'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'

const onSearch = async (condition, pagination) => {
  NProgress.start()
  const response = await CheckInRepository.onSearch(condition, pagination).catch(e => e)
  NProgress.done()
  if (response instanceof Error) {
    appActions.showAlert(response, 'チェックインの検索に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(CheckInEvent.fetchAll, {
    reservations: response.reservations,
    pagination: response.pagination
  })
  return true
}

export default onSearch
