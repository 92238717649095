/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body34 from '../model/Body34'
import InlineResponse20036 from '../model/InlineResponse20036'
import SegmentCategoryList from '../model/SegmentCategoryList'
import SegmentUpdateBody1 from '../model/SegmentUpdateBody1'

/**
 * Segmentation service.
 * @module api/SegmentationApi
 * @version 1.0.0
 */
export default class SegmentationApi {
  /**
   * Constructs a new SegmentationApi.
   * @alias module:api/SegmentationApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 客層マスタを削除する
   * @param {Number} segmentCategoryId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  callDeleteWithHttpInfo(segmentCategoryId) {
    let postBody = null

    // verify the required parameter 'segmentCategoryId' is set
    if (segmentCategoryId === undefined || segmentCategoryId === null) {
      throw new Error("Missing the required parameter 'segmentCategoryId' when calling callDelete")
    }

    let pathParams = {
      segment_category_id: segmentCategoryId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/segmentation/{segment_category_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層マスタを削除する
   * @param {Number} segmentCategoryId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  callDelete(segmentCategoryId) {
    return this.callDeleteWithHttpInfo(segmentCategoryId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層マスタを新規登録する
   * @param {module:model/Body34} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling create")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/segmentation', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層マスタを新規登録する
   * @param {module:model/Body34} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  create(body) {
    return this.createWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層マスタを取得する
   * @param {Number} segmentCategoryId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20036} and HTTP response
   */
  getWithHttpInfo(segmentCategoryId) {
    let postBody = null

    // verify the required parameter 'segmentCategoryId' is set
    if (segmentCategoryId === undefined || segmentCategoryId === null) {
      throw new Error("Missing the required parameter 'segmentCategoryId' when calling get")
    }

    let pathParams = {
      segment_category_id: segmentCategoryId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20036

    return this.apiClient.callApi('/segmentation/{segment_category_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層マスタを取得する
   * @param {Number} segmentCategoryId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20036}
   */
  get(segmentCategoryId) {
    return this.getWithHttpInfo(segmentCategoryId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層マスタ一覧取得
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SegmentCategoryList} and HTTP response
   */
  getListWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = SegmentCategoryList

    return this.apiClient.callApi('/segmentation', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層マスタ一覧取得
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SegmentCategoryList}
   */
  getList() {
    return this.getListWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層マスタを更新する
   * @param {Number} segmentCategoryId Target id
   * @param {module:model/SegmentUpdateBody1} segmentUpdateBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateWithHttpInfo(segmentCategoryId, segmentUpdateBody) {
    let postBody = segmentUpdateBody

    // verify the required parameter 'segmentCategoryId' is set
    if (segmentCategoryId === undefined || segmentCategoryId === null) {
      throw new Error("Missing the required parameter 'segmentCategoryId' when calling update")
    }

    // verify the required parameter 'segmentUpdateBody' is set
    if (segmentUpdateBody === undefined || segmentUpdateBody === null) {
      throw new Error("Missing the required parameter 'segmentUpdateBody' when calling update")
    }

    let pathParams = {
      segment_category_id: segmentCategoryId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/segmentation/{segment_category_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層マスタを更新する
   * @param {Number} segmentCategoryId Target id
   * @param {module:model/SegmentUpdateBody1} segmentUpdateBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  update(segmentCategoryId, segmentUpdateBody) {
    return this.updateWithHttpInfo(segmentCategoryId, segmentUpdateBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
