import ApiHandle from '../../util/ApiHandle'
import { appActions } from '../AppActions'
import hotelManualRepository from '../../infra/HotelManualRepository'
import fetchAll from './fetchAll'

const onDropItem = async (id, srcIndex, roomId) => {
  const position = srcIndex + 1
  const response = await hotelManualRepository.requestUpdatePosition(id, position).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '並び替えに失敗しました')
    return false
  }

  // 実際のindexとpositionがずれるので再取得する
  fetchAll(roomId)
  appActions.snackbar('並び替えました', 'success', true)
  return true
}

export default onDropItem
