import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import SegmentationEvent from '../../event/SegmentationEvent'

const toCreateView = () => {
  history.push('/segment_settings/new')

  AppDispatcher.dispatchWithType(SegmentationEvent.onClear)
  return true
}

export default toCreateView
