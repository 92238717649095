import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'

const deleteForm = (event, index) => {
  event.stopPropagation()
  AppDispatcher.dispatchWithType(CheckInEvent.deleteForm, { index: index })
  return true
}

export default deleteForm
