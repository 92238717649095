import TaskAction from '../task'
import history from '../../util/history'

const showDetail = (reservationId, taskId) => {
  TaskAction.onInit()
  history.push(`/tasks/${taskId}`, { reservationId: reservationId })
  return true
}

export default showDetail
