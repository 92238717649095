/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import DailyClosingHead from './DailyClosingHead'

/**
 * The InlineResponse20024 model module.
 * @module model/InlineResponse20024
 * @version 1.0.0
 */
export default class InlineResponse20024 {
  /**
   * Constructs a new <code>InlineResponse20024</code>.
   * @alias module:model/InlineResponse20024
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20024</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20024} obj Optional instance to populate.
   * @return {module:model/InlineResponse20024} The populated <code>InlineResponse20024</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20024()

      if (data.hasOwnProperty('occupancy_dates')) {
        obj['occupancy_dates'] = ApiClient.convertToType(data['occupancy_dates'], 'Number')
      }
      if (data.hasOwnProperty('daily_closings')) {
        obj['daily_closings'] = ApiClient.convertToType(data['daily_closings'], [DailyClosingHead])
      }
    }
    return obj
  }

  /**
   * @member {Number} occupancy_dates
   */
  occupancy_dates = undefined
  /**
   * @member {Array.<module:model/DailyClosingHead>} daily_closings
   */
  daily_closings = undefined
}
