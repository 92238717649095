import AppDispatcher from '../../../core/AppDispatcher'
import CheckInEvent from '../../../event/CheckInEvent'
import AWS from 'aws-sdk'
import uuid from 'shortid'
import SelfCheckInRepository from '../../../infra/SelfCheckInRepository'
import arrayBufferToDataURI from '../../../util/DataURI'

async function uploadPassport(token, file, index) {
  const repository = new SelfCheckInRepository(token)
  const response = await repository.selfCheckInRequest().catch(() => false)
  if (response === false || !response.aws) {
    return false
  }
  return uploadObject(token, response.aws, file, index)
}

function uploadObject(token, credentials, file, index) {
  const reader = new FileReader()
  reader.onload = onLoad.bind(null, credentials, file, index)
  reader.readAsArrayBuffer(file)
  return true
}

function onLoad(credentials, file, index, event) {
  const unique = uuid.generate()
  const filename = unique + '_' + file.name
  const body = event.target.result
  const response = putObject(credentials, filename, body)
  if (response === false) {
    return false
  }

  // アップロードファイル名と画像を反映
  const filepath = `https://s3-ap-northeast-1.amazonaws.com/${credentials.bucket_name}/${credentials.bucket_dir}/${filename}`
  AppDispatcher.dispatchWithType(CheckInEvent.onEdit, {
    index: index,
    name: 'passport_url',
    value: filepath
  })

  const dataURI = arrayBufferToDataURI(body)
  AppDispatcher.dispatchWithType(CheckInEvent.onEdit, {
    index: index,
    name: 'passport_image',
    value: dataURI
  })
  return true
}

function putObject(credentials, filename, body) {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    accessKeyId: credentials.access_key,
    secretAccessKey: credentials.secret_key,
    sessionToken: credentials.session_token,
    region: 'ap-northeast-1'
  })
  const params = {
    Bucket: credentials.bucket_name,
    Key: credentials.bucket_dir + '/' + filename,
    Body: body
  }
  const response = s3
    .putObject(params)
    .promise()
    .catch(() => false)
  if (response === false) {
    return false
  }
  return true
}

export default uploadPassport
