import AppDispatcher from '../../core/AppDispatcher'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const fetchOne = async dailyClosingHeadId => {
  const response = await dailyClosingRepository.fetchOne(dailyClosingHeadId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(DailyClosingEvent.fetchOne, response)

  return response
}

export default fetchOne
