import HotelManualEvent from '../../event/HotelManualEvent'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import hotelManualRepository from '../../infra/HotelManualRepository'

const fetchOne = async id => {
  const response = await hotelManualRepository.fetchOne(id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(HotelManualEvent.fetchOne, response)
  return true
}

export default fetchOne
