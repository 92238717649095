import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const onCancelEditView = async () => {
  AppDispatcher.dispatchWithType(StripeKeyEvent.onClear)

  if (!history.canGoBack()) {
    history.push('/')
    return false
  }
  history.goBack()

  return true
}

export default onCancelEditView
