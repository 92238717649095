import history from '../../../util/history'

const onMonthChanged = (month, hotel_id) => {
  if (month) {
    history.push(`/report/accommodation_tax/date/${month}`, { hotel_id: hotel_id })
  } else {
    history.push(`/report/accommodation_tax`)
  }

  return true
}

export default onMonthChanged
