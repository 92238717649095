import history from '../../util/history'
import { appActions } from '../AppActions'
import SegmentationRepository from '../../infra/SegmentationRepository'

const onRequestUpdate = async (segmentCategory, segmentValues) => {
  const res = await SegmentationRepository.requestUpdate(segmentCategory, segmentValues).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '更新に失敗しました')
    return false
  }

  appActions.snackbar('更新しました', 'success', true)
  history.push('/segment_settings')
  return true
}

export default onRequestUpdate
