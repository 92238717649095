/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body12 from '../model/Body12'
import InlineResponse20013 from '../model/InlineResponse20013'
import InlineResponse20014 from '../model/InlineResponse20014'

/**
 * Plan service.
 * @module api/PlanApi
 * @version 1.0.0
 */
export default class PlanApi {
  /**
   * Constructs a new PlanApi.
   * @alias module:api/PlanApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * プランを取得する
   * @param {Number} planId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20013} and HTTP response
   */
  getPlanWithHttpInfo(planId) {
    let postBody = null

    // verify the required parameter 'planId' is set
    if (planId === undefined || planId === null) {
      throw new Error("Missing the required parameter 'planId' when calling getPlan")
    }

    let pathParams = {
      plan_id: planId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20013

    return this.apiClient.callApi('/plans/{plan_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * プランを取得する
   * @param {Number} planId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20013}
   */
  getPlan(planId) {
    return this.getPlanWithHttpInfo(planId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * プラン一覧を取得する
   * @param {module:model/Body12} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20014} and HTTP response
   */
  searchPlanWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchPlan")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20014

    return this.apiClient.callApi('/plans/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * プラン一覧を取得する
   * @param {module:model/Body12} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20014}
   */
  searchPlan(body) {
    return this.searchPlanWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
