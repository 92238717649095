/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AutoActionConfigHouse model module.
 * @module model/AutoActionConfigHouse
 * @version 1.0.0
 */
export default class AutoActionConfigHouse {
  /**
   * Constructs a new <code>AutoActionConfigHouse</code>.
   * @alias module:model/AutoActionConfigHouse
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AutoActionConfigHouse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutoActionConfigHouse} obj Optional instance to populate.
   * @return {module:model/AutoActionConfigHouse} The populated <code>AutoActionConfigHouse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutoActionConfigHouse()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('auto_action_config_id')) {
        obj['auto_action_config_id'] = ApiClient.convertToType(data['auto_action_config_id'], 'Number')
      }
      if (data.hasOwnProperty('room_id')) {
        obj['room_id'] = ApiClient.convertToType(data['room_id'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} auto_action_config_id
   */
  auto_action_config_id = undefined
  /**
   * @member {Number} room_id
   */
  room_id = undefined
}
