import './AccountTitleView.scss'
import React from 'react'
import { Edit } from '../../molecules'
import r from '../../../util/Renderer'
import { DeleteButton, Dropdown } from '../../atoms'
import TextInput from '../../atoms/input/TextInput'
import AccountTitleAction from '../../../action/account_title'

function AccountTitleEditView(props) {
  const title = props.title ? props.title : {}
  const id = title.id ? title.id : null
  const label = id ? '更新' : '作成'
  const onRequestSave = id ? t => AccountTitleAction.onRequestUpdate(t) : t => AccountTitleAction.onRequestCreate(t)
  const onCancel = () => AccountTitleAction.onCancelEditView()
  const onRequestDelete = title => AccountTitleAction.onRequestDelete(id)

  return (
    <Edit title={`勘定科目管理[${label}]`} buttonLabel={label} onCancel={e => onCancel()} onSubmit={e => onRequestSave(title)}>
      <div>
        <div>
          <div className="EditArea__row">
            <div className="EditArea__row--full">
              <div className="EditArea__column">
                <p className="input-required">分類名</p>
                <Dropdown name="account_title_group_id" options={props.group_options} value={title.account_title_group_id} onChange={AccountTitleAction.onEdit} placeholder="未選択" isMulti={false} />
              </div>
            </div>
          </div>
          <div className="EditArea__row">
            <div className="EditArea__row--full">
              <div className="EditArea__column">
                <p className="input-required">勘定科目名</p>
                <TextInput name="name" value={title.name} placeholder="勘定科目名" onChange={AccountTitleAction.onEdit} />
              </div>
            </div>
          </div>
        </div>
        {r.renderIf(id)(
          <div className="EditArea__Deletion">
            <DeleteButton type="danger" size="lg" onClick={onRequestDelete}>
              削除
            </DeleteButton>
          </div>
        )}
      </div>
    </Edit>
  )
}
export default AccountTitleEditView
