/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import SegmentCategory from './SegmentCategory'
import SegmentValue from './SegmentValue'

/**
 * The InlineResponse20036 model module.
 * @module model/InlineResponse20036
 * @version 1.0.0
 */
export default class InlineResponse20036 {
  /**
   * Constructs a new <code>InlineResponse20036</code>.
   * @alias module:model/InlineResponse20036
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20036</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20036} obj Optional instance to populate.
   * @return {module:model/InlineResponse20036} The populated <code>InlineResponse20036</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20036()

      if (data.hasOwnProperty('segment_category')) {
        obj['segment_category'] = SegmentCategory.constructFromObject(data['segment_category'])
      }
      if (data.hasOwnProperty('segment_values')) {
        obj['segment_values'] = ApiClient.convertToType(data['segment_values'], [SegmentValue])
      }
    }
    return obj
  }

  /**
   * @member {module:model/SegmentCategory} segment_category
   */
  segment_category = undefined
  /**
   * @member {Array.<module:model/SegmentValue>} segment_values
   */
  segment_values = undefined
}
