import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import SegmentationRepository from '../../infra/SegmentationRepository'
import SegmentationEvent from '../../event/SegmentationEvent'

const fetchOne = async id => {
  const response = await SegmentationRepository.fetchOne(id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(SegmentationEvent.fetchOne, response)

  return true
}

export default fetchOne
