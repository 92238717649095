import { appActions } from '../AppActions'
import EventScheduleRepository from '../../infra/EventScheduleRepository'

const onRequestCreate = async eventScheduleState => {
  const res = await EventScheduleRepository.requestCreate(eventScheduleState).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '作成に失敗しました')
    return false
  }

  appActions.snackbar('作成しました', 'success', true)
  return true
}

export default onRequestCreate
