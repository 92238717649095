import React from 'react'
import r from '../../../util/Renderer'
import { BaseLayout, ListViewLayout, LocalAreaLayout } from 'ui-library/dist/external/layouts'
import {
  DataTable,
  DataTableColumn as TD,
  DataTableHeader as TH,
  DataTableRow as TR,
  Detail,
  List,
  ListRow,
  ListRowItem,
  NavigationDrawer,
  Pagination,
  SearchField,
  SearchOption,
  TabHeader
} from 'ui-library/dist/external/components'
import { DateRangeField, Dropdown } from '../../atoms'

export default props => {
  const toolbar = { label: `売上一覧`, items: props.accountMenu }
  const pagination = props.pagination

  return (
    <React.Fragment>
      <NavigationDrawer toolbar={toolbar} navigations={props.navigations} />

      <BaseLayout>
        <LocalAreaLayout
          left={
            <SearchField
              name="search_keywords"
              value={''}
              onChange={e => {}}
              onSearch={e => {}}
              searchOptions={[
                <SearchOption
                  key={1}
                  label={'宿泊施設'}
                  field={<Dropdown className="searchHouse" name="search_room_ids" value={''} options={[]} isClearable={true} isMulti={true} searchable={true} onChange={e => {}} />}
                />,
                <SearchOption
                  key={2}
                  label={'期間'}
                  field={<DateRangeField className="searchDate" startDateName="search_start_date" endDateName="search_end_date" startDate={''} endDate={''} onChange={e => {}} />}
                />,
                <SearchOption
                  key={3}
                  label={'状態'}
                  field={
                    <ul>
                      <li />
                      <li />
                      <li />
                      <li />
                      <li />
                    </ul>
                  }
                />
              ]}
            />
          }
        />

        <ListViewLayout>
          <DataTable
            headers={[
              <TH key={1} width="10%">
                状態
              </TH>,
              <TH key={2} width="18%">
                宿泊施設
              </TH>,
              <TH key={3} width="20%">
                氏名
              </TH>,
              <TH key={4} width="18%">
                金額
              </TH>,
              <TH key={5} width="10%">
                通貨
              </TH>,
              <TH key={6} width="15%" isNumber>
                締め状態
              </TH>,
              <TH key={7} width="10%">
                作成日時
              </TH>
            ]}
            rows={[
              <TR key={1}>
                <TD>Status</TD>
                <TD>aa</TD>
                <TD>invoice.room_name</TD>
                <TD>invoice.guest_name</TD>
                <TD>InvoiceType</TD>
                <TD isNumber>Price</TD>
                <TD>invoice</TD>
              </TR>
            ]}
          />
        </ListViewLayout>

        {r.renderIf(props.showDetail)(
          <Detail
            showHeader
            showFooter
            primaryButton={{ label: '入金確認済み', onClick: e => console.log('完了') }}
            menuOptions={[
              {
                label: '未払いにする',
                onClick: () => {}
              },
              {
                label: '削除',
                onClick: () => {}
              }
            ]}
          >
            <TabHeader tabs={[]} />
            <List>
              <ListRow key={1}>
                <ListRowItem width={'100%'} overline={`請求ID`} primaryText={'props.invoice.id'} />
              </ListRow>
              <ListRow key={2}>
                <ListRowItem width={'100%'} overline={`請求ID`} primaryText={'props.invoice.id'} />
              </ListRow>
            </List>
          </Detail>
        )}
      </BaseLayout>
      <Pagination name="pagination" perPage={50} total={100} page={1} prev={e => {}} next={e => {}} perPageChanged={e => {}} />
    </React.Fragment>
  )
}
