import AppDispatcher from '../../core/AppDispatcher'
import ApiHandle from '../../util/ApiHandle'
import { appActions } from '../AppActions'
import ReservationApi from '../../api/ReservationApi'
import ApiClient from '../../ApiClient'
import ReservationEvent from '../../event/ReservationEvent'
import ToggleRequestEvent from '../../core/ToggleRequestEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchAuditLog = reservationId => {
  return reservationRepository.fetchRequest(ReservationEvent.fetchAuditLog, () => reservationRepository.fetchAuditLog(reservationId), '変更履歴が取得できませんした')
}

export default fetchAuditLog
