/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import DailyClosingSummary from './DailyClosingSummary'
import MonthlyClosingDetail from './MonthlyClosingDetail'
import MonthlyClosingHead from './MonthlyClosingHead'

/**
 * The InlineResponse201 model module.
 * @module model/InlineResponse201
 * @version 1.0.0
 */
export default class InlineResponse201 {
  /**
   * Constructs a new <code>InlineResponse201</code>.
   * @alias module:model/InlineResponse201
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse201</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse201} obj Optional instance to populate.
   * @return {module:model/InlineResponse201} The populated <code>InlineResponse201</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse201()

      if (data.hasOwnProperty('monthly_closing_head')) {
        obj['monthly_closing_head'] = MonthlyClosingHead.constructFromObject(data['monthly_closing_head'])
      }
      if (data.hasOwnProperty('monthly_closing_details')) {
        obj['monthly_closing_details'] = ApiClient.convertToType(data['monthly_closing_details'], [MonthlyClosingDetail])
      }
      if (data.hasOwnProperty('daily_closing_summary')) {
        obj['daily_closing_summary'] = DailyClosingSummary.constructFromObject(data['daily_closing_summary'])
      }
    }
    return obj
  }

  /**
   * @member {module:model/MonthlyClosingHead} monthly_closing_head
   */
  monthly_closing_head = undefined
  /**
   * @member {Array.<module:model/MonthlyClosingDetail>} monthly_closing_details
   */
  monthly_closing_details = undefined
  /**
   * @member {module:model/DailyClosingSummary} daily_closing_summary
   */
  daily_closing_summary = undefined
}
