import ApiClient from '../ApiClient'
import ReservationApi from '../api/ReservationApi'
import AppRepository from './AppRepository'

class ReservationRepository extends AppRepository {
  constructor() {
    super()
    this._api = new ReservationApi(ApiClient.instance)
  }

  fetchOne(reservationId) {
    return this._api.getReservationDetail(reservationId)
  }

  fetchByDate(date) {
    const dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this._api.getDailyReservation(dateStr)
  }

  fetchMonthlyByDate(date) {
    const dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this._api.getMonthlyReservation(dateStr)
  }

  fetchBySearchCondition(state, pagination) {
    let reservation = state.toObject()
    delete reservation.loading_room_option
    delete reservation.search_room_options
    const body = { reservation: reservation, pagination: pagination.toObject() }
    return this._api.searchReservation(body)
  }

  // Used in ReceptionistView
  fetchReceptionistBy(receptionistSearchConditionState) {
    let searchCondition = receptionistSearchConditionState.toObject()
    delete searchCondition.search_hotel_options
    const body = { reservation: searchCondition }
    return this._api.searchReceptionist(body)
  }

  // TODO: -> TaskRepository
  fetchTasks(reservationId) {
    return this._api.getReservationTasks(reservationId)
  }

  // TODO: => CheckInRepository
  fetchCheckIns(reservationId) {
    return this._api.getCheckIns(reservationId)
  }

  fetchPayments(reservationId) {
    return this._api.getPayments(reservationId)
  }

  fetchInvoices(reservationId) {
    return this._api.getInvoices(reservationId)
  }

  fetchAuditLog(reservationId) {
    return this._api.getReservationLog(reservationId)
  }

  fetchTransaction(reservationId) {
    return this._api.getReservationTransactions(reservationId)
  }

  requestCreate(reservation) {
    const body = { reservation: reservation }
    return this._api.createReservation(body)
  }

  requestUpdate(reservation) {
    const body = { reservation: reservation }
    return this._api.editReservation(reservation.id, body)
  }

  requestCancel(reservation) {
    const body = { reservation: reservation.merge({ status: 'cancelled' }) }
    return this._api.editReservation(reservation.id, body)
  }

  requestCheckin(reservationId) {
    return this._api.checkinReservation(reservationId)
  }

  updateReservationPay(reservationId, paidFee, paidTax) {
    const body = {
      reservation_pay: {
        reservation_id: reservationId,
        accommodation_fee_paid: paidFee,
        accommodation_tax_paid: paidTax
      }
    }
    return this._api.updateReservationPay(reservationId, body)
  }

  updateNoshow(reservationId, noshow) {
    const body = {
      reservation: {
        id: reservationId,
        noshow: noshow
      }
    }
    return this._api.updateNoshow(reservationId, body)
  }

  fetchSegment(reservationId) {
    return this._api.getSegment(reservationId)
  }
  requestSaveSegment(reservationId, reservationSegments) {
    const body = reservationSegments.map(segment => segment.toObject())
    return this._api.saveSegment(reservationId, { reservation_segments: body })
  }
}

// singleton
export default new ReservationRepository()
