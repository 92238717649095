import fetchUserHotels from './fetchUserHotels'
import fetchUserRooms from './fetchUserRooms'
import fetchRoomsByHotelId from './fetchRoomsByHotelId'

const SessionAction = {
  fetchRoomsByHotelId,
  fetchUserHotels,
  fetchUserRooms
}

export default SessionAction
