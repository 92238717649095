import React from 'react'

export default function IconWrong(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
      <g fill="none" fillRule="evenodd" stroke="#DB2D26" strokeWidth="2">
        <circle cx="30" cy="30" r="29.5" />
        <g strokeLinecap="square">
          <path d="M42.728 17.272L17.272 42.728M43.081 42.374L17.626 16.92" />
        </g>
      </g>
    </svg>
  )
}
