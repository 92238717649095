import { Record } from 'immutable'

/**
 * `auto_action_configs` と `auto_action_mails` の2テーブルを表現したステートクラス
 *  自動送信メールの設定ではこの2テーブルを1画面で同時に扱う
 */
const _AutoActionMailConfigState = {
  // config
  auto_action_config_id: null,
  user_id: null,
  status: null,
  action_type: 'mail',
  trigger_event: '', // {'cancelled', 'after_booking', 'check_in', 'check_out'}
  trigger_time: 0,
  retry_period: 0,
  // mail
  auto_action_mail_id: null,
  name: '',
  title: '',
  body: null,
  mail_to: null,
  smtp_setting_id: null,
  mail_reply_to: '',
  mail_cc: null,
  mail_bcc: null,
  // rooms
  room_ids: [],
  // site_referrers
  site_referrer_ids: []
}

class AutoActionMailConfigState extends Record(_AutoActionMailConfigState) {
  mergeModel(model) {
    const config = model.config
    const mail = model.mail

    return this.merge({
      // config
      auto_action_config_id: config.id,
      user_id: config.user_id,
      status: config.status,
      action_type: 'mail',
      trigger_event: config.trigger_event,
      trigger_time: config.trigger_time,
      retry_period: config.retry_period,
      // mail
      auto_action_mail_id: mail.id,
      name: mail.name,
      title: mail.title,
      body: mail.body,
      mail_to: mail.mail_to,
      smtp_setting_id: mail.smtp_setting_id,
      mail_reply_to: mail.mail_reply_to,
      mail_cc: mail.mail_cc,
      mail_bcc: mail.mail_bcc,

      room_ids: model.rooms.map(_ => _.room_id),
      site_referrer_ids: model.site_referrers.map(_ => _.site_referrer_id)
    })
  }

  updateValue(key, value) {
    return this.set(key, value)
  }

  getConfig() {
    return {
      id: this.auto_action_config_id,
      user_id: this.user_id,
      status: this.status,
      action_type: this.action_type,
      trigger_event: this.trigger_event,
      trigger_time: this.trigger_time,
      retry_period: this.retry_period
    }
  }

  getApiParameters() {
    return {
      config: {
        status: this.status,
        action_type: 'mail',
        trigger_event: this.trigger_event,
        trigger_time: this.trigger_time,
        retry_period: this.retry_period
      },
      mail: {
        name: this.name,
        title: this.title,
        body: this.body,
        mail_to: this.mail_to,
        smtp_setting_id: this.smtp_setting_id,
        mail_reply_to: this.mail_reply_to,
        mail_cc: this.mail_cc,
        mail_bcc: this.mail_bcc
      },
      room_ids: this.room_ids,
      site_referrer_ids: this.site_referrer_ids
    }
  }
}

export { AutoActionMailConfigState }
