import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import NProgress from 'nprogress'
import StripeApiKeyRepository from '../../infra/StripeApiKeyRepository'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const onSearch = async (state, pagination) => {
  NProgress.start()
  const response = await StripeApiKeyRepository.fetchBySearchCondition(state, pagination).catch(e => e)
  NProgress.done()
  if (response instanceof Error) {
    appActions.showAlert(response, '予約の検索に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(StripeKeyEvent.onSearch, response)

  return true
}

export default onSearch
