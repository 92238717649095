import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import ReservationEvent from '../../event/ReservationEvent'

const cancelCreationView = () => {
  AppDispatcher.dispatchWithType(ReservationEvent.cancelCreation)
  history.goBack()

  return true
}

export default cancelCreationView
