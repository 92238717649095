/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The InlineResponse2005Aws model module.
 * @module model/InlineResponse2005Aws
 * @version 1.0.0
 */
export default class InlineResponse2005Aws {
  /**
   * Constructs a new <code>InlineResponse2005Aws</code>.
   * @alias module:model/InlineResponse2005Aws
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse2005Aws</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2005Aws} obj Optional instance to populate.
   * @return {module:model/InlineResponse2005Aws} The populated <code>InlineResponse2005Aws</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2005Aws()

      if (data.hasOwnProperty('bucket_name')) {
        obj['bucket_name'] = ApiClient.convertToType(data['bucket_name'], 'String')
      }
      if (data.hasOwnProperty('bucket_dir')) {
        obj['bucket_dir'] = ApiClient.convertToType(data['bucket_dir'], 'String')
      }
      if (data.hasOwnProperty('access_key')) {
        obj['access_key'] = ApiClient.convertToType(data['access_key'], 'String')
      }
      if (data.hasOwnProperty('secret_key')) {
        obj['secret_key'] = ApiClient.convertToType(data['secret_key'], 'String')
      }
      if (data.hasOwnProperty('session_token')) {
        obj['session_token'] = ApiClient.convertToType(data['session_token'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {String} bucket_name
   */
  bucket_name = undefined
  /**
   * @member {String} bucket_dir
   */
  bucket_dir = undefined
  /**
   * @member {String} access_key
   */
  access_key = undefined
  /**
   * @member {String} secret_key
   */
  secret_key = undefined
  /**
   * @member {String} session_token
   */
  session_token = undefined
}
