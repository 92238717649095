import AppDispatcher from '../../core/AppDispatcher'
import HotelRepository from '../../infra/HotelRepository'
import { appActions } from '../AppActions'
import HotelEvent from '../../event/HotelEvent'

const fetchAll = async () => {
  const response = await HotelRepository.fetchAll().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(HotelEvent.fetchAll, { hotels: response })
  return true
}

export default fetchAll
