import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleGroupEvent from '../../event/AccountTitileGroupEvent'

const fetchAccountTitleGroups = async () => {
  const response = await AccountTitleRepository.getAccountTitleGroups().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleGroupEvent.fetchAll, { groups: response })
  return true
}

export default fetchAccountTitleGroups
