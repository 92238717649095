import fetchOne from './fetchOne'
import onCancelEditView from './onCancelEditView'
import onEdit from './onEdit'
import onRequestCreate from './onRequestCreate'
import onRequestDelete from './onRequestDelete'
import onRequestUpdate from './onRequestUpdate'

const EventScheduleAction = {
  fetchOne,
  onCancelEditView,
  onEdit,
  onRequestCreate,
  onRequestDelete,
  onRequestUpdate
}

export default EventScheduleAction
