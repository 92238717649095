/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AutoActionMailContent from './AutoActionMailContent'

/**
 * The AutoActionLog model module.
 * @module model/AutoActionLog
 * @version 1.0.0
 */
export default class AutoActionLog {
  /**
   * Constructs a new <code>AutoActionLog</code>.
   * @alias module:model/AutoActionLog
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AutoActionLog</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutoActionLog} obj Optional instance to populate.
   * @return {module:model/AutoActionLog} The populated <code>AutoActionLog</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutoActionLog()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
      if (data.hasOwnProperty('auto_action_mail_id')) {
        obj['auto_action_mail_id'] = ApiClient.convertToType(data['auto_action_mail_id'], 'Number')
      }
      if (data.hasOwnProperty('auto_action_task_id')) {
        obj['auto_action_task_id'] = ApiClient.convertToType(data['auto_action_task_id'], 'Number')
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String')
      }
      if (data.hasOwnProperty('mail_to')) {
        obj['mail_to'] = ApiClient.convertToType(data['mail_to'], 'String')
      }
      if (data.hasOwnProperty('mail_from')) {
        obj['mail_from'] = ApiClient.convertToType(data['mail_from'], 'String')
      }
      if (data.hasOwnProperty('mail_reply_to')) {
        obj['mail_reply_to'] = ApiClient.convertToType(data['mail_reply_to'], 'String')
      }
      if (data.hasOwnProperty('mail_cc')) {
        obj['mail_cc'] = ApiClient.convertToType(data['mail_cc'], 'String')
      }
      if (data.hasOwnProperty('mail_bcc')) {
        obj['mail_bcc'] = ApiClient.convertToType(data['mail_bcc'], 'String')
      }
      if (data.hasOwnProperty('execute_at')) {
        obj['execute_at'] = ApiClient.convertToType(data['execute_at'], 'Date')
      }
      if (data.hasOwnProperty('expire_at')) {
        obj['expire_at'] = ApiClient.convertToType(data['expire_at'], 'Date')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('error_message')) {
        obj['error_message'] = ApiClient.convertToType(data['error_message'], 'String')
      }
      if (data.hasOwnProperty('auto_action_mail_content')) {
        obj['auto_action_mail_content'] = AutoActionMailContent.constructFromObject(data['auto_action_mail_content'])
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
  /**
   * @member {Number} auto_action_mail_id
   */
  auto_action_mail_id = undefined
  /**
   * @member {Number} auto_action_task_id
   */
  auto_action_task_id = undefined
  /**
   * @member {String} title
   */
  title = undefined
  /**
   * @member {String} mail_to
   */
  mail_to = undefined
  /**
   * @member {String} mail_from
   */
  mail_from = undefined
  /**
   * @member {String} mail_reply_to
   */
  mail_reply_to = undefined
  /**
   * @member {String} mail_cc
   */
  mail_cc = undefined
  /**
   * @member {String} mail_bcc
   */
  mail_bcc = undefined
  /**
   * @member {Date} execute_at
   */
  execute_at = undefined
  /**
   * @member {Date} expire_at
   */
  expire_at = undefined
  /**
   * Enum(failed, closed, in_progress)
   * @member {String} status
   */
  status = undefined
  /**
   * @member {String} error_message
   */
  error_message = undefined
  /**
   * @member {module:model/AutoActionMailContent} auto_action_mail_content
   */
  auto_action_mail_content = undefined
}
