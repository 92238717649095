/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The Sensor model module.
 * @module model/Sensor
 * @version 1.0.0
 */
export default class Sensor {
  /**
   * Constructs a new <code>Sensor</code>.
   * @alias module:model/Sensor
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>Sensor</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Sensor} obj Optional instance to populate.
   * @return {module:model/Sensor} The populated <code>Sensor</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Sensor()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('sound_threshold')) {
        obj['sound_threshold'] = ApiClient.convertToType(data['sound_threshold'], 'Number')
      }
      if (data.hasOwnProperty('current_sound_level')) {
        obj['current_sound_level'] = ApiClient.convertToType(data['current_sound_level'], 'Number')
      }
      if (data.hasOwnProperty('current_temperature')) {
        obj['current_temperature'] = ApiClient.convertToType(data['current_temperature'], 'Number')
      }
      if (data.hasOwnProperty('current_humidity')) {
        obj['current_humidity'] = ApiClient.convertToType(data['current_humidity'], 'Number')
      }
      if (data.hasOwnProperty('current_voltage')) {
        obj['current_voltage'] = ApiClient.convertToType(data['current_voltage'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} sound_threshold
   */
  sound_threshold = undefined
  /**
   * @member {Number} current_sound_level
   */
  current_sound_level = undefined
  /**
   * @member {Number} current_temperature
   */
  current_temperature = undefined
  /**
   * @member {Number} current_humidity
   */
  current_humidity = undefined
  /**
   * @member {Number} current_voltage
   */
  current_voltage = undefined
}
