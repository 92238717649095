import { appActions } from '../AppActions'
import hotelManualRepository from '../../infra/HotelManualRepository'
import fetchAll from './fetchAll'

const onDelete = async (roomId, id) => {
  const response = await hotelManualRepository.requestDelete(id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '削除に失敗しました')
    return false
  }

  fetchAll(roomId)
  appActions.snackbar('削除しました', 'success', true)
  return true
}

export default onDelete
