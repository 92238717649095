import addDetail from './addDetail'
import addReservationEditView from './addReservationEditView'
import addReservation from './addReservation'
import dateChange from './dateChange'
import fetchAll from './fetchAll'
import fetchDate from './fetchDate'
import fetchOne from './fetchOne'
import fetchOneByReservationId from './fetchOneByReservationId'
import initialize from './initialize'
import onCancelEditView from './onCancelEditView'
import onEdit from './onEdit'
import onEditDetail from './onEditDetail'
import onEditSearchCondition from './onEditSearchCondition'
import onEditReservation from './onEditReservation'
import onDeleteDetail from './onDeleteDetail'
import onDeleteReservation from './onDeleteReservation'
import onRequestCreate from './onRequestCreate'
import onRequestDelete from './onRequestDelete'
import onRequestUnlock from './onRequestUnlock'
import onRequestUpdate from './onRequestUpdate'
import onRequestSearch from './onRequestSearch'
import setDailyClosingReservations from './setDailyClosingReservations'
import toEditView from './toEditView'
import toShowView from './toShowView'
import getTransactionReservation from './getTransactionReservation'

const DailyClosingAction = {
  addDetail,
  addReservationEditView,
  addReservation,
  dateChange,
  fetchAll,
  fetchDate,
  fetchOne,
  fetchOneByReservationId,
  getTransactionReservation,
  initialize,
  onCancelEditView,
  onEdit,
  onEditDetail,
  onEditSearchCondition,
  onEditReservation,
  onDeleteDetail,
  onDeleteReservation,
  onRequestCreate,
  onRequestDelete,
  onRequestUnlock,
  onRequestUpdate,
  onRequestSearch,
  setDailyClosingReservations,
  toEditView,
  toShowView
}

export default DailyClosingAction
