import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationRepository from '../../infra/ReservationRepository'
import ReservationSegmentEvent from '../../event/ReservationSegmentEvent'

const fetch = async reservation_id => {
  const response = await ReservationRepository.fetchSegment(reservation_id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationSegmentEvent.fetch, {
    reservation_id: reservation_id,
    reservation_segments: response.reservation_segments,
    segment_category_options: response.segment_category_options
  })
  return true
}

export default fetch
