import TaskEvent from '../../event/TaskEvent'
import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import taskRepository from '../../infra/TaskRepository'

const fetchOne = async taskId => {
  const response = await taskRepository.fetchOne(taskId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(TaskEvent.fetchOne, {
    task: response
  })
  return true
}

export default fetchOne
