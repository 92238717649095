import checkConnection from './checkConnection'
import fetchOne from './fetchOne'
import onCancelEditView from './onCancelEditView'
import onEdit from './onEdit'
import onEditSearchCondition from './onEditSearchCondition'
import onRequestCreate from './onRequestCreate'
import onRequestDelete from './onRequestDelete'
import onRequestUpdate from './onRequestUpdate'
import onSearch from './onSearch'
import toEditView from './toEditView'

const StripeKeyAction = {
  checkConnection,
  fetchOne,
  onCancelEditView,
  onEdit,
  onEditSearchCondition,
  onRequestCreate,
  onRequestDelete,
  onRequestUpdate,
  onSearch,
  toEditView
}

export default StripeKeyAction
