/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The Room model module.
 * @module model/Room
 * @version 1.0.0
 */
export default class Room {
  /**
   * Constructs a new <code>Room</code>.
   * @alias module:model/Room
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>Room</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Room} obj Optional instance to populate.
   * @return {module:model/Room} The populated <code>Room</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Room()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('room_group_code')) {
        obj['room_group_code'] = ApiClient.convertToType(data['room_group_code'], 'String')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('image_url')) {
        obj['image_url'] = ApiClient.convertToType(data['image_url'], 'String')
      }
      if (data.hasOwnProperty('information_url')) {
        obj['information_url'] = ApiClient.convertToType(data['information_url'], 'String')
      }
      if (data.hasOwnProperty('map_url')) {
        obj['map_url'] = ApiClient.convertToType(data['map_url'], 'String')
      }
      if (data.hasOwnProperty('alert_email')) {
        obj['alert_email'] = ApiClient.convertToType(data['alert_email'], 'String')
      }
      if (data.hasOwnProperty('system_error_email')) {
        obj['system_error_email'] = ApiClient.convertToType(data['system_error_email'], 'String')
      }
      if (data.hasOwnProperty('beds24_api_key')) {
        obj['beds24_api_key'] = ApiClient.convertToType(data['beds24_api_key'], 'String')
      }
      if (data.hasOwnProperty('beds24_prop_key')) {
        obj['beds24_prop_key'] = ApiClient.convertToType(data['beds24_prop_key'], 'String')
      }
      if (data.hasOwnProperty('beds24_roomid')) {
        obj['beds24_roomid'] = ApiClient.convertToType(data['beds24_roomid'], 'String')
      }
      if (data.hasOwnProperty('neppan_base_url')) {
        obj['neppan_base_url'] = ApiClient.convertToType(data['neppan_base_url'], 'String')
      }
      if (data.hasOwnProperty('neppan_userid')) {
        obj['neppan_userid'] = ApiClient.convertToType(data['neppan_userid'], 'String')
      }
      if (data.hasOwnProperty('neppan_password')) {
        obj['neppan_password'] = ApiClient.convertToType(data['neppan_password'], 'String')
      }
      if (data.hasOwnProperty('neppan_room_type_code')) {
        obj['neppan_room_type_code'] = ApiClient.convertToType(data['neppan_room_type_code'], 'String')
      }
      if (data.hasOwnProperty('key_code')) {
        obj['key_code'] = ApiClient.convertToType(data['key_code'], 'String')
      }
      if (data.hasOwnProperty('default_check_in')) {
        obj['default_check_in'] = ApiClient.convertToType(data['default_check_in'], 'String')
      }
      if (data.hasOwnProperty('default_check_out')) {
        obj['default_check_out'] = ApiClient.convertToType(data['default_check_out'], 'String')
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = ApiClient.convertToType(data['address'], 'String')
      }
      if (data.hasOwnProperty('logo_url')) {
        obj['logo_url'] = ApiClient.convertToType(data['logo_url'], 'String')
      }
      if (data.hasOwnProperty('menu_image_url')) {
        obj['menu_image_url'] = ApiClient.convertToType(data['menu_image_url'], 'String')
      }
      if (data.hasOwnProperty('top_logo_url')) {
        obj['top_logo_url'] = ApiClient.convertToType(data['top_logo_url'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} room_group_code
   */
  room_group_code = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {String} image_url
   */
  image_url = undefined
  /**
   * @member {String} information_url
   */
  information_url = undefined
  /**
   * @member {String} map_url
   */
  map_url = undefined
  /**
   * @member {String} alert_email
   */
  alert_email = undefined
  /**
   * @member {String} system_error_email
   */
  system_error_email = undefined
  /**
   * @member {String} beds24_api_key
   */
  beds24_api_key = undefined
  /**
   * @member {String} beds24_prop_key
   */
  beds24_prop_key = undefined
  /**
   * @member {String} beds24_roomid
   */
  beds24_roomid = undefined
  /**
   * @member {String} neppan_base_url
   */
  neppan_base_url = undefined
  /**
   * @member {String} neppan_userid
   */
  neppan_userid = undefined
  /**
   * @member {String} neppan_password
   */
  neppan_password = undefined
  /**
   * @member {String} neppan_room_type_code
   */
  neppan_room_type_code = undefined
  /**
   * @member {String} key_code
   */
  key_code = undefined
  /**
   * @member {String} default_check_in
   */
  default_check_in = undefined
  /**
   * @member {String} default_check_out
   */
  default_check_out = undefined
  /**
   * @member {String} address
   */
  address = undefined
  /**
   * @member {String} logo_url
   */
  logo_url = undefined
  /**
   * @member {String} menu_image_url
   */
  menu_image_url = undefined
  /**
   * @member {String} top_logo_url
   */
  top_logo_url = undefined
}
