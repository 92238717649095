import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'
import history from '../../util/history'

const onRequestDelete = async id => {
  const res = await dailyClosingRepository.requestDelete(id).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '削除できませんでした')
    return false
  }

  if (history.canGoBack()) {
    history.goBack()
  } else {
    window.close()
  }
  appActions.snackbar('削除しました', 'success', true)

  return true
}

export default onRequestDelete
