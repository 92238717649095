import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'

const onUpdatePaid = async invoiceId => {
  const response = await repository.updatePaid(invoiceId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }

  appActions.snackbar(`この請求を支払済みに変更しました`, 'success', true)

  return true
}

export default onUpdatePaid
