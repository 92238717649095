import { Record } from 'immutable'

const AutoActionMailContentState = Record({
  id: null,
  smtp_setting_id: null,
  subject: null,
  body: null,
  to: null,
  from: null,
  reply_to: null,
  cc: '',
  bcc: ''
})

export { AutoActionMailContentState }
