import AppDispatcher from '../../core/AppDispatcher'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onEditSearchCondition = event => {
  const name = event.target.name

  if (name === 'search_status_booked' || name === 'search_status_cancelled' || name === 'search_status_not_a_reservation') {
    AppDispatcher.dispatchWithType(DailyClosingEvent.onEditSearchCondition, {
      name: name,
      value: event.target.checked
    })
    return true
  }

  AppDispatcher.dispatchWithType(DailyClosingEvent.onEditSearchCondition, {
    name: name,
    value: event.target.value
  })

  return true
}

export default onEditSearchCondition
