import fetchAccommodationTaxByDate from './fetchByDate'
import fetchAccommodationTaxDetail from './fetchDetail'
import onAccommodataTaxMonthChanged from './onMonthChanged'
import onEditAccommodationTaxSearchCondition from './onEditSearchCondition'
import onAccommodationTaxRequestSearch from './onRequestSearch'
import showAccommodationTaxDetail from './showDetailView'
import hideAccommodationTaxDetail from './hideDetailView'
import onRequestUpdate from './onRequestUpdate'

const ReportAccommodationTaxAction = {
  fetchAccommodationTaxByDate,
  fetchAccommodationTaxDetail,
  onAccommodataTaxMonthChanged,
  onEditAccommodationTaxSearchCondition,
  onAccommodationTaxRequestSearch,
  showAccommodationTaxDetail,
  hideAccommodationTaxDetail,
  onRequestUpdate
}

export default ReportAccommodationTaxAction
