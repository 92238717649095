/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationTaxBody from '../model/AccommodationTaxBody'
import AccommodationTaxBody1 from '../model/AccommodationTaxBody1'
import InlineResponse20012 from '../model/InlineResponse20012'

/**
 * AccommodationTax service.
 * @module api/AccommodationTaxApi
 * @version 1.0.0
 */
export default class AccommodationTaxApi {
  /**
   * Constructs a new AccommodationTaxApi.
   * @alias module:api/AccommodationTaxApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 宿泊税の実績を新規登録
   * @param {Number} reservationId parent reservation id
   * @param {module:model/AccommodationTaxBody} accommodationTaxBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20012} and HTTP response
   */
  createAccommodationTaxWithHttpInfo(reservationId, accommodationTaxBody) {
    let postBody = accommodationTaxBody

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling createAccommodationTax")
    }

    // verify the required parameter 'accommodationTaxBody' is set
    if (accommodationTaxBody === undefined || accommodationTaxBody === null) {
      throw new Error("Missing the required parameter 'accommodationTaxBody' when calling createAccommodationTax")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20012

    return this.apiClient.callApi('/reservations/{reservation_id}/accommodation_tax', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊税の実績を新規登録
   * @param {Number} reservationId parent reservation id
   * @param {module:model/AccommodationTaxBody} accommodationTaxBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20012}
   */
  createAccommodationTax(reservationId, accommodationTaxBody) {
    return this.createAccommodationTaxWithHttpInfo(reservationId, accommodationTaxBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊税の実績を削除する
   * @param {Number} reservationId parent reservation id
   * @param {Number} id Target accommodation tax id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteAccommodationTaxWithHttpInfo(reservationId, id) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling deleteAccommodationTax")
    }

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling deleteAccommodationTax")
    }

    let pathParams = {
      reservation_id: reservationId,
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi(
      '/reservations/{reservation_id}/accommodation_tax/{id}',
      'DELETE',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 宿泊税の実績を削除する
   * @param {Number} reservationId parent reservation id
   * @param {Number} id Target accommodation tax id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteAccommodationTax(reservationId, id) {
    return this.deleteAccommodationTaxWithHttpInfo(reservationId, id).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get accommodation tax
   * @param {Number} reservationId parent reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20012} and HTTP response
   */
  getAccommodationTaxWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getAccommodationTax")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20012

    return this.apiClient.callApi('/reservations/{reservation_id}/accommodation_tax', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get accommodation tax
   * @param {Number} reservationId parent reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20012}
   */
  getAccommodationTax(reservationId) {
    return this.getAccommodationTaxWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get accommodation tax by id
   * @param {Number} reservationId parent reservation id
   * @param {Number} id Target accommodation tax id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20012} and HTTP response
   */
  getAccommodationTaxByIdWithHttpInfo(reservationId, id) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getAccommodationTaxById")
    }

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAccommodationTaxById")
    }

    let pathParams = {
      reservation_id: reservationId,
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20012

    return this.apiClient.callApi(
      '/reservations/{reservation_id}/accommodation_tax/{id}',
      'GET',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * get accommodation tax by id
   * @param {Number} reservationId parent reservation id
   * @param {Number} id Target accommodation tax id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20012}
   */
  getAccommodationTaxById(reservationId, id) {
    return this.getAccommodationTaxByIdWithHttpInfo(reservationId, id).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊税の実績を更新する
   * @param {Number} reservationId parent reservation id
   * @param {Number} id Target accommodation tax id
   * @param {module:model/AccommodationTaxBody1} accommodationTaxBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20012} and HTTP response
   */
  updateAccommodationTaxWithHttpInfo(reservationId, id, accommodationTaxBody) {
    let postBody = accommodationTaxBody

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling updateAccommodationTax")
    }

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling updateAccommodationTax")
    }

    // verify the required parameter 'accommodationTaxBody' is set
    if (accommodationTaxBody === undefined || accommodationTaxBody === null) {
      throw new Error("Missing the required parameter 'accommodationTaxBody' when calling updateAccommodationTax")
    }

    let pathParams = {
      reservation_id: reservationId,
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20012

    return this.apiClient.callApi(
      '/reservations/{reservation_id}/accommodation_tax/{id}',
      'PATCH',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 宿泊税の実績を更新する
   * @param {Number} reservationId parent reservation id
   * @param {Number} id Target accommodation tax id
   * @param {module:model/AccommodationTaxBody1} accommodationTaxBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20012}
   */
  updateAccommodationTax(reservationId, id, accommodationTaxBody) {
    return this.updateAccommodationTaxWithHttpInfo(reservationId, id, accommodationTaxBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
