import AppDispatcher from '../../core/AppDispatcher'
import TransactionEvent from '../../event/TransactionEvent'

const onEditAccommodationTax = (reservation, name, value) => {
  AppDispatcher.dispatchWithType(TransactionEvent.onEditAccommodationTax, {
    reservation: reservation,
    name: name,
    value: value
  })

  return true
}

export default onEditAccommodationTax
