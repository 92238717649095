import history from '../../util/history'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../../action/AppActions'

const addReservationEditView = async (date, room_id, reservation) => {
  const res = await dailyClosingRepository.fetchOneByCondition(date, room_id)
  if (res instanceof Error) {
    appActions.showAlert(res, '取得できませんでした')
    return false
  }

  if (res.daily_closing_head.id) {
    if (res.daily_closing_head.status === 'closed') {
      appActions.snackbar(`締め処理済みのため編集できません(ID:${res.daily_closing_head.id})`, 'error')
      return false
    }
    // すでに締め処理データ作成済み
    history.push(`/daily/id/${res.daily_closing_head.id}/edit`, { reservation: reservation })
  } else {
    // 締め処理データを新規作成
    history.push(`/daily/new`, { close_date: date, room_id: room_id, reservation: reservation })
  }

  return true
}

export default addReservationEditView
