import { appActions } from '../AppActions'
import neppanRepository from '../../infra/NeppanRepository'

const onRequestSyncInventory = async roomId => {
  const res = await neppanRepository.requestSyncInventory(roomId).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '在庫の同期に失敗しました')
    return false
  }

  appActions.snackbar('在庫の同期処理をリクエストしました。しばらく経ってからねっぱん在庫を確認ください。', 'success', true)
  return true
}

export default onRequestSyncInventory
