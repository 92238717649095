import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchMonthlyByDate = async date => {
  const response = await reservationRepository.fetchMonthlyByDate(date).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchMonthlyByDate, {
    date: date,
    today: response.today,
    rooms: response.rooms,
    reservations: response.reservations,
    event_schedules: response.event_schedules
  })

  return true
}

export default fetchMonthlyByDate
