import RoomEvent from '../../event/RoomEvent'
import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'

const onCreate = () => {
  AppDispatcher.dispatchWithType(RoomEvent.onCreate)
  history.push(`/setting/info/new`)
}

export default onCreate
