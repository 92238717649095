import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import ReservationEvent from '../../event/ReservationEvent'

const hideDetailView = () => {
  AppDispatcher.dispatchWithType(ReservationEvent.hideDetail)

  const backUrl = history.backUrl()
  if (backUrl !== null) {
    const isCalendar = backUrl.match(/\/calendar\//)
    const isReceptionist = backUrl.match(/\/receptionist\//)
    if (isCalendar !== null || isReceptionist !== null) {
      history.push(backUrl)
      return true
    }
  }

  if (window.location.pathname.match(/\/calendar\//) !== null) {
    history.push('/calendar')
    return true
  }

  if (window.location.pathname.match(/\/reservation\//) !== null) {
    history.push('/reservation/search')
    return true
  }

  history.push('/receptionist')

  return true
}

export default hideDetailView
