import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import PlanRepository from '../../infra/PlanRepository'
import PlanEvent from '../../event/PlanEvent'
import NProgress from 'nprogress'

const onSearch = async pagination => {
  NProgress.start()
  const response = await PlanRepository.onSearch(pagination).catch(e => e)
  NProgress.done()
  if (response instanceof Error) {
    appActions.showAlert(response, '一覧取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(PlanEvent.onSearch, response)

  return true
}

export default onSearch
