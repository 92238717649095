import history from '../../util/history'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'

const onRequestCreate = async (status, head, details, daily_closing_reservations) => {
  const _head = head.set('status', status)
  const res = await dailyClosingRepository.requestCreate(_head, details, daily_closing_reservations).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '登録できませんでした')
    return false
  }

  appActions.snackbar('作成しました', 'success', true)

  const id = res.daily_closing_head.id
  history.replace(`/daily/id/${id}/edit`)

  return true
}

export default onRequestCreate
