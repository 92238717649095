import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const onRequestSearch = async (date, state) => {
  const response = await dailyClosingRepository.fetchBySearchCondition(date, state).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '日次締めデータの取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(DailyClosingEvent.fetchBySearchCondition, { date: date, result: response })

  return true
}

export default onRequestSearch
