import fetchOne from './fetchOne'
import onSearch from './onSearch'
import onEditSearchCondition from './onEditSearchCondition'
import addGuest from './addGuest'
import confirmDeletion from './confirmDeletion'
import closeDeletionDialog from './closeDeletionDialog'
import deleteForm from './deleteForm'
import onRequestSubmit from './onRequestSubmit'
import onEdit from './onEdit'
import disposePassport from './disposePassport'
import onRequestDelete from './onRequestDelete'

const CheckInAction = {
  fetchOne,
  onSearch,
  addGuest,
  onEditSearchCondition,
  confirmDeletion,
  closeDeletionDialog,
  deleteForm,
  onRequestSubmit,
  onEdit,
  disposePassport,
  onRequestDelete
}

export default CheckInAction
