import RoomEvent from '../../event/RoomEvent'
import AppDispatcher from '../../core/AppDispatcher'
import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const fetchOneByReservationId = async reservationId => {
  const response = await dailyClosingRepository.fetchOneByReservationId(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  if (response && response.daily_closing_head) {
    AppDispatcher.dispatchWithType(DailyClosingEvent.fetchOne, response)
  }

  return true
}

export default fetchOneByReservationId
