import AppDispatcher from '../../../core/AppDispatcher'
import { appActions } from '../../AppActions'
import ReservationEvent from '../../../event/ReservationEvent'
import reservationRepository from '../../../infra/ReservationRepository'

const onSearch = async state => {
  const response = await reservationRepository.fetchReceptionistBy(state).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '予約の検索に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.onReceptionistSearch, {
    reservations: response,
    date: state.search_date
  })

  return true
}

export default onSearch
