import { ReduceStore } from 'flux/utils'
import { Record } from 'immutable'
import AppDispatcher from '../../core/AppDispatcher'
import LocalDate from '../../domain/type/Date'
import DailyClosingEvent from '../../event/DailyClosingEvent'

const DailyProcessListViewState = Record({
  date: LocalDate.today()
})

class DailyProcessListViewStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new DailyProcessListViewState()
  }

  reduce(state, action) {
    const payload = action.payload

    switch (action.type) {
      case DailyClosingEvent.dateChange:
        return state.merge({ date: payload.date })

      default:
        return state
    }
  }
}

export default new DailyProcessListViewStore()
