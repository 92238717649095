import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'

const onEditSearchCondition = async event => {
  AppDispatcher.dispatchWithType(CheckInEvent.onEditSearchCondition, {
    name: event.target.name,
    value: event.target.value
  })

  return true
}
export default onEditSearchCondition
