import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import EventScheduleEvent from '../../event/EventScheduleEvent'

const onCancelEditView = async () => {
  AppDispatcher.dispatchWithType(EventScheduleEvent.onClear)

  if (!history.canGoBack()) {
    history.push('/')
    return false
  }
  history.goBack()

  return true
}

export default onCancelEditView
