/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationInvoice from '../model/AccommodationInvoice'
import Audit from '../model/Audit'
import AutoActionLog from '../model/AutoActionLog'
import Body10 from '../model/Body10'
import Body4 from '../model/Body4'
import Body5 from '../model/Body5'
import Body6 from '../model/Body6'
import Body7 from '../model/Body7'
import Body8 from '../model/Body8'
import Body9 from '../model/Body9'
import InlineResponse200 from '../model/InlineResponse200'
import InlineResponse20010 from '../model/InlineResponse20010'
import InlineResponse2002 from '../model/InlineResponse2002'
import InlineResponse2003 from '../model/InlineResponse2003'
import InlineResponse2004 from '../model/InlineResponse2004'
import InlineResponse2005 from '../model/InlineResponse2005'
import InlineResponse2006 from '../model/InlineResponse2006'
import InlineResponse2007 from '../model/InlineResponse2007'
import InlineResponse2008 from '../model/InlineResponse2008'
import InlineResponse2009 from '../model/InlineResponse2009'
import InlineResponse421 from '../model/InlineResponse421'
import Reservation from '../model/Reservation'
import SegmentUpdateBody from '../model/SegmentUpdateBody'
import TaskSummary from '../model/TaskSummary'

/**
 * Reservation service.
 * @module api/ReservationApi
 * @version 1.0.0
 */
export default class ReservationApi {
  /**
   * Constructs a new ReservationApi.
   * @alias module:api/ReservationApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * check-in reservation
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2004} and HTTP response
   */
  checkinReservationWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling checkinReservation")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2004

    return this.apiClient.callApi('/reservations/{reservation_id}/checkin', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * check-in reservation
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2004}
   */
  checkinReservation(reservationId) {
    return this.checkinReservationWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 予約情報の新規作成
   * @param {module:model/Body6} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Reservation} and HTTP response
   */
  createReservationWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createReservation")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Reservation

    return this.apiClient.callApi('/reservations/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 予約情報の新規作成
   * @param {module:model/Body6} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Reservation}
   */
  createReservation(body) {
    return this.createReservationWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層を削除する
   * @param {Number} reservationId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteSegmentWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling deleteSegment")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/reservations/{reservation_id}/segment', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層を削除する
   * @param {Number} reservationId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteSegment(reservationId) {
    return this.deleteSegmentWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Edit reservation
   * @param {Number} reservationId Target reservation id
   * @param {module:model/Body7} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2004} and HTTP response
   */
  editReservationWithHttpInfo(reservationId, body) {
    let postBody = body

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling editReservation")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling editReservation")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2004

    return this.apiClient.callApi('/reservations/{reservation_id}/edit', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Edit reservation
   * @param {Number} reservationId Target reservation id
   * @param {module:model/Body7} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2004}
   */
  editReservation(reservationId, body) {
    return this.editReservationWithHttpInfo(reservationId, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 当該の日にチェックイン、チェックアウト、滞在中のReservationの一覧を取得する
   * @param {Date} _date Target date of the reservation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Reservation>} and HTTP response
   */
  getDailyReservationWithHttpInfo(_date) {
    let postBody = null

    // verify the required parameter '_date' is set
    if (_date === undefined || _date === null) {
      throw new Error("Missing the required parameter '_date' when calling getDailyReservation")
    }

    let pathParams = {
      date: _date
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Reservation]

    return this.apiClient.callApi('/reservations/daily/{date}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 当該の日にチェックイン、チェックアウト、滞在中のReservationの一覧を取得する
   * @param {Date} _date Target date of the reservation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Reservation>}
   */
  getDailyReservation(_date) {
    return this.getDailyReservationWithHttpInfo(_date).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 予約情報に紐づいた請求書一覧を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AccommodationInvoice>} and HTTP response
   */
  getInvoicesWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getInvoices")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AccommodationInvoice]

    return this.apiClient.callApi('/reservations/{reservation_id}/invoices', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 予約情報に紐づいた請求書一覧を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AccommodationInvoice>}
   */
  getInvoices(reservationId) {
    return this.getInvoicesWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 指定した月の予約情報を取得する
   * @param {Date} _date Target date of the reservation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2003} and HTTP response
   */
  getMonthlyReservationWithHttpInfo(_date) {
    let postBody = null

    // verify the required parameter '_date' is set
    if (_date === undefined || _date === null) {
      throw new Error("Missing the required parameter '_date' when calling getMonthlyReservation")
    }

    let pathParams = {
      date: _date
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2003

    return this.apiClient.callApi('/reservations/monthly/{date}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 指定した月の予約情報を取得する
   * @param {Date} _date Target date of the reservation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2003}
   */
  getMonthlyReservation(_date) {
    return this.getMonthlyReservationWithHttpInfo(_date).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Get a target reservation
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2006} and HTTP response
   */
  getReservationWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2006

    return this.apiClient.callApi('/reservations/self_check_in/show', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Get a target reservation
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2006}
   */
  getReservation() {
    return this.getReservationWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 予約情報に紐づいた自動アクション履歴一覧を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AutoActionLog>} and HTTP response
   */
  getReservationAutoActionLogsWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getReservationAutoActionLogs")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AutoActionLog]

    return this.apiClient.callApi('/reservations/{reservation_id}/auto_action_logs', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 予約情報に紐づいた自動アクション履歴一覧を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AutoActionLog>}
   */
  getReservationAutoActionLogs(reservationId) {
    return this.getReservationAutoActionLogsWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get reservation detail
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Reservation} and HTTP response
   */
  getReservationDetailWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getReservationDetail")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Reservation

    return this.apiClient.callApi('/reservations/{reservation_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get reservation detail
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Reservation}
   */
  getReservationDetail(reservationId) {
    return this.getReservationDetailWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get reservation history
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Audit>} and HTTP response
   */
  getReservationLogWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getReservationLog")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Audit]

    return this.apiClient.callApi('/reservations/{reservation_id}/logs', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get reservation history
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Audit>}
   */
  getReservationLog(reservationId) {
    return this.getReservationLogWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 予約情報に紐づいたタスク一覧を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/TaskSummary>} and HTTP response
   */
  getReservationTasksWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getReservationTasks")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [TaskSummary]

    return this.apiClient.callApi('/reservations/{reservation_id}/tasks', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 予約情報に紐づいたタスク一覧を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/TaskSummary>}
   */
  getReservationTasks(reservationId) {
    return this.getReservationTasksWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 予約情報に紐づいた売上を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2007} and HTTP response
   */
  getReservationTransactionsWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getReservationTransactions")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2007

    return this.apiClient.callApi('/reservations/{reservation_id}/transactions', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 予約情報に紐づいた売上を返します
   * @param {Number} reservationId Target reservation id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2007}
   */
  getReservationTransactions(reservationId) {
    return this.getReservationTransactionsWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層を取得する
   * @param {Number} reservationId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20010} and HTTP response
   */
  getSegmentWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling getSegment")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20010

    return this.apiClient.callApi('/reservations/{reservation_id}/segment', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層を取得する
   * @param {Number} reservationId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20010}
   */
  getSegment(reservationId) {
    return this.getSegmentWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * その週に滞在する予定のユーザ一覧をRoomごとに取得する
   * @param {Date} _date Target date of the reservation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Reservation>} and HTTP response
   */
  getWeeklyReservationWithHttpInfo(_date) {
    let postBody = null

    // verify the required parameter '_date' is set
    if (_date === undefined || _date === null) {
      throw new Error("Missing the required parameter '_date' when calling getWeeklyReservation")
    }

    let pathParams = {
      date: _date
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Reservation]

    return this.apiClient.callApi('/reservations/weekly/{date}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * その週に滞在する予定のユーザ一覧をRoomごとに取得する
   * @param {Date} _date Target date of the reservation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Reservation>}
   */
  getWeeklyReservation(_date) {
    return this.getWeeklyReservationWithHttpInfo(_date).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * セルフチェックイン用のURLを発行する
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
   */
  issueCheckInUrlWithHttpInfo(reservationId) {
    let postBody = null

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling issueCheckInUrl")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse200

    return this.apiClient.callApi('/reservations/{reservation_id}/check_in/issue', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * セルフチェックイン用のURLを発行する
   * @param {Number} reservationId Target reservation id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
   */
  issueCheckInUrl(reservationId) {
    return this.issueCheckInUrlWithHttpInfo(reservationId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 客層を登録する
   * @param {Number} reservationId Target id
   * @param {module:model/SegmentUpdateBody} segmentUpdateBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  saveSegmentWithHttpInfo(reservationId, segmentUpdateBody) {
    let postBody = segmentUpdateBody

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling saveSegment")
    }

    // verify the required parameter 'segmentUpdateBody' is set
    if (segmentUpdateBody === undefined || segmentUpdateBody === null) {
      throw new Error("Missing the required parameter 'segmentUpdateBody' when calling saveSegment")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/reservations/{reservation_id}/segment', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 客層を登録する
   * @param {Number} reservationId Target id
   * @param {module:model/SegmentUpdateBody} segmentUpdateBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  saveSegment(reservationId, segmentUpdateBody) {
    return this.saveSegmentWithHttpInfo(reservationId, segmentUpdateBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Search receptionist
   * @param {module:model/Body5} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Reservation>} and HTTP response
   */
  searchReceptionistWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchReceptionist")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Reservation]

    return this.apiClient.callApi('/reservations/search_receptionist', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Search receptionist
   * @param {module:model/Body5} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Reservation>}
   */
  searchReceptionist(body) {
    return this.searchReceptionistWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Search reservation
   * @param {module:model/Body4} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2002} and HTTP response
   */
  searchReservationWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchReservation")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2002

    return this.apiClient.callApi('/reservations/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Search reservation
   * @param {module:model/Body4} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2002}
   */
  searchReservation(body) {
    return this.searchReservationWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * チェックインフォームの入力完了後に、その情報をReservationに紐付ける
   * @param {module:model/Body8} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  selfCheckInDoneWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling selfCheckInDone")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/reservations/self_check_in/done', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * チェックインフォームの入力完了後に、その情報をReservationに紐付ける
   * @param {module:model/Body8} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  selfCheckInDone(body) {
    return this.selfCheckInDoneWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * ゲストがチェックインフォームを入力する際に、チェックイン情報をS3にアップロードするための一時アクセスキーを入手する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2005} and HTTP response
   */
  selfCheckInRequestWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2005

    return this.apiClient.callApi('/reservations/self_check_in/request', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * ゲストがチェックインフォームを入力する際に、チェックイン情報をS3にアップロードするための一時アクセスキーを入手する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2005}
   */
  selfCheckInRequest() {
    return this.selfCheckInRequestWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Update reservation to noshow
   * @param {Number} reservationId Target reservation id
   * @param {module:model/Body10} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2009} and HTTP response
   */
  updateNoshowWithHttpInfo(reservationId, body) {
    let postBody = body

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling updateNoshow")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateNoshow")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2009

    return this.apiClient.callApi('/reservations/{reservation_id}/noshow', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Update reservation to noshow
   * @param {Number} reservationId Target reservation id
   * @param {module:model/Body10} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2009}
   */
  updateNoshow(reservationId, body) {
    return this.updateNoshowWithHttpInfo(reservationId, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Update reservation paid status
   * @param {Number} reservationId Target reservation id
   * @param {module:model/Body9} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2008} and HTTP response
   */
  updateReservationPayWithHttpInfo(reservationId, body) {
    let postBody = body

    // verify the required parameter 'reservationId' is set
    if (reservationId === undefined || reservationId === null) {
      throw new Error("Missing the required parameter 'reservationId' when calling updateReservationPay")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateReservationPay")
    }

    let pathParams = {
      reservation_id: reservationId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2008

    return this.apiClient.callApi('/reservation/{reservation_id}/reservation_pay', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Update reservation paid status
   * @param {Number} reservationId Target reservation id
   * @param {module:model/Body9} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2008}
   */
  updateReservationPay(reservationId, body) {
    return this.updateReservationPayWithHttpInfo(reservationId, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
