/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body11 from '../model/Body11'
import EventSchedule from '../model/EventSchedule'
import InlineResponse20011 from '../model/InlineResponse20011'

/**
 * EventSchedule service.
 * @module api/EventScheduleApi
 * @version 1.0.0
 */
export default class EventScheduleApi {
  /**
   * Constructs a new EventScheduleApi.
   * @alias module:api/EventScheduleApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * イベント予定を新規登録する
   * @param {module:model/Body11} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createEventScheduleWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createEventSchedule")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/event_schedules', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * イベント予定を新規登録する
   * @param {module:model/Body11} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createEventSchedule(body) {
    return this.createEventScheduleWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * イベント予定を削除する
   * @param {Number} id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteEventScheduleWithHttpInfo(id) {
    let postBody = null

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling deleteEventSchedule")
    }

    let pathParams = {
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/event_schedules/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * イベント予定を削除する
   * @param {Number} id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteEventSchedule(id) {
    return this.deleteEventScheduleWithHttpInfo(id).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * イベント予定を1件取得する
   * @param {Number} id Target cleaning schedule id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EventSchedule} and HTTP response
   */
  getEventScheduleWithHttpInfo(id) {
    let postBody = null

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getEventSchedule")
    }

    let pathParams = {
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = EventSchedule

    return this.apiClient.callApi('/event_schedules/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * イベント予定を1件取得する
   * @param {Number} id Target cleaning schedule id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EventSchedule}
   */
  getEventSchedule(id) {
    return this.getEventScheduleWithHttpInfo(id).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * イベント予定を更新する
   * @param {Number} id Target cleaning schedule id
   * @param {module:model/EventSchedule} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20011} and HTTP response
   */
  updateEventScheduleWithHttpInfo(id, body) {
    let postBody = body

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling updateEventSchedule")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateEventSchedule")
    }

    let pathParams = {
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20011

    return this.apiClient.callApi('/event_schedules/{id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * イベント予定を更新する
   * @param {Number} id Target cleaning schedule id
   * @param {module:model/EventSchedule} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20011}
   */
  updateEventSchedule(id, body) {
    return this.updateEventScheduleWithHttpInfo(id, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
