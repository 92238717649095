import React from 'react'
import { BaseLayout, ListViewLayout, LocalAreaLayout } from 'ui-library/dist/external/layouts'
import {
  DataTable,
  DataTableColumn as TD,
  DataTableHeader as TH,
  DataTableRow as TR,
  NavigationDrawer,
  NormalButton,
  OutlinedButton,
  Pagination,
  SearchField,
  SearchOption
} from 'ui-library/dist/external/components'
import { Dropdown } from '../../atoms'
import StripeKeyAction from '../../../action/stripe_key'

export default props => {
  const toolbar = { label: `Stripeキー設定`, items: props.accountMenu }
  const pagination = props.pagination

  const searchFunction = params => {
    const pagination = props.pagination.merge({ page: params.page, per_page: params.perPage })
    StripeKeyAction.onSearch(props.search, props.pagination)
  }
  return (
    <React.Fragment>
      <NavigationDrawer toolbar={toolbar} navigations={props.navigations} />

      <BaseLayout>
        <LocalAreaLayout
          left={
            <SearchField
              name="search_keywords"
              value={props.search.search_keywords}
              onChange={e => StripeKeyAction.onEditSearchCondition(e)}
              onSearch={e => StripeKeyAction.onSearch(props.search, props.pagination)}
              searchOptions={[
                <SearchOption
                  key={1}
                  label={'宿泊施設'}
                  field={
                    <Dropdown
                      className="searchHotel"
                      name="search_hotel_ids"
                      value={props.search.search_hotel_ids}
                      options={props.hotel_options}
                      isClearable={true}
                      isMulti={true}
                      searchable={true}
                      onChange={e => StripeKeyAction.onEditSearchCondition(e)}
                    />
                  }
                />
              ]}
            />
          }
          right={<NormalButton onClick={e => StripeKeyAction.toEditView(null)}>新規作成</NormalButton>}
        />
        <ListViewLayout>
          <DataTable
            headers={[
              <TH key={1} width="55px" isNumber>
                ID
              </TH>,
              <TH key={2} width="40%">
                宿泊施設
              </TH>,
              <TH key={3} width="40%">
                シークレットキー
              </TH>,
              <TH key={4} width="10%">
                &nbsp;
              </TH>
            ]}
            rows={props.stripe_keys.map((item, index) => (
              <TR key={index}>
                <TD isNumber>{item.id}</TD>
                <TD>{item.hotel_name}</TD>
                <TD>{item.api_key}</TD>
                <TD>
                  <OutlinedButton onClick={() => StripeKeyAction.toEditView(item.id)}>編集</OutlinedButton>
                </TD>
              </TR>
            ))}
          />
        </ListViewLayout>
      </BaseLayout>
      <Pagination
        name="pagination"
        perPage={props.pagination.per_page}
        total={props.pagination.total}
        page={props.pagination.page}
        prev={e => searchFunction(e.value)}
        next={e => searchFunction(e.value)}
        perPageChanged={e => searchFunction(e.value)}
      />
    </React.Fragment>
  )
}
