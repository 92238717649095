/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The SegmentValue model module.
 * @module model/SegmentValue
 * @version 1.0.0
 */
export default class SegmentValue {
  /**
   * Constructs a new <code>SegmentValue</code>.
   * @alias module:model/SegmentValue
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>SegmentValue</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SegmentValue} obj Optional instance to populate.
   * @return {module:model/SegmentValue} The populated <code>SegmentValue</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SegmentValue()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('priority')) {
        obj['priority'] = ApiClient.convertToType(data['priority'], 'Number')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {Number} priority
   */
  priority = undefined
  /**
   * @member {String} status
   */
  status = undefined
}
