import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchInvoices = async reservationId => {
  const response = await reservationRepository.fetchInvoices(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '請求書情報が取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchInvoices, {
    invoices: response
  })

  return true
}

export default fetchInvoices
