import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import EventScheduleEvent from '../../event/EventScheduleEvent'
import { EventScheduleState } from '../state/EventScheduleState'

class EventScheduleStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new EventScheduleState()
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case EventScheduleEvent.fetchOne:
        const model = payload.eventSchedule
        return state.mergeModel(model)

      case EventScheduleEvent.onEdit:
        return state.set(payload.name, payload.value)

      case EventScheduleEvent.onClear:
        return state.clear()

      default:
        return state
    }
  }
}

export default new EventScheduleStore()
