import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import SegmentationRepository from '../../infra/SegmentationRepository'
import SegmentationEvent from '../../event/SegmentationEvent'

const onRequestCreate = async (segmentCategory, segmentValues) => {
  const res = await SegmentationRepository.requestCreate(segmentCategory, segmentValues).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '作成に失敗しました')
    return false
  }

  appActions.snackbar('作成しました', 'success', true)

  AppDispatcher.dispatchWithType(SegmentationEvent.onClear)
  history.push('/segment_settings')
  return true
}

export default onRequestCreate
