/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import DailyClosingCount from './DailyClosingCount'

/**
 * The MonthlyClosingHead model module.
 * @module model/MonthlyClosingHead
 * @version 1.0.0
 */
export default class MonthlyClosingHead {
  /**
   * Constructs a new <code>MonthlyClosingHead</code>.
   * @alias module:model/MonthlyClosingHead
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>MonthlyClosingHead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MonthlyClosingHead} obj Optional instance to populate.
   * @return {module:model/MonthlyClosingHead} The populated <code>MonthlyClosingHead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MonthlyClosingHead()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('monthly_close_date')) {
        obj['monthly_close_date'] = ApiClient.convertToType(data['monthly_close_date'], 'Date')
      }
      if (data.hasOwnProperty('hotel_id')) {
        obj['hotel_id'] = ApiClient.convertToType(data['hotel_id'], 'Number')
      }
      if (data.hasOwnProperty('hotel_name')) {
        obj['hotel_name'] = ApiClient.convertToType(data['hotel_name'], 'String')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('rsvn_sales')) {
        obj['rsvn_sales'] = ApiClient.convertToType(data['rsvn_sales'], 'Number')
      }
      if (data.hasOwnProperty('ota_comsn')) {
        obj['ota_comsn'] = ApiClient.convertToType(data['ota_comsn'], 'Number')
      }
      if (data.hasOwnProperty('rsvn_pay_comsn')) {
        obj['rsvn_pay_comsn'] = ApiClient.convertToType(data['rsvn_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('sales')) {
        obj['sales'] = ApiClient.convertToType(data['sales'], 'Number')
      }
      if (data.hasOwnProperty('variable_costs')) {
        obj['variable_costs'] = ApiClient.convertToType(data['variable_costs'], 'Number')
      }
      if (data.hasOwnProperty('fixed_costs')) {
        obj['fixed_costs'] = ApiClient.convertToType(data['fixed_costs'], 'Number')
      }
      if (data.hasOwnProperty('variable_expenses')) {
        obj['variable_expenses'] = ApiClient.convertToType(data['variable_expenses'], 'Number')
      }
      if (data.hasOwnProperty('fixed_expenses')) {
        obj['fixed_expenses'] = ApiClient.convertToType(data['fixed_expenses'], 'Number')
      }
      if (data.hasOwnProperty('accom_tax')) {
        obj['accom_tax'] = ApiClient.convertToType(data['accom_tax'], 'Number')
      }
      if (data.hasOwnProperty('accom_pay_comsn')) {
        obj['accom_pay_comsn'] = ApiClient.convertToType(data['accom_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('management_comsn')) {
        obj['management_comsn'] = ApiClient.convertToType(data['management_comsn'], 'Number')
      }
      if (data.hasOwnProperty('memo')) {
        obj['memo'] = ApiClient.convertToType(data['memo'], 'String')
      }
      if (data.hasOwnProperty('daily_closing_count')) {
        obj['daily_closing_count'] = DailyClosingCount.constructFromObject(data['daily_closing_count'])
      }
      if (data.hasOwnProperty('occupancy_dates')) {
        obj['occupancy_dates'] = ApiClient.convertToType(data['occupancy_dates'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Date} monthly_close_date
   */
  monthly_close_date = undefined
  /**
   * @member {Number} hotel_id
   */
  hotel_id = undefined
  /**
   * @member {String} hotel_name
   */
  hotel_name = undefined
  /**
   * @member {module:model/MonthlyClosingHead.StatusEnum} status
   */
  status = undefined
  /**
   * @member {Number} rsvn_sales
   */
  rsvn_sales = undefined
  /**
   * @member {Number} ota_comsn
   */
  ota_comsn = undefined
  /**
   * @member {Number} rsvn_pay_comsn
   */
  rsvn_pay_comsn = undefined
  /**
   * @member {Number} sales
   */
  sales = undefined
  /**
   * @member {Number} variable_costs
   */
  variable_costs = undefined
  /**
   * @member {Number} fixed_costs
   */
  fixed_costs = undefined
  /**
   * @member {Number} variable_expenses
   */
  variable_expenses = undefined
  /**
   * @member {Number} fixed_expenses
   */
  fixed_expenses = undefined
  /**
   * @member {Number} accom_tax
   */
  accom_tax = undefined
  /**
   * @member {Number} accom_pay_comsn
   */
  accom_pay_comsn = undefined
  /**
   * @member {Number} management_comsn
   */
  management_comsn = undefined
  /**
   * @member {String} memo
   */
  memo = undefined
  /**
   * @member {module:model/DailyClosingCount} daily_closing_count
   */
  daily_closing_count = undefined
  /**
   * @member {Number} occupancy_dates
   */
  occupancy_dates = undefined

  /**
   * Allowed values for the <code>status</code> property.
   * @enum {String}
   * @readonly
   */
  static StatusEnum = {
    /**
     * value: "saved"
     * @const
     */
    saved: 'saved',

    /**
     * value: "closed"
     * @const
     */
    closed: 'closed'
  }
}
