// Rename to LocalDate because it conflicts JavaScript API.
import { DateTime } from 'luxon'

class LocalDate {
  constructor(date) {
    if (!date) {
      date = DateTime.local()
    } else if (typeof date === 'string') {
      date = DateTime.fromISO(date)
    } else if (date instanceof Date) {
      date = DateTime.fromJSDate(date)
    } else if (typeof date === 'object') {
      // domain/type/DateをReactRouterのstate経由で渡すと型情報が失われるためここでチェックしている
      if (date.hasOwnProperty('date') && date['date'].hasOwnProperty('ts')) {
        const ts = date['date']['ts']
        date = DateTime.fromMillis(ts)
      }
    }
    this.date = date
  }

  static of(year, month, day) {
    const hours = 0,
      minutes = 0,
      seconds = 0,
      milliseconds = 0

    const date = DateTime.local(year, month, day, hours, minutes, seconds, milliseconds)

    return new LocalDate(date)
  }

  static today() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return LocalDate.of(year, month, day)
  }

  // Y-m-d or Y/m/d only
  static parse(str) {
    if (!str) {
      return str
    }
    const replaced = str.replace(/-/g, '/')
    const date = new Date(replaced)

    return LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate())
  }

  // 同じ型であることが前提条件
  isSameDate(other) {
    return this.toISODate() === other.toISODate()
  }

  toISO() {
    // return this.date.setZone("Asia/Tokyo", { keepLocalTime: true }).toISO()
    return this.date ? this.date.toISO() : null
  }

  toISODate() {
    // return this.date.setZone("Asia/Tokyo", { keepLocalTime: true }).toISODate()
    return this.date ? this.date.toISODate() : null
  }

  toJSDate() {
    return this.date ? this.date.toJSDate() : null
  }

  beginningOfMonth() {
    return LocalDate.of(this.date.year, this.date.month, 1)
  }

  endOfMonth() {
    return LocalDate.of(this.date.year, this.date.month, this.date.daysInMonth)
  }

  // https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
  format(format = 'yyyy-LL-dd') {
    if (!this.date) {
      return ''
    }
    return this.date.toFormat(format)
  }

  // Year year()
  // Month month()

  // LocalDate nextDay()
  nextDay(n) {
    const days = n ? n : 1
    const date = this.date.plus({ days: days })
    return LocalDate.of(date.year, date.month, date.day)
  }

  // LocalDate previousDay()
  previousDay(n) {
    const days = n ? n : 1
    const date = this.date.minus({ days: days })
    return LocalDate.of(date.year, date.month, date.day)
  }

  nextMonth() {
    const date = this.date.plus({ months: 1 })
    return LocalDate.of(date.year, date.month, date.day)
  }

  lastMonth() {
    const date = this.date.minus({ months: 1 })
    return LocalDate.of(date.year, date.month, date.day)
  }

  equals(other) {
    return this.date.equals(other)
  }
  valueOf() {
    return this.date.valueOf()
  }

  // LocalDate nextYear()
  // LocalDate lastYear()

  get year() {
    return this.date.year
  }

  // Get the month (1-12).
  get month() {
    return this.date.month
  }

  get day() {
    return this.date.day
  }

  //
  // -- For compatible with Date
  //
  getFullYear() {
    return this.date.year
  }

  getMonth() {
    return this.date.month - 1
  }

  getDate() {
    return this.date.day
  }

  getDay() {
    const weekday = this.date.weekday

    if (weekday >= 7) return 0

    return weekday
  }
}

export default LocalDate
