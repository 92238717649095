import history from '../../util/history'

const showDetailView = (monthly_close_date, monthlyClosingHeadState) => {
  const id = monthlyClosingHeadState.id
  const date = monthly_close_date.toISODate()
  if (!id) {
    history.push(`/monthly/date/${date}/hotel/${monthlyClosingHeadState.hotel_id}/detail`)
    return true
  }

  history.push(`/monthly/date/${date}/id/${id}/detail`)

  return true
}

export default showDetailView
