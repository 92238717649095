import React from 'react'

export default function ArrowRight(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        fill="#0E141A"
        fillRule="evenodd"
        d="M15.513 11.222l-1.205-1.211-.501-.504L14.809 8.5l.501.504 1.966 1.974.01-.01 1.003 1.007-.501.503-2.507 2.518-.5.504-1.003-1.007.501-.504 1.206-1.211H6v-1.556h9.513z"
      />
    </svg>
  )
}
