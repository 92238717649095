import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import PlanRepository from '../../infra/PlanRepository'
import PlanEvent from '../../event/PlanEvent'

const fetchOne = async id => {
  const response = await PlanRepository.fetchOne(id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(PlanEvent.fetchOne, response)
  return true
}

export default fetchOne
