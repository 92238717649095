import AppDispatcher from '../../core/AppDispatcher'
import SegmentationEvent from '../../event/SegmentationEvent'

const onEditValue = (name, value, index) => {
  AppDispatcher.dispatchWithType(SegmentationEvent.onEditValue, {
    name: name,
    value: value,
    index: index
  })

  return true
}

export default onEditValue
