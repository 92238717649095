/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccountTitle from '../model/AccountTitle'
import AccountTitleBody from '../model/AccountTitleBody'
import AccountTitleBody1 from '../model/AccountTitleBody1'
import AccountTitleDetail from '../model/AccountTitleDetail'
import AccountTitleDetailBody from '../model/AccountTitleDetailBody'
import AccountTitleDetailBody1 from '../model/AccountTitleDetailBody1'
import AccountTitleGroup from '../model/AccountTitleGroup'

/**
 * AccountTitle service.
 * @module api/AccountTitleApi
 * @version 1.0.0
 */
export default class AccountTitleApi {
  /**
   * Constructs a new AccountTitleApi.
   * @alias module:api/AccountTitleApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 勘定科目を新規登録する
   * @param {module:model/AccountTitleBody} accountTitleBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccountTitle} and HTTP response
   */
  createAccountTitleWithHttpInfo(accountTitleBody) {
    let postBody = accountTitleBody

    // verify the required parameter 'accountTitleBody' is set
    if (accountTitleBody === undefined || accountTitleBody === null) {
      throw new Error("Missing the required parameter 'accountTitleBody' when calling createAccountTitle")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = AccountTitle

    return this.apiClient.callApi('/account_titles', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 勘定科目を新規登録する
   * @param {module:model/AccountTitleBody} accountTitleBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccountTitle}
   */
  createAccountTitle(accountTitleBody) {
    return this.createAccountTitleWithHttpInfo(accountTitleBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 補助科目の新規登録する
   * @param {module:model/AccountTitleDetailBody} accountTitleDetailBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccountTitleDetail} and HTTP response
   */
  createAccountTitleDetailWithHttpInfo(accountTitleDetailBody) {
    let postBody = accountTitleDetailBody

    // verify the required parameter 'accountTitleDetailBody' is set
    if (accountTitleDetailBody === undefined || accountTitleDetailBody === null) {
      throw new Error("Missing the required parameter 'accountTitleDetailBody' when calling createAccountTitleDetail")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = AccountTitleDetail

    return this.apiClient.callApi('/account_titles/details', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 補助科目の新規登録する
   * @param {module:model/AccountTitleDetailBody} accountTitleDetailBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccountTitleDetail}
   */
  createAccountTitleDetail(accountTitleDetailBody) {
    return this.createAccountTitleDetailWithHttpInfo(accountTitleDetailBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 対象の勘定科目を削除する
   * @param {Number} accountTitleId Target account title id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteAccountTitleWithHttpInfo(accountTitleId) {
    let postBody = null

    // verify the required parameter 'accountTitleId' is set
    if (accountTitleId === undefined || accountTitleId === null) {
      throw new Error("Missing the required parameter 'accountTitleId' when calling deleteAccountTitle")
    }

    let pathParams = {
      account_title_id: accountTitleId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/account_titles/{account_title_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 対象の勘定科目を削除する
   * @param {Number} accountTitleId Target account title id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteAccountTitle(accountTitleId) {
    return this.deleteAccountTitleWithHttpInfo(accountTitleId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 対象の補助科目を削除する
   * @param {Number} accountTitleDetailId Target account title detail id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteAccountTitleDetailWithHttpInfo(accountTitleDetailId) {
    let postBody = null

    // verify the required parameter 'accountTitleDetailId' is set
    if (accountTitleDetailId === undefined || accountTitleDetailId === null) {
      throw new Error("Missing the required parameter 'accountTitleDetailId' when calling deleteAccountTitleDetail")
    }

    let pathParams = {
      account_title_detail_id: accountTitleDetailId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi(
      '/account_titles/details/{account_title_detail_id}',
      'DELETE',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 対象の補助科目を削除する
   * @param {Number} accountTitleDetailId Target account title detail id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteAccountTitleDetail(accountTitleDetailId) {
    return this.deleteAccountTitleDetailWithHttpInfo(accountTitleDetailId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get account title
   * @param {Number} accountTitleId Target account title id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccountTitle} and HTTP response
   */
  getAccountTitleWithHttpInfo(accountTitleId) {
    let postBody = null

    // verify the required parameter 'accountTitleId' is set
    if (accountTitleId === undefined || accountTitleId === null) {
      throw new Error("Missing the required parameter 'accountTitleId' when calling getAccountTitle")
    }

    let pathParams = {
      account_title_id: accountTitleId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = AccountTitle

    return this.apiClient.callApi('/account_titles/{account_title_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get account title
   * @param {Number} accountTitleId Target account title id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccountTitle}
   */
  getAccountTitle(accountTitleId) {
    return this.getAccountTitleWithHttpInfo(accountTitleId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get account title detail
   * @param {Number} accountTitleDetailId Target account title detail id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccountTitleDetail} and HTTP response
   */
  getAccountTitleDetailWithHttpInfo(accountTitleDetailId) {
    let postBody = null

    // verify the required parameter 'accountTitleDetailId' is set
    if (accountTitleDetailId === undefined || accountTitleDetailId === null) {
      throw new Error("Missing the required parameter 'accountTitleDetailId' when calling getAccountTitleDetail")
    }

    let pathParams = {
      account_title_detail_id: accountTitleDetailId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = AccountTitleDetail

    return this.apiClient.callApi('/account_titles/details/{account_title_detail_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get account title detail
   * @param {Number} accountTitleDetailId Target account title detail id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccountTitleDetail}
   */
  getAccountTitleDetail(accountTitleDetailId) {
    return this.getAccountTitleDetailWithHttpInfo(accountTitleDetailId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get account title details
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AccountTitleDetail>} and HTTP response
   */
  getAccountTitleDetailsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AccountTitleDetail]

    return this.apiClient.callApi('/account_titles/details', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get account title details
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AccountTitleDetail>}
   */
  getAccountTitleDetails() {
    return this.getAccountTitleDetailsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get account title group
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AccountTitleGroup>} and HTTP response
   */
  getAccountTitleGroupsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AccountTitleGroup]

    return this.apiClient.callApi('/account_titles/groups', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get account title group
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AccountTitleGroup>}
   */
  getAccountTitleGroups() {
    return this.getAccountTitleGroupsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get account title
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AccountTitle>} and HTTP response
   */
  getAccountTitlesWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AccountTitle]

    return this.apiClient.callApi('/account_titles', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get account title
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AccountTitle>}
   */
  getAccountTitles() {
    return this.getAccountTitlesWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 勘定科目を更新する
   * @param {Number} accountTitleId Target account title id
   * @param {module:model/AccountTitleBody1} accountTitleBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccountTitle} and HTTP response
   */
  updateAccountTitleWithHttpInfo(accountTitleId, accountTitleBody) {
    let postBody = accountTitleBody

    // verify the required parameter 'accountTitleId' is set
    if (accountTitleId === undefined || accountTitleId === null) {
      throw new Error("Missing the required parameter 'accountTitleId' when calling updateAccountTitle")
    }

    // verify the required parameter 'accountTitleBody' is set
    if (accountTitleBody === undefined || accountTitleBody === null) {
      throw new Error("Missing the required parameter 'accountTitleBody' when calling updateAccountTitle")
    }

    let pathParams = {
      account_title_id: accountTitleId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = AccountTitle

    return this.apiClient.callApi('/account_titles/{account_title_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 勘定科目を更新する
   * @param {Number} accountTitleId Target account title id
   * @param {module:model/AccountTitleBody1} accountTitleBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccountTitle}
   */
  updateAccountTitle(accountTitleId, accountTitleBody) {
    return this.updateAccountTitleWithHttpInfo(accountTitleId, accountTitleBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 補助科目を更新する
   * @param {Number} accountTitleDetailId Target account title detail id
   * @param {module:model/AccountTitleDetailBody1} accountTitleDetailBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccountTitleDetail} and HTTP response
   */
  updateAccountTitleDetailWithHttpInfo(accountTitleDetailId, accountTitleDetailBody) {
    let postBody = accountTitleDetailBody

    // verify the required parameter 'accountTitleDetailId' is set
    if (accountTitleDetailId === undefined || accountTitleDetailId === null) {
      throw new Error("Missing the required parameter 'accountTitleDetailId' when calling updateAccountTitleDetail")
    }

    // verify the required parameter 'accountTitleDetailBody' is set
    if (accountTitleDetailBody === undefined || accountTitleDetailBody === null) {
      throw new Error("Missing the required parameter 'accountTitleDetailBody' when calling updateAccountTitleDetail")
    }

    let pathParams = {
      account_title_detail_id: accountTitleDetailId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = AccountTitleDetail

    return this.apiClient.callApi(
      '/account_titles/details/{account_title_detail_id}',
      'PATCH',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 補助科目を更新する
   * @param {Number} accountTitleDetailId Target account title detail id
   * @param {module:model/AccountTitleDetailBody1} accountTitleDetailBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccountTitleDetail}
   */
  updateAccountTitleDetail(accountTitleDetailId, accountTitleDetailBody) {
    return this.updateAccountTitleDetailWithHttpInfo(accountTitleDetailId, accountTitleDetailBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
