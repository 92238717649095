/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The DailyClosingDetail model module.
 * @module model/DailyClosingDetail
 * @version 1.0.0
 */
export default class DailyClosingDetail {
  /**
   * Constructs a new <code>DailyClosingDetail</code>.
   * @alias module:model/DailyClosingDetail
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>DailyClosingDetail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DailyClosingDetail} obj Optional instance to populate.
   * @return {module:model/DailyClosingDetail} The populated <code>DailyClosingDetail</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyClosingDetail()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('daily_closing_head_id')) {
        obj['daily_closing_head_id'] = ApiClient.convertToType(data['daily_closing_head_id'], 'Number')
      }
      if (data.hasOwnProperty('account_title_group_id')) {
        obj['account_title_group_id'] = ApiClient.convertToType(data['account_title_group_id'], 'Number')
      }
      if (data.hasOwnProperty('account_title_id')) {
        obj['account_title_id'] = ApiClient.convertToType(data['account_title_id'], 'Number')
      }
      if (data.hasOwnProperty('account_title_detail_id')) {
        obj['account_title_detail_id'] = ApiClient.convertToType(data['account_title_detail_id'], 'Number')
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = ApiClient.convertToType(data['price'], 'Number')
      }
      if (data.hasOwnProperty('remarks')) {
        obj['remarks'] = ApiClient.convertToType(data['remarks'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} daily_closing_head_id
   */
  daily_closing_head_id = undefined
  /**
   * @member {Number} account_title_group_id
   */
  account_title_group_id = undefined
  /**
   * @member {Number} account_title_id
   */
  account_title_id = undefined
  /**
   * @member {Number} account_title_detail_id
   */
  account_title_detail_id = undefined
  /**
   * @member {Number} price
   */
  price = undefined
  /**
   * @member {String} remarks
   */
  remarks = undefined
}
