import AppDispatcher from '../../core/AppDispatcher'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onEditRefund = (name, value) => {
  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.onEditRefund, {
    name: name,
    value: value
  })

  return true
}

export default onEditRefund
