import ApiClient from '../ApiClient'
import AppRepository from './AppRepository'
import InvoiceAutomationApi from '../api/InvoiceAutomationApi'

class InvoiceAutomationRepository extends AppRepository {
  constructor() {
    super()
    this._api = new InvoiceAutomationApi(ApiClient.instance)
  }

  fetchOne() {
    return this._api.getInvoiceAutomation()
  }
}

// singleton
export default new InvoiceAutomationRepository()
