// Pages
import RoomStatus from './container/room/RoomStatusContainer'
import Receptionist from './container/reservation/ReceptionistContainer'
import ReservationEdit from './container/reservation/ReservationEditContainer'
import InvoiceCreate from './container/accommodation_invoice/CreateContainer'
import TransactionCreate from './container/transaction/CreateContainer'
import Calendar from './container/reservation/ReservationCalendarContainer'
import TaskEdit from './container/task/TaskEditContainer'
import TaskSearch from './container/task/TaskSearchContainer'
import Rooms from './container/room/RoomsContainer'
import Hotels from './container/hotel/HotelsContainer'
import HotelEdit from './container/hotel/HotelEditContainer'
import SettingInfo from './container/room/SettingInfoContainer'
import SettingTablet from './container/room/SettingTabletContainer'
import SettingSensor from './container/room/SettingSensorContainer'
import SmtpSetting from './container/auto_action/SmtpSettingContainer'
import AutoActionMail from './container/auto_action/AutoActionMailContainer'
import AutoActionMailContent from './container/auto_action/AutoActionMailContentContainer'
import AutoActionTask from './container/auto_action/AutoActionTaskContainer'
import CheckIn from './container/check_in/CheckInContainer'
import AccommodationTaxList from './container/accommodation_tax/ListContainer'
import ReportAccommodationTaxList from './container/report/accommodation_tax/ListContainer'
import ReportMonthlySalesList from './container/report/monthly_sales/ListContainer'
import ShowReportCheckIn from './container/report/check_in/ShowReportContainer'
import SelfCheckIn from './container/check_in/SelfCheckInContainer'
import AccommodationInvoiceList from './container/accommodation_invoice/ListContainer'
import DailyProcessList from './container/daily_process/ListContainer'
import DailyProcessEdit from './container/daily_process/EditContainer'
import DailyProcessShow from './container/daily_process/ShowContainer'
import MonthlyProcessList from './container/monthly/ListContainer'
import MonthlyProcessEdit from './container/monthly/EditContainer'
import ReservationList from './container/reservation/ReservationListContainer.js'
import AccountTitleList from './container/account_title/AccountTitleListContainer'
import AccountTitleEdit from './container/account_title/AccountTitleEditContainer'
import AccountTitleDetailList from './container/account_title/AccountTitleDetailListContainer'
import AccountTitleDetailEdit from './container/account_title/AccountTitleDetailEditContainer'
import TransactionList from './container/transaction/ListContainer'
import TransactionMonthlyList from './container/transaction/MonthlyListContainer'
import EventScheduleEdit from './container/event_schedule/EditContainer'
import StripeKeyList from './container/stripe_key/ListContainer'
import StripeKeyEdit from './container/stripe_key/EditContainer'
import SiteRefererList from './container/site_referer/ListContainer'
import PlanList from './container/plan/ListContainer'
import MealList from './container/meal/ListContainer'
import ActivityList from './container/activity/ListContainer'
import SegmentList from './container/segmentation/ListContainer'
import SegmentEdit from './container/segmentation/EditContainer'
import ReservationSegment from './container/segmentation/ReservationContainer'

const routes = [
  // 宿の情報
  {
    path: '/status',
    exact: true,
    menu: true,
    toolbar: true,
    view: RoomStatus
  },
  // 本日のチェックイン
  {
    path: '/receptionist',
    exact: true,
    menu: true,
    toolbar: true,
    view: Receptionist
  },
  {
    path: '/receptionist/:date',
    exact: true,
    menu: true,
    toolbar: true,
    view: Receptionist
  },
  {
    path: '/receptionist/:date/:id/:tab',
    exact: true,
    menu: true,
    toolbar: true,
    view: Receptionist,
    props: { showDetail: true }
  },
  // 予約
  {
    path: '/reservation/new',
    exact: true,
    menu: false,
    toolbar: true,
    view: ReservationEdit
  },
  {
    path: '/reservation/:id/edit',
    exact: true,
    menu: false,
    toolbar: true,
    view: ReservationEdit
  },
  {
    path: '/reservation/:id/invoice',
    exact: true,
    menu: false,
    toolbar: true,
    view: InvoiceCreate
  },
  {
    path: '/reservation/:id/transaction',
    exact: true,
    menu: false,
    toolbar: true,
    view: TransactionCreate
  },
  {
    path: '/reservation/:id/segment',
    exact: true,
    menu: false,
    toolbar: true,
    view: ReservationSegment
  },
  {
    path: '/reservation/search',
    exact: true,
    menu: false,
    toolbar: true,
    view: ReservationList
  },
  {
    path: '/reservation/:id/:tab',
    exact: true,
    menu: true,
    toolbar: true,
    view: ReservationList,
    props: { showDetail: true }
  },
  // イベント
  {
    path: '/event_schedule/new',
    exact: true,
    menu: false,
    toolbar: false,
    view: EventScheduleEdit
  },
  {
    path: '/event_schedule/:id/edit',
    exact: true,
    menu: false,
    toolbar: false,
    view: EventScheduleEdit
  },
  {
    path: '/plan',
    exact: true,
    view: PlanList
  },
  {
    path: '/plan/:id/detail',
    exact: true,
    view: PlanList,
    props: { showDetail: true }
  },
  {
    path: '/meal',
    exact: true,
    view: MealList
  },
  {
    path: '/activity',
    exact: true,
    view: ActivityList
  },
  // タスク
  {
    path: '/task/search',
    exact: true,
    menu: false,
    toolbar: true,
    view: TaskSearch
  },
  {
    path: '/tasks/new',
    exact: true,
    menu: false,
    toolbar: true,
    view: TaskEdit
  },
  {
    path: '/tasks/:taskId',
    exact: true,
    menu: false,
    toolbar: true,
    view: TaskEdit
  },
  // 宿泊予約カレンダー
  {
    path: '/calendar',
    exact: true,
    menu: true,
    toolbar: true,
    view: Calendar
  },
  {
    path: '/calendar/:date',
    exact: true,
    menu: true,
    toolbar: true,
    view: Calendar
  },
  {
    path: '/calendar/:date/:id/:tab',
    exact: true,
    menu: true,
    toolbar: true,
    view: Calendar
  },
  {
    path: '/check_in',
    exact: true,
    menu: true,
    toolbar: true,
    view: CheckIn
  },
  {
    path: '/check_in/:reservationId/edit',
    exact: true,
    toolbar: true,
    view: SelfCheckIn
  },
  // 請求書
  {
    path: '/invoice',
    exact: true,
    toolbar: true,
    view: AccommodationInvoiceList
  },
  {
    path: '/invoice/:id/detail',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccommodationInvoiceList,
    props: { showDetail: true }
  },
  {
    path: '/invoice/:id/refund',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccommodationInvoiceList,
    props: { showDetail: true, showDialog: true }
  },
  {
    path: '/stripe_keys',
    exact: true,
    menu: true,
    toolbar: true,
    view: StripeKeyList
  },
  {
    path: '/stripe_keys/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: StripeKeyEdit
  },
  {
    path: '/stripe_keys/:id',
    exact: true,
    menu: true,
    toolbar: true,
    view: StripeKeyEdit
  },
  {
    path: '/transaction',
    exact: true,
    toolbar: true,
    view: TransactionList
  },
  {
    path: '/transaction/monthly',
    exact: true,
    toolbar: true,
    view: TransactionMonthlyList
  },
  // 日次締め
  {
    path: '/daily',
    exact: true,
    toolbar: true,
    view: DailyProcessList
  },
  {
    path: '/daily/date/:date',
    exact: true,
    toolbar: true,
    view: DailyProcessList
  },
  {
    path: '/daily/new',
    exact: true,
    toolbar: false,
    view: DailyProcessEdit
  },
  {
    path: '/daily/id/:id/show',
    exact: true,
    toolbar: false,
    view: DailyProcessShow
  },
  {
    path: '/daily/id/:id/edit',
    exact: true,
    toolbar: false,
    view: DailyProcessEdit
  },
  // 月次締め
  {
    path: '/monthly',
    exact: true,
    toolbar: true,
    view: MonthlyProcessList
  },
  {
    path: '/monthly/date/:date',
    exact: true,
    toolbar: true,
    view: MonthlyProcessList
  },
  {
    path: '/monthly/date/:date/id/:id/detail',
    exact: true,
    toolbar: true,
    view: MonthlyProcessList,
    props: { showDetail: true }
  },
  {
    path: '/monthly/date/:date/hotel/:hotel_id/detail',
    exact: true,
    toolbar: true,
    view: MonthlyProcessList,
    props: { showDetail: true }
  },
  {
    path: '/monthly/new/:hotel_id/:date',
    exact: true,
    toolbar: false,
    view: MonthlyProcessEdit,
    props: { isNew: true }
  },
  {
    path: '/monthly/:monthlyClosingHeadId/edit',
    exact: true,
    toolbar: false,
    view: MonthlyProcessEdit
  },
  {
    path: '/monthly/:monthlyClosingHeadId/edit',
    exact: true,
    menu: false,
    toolbar: false,
    view: MonthlyProcessEdit
  },
  // [月次レポート] 宿泊税
  {
    path: '/monthly/accommodation_tax',
    exact: true,
    toolbar: true,
    view: AccommodationTaxList
  },
  {
    path: '/monthly/accommodation_tax/date/:date',
    exact: true,
    toolbar: true,
    view: AccommodationTaxList
  },
  {
    path: '/monthly/accommodation_tax/date/:date/id/:id/detail',
    exact: true,
    toolbar: true,
    view: AccommodationTaxList,
    props: { showDetail: true }
  },
  // [月次レポート] 売上一覧
  {
    path: '/report/monthly_sales',
    exact: true,
    toolbar: true,
    view: ReportMonthlySalesList
  },
  {
    path: '/report/monthly_sales/date/:date',
    exact: true,
    toolbar: true,
    view: ReportMonthlySalesList
  },
  {
    path: '/report/monthly_sales/date/:date/hotel/:hotel_id',
    exact: true,
    toolbar: true,
    view: ReportMonthlySalesList
  },
  {
    path: '/report/accommodation_tax',
    exact: true,
    toolbar: true,
    view: ReportAccommodationTaxList
  },
  {
    path: '/report/accommodation_tax/date/:date',
    exact: true,
    toolbar: true,
    view: ReportAccommodationTaxList
  },
  {
    path: '/report/accommodation_tax/date/:date/id/:id/detail',
    exact: true,
    toolbar: true,
    view: ReportAccommodationTaxList,
    props: { showDetail: true }
  },
  {
    path: '/report/check_in',
    exact: true,
    toolbar: true,
    view: ShowReportCheckIn
  },
  {
    path: '/report/check_in/date/:date',
    exact: true,
    toolbar: true,
    view: ShowReportCheckIn
  },

  // 勘定科目管理
  {
    path: '/account_titles',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccountTitleList
  },
  {
    path: '/account_titles/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccountTitleEdit
  },
  {
    path: '/account_titles/:title_id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccountTitleEdit
  },
  {
    path: '/account_titles/details',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccountTitleDetailList
  },
  {
    path: '/account_titles/details/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccountTitleDetailEdit
  },
  {
    path: '/account_titles/details/:detail_id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: AccountTitleDetailEdit
  },
  // 宿泊施設管理
  {
    path: '/hotels',
    exact: true,
    menu: true,
    toolbar: true,
    view: Hotels
  },
  {
    path: '/hotels/:id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: HotelEdit
  },
  {
    path: '/hotels/:id/details',
    exact: true,
    menu: true,
    toolbar: true,
    view: Hotels,
    props: { showDetail: true }
  },
  // 宿の設定
  {
    path: '/setting',
    exact: true,
    menu: true,
    toolbar: true,
    view: Rooms
  },
  {
    path: '/setting/:id/info',
    menu: true,
    toolbar: true,
    view: SettingInfo
  },
  {
    path: '/setting/info/new',
    menu: true,
    toolbar: true,
    view: SettingInfo
  },
  {
    path: '/setting/:id/tablet/preview',
    menu: true,
    toolbar: true,
    view: SettingTablet,
    props: { showTabletPreviewDialog: true }
  },
  {
    path: '/setting/:id/tablet',
    menu: true,
    toolbar: true,
    view: SettingTablet
  },
  {
    path: '/setting/:id/manuals/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: SettingTablet,
    props: { showManualDialog: true }
  },
  {
    path: '/setting/:id/manuals/edit/:mid',
    exact: true,
    menu: true,
    toolbar: true,
    view: SettingTablet,
    props: { showManualDialog: true }
  },
  {
    path: '/setting/:id/sensor',
    menu: true,
    toolbar: true,
    view: SettingSensor
  },
  {
    path: '/check_in',
    exact: true,
    menu: true,
    toolbar: true,
    view: CheckIn
  },
  {
    path: '/check_in/:reservationId/edit',
    exact: true,
    toolbar: true,
    view: SelfCheckIn
  },
  // 自動メール
  {
    path: '/auto_action/mails',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionMail
  },
  {
    path: '/auto_action/mails/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionMail,
    props: { showEdit: true }
  },
  {
    path: '/auto_action/mails/:id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionMail,
    props: { showEdit: true }
  },
  // 自動メール送信の再編集
  {
    path: '/auto_action/mail/:id/content/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionMailContent,
    props: { showEdit: true }
  },
  // 自動タスク
  {
    path: '/auto_action/tasks',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionTask
  },
  {
    path: '/auto_action/tasks/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionTask,
    props: { showEdit: true }
  },
  {
    path: '/auto_action/tasks/:id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: AutoActionTask,
    props: { showEdit: true }
  },
  // 客層設定
  {
    path: '/segment_settings',
    exact: true,
    menu: true,
    toolbar: true,
    view: SegmentList
  },
  {
    path: '/segment_settings/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: SegmentEdit
  },
  {
    path: '/segment_settings/:id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: SegmentEdit
  },
  // OTA設定
  {
    path: '/site_referers',
    exact: true,
    menu: true,
    toolbar: true,
    view: SiteRefererList
  },

  // SMTP設定
  {
    path: '/mail/smtp_settings',
    exact: true,
    menu: true,
    toolbar: true,
    view: SmtpSetting
  },
  {
    path: '/mail/smtp_settings/new',
    exact: true,
    menu: true,
    toolbar: true,
    view: SmtpSetting,
    props: { showEdit: true }
  },
  {
    path: '/mail/smtp_settings/:id/edit',
    exact: true,
    menu: true,
    toolbar: true,
    view: SmtpSetting,
    props: { showEdit: true }
  }

  // ユーザー管理
  // {
  //   path: '/users',
  //   exact: true,
  //   menu: true,
  //   toolbar: true,
  //   view: Users
  // },
  // {
  //   path: '/users/new',
  //   exact: true,
  //   menu: true,
  //   toolbar: true,
  //   view: Users,
  //   props: { showCreateDialog: true }
  // },
  // {
  //   path: '/users/edit/:id',
  //   menu: true,
  //   toolbar: true,
  //   view: Users,
  //   props: { showEditDialog: true }
  // },
  // {
  //   path: '/users/permission/:id',
  //   menu: true,
  //   toolbar: true,
  //   view: Users,
  //   props: { showPermissionDialog: true }
  // }
]

export default routes
