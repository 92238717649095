import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleEvent from '../../event/AccountTitleEvent'

const fetchAccountTitle = async titleId => {
  const response = await AccountTitleRepository.getAccountTitle(titleId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleEvent.fetchOne, { title: response })
  return true
}

export default fetchAccountTitle
