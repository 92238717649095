import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchByDate = async date => {
  const response = await reservationRepository.fetchByDate(date).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchByDate, {
    date: date,
    reservations: response
  })

  return true
}

export default fetchByDate
