import { appActions } from '../AppActions'
import tabletRepository from '../../infra/TabletRepository'
import fetchAll from './fetchAll'

const onRequestDelete = async (roomId, uuid) => {
  const response = await tabletRepository.requestDeleteByUuid(uuid).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '削除できませんでした')
    return false
  }

  appActions.snackbar('削除しました', 'success', true)
  fetchAll(roomId)
  return true
}

export default onRequestDelete
