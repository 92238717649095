import onRequestLogout from '../action/login/onRequestLogout'

const AccountMenu = [
  {
    path: '',
    label: 'ログアウト',
    onClick: e => {
      onRequestLogout(e)
    }
  }
]
export default AccountMenu
