import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleEvent from '../../event/AccountTitleEvent'

const fetchAccountTitles = async () => {
  const response = await AccountTitleRepository.getAccoountTitles().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleEvent.fetchAll, { titles: response })
  return true
}

export default fetchAccountTitles
