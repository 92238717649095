import { UPLOAD_URLS } from '../../store/state/RoomState'
import onRequestUpdate from './onRequestUpdate'

const onUpload = (room, e) => {
  const url = e.target.name

  // ファイルアップロード以外のときに処理が実行されないようにする
  if (UPLOAD_URLS.some(filename => filename === url)) {
    let reader = new FileReader()
    const file = e.target.files[0]
    if (!file) return

    reader.onload = () => {
      onRequestUpdate(room.set(url, reader.result))
    }
    reader.readAsDataURL(file)
  }
}

export default onUpload
