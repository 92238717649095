import ReservationApi from '../api/ReservationApi'
import ApiClient from '../ApiClient'
import AppRepository from './AppRepository'

export class SelfCheckInRepository extends AppRepository {
  constructor(token) {
    super()
    this._api = new ReservationApi(new ApiClient(token))
  }

  fetchOne(reservationId) {
    return this._api.getReservation(reservationId)
  }

  checkIn(reservationId, state) {
    const forms = state.forms.map(f => {
      return f.merge({ passport_image: null })
    })
    return this._api.selfCheckInDone({ guests: forms, deletes: state.deletes })
  }

  selfCheckInRequest() {
    return this._api.selfCheckInRequest()
  }
}

// singleton
export default SelfCheckInRepository
