import NProgress from 'nprogress'
import MonthlyReportAction from '../index'
import { appActions } from '../../AppActions'
import LocalDate from '../../../domain/type/Date'

const onRequestSearch = async (state, date) => {
  if (!state.search_hotel_id) {
    appActions.snackbar('宿泊施設を設定してください')
    return false
  }

  if (state.search_hotel_id) {
    NProgress.start()
    const target_date = date ? LocalDate.parse(date) : state.search_monthly_close_date
    await MonthlyReportAction.fetchAccommodationTaxByDate(target_date, state.search_hotel_id)
    NProgress.done()
  }

  return true
}

export default onRequestSearch
