import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import repository from '../../infra/TransactionRepository'
import TransactionEvent from '../../event/TransactionEvent'

const requestSave = async (reservationId, reservationPay, accommodationTax, transactionHead, transactionDetails) => {
  const response = await repository.requestSave(reservationId, reservationPay, accommodationTax, transactionHead, transactionDetails).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '登録できませんした')
    return false
  }

  return true
}

export default requestSave
