import TaskEvent from '../../event/TaskEvent'
import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import taskRepository from '../../infra/TaskRepository'

const onRequestUpdateStatus = async (checked, task) => {
  const taskId = task.id
  const status = checked ? 'closed' : 'open'
  const merged = task.merge({ status: status })

  AppDispatcher.dispatchWithType(TaskEvent.updateTaskStatus, {
    task: merged
  })

  const response = await taskRepository.requestUpdateStatus(taskId, merged).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }

  const label = checked ? '解決済みに' : 'オープン'
  appActions.snackbar(`${task.title}を${label}しました`, 'success', true)
}

export default onRequestUpdateStatus
