import * as Action from './Action'
import TaxCalculator from '../../../../domain/model/tax/AccommodationTax'
import { loadPlan } from '../../../../domain/model/plan/PlanLoader'

const reducer = (state, action) => {
  const payload = action.payload
  switch (action.type) {
    case Action.INIT:
      const { hotel_id, plan_code, booking_price, people, stay, site_referer, accommodation_tax } = payload
      // 予約元からOTA手数料などを計算
      const commission = TaxCalculator.getCommissionRate(site_referer)

      const merged = state.merge({
        booking_price: booking_price,
        people: people,
        stay: stay,
        include_commission: commission.include,
        commission_rate: commission.rate
      })
      if (accommodation_tax) {
        return merged.load(accommodation_tax).recalculate(true)
      }
      return merged.recalculate()

    case Action.EDIT:
      const updated = state.set(payload.name, payload.value)
      if (state.isUncollected) {
        return updated.uncollected(state.isUncollected)
      }
      return updated.recalculate(false)

    case Action.ACCOMMODATION:
      if (state.isUncollected) {
        return state.set(payload.name, payload.value)
      }
      return state.set(payload.name, payload.value).calculateAccommodationTax()

    case Action.UNCOLLECTED:
      if (payload.value) {
        return state.set(payload.name, payload.value).uncollected(payload.value)
      }

      return state.set(payload.name, payload.value).recalculate(false)

    case Action.MEMO:
      return state.set('memo', payload.value)

    case Action.LOAD_PLAN:
      if (!payload.hotelId || !payload.planCode || !payload.plan) {
        return state
      }

      const plan = loadPlan(payload.hotelId, payload.planCode, payload.plan)
      let plan_price = 0
      if (plan) {
        plan_price = plan.total({ stay: stay, people: people })
        return state.merge({
          plan: plan,
          plan_price: plan_price
        })
      }
      return state

    default:
      return state
  }
}

export { reducer }
