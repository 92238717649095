import fetchAccommodationTaxByDate from './accommodation_tax/fetchByDate'
import fetchAccommodationTaxDetail from './accommodation_tax/fetchDetail'
import onAccommodataTaxMonthChanged from './accommodation_tax/onMonthChanged'
import onEditAccommodationTaxSearchCondition from './accommodation_tax/onEditSearchCondition'
import onAccommodationTaxRequestSearch from './accommodation_tax/onRequestSearch'
import showAccommodationTaxDetail from './accommodation_tax/showDetailView'
import hideAccommodationTaxDetail from './accommodation_tax/hideDetailView'
import fetchMonthlySalesByDate from './monthly_sales/fetchByDate'
import onEditMonthlySalesSearchCondition from './monthly_sales/onEditSearchCondition'
import onMonthlySalesMonthChanged from './monthly_sales/onMonthChanged'
import onMonthlySalesRequestSearch from './monthly_sales/onRequestSearch'

const MonthlyReportAction = {
  fetchAccommodationTaxByDate,
  fetchAccommodationTaxDetail,
  onAccommodataTaxMonthChanged,
  onEditAccommodationTaxSearchCondition,
  onAccommodationTaxRequestSearch,
  showAccommodationTaxDetail,
  hideAccommodationTaxDetail,
  fetchMonthlySalesByDate,
  onEditMonthlySalesSearchCondition,
  onMonthlySalesMonthChanged,
  onMonthlySalesRequestSearch
}

export default MonthlyReportAction
