import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleEvent from '../../event/AccountTitleEvent'

const onRequestUpdate = async title => {
  const response = await AccountTitleRepository.requestUpdateByTitle(title.id, title).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleEvent.fetchOne, { title: response })
  appActions.snackbar('更新しました', 'success', true)
  return true
}

export default onRequestUpdate
