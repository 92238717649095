const DailyClosingEvent = {
  addDetail: 'daily-closing/add-detail',
  addReservation: 'daily-closing/add-reservation',
  cancelCreation: 'daily-closing/cancel-creation',
  dateChange: 'daily-closing/date-change',
  fetchOne: 'daily-closing/fetch-one',
  fetchBySearchCondition: 'daily-closing/fetch-by-search-condition',
  handleEdit: 'daily-closing/editted-reservation',
  initialize: 'daily-closing/initialize',
  onClear: 'daily-closing/on-clear',
  onEdit: 'daily-closing/on-edit',
  onEditDetail: 'daily-closing/on-edit-detail',
  onEditReservation: 'daily-closing/on-edit-reservation',
  onEditSearchCondition: 'daily-closing/on-edit-search-condition',
  onDeleteDetail: 'daily-closing/on-delete-detail',
  onDeleteReservation: 'daily-closing/on-delete-reservation',
  setDailyClosingReservations: 'daily-closing/set-daily-closing-reservations',
  setReservation: 'daily-closing/set-reservation',
  requestSave: 'daily-closing/request-save'
}

export default DailyClosingEvent
