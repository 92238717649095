import cancelCreationView from './cancelCreationView'
import cancelTaskView from './cancelTaskView'
import confirmCheckIn from './confirmCheckIn'
import dateChange from './dateChange'
import downloadCsv from './downloadCsv'
import fetchAuditLog from './fetchAuditLog'
import fetchAutoActionLog from './fetchAutoActionLog'
import fetchByDate from './fetchByDate'
import fetchCheckIns from './fetchCheckIns'
import fetchInvoices from './fetchInvoices'
import fetchMonthlyByDate from './fetchMonthlyByDate'
import fetchOne from './fetchOne'
import fetchPayments from './fetchPayments'
import fetchRooms from './fetchRooms'
import fetchTasks from './fetchTasks'
import getReservation from './getReservation'
import hideDetailView from './hideDetailView'
import monthChange from './monthChange'
import onEdit from './onEdit'
import onEditSearchCondition from './onEditSearchCondition'
import onRequestCancel from './onRequestCancel'
import onRequestCreate from './onRequestCreate'
import onRequestUpdate from './onRequestUpdate'
import onSearch from './onSearch'
import onUpdateReservationPay from './onUpdateReservationPay'
import onUpdateNoshow from './onUpdateNoshow'
import showDetail from './showDetail'
import showDetailView from './showDetailView'
import toApiMessageTabView from './toApiMessageTabView'
import toAuditLogTabView from './toAuditLogTabView'
import toAutoActionTabView from './toAutoActionTabView'
import toCheckInView from './toCheckInView'
import toCreateInvoiceView from './toCreateInvoiceView'
import toCreateTransactionView from './toCreateTransactionView'
import toCreationView from './toCreationView'
import toDailyClosingEditView from './toDailyClosingEditView'
import toDetailTabView from './toDetailTabView'
import toEditView from './toEditView'
import toInvoiceTabView from './toInvoiceTabView'
import toSegmentView from './toSegmentView'

const ReservationAction = {
  cancelCreationView,
  cancelTaskView,
  confirmCheckIn,
  dateChange,
  downloadCsv,
  fetchAuditLog,
  fetchAutoActionLog,
  fetchByDate,
  fetchCheckIns,
  fetchInvoices,
  fetchMonthlyByDate,
  fetchOne,
  fetchPayments,
  fetchRooms,
  fetchTasks,
  getReservation,
  hideDetailView,
  monthChange,
  onEdit,
  onEditSearchCondition,
  onRequestCancel,
  onRequestCreate,
  onRequestUpdate,
  onSearch,
  onUpdateReservationPay,
  onUpdateNoshow,
  showDetail,
  showDetailView,
  toApiMessageTabView,
  toAuditLogTabView,
  toAutoActionTabView,
  toCheckInView,
  toCreateInvoiceView,
  toCreateTransactionView,
  toCreationView,
  toDailyClosingEditView,
  toDetailTabView,
  toEditView,
  toInvoiceTabView,
  toSegmentView
}

export default ReservationAction
