import AppDispatcher from '../../core/AppDispatcher'
import EventScheduleEvent from '../../event/EventScheduleEvent'
import SessionAction from '../../action/session'

const onEdit = async (name, value) => {
  AppDispatcher.dispatchWithType(EventScheduleEvent.onEdit, { name: name, value: value })

  if (name === 'hotel_id' && value) {
    // 部屋を取得
    await SessionAction.fetchRoomsByHotelId(value)
  }

  return true
}

export default onEdit
