import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const onRequestCancel = async reservation => {
  const response = await reservationRepository.requestCancel(reservation).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '予約キャンセルに失敗しました')
    return false
  }
  AppDispatcher.dispatchWithType(ReservationEvent.hideDetail)
  history.push('/receptionist') // TODO
  appActions.snackbar('予約をキャンセルしました', 'success', true)

  return true
}

export default onRequestCancel
