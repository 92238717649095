import React from 'react'

export default function IconTask(props) {
  return (
    <svg viewBox="0 0 512 512" width="14px" height="14px">
      <g>
        <path
          d="M378.405,0H133.594C86.229,0,47.703,38.526,47.703,85.891v340.217c0,47.358,38.526,85.892,85.892,85.892
                        h170.12h13.164l9.319-9.311L454.986,373.9l9.311-9.318v-13.18V85.891C464.297,38.526,425.771,0,378.405,0z M432.495,351.402h-83.71
                        c-24.898,0-45.072,20.189-45.072,45.078v83.718h-170.12c-29.868,0-54.09-24.215-54.09-54.09V85.891
                        c0-29.875,24.223-54.09,54.09-54.09h244.811c29.883,0,54.09,24.215,54.09,54.09V351.402z"
          fill="rgb(75, 75, 75)"
        />
        <rect x="133.677" y="126.915" width="30.684" height="29.282" fill="rgb(75, 75, 75)" />
        <rect x="133.677" y="198.195" width="30.684" height="29.276" fill="rgb(75, 75, 75)" />
        <rect x="133.677" y="269.476" width="30.684" height="29.276" fill="rgb(75, 75, 75)" />
        <rect x="133.677" y="340.756" width="30.684" height="29.275" fill="rgb(75, 75, 75)" />
        <rect x="197.461" y="126.915" width="180.87" height="29.282" fill="rgb(75, 75, 75)" />
        <rect x="197.461" y="198.195" width="180.87" height="29.276" fill="rgb(75, 75, 75)" />
        <rect x="197.461" y="269.476" width="180.87" height="29.276" fill="rgb(75, 75, 75)" />
        <rect x="197.461" y="340.756" width="74.339" height="29.275" fill="rgb(75, 75, 75)" />
      </g>
    </svg>
  )
}
