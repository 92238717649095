import { Record } from 'immutable'

const AutoActionTaskState = Record({
  id: null,
  auto_action_config_id: null,
  name: null,
  title: null,
  message: null
})

export { AutoActionTaskState }
