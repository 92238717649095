import AppDispatcher from '../../../core/AppDispatcher'
import { appActions } from '../../AppActions'
import MonthlyReportEvent from '../../../event/MonthlyReportEvent'
import monthlyReportRepository from '../../../infra/MonthlyReportRepository'

const fetchByDate = async (date, hotel_id) => {
  const response = await monthlyReportRepository.fetchAccommodationTax(date, hotel_id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyReportEvent.accommodationTaxMonthChanged, { date: date, hotel_id: hotel_id, accommodation_tax: response })

  return true
}

export default fetchByDate
