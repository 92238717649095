import HotelManualEvent from '../../event/HotelManualEvent'
import AppDispatcher from '../../core/AppDispatcher'

const onEdit = e => {
  const name = e.target.name

  if (name === 'manual') {
    let reader = new FileReader()
    const file = e.target.files[0]
    if (!file) return

    const fileName = file.name
    reader.onload = () => {
      AppDispatcher.dispatchWithType(HotelManualEvent.onUpload, {
        name: name,
        fileName: fileName,
        fileContent: reader.result
      })
    }
    reader.readAsDataURL(file)
    return
  }
  AppDispatcher.dispatchWithType(HotelManualEvent.onEdit, { name: name, value: e.target.value })
}

export default onEdit
