import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import StripeApiKeyRepository from '../../infra/StripeApiKeyRepository'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const fetchOne = async stripeApiKeyId => {
  const response = await StripeApiKeyRepository.fetchOne(stripeApiKeyId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(StripeKeyEvent.fetchOne, response)
  return true
}

export default fetchOne
