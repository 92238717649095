import React from 'react'
import f from '../../../../../util/NumberFormat'
import TaxCalculator from '../../../../../domain/model/tax/AccommodationTax'

function PlanSimpleDisplay(props) {
  const { plan, people, stay, total_price } = props

  if (!plan) {
    return <></>
  }
  const total = plan.total({ stay: stay, people: people })
  let priceLeft = total_price - total
  if (priceLeft < 0) {
    priceLeft = 0
  }
  const accommodation_fee = TaxCalculator.calculateExcludingPrice(priceLeft)

  return (
    <table>
      <tbody>
        <tr>
          <th style={{ textAlign: 'left' }}>プラン名</th>
          <td style={{ maxWidth: '150px' }}>{plan.name}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>
            プラン料金
            <small>(税込)</small>
          </th>
          <td style={{ textAlign: 'right' }}>{f.commaSeparated(total)} 円</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>
            <small>宿泊費(税込)</small>
          </th>
          <td style={{ textAlign: 'right' }}>{f.commaSeparated(priceLeft)} 円</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>
            <small>消費税</small>
          </th>
          <td style={{ textAlign: 'right' }}>{f.commaSeparated(priceLeft - accommodation_fee)} 円</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>
            <small>宿泊費(税抜)</small>
          </th>
          <td style={{ textAlign: 'right' }}>{f.commaSeparated(accommodation_fee)} 円</td>
        </tr>
      </tbody>
    </table>
  )
}

export default PlanSimpleDisplay
