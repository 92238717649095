import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ReservationEvent from '../../event/ReservationEvent'
import reservationRepository from '../../infra/ReservationRepository'

const fetchCheckIns = async reservationId => {
  const response = await reservationRepository.fetchCheckIns(reservationId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, 'チェックイン情報が取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(ReservationEvent.fetchCheckIns, {
    check_ins: response
  })
  return true
}

export default fetchCheckIns
