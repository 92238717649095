import ApiClient from '../ApiClient'
import StripeApi from '../api/StripeApi'

export class StripeApiKeyRepository {
  constructor() {
    this._api = new StripeApi(ApiClient.instance)
  }

  fetchBySearchCondition(searchState, pagination) {
    const body = { stripe_api_key: searchState.toObject(), pagination: pagination }
    return this._api.searchApiKey(body)
  }

  fetchOne(id) {
    return this._api.getApiKey(id)
  }

  requestCreate(state) {
    const body = { stripe_api_key: state.toObject() }
    return this._api.createApiKey(body)
  }

  requestUpdate(state) {
    const body = { stripe_api_key: state.toObject() }
    return this._api.updateApiKey(state.id, body)
  }

  requestDelete(id) {
    return this._api.deleteApiKey(id)
  }

  testConnection(state) {
    const body = { stripe_api_key: state.toObject() }
    return this._api.testApiKey(body)
  }
}

export default new StripeApiKeyRepository()
