import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import UserEvent from '../../event/UserEvent'
import HotelRepository from '../../infra/HotelRepository'

const fetchUserHotels = async () => {
  const response = await HotelRepository.fetchByUser().catch(() => false)
  if (response === false) {
    appActions.showAlert('部屋情報の取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(UserEvent.fetchUserHotels, {
    hotels: response
  })

  return true
}

export default fetchUserHotels
