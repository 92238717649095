/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import CheckIn from './CheckIn'
import Prefecture from './Prefecture'
import Reservation from './Reservation'

/**
 * The InlineResponse2006 model module.
 * @module model/InlineResponse2006
 * @version 1.0.0
 */
export default class InlineResponse2006 {
  /**
   * Constructs a new <code>InlineResponse2006</code>.
   * @alias module:model/InlineResponse2006
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse2006</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2006} obj Optional instance to populate.
   * @return {module:model/InlineResponse2006} The populated <code>InlineResponse2006</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2006()

      if (data.hasOwnProperty('reservation')) {
        obj['reservation'] = Reservation.constructFromObject(data['reservation'])
      }
      if (data.hasOwnProperty('check_ins')) {
        obj['check_ins'] = ApiClient.convertToType(data['check_ins'], [CheckIn])
      }
      if (data.hasOwnProperty('prefectures')) {
        obj['prefectures'] = ApiClient.convertToType(data['prefectures'], [Prefecture])
      }
    }
    return obj
  }

  /**
   * @member {module:model/Reservation} reservation
   */
  reservation = undefined
  /**
   * @member {Array.<module:model/CheckIn>} check_ins
   */
  check_ins = undefined
  /**
   * @member {Array.<module:model/Prefecture>} prefectures
   */
  prefectures = undefined
}
