import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import monthlyClosingRepository from '../../infra/MonthlyClosingRepository'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const fetchSummary = async (hotel_id, monthly_close_date) => {
  const res = await monthlyClosingRepository.fetchSummary(hotel_id, monthly_close_date).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '月次締めサマリーをできませんした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyClosingEvent.fetchOne, res)
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.fetchSummary, res)

  return true
}

export default fetchSummary
