import SelfCheckInRepository from '../../infra/SelfCheckInRepository'
import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'
import getPassports from './getPassports'

const fetchOne = async (reservationId, token) => {
  const response = await new SelfCheckInRepository(token).fetchOne(reservationId).catch(() => false)
  if (response === false) {
    AppDispatcher.dispatchWithType(CheckInEvent.expired)
    return false
  }

  AppDispatcher.dispatchWithType(CheckInEvent.fetchOne, response)
  getPassports(token, response.check_ins)
  return true
}

export default fetchOne
