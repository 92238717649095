import fetchAll from './fetchAll'
import fetchSiteReferrers from './fetchSiteReferrers'

export const SiteReferrerAvailableType = {
  CLOSING: 'closing',
  AUTO_ACTION: 'auto_action',
  RESERVATION: 'reservation'
}

const SiteReferrerAction = {
  fetchAll,
  fetchSiteReferrers
}

export default SiteReferrerAction
