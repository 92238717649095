import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const onUpdateUncollectible = async invoiceId => {
  const response = await repository.updateUncollectible(invoiceId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }

  appActions.snackbar(`この請求を未払いに変更しました`, 'success', true)

  return true
}

export default onUpdateUncollectible
