import history from '../../util/history'

const hideDetailView = () => {
  if (!history.canGoBack()) {
    history.push('/hotels')
    return false
  }

  history.goBack()
  return true
}

export default hideDetailView
