/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import EventSchedule from './EventSchedule'
import Reservation from './Reservation'
import Room from './Room'

/**
 * The InlineResponse2003 model module.
 * @module model/InlineResponse2003
 * @version 1.0.0
 */
export default class InlineResponse2003 {
  /**
   * Constructs a new <code>InlineResponse2003</code>.
   * @alias module:model/InlineResponse2003
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse2003</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2003} obj Optional instance to populate.
   * @return {module:model/InlineResponse2003} The populated <code>InlineResponse2003</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2003()

      if (data.hasOwnProperty('today')) {
        obj['today'] = ApiClient.convertToType(data['today'], 'Date')
      }
      if (data.hasOwnProperty('rooms')) {
        obj['rooms'] = ApiClient.convertToType(data['rooms'], [Room])
      }
      if (data.hasOwnProperty('reservations')) {
        obj['reservations'] = ApiClient.convertToType(data['reservations'], [Reservation])
      }
      if (data.hasOwnProperty('event_schedules')) {
        obj['event_schedules'] = ApiClient.convertToType(data['event_schedules'], [EventSchedule])
      }
    }
    return obj
  }

  /**
   * @member {Date} today
   */
  today = undefined
  /**
   * @member {Array.<module:model/Room>} rooms
   */
  rooms = undefined
  /**
   * @member {Array.<module:model/Reservation>} reservations
   */
  reservations = undefined
  /**
   * @member {Array.<module:model/EventSchedule>} event_schedules
   */
  event_schedules = undefined
}
