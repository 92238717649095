import onRequestCreate from './onRequestCreate'
import onRequestUpdate from './onRequestUpdate'
import onRequestDelete from './onRequestDelete'

const AccommodationTaxAction = {
  onRequestCreate,
  onRequestUpdate,
  onRequestDelete
}

export default AccommodationTaxAction
