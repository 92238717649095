/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body21 from '../model/Body21'
import Body22 from '../model/Body22'
import Body23 from '../model/Body23'
import DailyClosingBody from '../model/DailyClosingBody'
import DailyClosingHead from '../model/DailyClosingHead'
import InlineResponse20023 from '../model/InlineResponse20023'
import InlineResponse204 from '../model/InlineResponse204'

/**
 * DailyClosing service.
 * @module api/DailyClosingApi
 * @version 1.0.0
 */
export default class DailyClosingApi {
  /**
   * Constructs a new DailyClosingApi.
   * @alias module:api/DailyClosingApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * create daily closing
   * @param {module:model/Body21} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse204} and HTTP response
   */
  createDailyClosingWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createDailyClosing")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse204

    return this.apiClient.callApi('/daily/closings', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * create daily closing
   * @param {module:model/Body21} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse204}
   */
  createDailyClosing(body) {
    return this.createDailyClosingWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 日次締めデータを削除する
   * @param {Number} dailyClosingHeadId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteDailyClosingWithHttpInfo(dailyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'dailyClosingHeadId' is set
    if (dailyClosingHeadId === undefined || dailyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'dailyClosingHeadId' when calling deleteDailyClosing")
    }

    let pathParams = {
      daily_closing_head_id: dailyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/daily/closings/{daily_closing_head_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 日次締めデータを削除する
   * @param {Number} dailyClosingHeadId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteDailyClosing(dailyClosingHeadId) {
    return this.deleteDailyClosingWithHttpInfo(dailyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 日次締めデータを取得する
   * @param {Number} dailyClosingHeadId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20023} and HTTP response
   */
  getDailyClosingWithHttpInfo(dailyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'dailyClosingHeadId' is set
    if (dailyClosingHeadId === undefined || dailyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'dailyClosingHeadId' when calling getDailyClosing")
    }

    let pathParams = {
      daily_closing_head_id: dailyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20023

    return this.apiClient.callApi('/daily/closings/{daily_closing_head_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 日次締めデータを取得する
   * @param {Number} dailyClosingHeadId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20023}
   */
  getDailyClosing(dailyClosingHeadId) {
    return this.getDailyClosingWithHttpInfo(dailyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * search daily closings
   * @param {module:model/Body22} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/DailyClosingHead>} and HTTP response
   */
  searchDailyClosingWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchDailyClosing")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [DailyClosingHead]

    return this.apiClient.callApi('/daily/closings/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * search daily closings
   * @param {module:model/Body22} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/DailyClosingHead>}
   */
  searchDailyClosing(body) {
    return this.searchDailyClosingWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * search daily closings for edit
   * @param {module:model/Body23} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20023} and HTTP response
   */
  searchDailyClosingForEditWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchDailyClosingForEdit")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20023

    return this.apiClient.callApi('/daily/closings/new', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * search daily closings for edit
   * @param {module:model/Body23} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20023}
   */
  searchDailyClosingForEdit(body) {
    return this.searchDailyClosingForEditWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 日次締めを解除する
   * @param {Number} dailyClosingHeadId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  unlockDailyClosingWithHttpInfo(dailyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'dailyClosingHeadId' is set
    if (dailyClosingHeadId === undefined || dailyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'dailyClosingHeadId' when calling unlockDailyClosing")
    }

    let pathParams = {
      daily_closing_head_id: dailyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/daily/closings/{daily_closing_head_id}/unlock', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 日次締めを解除する
   * @param {Number} dailyClosingHeadId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  unlockDailyClosing(dailyClosingHeadId) {
    return this.unlockDailyClosingWithHttpInfo(dailyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 日次締めデータを更新する
   * @param {Number} dailyClosingHeadId Target id
   * @param {module:model/DailyClosingBody} dailyClosingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse204} and HTTP response
   */
  updateDailyClosingWithHttpInfo(dailyClosingHeadId, dailyClosingBody) {
    let postBody = dailyClosingBody

    // verify the required parameter 'dailyClosingHeadId' is set
    if (dailyClosingHeadId === undefined || dailyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'dailyClosingHeadId' when calling updateDailyClosing")
    }

    // verify the required parameter 'dailyClosingBody' is set
    if (dailyClosingBody === undefined || dailyClosingBody === null) {
      throw new Error("Missing the required parameter 'dailyClosingBody' when calling updateDailyClosing")
    }

    let pathParams = {
      daily_closing_head_id: dailyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse204

    return this.apiClient.callApi('/daily/closings/{daily_closing_head_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 日次締めデータを更新する
   * @param {Number} dailyClosingHeadId Target id
   * @param {module:model/DailyClosingBody} dailyClosingBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse204}
   */
  updateDailyClosing(dailyClosingHeadId, dailyClosingBody) {
    return this.updateDailyClosingWithHttpInfo(dailyClosingHeadId, dailyClosingBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
