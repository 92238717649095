import { Activity, ActivityPlan, Meal, MealPlan, Plan, Plans, SaleUnitPeopleStay, SaleUnitPeople, SaleUnitStay, SaleUnitOnce } from './Plan'

const saleUnit = (saleUnit, unitPrice) => {
  switch (saleUnit) {
    case 'people_stay':
      return new SaleUnitPeopleStay(unitPrice)
    case 'people':
      return new SaleUnitPeople(unitPrice)
    case 'stay':
      return new SaleUnitStay(unitPrice)
    case 'once':
      return new SaleUnitOnce(unitPrice)
    default:
      // Errorの方が良い？
      return new SaleUnitOnce(unitPrice)
  }
}

const loadPlan = (hotelId, planCode, p) => {
  const plan = new Plan(p.name, hotelId, planCode)

  if (p.breakfasts && p.breakfasts.length > 0) {
    p.breakfasts.forEach(m => {
      plan.addMealPlan(new MealPlan(new Meal(m.name, saleUnit(m.sale_unit, m.unit_price)), null, null))
    })
  }

  if (p.lunches && p.lunches.length > 0) {
    p.lunches.forEach(m => {
      plan.addMealPlan(new MealPlan(null, new Meal(m.name, saleUnit(m.sale_unit, m.unit_price)), null))
    })
  }

  if (p.dinners && p.dinners.length > 0) {
    p.dinners.forEach(m => {
      plan.addMealPlan(new MealPlan(null, null, new Meal(m.name, saleUnit(m.sale_unit, m.unit_price))))
    })
  }

  if (p.breakfasts && p.breakfasts.length > 0) {
    p.activities.forEach(a => {
      plan.addActivityPlan(new ActivityPlan(new Activity(a.name, saleUnit(a.sale_unit, a.unit_price))))
    })
  }

  return plan
}

export { loadPlan }
