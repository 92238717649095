const AccommodationInvoiceEvent = {
  initialize: 'accommodation-invoice/initialize',
  fetchOne: 'accommodation-invoice/fetch-one',
  onSearch: 'accommodation-invoice/on-search',
  clearRefund: 'accommodation-invoice/clear-refund',
  createStart: 'accommodation-invoice/create-start',
  onEditSearchCondition: 'accommodation-invoice/edit-search-condition',
  onEdit: 'accommodation-invoice/on-edit',
  fetchReservation: 'accommodation-invoice/fetch-reservation',
  onEditAccommodationTax: 'accommodation-invoice/on-edit-accommodation-tax',
  onEditRefund: 'accommodation-invoice/on-edit-refund',
  onUpdateAccommodationTax: 'accommodation-invoice/on-update-accommodation-tax'
}

export default AccommodationInvoiceEvent
