import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'
import monthlyClosingRepository from '../../infra/MonthlyClosingRepository'
import { appActions } from '../AppActions'
import ClosingStatus from '../../domain/model/closing/Status'

const onRequestUpdate = async (id, nextStatus, head, details, deletedDetailIds) => {
  const _head = ClosingStatus.CLOSED === nextStatus ? ClosingStatus.markAsClosed(head) : ClosingStatus.markAsSaved(head)

  const res = await monthlyClosingRepository.requestUpdate(id, _head, details, deletedDetailIds).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '更新できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyClosingEvent.onClear)
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.fetchOne, res)

  appActions.snackbar('更新しました', 'success', true)

  return true
}

export default onRequestUpdate
