import TaskEvent from '../../event/TaskEvent'
import AppDispatcher from '../../core/AppDispatcher'

const onEdit = event => {
  AppDispatcher.dispatchWithType(TaskEvent.onEdit, {
    name: event.target.name,
    value: event.target.value
  })
}

export default onEdit
