/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AccountTitleDetail model module.
 * @module model/AccountTitleDetail
 * @version 1.0.0
 */
export default class AccountTitleDetail {
  /**
   * Constructs a new <code>AccountTitleDetail</code>.
   * @alias module:model/AccountTitleDetail
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AccountTitleDetail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccountTitleDetail} obj Optional instance to populate.
   * @return {module:model/AccountTitleDetail} The populated <code>AccountTitleDetail</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccountTitleDetail()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('account_title_group_id')) {
        obj['account_title_group_id'] = ApiClient.convertToType(data['account_title_group_id'], 'Number')
      }
      if (data.hasOwnProperty('account_title_group_name')) {
        obj['account_title_group_name'] = ApiClient.convertToType(data['account_title_group_name'], 'String')
      }
      if (data.hasOwnProperty('account_title_id')) {
        obj['account_title_id'] = ApiClient.convertToType(data['account_title_id'], 'Number')
      }
      if (data.hasOwnProperty('account_title_name')) {
        obj['account_title_name'] = ApiClient.convertToType(data['account_title_name'], 'String')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('position')) {
        obj['position'] = ApiClient.convertToType(data['position'], 'Number')
      }
      if (data.hasOwnProperty('cost_type')) {
        obj['cost_type'] = ApiClient.convertToType(data['cost_type'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} account_title_group_id
   */
  account_title_group_id = undefined
  /**
   * @member {String} account_title_group_name
   */
  account_title_group_name = undefined
  /**
   * @member {Number} account_title_id
   */
  account_title_id = undefined
  /**
   * @member {String} account_title_name
   */
  account_title_name = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {Number} position
   */
  position = undefined
  /**
   * @member {module:model/AccountTitleDetail.CostTypeEnum} cost_type
   */
  cost_type = undefined

  /**
   * Allowed values for the <code>cost_type</code> property.
   * @enum {String}
   * @readonly
   */
  static CostTypeEnum = {
    /**
     * value: "unrelated"
     * @const
     */
    unrelated: 'unrelated',

    /**
     * value: "variable"
     * @const
     */
    variable: 'variable',

    /**
     * value: "fixed"
     * @const
     */
    fixed: 'fixed'
  }
}
