const HotelEvent = {
  getStatus: 'hotels/fetch-status',
  fetchOne: 'hotel/fetch-hotel',
  fetchAll: 'hotels/fetch-all',
  fetchRoomsById: 'hotels/fetch-rooms-by-id',
  onCreate: 'hotel/on-create',
  onEdit: 'hotel/on-edit'
}

export default HotelEvent
