/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationTax from '../model/AccommodationTax'
import Body28 from '../model/Body28'
import InlineResponse20026 from '../model/InlineResponse20026'

/**
 * OwnerReport service.
 * @module api/OwnerReportApi
 * @version 1.0.0
 */
export default class OwnerReportApi {
  /**
   * Constructs a new OwnerReportApi.
   * @alias module:api/OwnerReportApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 宿泊税IDに紐づく宿泊データを返す
   * @param {Number} dailyClosingHeadId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20026} and HTTP response
   */
  getDailyDlosingsOfAccommodationTaxForOwnerWithHttpInfo(dailyClosingHeadId) {
    let postBody = null

    // verify the required parameter 'dailyClosingHeadId' is set
    if (dailyClosingHeadId === undefined || dailyClosingHeadId === null) {
      throw new Error("Missing the required parameter 'dailyClosingHeadId' when calling getDailyDlosingsOfAccommodationTaxForOwner")
    }

    let pathParams = {
      daily_closing_head_id: dailyClosingHeadId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20026

    return this.apiClient.callApi(
      '/reports/owner/accommodation_tax/{daily_closing_head_id}',
      'GET',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 宿泊税IDに紐づく宿泊データを返す
   * @param {Number} dailyClosingHeadId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20026}
   */
  getDailyDlosingsOfAccommodationTaxForOwner(dailyClosingHeadId) {
    return this.getDailyDlosingsOfAccommodationTaxForOwnerWithHttpInfo(dailyClosingHeadId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 月次締めで締め済みの対象月にチェックインしている宿泊税一覧を返す
   * @param {module:model/Body28} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AccommodationTax>} and HTTP response
   */
  searchAccommodationTaxForOwnerWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchAccommodationTaxForOwner")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AccommodationTax]

    return this.apiClient.callApi('/reports/owner/accommodation_tax/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 月次締めで締め済みの対象月にチェックインしている宿泊税一覧を返す
   * @param {module:model/Body28} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AccommodationTax>}
   */
  searchAccommodationTaxForOwner(body) {
    return this.searchAccommodationTaxForOwnerWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
