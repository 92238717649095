import ApiClient from '../ApiClient'
import SegmentationApi from '../api/SegmentationApi'

export class SegmentationRepository {
  constructor() {
    this._api = new SegmentationApi(ApiClient.instance)
  }

  fetchAll() {
    return this._api.getList()
  }

  fetchOne(planId) {
    return this._api.get(planId)
  }

  requestCreate(segmentCategory, segmentValues) {
    let values = []
    if (segmentValues) {
      values = segmentValues.map(v => v.toRequestBody())
    }
    const body = {
      segment_category: segmentCategory.toRequestBody(),
      segment_values: values
    }
    return this._api.create(body)
  }

  requestUpdate(segmentCategory, segmentValues) {
    let values = []
    if (segmentValues) {
      values = segmentValues.map(v => v.toRequestBody())
    }
    const body = {
      segment_category: segmentCategory.toRequestBody(),
      segment_values: values
    }
    return this._api.update(segmentCategory.id, body)
  }

  requestDelete(id) {
    return this._api.delete(id)
  }
}

// singleton
export default new SegmentationRepository()
