import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const requestRefund = async (invoiceId, accommodationInvoiceRefund) => {
  const response = await repository.requestRefund(invoiceId, accommodationInvoiceRefund).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '返金処理に失敗しました')
    return false
  }

  appActions.snackbar('返金処理を完了しました', 'success', true)
  return true
}

export default requestRefund
