/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The EventSchedule model module.
 * @module model/EventSchedule
 * @version 1.0.0
 */
export default class EventSchedule {
  /**
   * Constructs a new <code>EventSchedule</code>.
   * @alias module:model/EventSchedule
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>EventSchedule</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EventSchedule} obj Optional instance to populate.
   * @return {module:model/EventSchedule} The populated <code>EventSchedule</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EventSchedule()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String')
      }
      if (data.hasOwnProperty('start_date')) {
        obj['start_date'] = ApiClient.convertToType(data['start_date'], 'Date')
      }
      if (data.hasOwnProperty('end_date')) {
        obj['end_date'] = ApiClient.convertToType(data['end_date'], 'Date')
      }
      if (data.hasOwnProperty('hotel_id')) {
        obj['hotel_id'] = ApiClient.convertToType(data['hotel_id'], 'Number')
      }
      if (data.hasOwnProperty('room_id')) {
        obj['room_id'] = ApiClient.convertToType(data['room_id'], 'Number')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
      if (data.hasOwnProperty('memo')) {
        obj['memo'] = ApiClient.convertToType(data['memo'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} title
   */
  title = undefined
  /**
   * @member {Date} start_date
   */
  start_date = undefined
  /**
   * @member {Date} end_date
   */
  end_date = undefined
  /**
   * @member {Number} hotel_id
   */
  hotel_id = undefined
  /**
   * @member {Number} room_id
   */
  room_id = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
  /**
   * @member {String} memo
   */
  memo = undefined
}
