import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import siteReferrerRepository from '../../infra/SiteReferrerRepository'
import SiteReferrerEvent from '../../event/SiteReferrerEvent'

const fetchSiteReferrers = async type => {
  const res = await siteReferrerRepository.fetchByType(type).catch(e => e)
  if (res instanceof Error) {
    appActions.showError(res.response.body, 'OTA情報の一覧取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(SiteReferrerEvent.fetchSiteReferrers, { site_referrers: res })

  return true
}

export default fetchSiteReferrers
