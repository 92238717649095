import AppDispatcher from '../../core/AppDispatcher'
import reservationRepository from '../../infra/ReservationRepository'
import ReservationEvent from '../../event/ReservationEvent'
import { appActions } from '../AppActions'

const onUpdateNoshow = async (reservationId, noshow) => {
  const response = await reservationRepository.updateNoshow(reservationId, noshow).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }

  appActions.snackbar('更新しました', 'success', true)

  AppDispatcher.dispatchWithType(ReservationEvent.onUpdateNoshow, response.reservation)

  return true
}

export default onUpdateNoshow
