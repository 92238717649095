/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AutoAction from '../model/AutoAction'
import AutoActionBody from '../model/AutoActionBody'
import AutoActionBody1 from '../model/AutoActionBody1'
import AutoActionBody2 from '../model/AutoActionBody2'
import AutoActionBody3 from '../model/AutoActionBody3'
import AutoActionBody4 from '../model/AutoActionBody4'
import AutoActionLogBody from '../model/AutoActionLogBody'
import AutoActionMail from '../model/AutoActionMail'
import AutoActionTask from '../model/AutoActionTask'
import InlineResponse20029 from '../model/InlineResponse20029'
import InlineResponse2011 from '../model/InlineResponse2011'
import InlineResponse2012 from '../model/InlineResponse2012'

/**
 * AutoAction service.
 * @module api/AutoActionApi
 * @version 1.0.0
 */
export default class AutoActionApi {
  /**
   * Constructs a new AutoActionApi.
   * @alias module:api/AutoActionApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 自動アクションのメールを削除する
   * @param {Number} autoActionMailId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteAutoActionMailWithHttpInfo(autoActionMailId) {
    let postBody = null

    // verify the required parameter 'autoActionMailId' is set
    if (autoActionMailId === undefined || autoActionMailId === null) {
      throw new Error("Missing the required parameter 'autoActionMailId' when calling deleteAutoActionMail")
    }

    let pathParams = {
      auto_action_mail_id: autoActionMailId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/auto_action/mails/{auto_action_mail_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのメールを削除する
   * @param {Number} autoActionMailId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteAutoActionMail(autoActionMailId) {
    return this.deleteAutoActionMailWithHttpInfo(autoActionMailId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのタスク設定を削除する
   * @param {Number} autoActionTaskId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteAutoActionTaskWithHttpInfo(autoActionTaskId) {
    let postBody = null

    // verify the required parameter 'autoActionTaskId' is set
    if (autoActionTaskId === undefined || autoActionTaskId === null) {
      throw new Error("Missing the required parameter 'autoActionTaskId' when calling deleteAutoActionTask")
    }

    let pathParams = {
      auto_action_task_id: autoActionTaskId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/auto_action/tasks/{auto_action_task_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのタスク設定を削除する
   * @param {Number} autoActionTaskId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteAutoActionTask(autoActionTaskId) {
    return this.deleteAutoActionTaskWithHttpInfo(autoActionTaskId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのメール設定を取得する
   * @param {Number} autoActionMailId Target auto action mail id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2011} and HTTP response
   */
  getAutoActionMailWithHttpInfo(autoActionMailId) {
    let postBody = null

    // verify the required parameter 'autoActionMailId' is set
    if (autoActionMailId === undefined || autoActionMailId === null) {
      throw new Error("Missing the required parameter 'autoActionMailId' when calling getAutoActionMail")
    }

    let pathParams = {
      auto_action_mail_id: autoActionMailId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2011

    return this.apiClient.callApi('/auto_action/mails/{auto_action_mail_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのメール設定を取得する
   * @param {Number} autoActionMailId Target auto action mail id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2011}
   */
  getAutoActionMail(autoActionMailId) {
    return this.getAutoActionMailWithHttpInfo(autoActionMailId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションログから手動で送信/登録する
   * @param {Number} autoActionLogId Target auto action log id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20029} and HTTP response
   */
  getAutoActionMailContentWithHttpInfo(autoActionLogId) {
    let postBody = null

    // verify the required parameter 'autoActionLogId' is set
    if (autoActionLogId === undefined || autoActionLogId === null) {
      throw new Error("Missing the required parameter 'autoActionLogId' when calling getAutoActionMailContent")
    }

    let pathParams = {
      auto_action_log_id: autoActionLogId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20029

    return this.apiClient.callApi('/auto_action/logs/{auto_action_log_id}/content', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションログから手動で送信/登録する
   * @param {Number} autoActionLogId Target auto action log id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20029}
   */
  getAutoActionMailContent(autoActionLogId) {
    return this.getAutoActionMailContentWithHttpInfo(autoActionLogId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのメール設定一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AutoActionMail>} and HTTP response
   */
  getAutoActionMailsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AutoActionMail]

    return this.apiClient.callApi('/auto_action/mails', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのメール設定一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AutoActionMail>}
   */
  getAutoActionMails() {
    return this.getAutoActionMailsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのタスク設定を取得する
   * @param {Number} autoActionTaskId Target auto action task id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2012} and HTTP response
   */
  getAutoActionTaskWithHttpInfo(autoActionTaskId) {
    let postBody = null

    // verify the required parameter 'autoActionTaskId' is set
    if (autoActionTaskId === undefined || autoActionTaskId === null) {
      throw new Error("Missing the required parameter 'autoActionTaskId' when calling getAutoActionTask")
    }

    let pathParams = {
      auto_action_task_id: autoActionTaskId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2012

    return this.apiClient.callApi('/auto_action/tasks/{auto_action_task_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのタスク設定を取得する
   * @param {Number} autoActionTaskId Target auto action task id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2012}
   */
  getAutoActionTask(autoActionTaskId) {
    return this.getAutoActionTaskWithHttpInfo(autoActionTaskId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのタスク設定一覧を取得
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AutoActionTask>} and HTTP response
   */
  getAutoActionTasksWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AutoActionTask]

    return this.apiClient.callApi('/auto_action/tasks', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのタスク設定一覧を取得
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AutoActionTask>}
   */
  getAutoActionTasks() {
    return this.getAutoActionTasksWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのメール設定を新規登録する
   * @param {module:model/AutoAction} autoAction
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2011} and HTTP response
   */
  newAutoActionMailWithHttpInfo(autoAction) {
    let postBody = autoAction

    // verify the required parameter 'autoAction' is set
    if (autoAction === undefined || autoAction === null) {
      throw new Error("Missing the required parameter 'autoAction' when calling newAutoActionMail")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2011

    return this.apiClient.callApi('/auto_action/mails', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのメール設定を新規登録する
   * @param {module:model/AutoAction} autoAction
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2011}
   */
  newAutoActionMail(autoAction) {
    return this.newAutoActionMailWithHttpInfo(autoAction).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのタスク設定を新規登録する
   * @param {module:model/AutoActionBody2} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2012} and HTTP response
   */
  newAutoActionTaskWithHttpInfo(autoActionBody) {
    let postBody = autoActionBody

    // verify the required parameter 'autoActionBody' is set
    if (autoActionBody === undefined || autoActionBody === null) {
      throw new Error("Missing the required parameter 'autoActionBody' when calling newAutoActionTask")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2012

    return this.apiClient.callApi('/auto_action/tasks', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのタスク設定を新規登録する
   * @param {module:model/AutoActionBody2} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2012}
   */
  newAutoActionTask(autoActionBody) {
    return this.newAutoActionTaskWithHttpInfo(autoActionBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションログから手動で送信/登録する
   * @param {Number} autoActionLogId Target auto action log id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  processManualActionWithHttpInfo(autoActionLogId) {
    let postBody = null

    // verify the required parameter 'autoActionLogId' is set
    if (autoActionLogId === undefined || autoActionLogId === null) {
      throw new Error("Missing the required parameter 'autoActionLogId' when calling processManualAction")
    }

    let pathParams = {
      auto_action_log_id: autoActionLogId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/auto_action/logs/{auto_action_log_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションログから手動で送信/登録する
   * @param {Number} autoActionLogId Target auto action log id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  processManualAction(autoActionLogId) {
    return this.processManualActionWithHttpInfo(autoActionLogId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションログから再送信/再登録する
   * @param {module:model/AutoActionLogBody} autoActionLogBody Target auto action log id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  reprocessAutoActionWithHttpInfo(autoActionLogBody) {
    let postBody = autoActionLogBody

    // verify the required parameter 'autoActionLogBody' is set
    if (autoActionLogBody === undefined || autoActionLogBody === null) {
      throw new Error("Missing the required parameter 'autoActionLogBody' when calling reprocessAutoAction")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/auto_action/logs', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションログから再送信/再登録する
   * @param {module:model/AutoActionLogBody} autoActionLogBody Target auto action log id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  reprocessAutoAction(autoActionLogBody) {
    return this.reprocessAutoActionWithHttpInfo(autoActionLogBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Search Auto Action Mails
   * @param {module:model/AutoActionBody1} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AutoActionMail>} and HTTP response
   */
  searchAutoActionMailsWithHttpInfo(autoActionBody) {
    let postBody = autoActionBody

    // verify the required parameter 'autoActionBody' is set
    if (autoActionBody === undefined || autoActionBody === null) {
      throw new Error("Missing the required parameter 'autoActionBody' when calling searchAutoActionMails")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AutoActionMail]

    return this.apiClient.callApi('/auto_action/mails/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Search Auto Action Mails
   * @param {module:model/AutoActionBody1} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AutoActionMail>}
   */
  searchAutoActionMails(autoActionBody) {
    return this.searchAutoActionMailsWithHttpInfo(autoActionBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Search Auto Action Tasks
   * @param {module:model/AutoActionBody4} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AutoActionTask>} and HTTP response
   */
  searchAutoActionTasksWithHttpInfo(autoActionBody) {
    let postBody = autoActionBody

    // verify the required parameter 'autoActionBody' is set
    if (autoActionBody === undefined || autoActionBody === null) {
      throw new Error("Missing the required parameter 'autoActionBody' when calling searchAutoActionTasks")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AutoActionTask]

    return this.apiClient.callApi('/auto_action/tasks/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Search Auto Action Tasks
   * @param {module:model/AutoActionBody4} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AutoActionTask>}
   */
  searchAutoActionTasks(autoActionBody) {
    return this.searchAutoActionTasksWithHttpInfo(autoActionBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのメール設定を更新する
   * @param {Number} autoActionMailId Target auto action mail id
   * @param {module:model/AutoActionBody} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2011} and HTTP response
   */
  updateAutoActionMailWithHttpInfo(autoActionMailId, autoActionBody) {
    let postBody = autoActionBody

    // verify the required parameter 'autoActionMailId' is set
    if (autoActionMailId === undefined || autoActionMailId === null) {
      throw new Error("Missing the required parameter 'autoActionMailId' when calling updateAutoActionMail")
    }

    // verify the required parameter 'autoActionBody' is set
    if (autoActionBody === undefined || autoActionBody === null) {
      throw new Error("Missing the required parameter 'autoActionBody' when calling updateAutoActionMail")
    }

    let pathParams = {
      auto_action_mail_id: autoActionMailId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2011

    return this.apiClient.callApi('/auto_action/mails/{auto_action_mail_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのメール設定を更新する
   * @param {Number} autoActionMailId Target auto action mail id
   * @param {module:model/AutoActionBody} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2011}
   */
  updateAutoActionMail(autoActionMailId, autoActionBody) {
    return this.updateAutoActionMailWithHttpInfo(autoActionMailId, autoActionBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 自動アクションのタスク設定を更新する
   * @param {Number} autoActionTaskId Target auto action task id
   * @param {module:model/AutoActionBody3} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2012} and HTTP response
   */
  updateAutoActionTaskWithHttpInfo(autoActionTaskId, autoActionBody) {
    let postBody = autoActionBody

    // verify the required parameter 'autoActionTaskId' is set
    if (autoActionTaskId === undefined || autoActionTaskId === null) {
      throw new Error("Missing the required parameter 'autoActionTaskId' when calling updateAutoActionTask")
    }

    // verify the required parameter 'autoActionBody' is set
    if (autoActionBody === undefined || autoActionBody === null) {
      throw new Error("Missing the required parameter 'autoActionBody' when calling updateAutoActionTask")
    }

    let pathParams = {
      auto_action_task_id: autoActionTaskId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse2012

    return this.apiClient.callApi('/auto_action/tasks/{auto_action_task_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 自動アクションのタスク設定を更新する
   * @param {Number} autoActionTaskId Target auto action task id
   * @param {module:model/AutoActionBody3} autoActionBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2012}
   */
  updateAutoActionTask(autoActionTaskId, autoActionBody) {
    return this.updateAutoActionTaskWithHttpInfo(autoActionTaskId, autoActionBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
