import _ from 'lodash'
import downloadCsvUtil from '../../util/Csv'

function paddingDate(val) {
  return ('0' + val).slice(-2)
}
function getStatusLabel(status) {
  switch (status) {
    case 'check_in':
      return 'チェックイン'
    case 'check_out':
      return 'チェックアウト'
    case 'staying':
      return '滞在中'
    default:
      return ''
  }
}
function getCheckinStatusLabel(checkin_status) {
  switch (checkin_status) {
    case 'not_yet':
      return '未チェックイン'
    case 'only_checkin':
      return 'チェックインのみ'
    case 'form_wrote':
      return '帳簿記入済'
    case 'no_show':
      return 'ノーショウ'
    case 'checkin_completed':
      return '完了'
    default:
      return '不明'
  }
}

const downloadCsv = (reservations, isOwner = false) => {
  const filename = 'reservation.csv'
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const includeKeys = [
    'id',
    'room_id',
    'status',
    'stay',
    'key_code',
    'tasks',
    'check_in',
    'check_out',
    'name',
    'email',
    'phone_number',
    'mobile_number',
    'num_adult',
    'num_child',
    'num_infant',
    'accommodation_fee',
    'commission',
    'guest_arrival_time',
    'site_referer',
    'reservation_code',
    'country',
    'api_message',
    'guest_comment',
    'notes',
    'checkin_status',
    'rate_description'
  ]
  const ownerExcludeHeader = ['ノート', 'タスク', 'APIメッセージ', 'その他', 'チェックイン状況', '鍵番号']
  const ownerExcludeData = ['notes', 'tasks', 'api_message', 'rate_description', 'checkin_status', 'key_code']
  const filterHeader = key => (isOwner && ownerExcludeHeader.indexOf(key) >= 0) === false
  const filterData = key => (isOwner && ownerExcludeData.indexOf(key) >= 0) === false && includeKeys.indexOf(key) >= 0

  const header = [
    '',
    '宿泊施設ID',
    'チェックイン/チェックアウト',
    '宿泊施設名',
    '鍵番号',
    'タスク',
    'チェックイン',
    'チェックアウト',
    '宿泊者名',
    'E-mail',
    '電話番号',
    '携帯番号',
    '人数(大人)',
    '人数(子供)',
    '人数(幼児)',
    '料金',
    'OTA手数料',
    '到着時刻',
    '予約サイト',
    '予約番号',
    '国籍',
    'APIメッセージ',
    'ゲストコメント',
    'ノート',
    'チェックイン状況',
    'その他'
  ].filter(filterHeader)

  const csv_data = reservations
    .map(r => {
      const obj = r.toJS()
      return Object.keys(obj)
        .filter(filterData)
        .reduce((acc, key) => {
          let value = null
          switch (key) {
            case 'status':
              value = getStatusLabel(r[key])
              break
            case 'checkin_status':
              value = getCheckinStatusLabel(r[key])
              break
            case 'tasks':
              value = r[key].map(t => `${t.title} ${t.description ? t.description : ''} ${t.status === 'open' ? '未解決' : '解決済'}`).join('\n')
              break
            case 'check_in':
            case 'check_out':
              const date = r[key]
              value = date.getFullYear() + '/' + paddingDate(date.getMonth() + 1) + '/' + paddingDate(date.getDate())
              break
            default:
              value = r[key]
              break
          }

          if (_.isString(value)) {
            value = '"' + value.replace(/"/g, '""') + '"'
          }
          acc.push(value)
          return acc
        }, [])
    })
    .unshift(header)
  downloadCsvUtil(csv_data.join('\n'), filename, 'application/csv; charset=utf-8;', bom)

  return true
}

export default downloadCsv
