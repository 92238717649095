import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'

const onEditDetail = (event, index) => {
  AppDispatcher.dispatchWithType(MonthlyClosingEvent.onEditDetail, {
    index: index,
    name: event.target.name,
    value: event.target.value
  })

  return true
}

export default onEditDetail
