import onSearch from './onSearch'
import toDetailView from './toDetailView'
import hideDetailView from './hideDetailView'
import fetchOne from './fetchOne'

const PlanAction = {
  hideDetailView,
  fetchOne,
  onSearch,
  toDetailView
}

export default PlanAction
