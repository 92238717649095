/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body15 from '../model/Body15'
import Body16 from '../model/Body16'
import Body17 from '../model/Body17'
import InlineResponse20017 from '../model/InlineResponse20017'
import InlineResponse20018 from '../model/InlineResponse20018'
import InlineResponse20019 from '../model/InlineResponse20019'

/**
 * AccommodationInvoice service.
 * @module api/AccommodationInvoiceApi
 * @version 1.0.0
 */
export default class AccommodationInvoiceApi {
  /**
   * Constructs a new AccommodationInvoiceApi.
   * @alias module:api/AccommodationInvoiceApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 請求情報の新規作成
   * @param {module:model/Body15} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createAccommodationInvoiceWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createAccommodationInvoice")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/accommodation_invoice', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 請求情報の新規作成
   * @param {module:model/Body15} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createAccommodationInvoice(body) {
    return this.createAccommodationInvoiceWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 削除する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteAccommodationInvoiceWithHttpInfo(invoiceId) {
    let postBody = null

    // verify the required parameter 'invoiceId' is set
    if (invoiceId === undefined || invoiceId === null) {
      throw new Error("Missing the required parameter 'invoiceId' when calling deleteAccommodationInvoice")
    }

    let pathParams = {
      invoice_id: invoiceId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/accommodation_invoice/{invoice_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 削除する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteAccommodationInvoice(invoiceId) {
    return this.deleteAccommodationInvoiceWithHttpInfo(invoiceId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get a content of accommodation invoice
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20018} and HTTP response
   */
  getAccommodationInvoiceWithHttpInfo(invoiceId) {
    let postBody = null

    // verify the required parameter 'invoiceId' is set
    if (invoiceId === undefined || invoiceId === null) {
      throw new Error("Missing the required parameter 'invoiceId' when calling getAccommodationInvoice")
    }

    let pathParams = {
      invoice_id: invoiceId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20018

    return this.apiClient.callApi('/accommodation_invoice/{invoice_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get a content of accommodation invoice
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20018}
   */
  getAccommodationInvoice(invoiceId) {
    return this.getAccommodationInvoiceWithHttpInfo(invoiceId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 返金する
   * @param {Number} invoiceId Target accommodation invoice id
   * @param {module:model/Body17} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  refundWithHttpInfo(invoiceId, body) {
    let postBody = body

    // verify the required parameter 'invoiceId' is set
    if (invoiceId === undefined || invoiceId === null) {
      throw new Error("Missing the required parameter 'invoiceId' when calling refund")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling refund")
    }

    let pathParams = {
      invoice_id: invoiceId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/accommodation_invoice/{invoice_id}/refund', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 返金する
   * @param {Number} invoiceId Target accommodation invoice id
   * @param {module:model/Body17} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  refund(invoiceId, body) {
    return this.refundWithHttpInfo(invoiceId, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Search Accommodation Invoices
   * @param {module:model/Body16} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20017} and HTTP response
   */
  searchAccommodationInvoicesWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchAccommodationInvoices")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20017

    return this.apiClient.callApi('/accommodation_invoice/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Search Accommodation Invoices
   * @param {module:model/Body16} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20017}
   */
  searchAccommodationInvoices(body) {
    return this.searchAccommodationInvoicesWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 請求中に更新する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20019} and HTTP response
   */
  updateInvoicingWithHttpInfo(invoiceId) {
    let postBody = null

    // verify the required parameter 'invoiceId' is set
    if (invoiceId === undefined || invoiceId === null) {
      throw new Error("Missing the required parameter 'invoiceId' when calling updateInvoicing")
    }

    let pathParams = {
      invoice_id: invoiceId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20019

    return this.apiClient.callApi('/accommodation_invoice/{invoice_id}/invoicing', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 請求中に更新する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20019}
   */
  updateInvoicing(invoiceId) {
    return this.updateInvoicingWithHttpInfo(invoiceId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 支払済みに更新する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updatePaidWithHttpInfo(invoiceId) {
    let postBody = null

    // verify the required parameter 'invoiceId' is set
    if (invoiceId === undefined || invoiceId === null) {
      throw new Error("Missing the required parameter 'invoiceId' when calling updatePaid")
    }

    let pathParams = {
      invoice_id: invoiceId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/accommodation_invoice/{invoice_id}/paid', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 支払済みに更新する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updatePaid(invoiceId) {
    return this.updatePaidWithHttpInfo(invoiceId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 未払いに更新する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateUncollectibleWithHttpInfo(invoiceId) {
    let postBody = null

    // verify the required parameter 'invoiceId' is set
    if (invoiceId === undefined || invoiceId === null) {
      throw new Error("Missing the required parameter 'invoiceId' when calling updateUncollectible")
    }

    let pathParams = {
      invoice_id: invoiceId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi(
      '/accommodation_invoice/{invoice_id}/uncollectible',
      'PATCH',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 未払いに更新する
   * @param {Number} invoiceId Target accommodation invoice id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updateUncollectible(invoiceId) {
    return this.updateUncollectibleWithHttpInfo(invoiceId).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
