import NProgress from 'nprogress'
import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import ActivityRepository from '../../infra/ActivityRepository'
import ActivityEvent from '../../event/ActivityEvent'

const onSearch = async pagination => {
  NProgress.start()
  const response = await ActivityRepository.onSearch(pagination).catch(e => e)
  NProgress.done()
  if (response instanceof Error) {
    appActions.showAlert(response, '一覧取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(ActivityEvent.onSearch, response)

  return true
}

export default onSearch
