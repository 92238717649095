import dailyClosingRepository from '../../infra/DailyClosingRepository'
import { appActions } from '../AppActions'
import dateChange from './dateChange'

const onRequestUnlock = async (id, date) => {
  const res = await dailyClosingRepository.requestUnlock(id).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '締め処理を解除できませんでした')
    return false
  }

  appActions.snackbar('締め処理を解除しました', 'success', true)

  return dateChange(date)
}

export default onRequestUnlock
