const HotelManualEvent = {
  fetchAll: 'manuals/fetch-all',
  fetchOne: 'manuals/fetch-one',
  created: 'manual/created',
  updated: 'manual/updated',
  onEdit: 'manual/on-edit',
  onClear: 'manual/on-clear',
  onUpload: 'manual/on-upload',
  dragging: 'manual/dragging',
  onCancelAndCloseDialog: 'manual/on-cancelAndClose'
}

export default HotelManualEvent
