import AppDispatcher from '../../core/AppDispatcher'
import LoginEvent from '../../event/LoginEvent'
import UserSession from '../../core/UserSession'
import history from '../../util/history'

const onRequestLogout = async () => {
  // 入力済みのemailとpasswordを初期化
  AppDispatcher.dispatchWithType(LoginEvent.logoutSuccess)
  // セッションからトークンを破棄
  if (UserSession.read()) {
    UserSession.delete()
  }
  history.push('/')
}

export default onRequestLogout
