import ApiClient from '../ApiClient'
import EventScheduleApi from '../api/EventScheduleApi'

export class EventScheduleRepository {
  constructor() {
    this._api = new EventScheduleApi(ApiClient.instance)
  }

  fetchOne(id) {
    return this._api.getEventSchedule(id)
  }

  requestCreate(eventScheduleState) {
    const body = { event_schedule: eventScheduleState.toObject() }
    return this._api.createEventSchedule(body)
  }

  requestUpdate(eventScheduleState) {
    const body = { event_schedule: eventScheduleState.toObject() }
    return this._api.updateEventSchedule(eventScheduleState.id, body)
  }

  requestDelete(id) {
    return this._api.deleteEventSchedule(id)
  }
}

export default new EventScheduleRepository()
