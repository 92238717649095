import history from '../../util/history'

const onMonthChanged = month => {
  if (month) {
    history.push(`/monthly/date/${month}`)
  } else {
    history.push(`/monthly`)
  }

  return true
}

export default onMonthChanged
