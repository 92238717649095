import AppDispatcher from '../../../core/AppDispatcher'
import { appActions } from '../../AppActions'
import MonthlyReportEvent from '../../../event/MonthlyReportEvent'
import accommodationTaxRepository from '../../../infra/ReportAccommodationTaxRepository'

const fetchDetail = async accommodation_tax_id => {
  const response = await accommodationTaxRepository.fetchAccommodationTaxDetail(accommodation_tax_id).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyReportEvent.fetchAccommodationTaxDetail, response)

  return true
}

export default fetchDetail
