/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body33 from '../model/Body33'
import HouseManual from '../model/HouseManual'
import InlineResponse20030 from '../model/InlineResponse20030'
import InlineResponse20031 from '../model/InlineResponse20031'
import InlineResponse20032 from '../model/InlineResponse20032'
import InlineResponse20033 from '../model/InlineResponse20033'
import InlineResponse20035 from '../model/InlineResponse20035'
import Manual from '../model/Manual'
import Manual1 from '../model/Manual1'
import MinutPoint from '../model/MinutPoint'
import Room from '../model/Room'
import Room1 from '../model/Room1'
import Room2 from '../model/Room2'
import Tablet from '../model/Tablet'

/**
 * Config service.
 * @module api/ConfigApi
 * @version 1.0.0
 */
export default class ConfigApi {
  /**
   * Constructs a new ConfigApi.
   * @alias module:api/ConfigApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * HouseManualを削除する
   * @param {Number} manualId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteConfigHouseManualWithHttpInfo(manualId) {
    let postBody = null

    // verify the required parameter 'manualId' is set
    if (manualId === undefined || manualId === null) {
      throw new Error("Missing the required parameter 'manualId' when calling deleteConfigHouseManual")
    }

    let pathParams = {
      manual_id: manualId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/config/manuals/{manual_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * HouseManualを削除する
   * @param {Number} manualId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteConfigHouseManual(manualId) {
    return this.deleteConfigHouseManualWithHttpInfo(manualId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Roomを削除する
   * @param {Number} roomId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteConfigRoomWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling deleteConfigRoom")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/config/rooms/{room_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Roomを削除する
   * @param {Number} roomId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteConfigRoom(roomId) {
    return this.deleteConfigRoomWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿の設定 &gt; センサーを削除
   * @param {Number} id Target sensor id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteConfigRoomSensorWithHttpInfo(id) {
    let postBody = null

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling deleteConfigRoomSensor")
    }

    let pathParams = {
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/config/sensors/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿の設定 &gt; センサーを削除
   * @param {Number} id Target sensor id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteConfigRoomSensor(id) {
    return this.deleteConfigRoomSensorWithHttpInfo(id).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿の設定 &gt; タブレットの利用端末のログアウト
   * @param {String} uuid target tablet uuid
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteConfigRoomTabletsDeviceWithHttpInfo(uuid) {
    let postBody = null

    // verify the required parameter 'uuid' is set
    if (uuid === undefined || uuid === null) {
      throw new Error("Missing the required parameter 'uuid' when calling deleteConfigRoomTabletsDevice")
    }

    let pathParams = {
      uuid: uuid
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/config/tablets/{uuid}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿の設定 &gt; タブレットの利用端末のログアウト
   * @param {String} uuid target tablet uuid
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteConfigRoomTabletsDevice(uuid) {
    return this.deleteConfigRoomTabletsDeviceWithHttpInfo(uuid).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * HouseManualを取得する
   * @param {Number} manualId Target house manual id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/HouseManual} and HTTP response
   */
  getConfigHouseManualWithHttpInfo(manualId) {
    let postBody = null

    // verify the required parameter 'manualId' is set
    if (manualId === undefined || manualId === null) {
      throw new Error("Missing the required parameter 'manualId' when calling getConfigHouseManual")
    }

    let pathParams = {
      manual_id: manualId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = HouseManual

    return this.apiClient.callApi('/config/manuals/{manual_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * HouseManualを取得する
   * @param {Number} manualId Target house manual id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/HouseManual}
   */
  getConfigHouseManual(manualId) {
    return this.getConfigHouseManualWithHttpInfo(manualId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Houseに紐付いたHouseManual一覧を取得する
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20032} and HTTP response
   */
  getConfigHouseManualsWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling getConfigHouseManuals")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20032

    return this.apiClient.callApi('/config/rooms/{room_id}/manuals', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Houseに紐付いたHouseManual一覧を取得する
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20032}
   */
  getConfigHouseManuals(roomId) {
    return this.getConfigHouseManualsWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿の詳細を返す
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Room} and HTTP response
   */
  getConfigRoomWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling getConfigRoom")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Room

    return this.apiClient.callApi('/config/rooms/{room_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿の詳細を返す
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Room}
   */
  getConfigRoom(roomId) {
    return this.getConfigRoomWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿の設定 &gt; センサーの一覧を取得
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20035} and HTTP response
   */
  getConfigRoomSensorWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling getConfigRoomSensor")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20035

    return this.apiClient.callApi('/config/rooms/{room_id}/sensors', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿の設定 &gt; センサーの一覧を取得
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20035}
   */
  getConfigRoomSensor(roomId) {
    return this.getConfigRoomSensorWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿の設定 &gt; タブレットの利用端末情報を取得
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tablet>} and HTTP response
   */
  getConfigRoomTabletsDeviceWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling getConfigRoomTabletsDevice")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Tablet]

    return this.apiClient.callApi('/config/rooms/{room_id}/tablets', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿の設定 &gt; タブレットの利用端末情報を取得
   * @param {Number} roomId Target room id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tablet>}
   */
  getConfigRoomTabletsDevice(roomId) {
    return this.getConfigRoomTabletsDeviceWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 設定可能なRoomの一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Room>} and HTTP response
   */
  getConfigRoomsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Room]

    return this.apiClient.callApi('/config/rooms', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 設定可能なRoomの一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Room>}
   */
  getConfigRooms() {
    return this.getConfigRoomsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 設定可能なユーザーの一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/InlineResponse20030>} and HTTP response
   */
  getConfigUsersWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [InlineResponse20030]

    return this.apiClient.callApi('/config/users', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 設定可能なユーザーの一覧を取得する
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/InlineResponse20030>}
   */
  getConfigUsers() {
    return this.getConfigUsersWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * HouseManualを新規登録する
   * @param {Number} roomId Target room id
   * @param {module:model/Manual} manual
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20033} and HTTP response
   */
  newConfigHouseManualWithHttpInfo(roomId, manual) {
    let postBody = manual

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling newConfigHouseManual")
    }

    // verify the required parameter 'manual' is set
    if (manual === undefined || manual === null) {
      throw new Error("Missing the required parameter 'manual' when calling newConfigHouseManual")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20033

    return this.apiClient.callApi('/config/rooms/{room_id}/manuals', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * HouseManualを新規登録する
   * @param {Number} roomId Target room id
   * @param {module:model/Manual} manual
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20033}
   */
  newConfigHouseManual(roomId, manual) {
    return this.newConfigHouseManualWithHttpInfo(roomId, manual).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Roomを新規登録する
   * @param {module:model/Room1} room
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20031} and HTTP response
   */
  newConfigRoomWithHttpInfo(room) {
    let postBody = room

    // verify the required parameter 'room' is set
    if (room === undefined || room === null) {
      throw new Error("Missing the required parameter 'room' when calling newConfigRoom")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20031

    return this.apiClient.callApi('/config/rooms', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Roomを新規登録する
   * @param {module:model/Room1} room
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20031}
   */
  newConfigRoom(room) {
    return this.newConfigRoomWithHttpInfo(room).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * HouseManualを更新する
   * @param {Number} manualId Target house manual id
   * @param {module:model/Manual1} manual
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20033} and HTTP response
   */
  updateConfigHouseManualWithHttpInfo(manualId, manual) {
    let postBody = manual

    // verify the required parameter 'manualId' is set
    if (manualId === undefined || manualId === null) {
      throw new Error("Missing the required parameter 'manualId' when calling updateConfigHouseManual")
    }

    // verify the required parameter 'manual' is set
    if (manual === undefined || manual === null) {
      throw new Error("Missing the required parameter 'manual' when calling updateConfigHouseManual")
    }

    let pathParams = {
      manual_id: manualId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20033

    return this.apiClient.callApi('/config/manuals/{manual_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * HouseManualを更新する
   * @param {Number} manualId Target house manual id
   * @param {module:model/Manual1} manual
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20033}
   */
  updateConfigHouseManual(manualId, manual) {
    return this.updateConfigHouseManualWithHttpInfo(manualId, manual).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * HouseManualの表示順を更新する
   * @param {Number} manualId Target manual id
   * @param {module:model/Body33} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateConfigHouseManualPositionWithHttpInfo(manualId, body) {
    let postBody = body

    // verify the required parameter 'manualId' is set
    if (manualId === undefined || manualId === null) {
      throw new Error("Missing the required parameter 'manualId' when calling updateConfigHouseManualPosition")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateConfigHouseManualPosition")
    }

    let pathParams = {
      manual_id: manualId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/config/manuals/{manual_id}/position', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * HouseManualの表示順を更新する
   * @param {Number} manualId Target manual id
   * @param {module:model/Body33} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updateConfigHouseManualPosition(manualId, body) {
    return this.updateConfigHouseManualPositionWithHttpInfo(manualId, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * Roomを更新する
   * @param {Number} roomId Target room id
   * @param {module:model/Room2} room
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Room} and HTTP response
   */
  updateConfigRoomWithHttpInfo(roomId, room) {
    let postBody = room

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling updateConfigRoom")
    }

    // verify the required parameter 'room' is set
    if (room === undefined || room === null) {
      throw new Error("Missing the required parameter 'room' when calling updateConfigRoom")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Room

    return this.apiClient.callApi('/config/rooms/{room_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * Roomを更新する
   * @param {Number} roomId Target room id
   * @param {module:model/Room2} room
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Room}
   */
  updateConfigRoom(roomId, room) {
    return this.updateConfigRoomWithHttpInfo(roomId, room).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿の設定 &gt; センサーの閾値を更新
   * @param {Number} id Target sensor id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MinutPoint} and HTTP response
   */
  updateConfigRoomSensorWithHttpInfo(id) {
    let postBody = null

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling updateConfigRoomSensor")
    }

    let pathParams = {
      id: id
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = MinutPoint

    return this.apiClient.callApi('/config/sensors/{id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿の設定 &gt; センサーの閾値を更新
   * @param {Number} id Target sensor id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MinutPoint}
   */
  updateConfigRoomSensor(id) {
    return this.updateConfigRoomSensorWithHttpInfo(id).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
