/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import InlineResponse20027Occupations from './InlineResponse20027Occupations'

/**
 * The InlineResponse20027 model module.
 * @module model/InlineResponse20027
 * @version 1.0.0
 */
export default class InlineResponse20027 {
  /**
   * Constructs a new <code>InlineResponse20027</code>.
   * @alias module:model/InlineResponse20027
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20027</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20027} obj Optional instance to populate.
   * @return {module:model/InlineResponse20027} The populated <code>InlineResponse20027</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20027()

      if (data.hasOwnProperty('search_hotel_id')) {
        obj['search_hotel_id'] = ApiClient.convertToType(data['search_hotel_id'], 'Number')
      }
      if (data.hasOwnProperty('search_date')) {
        obj['search_date'] = ApiClient.convertToType(data['search_date'], 'Date')
      }
      if (data.hasOwnProperty('age0m')) {
        obj['age0m'] = ApiClient.convertToType(data['age0m'], 'Number')
      }
      if (data.hasOwnProperty('age0w')) {
        obj['age0w'] = ApiClient.convertToType(data['age0w'], 'Number')
      }
      if (data.hasOwnProperty('age10m')) {
        obj['age10m'] = ApiClient.convertToType(data['age10m'], 'Number')
      }
      if (data.hasOwnProperty('age10w')) {
        obj['age10w'] = ApiClient.convertToType(data['age10w'], 'Number')
      }
      if (data.hasOwnProperty('age20m')) {
        obj['age20m'] = ApiClient.convertToType(data['age20m'], 'Number')
      }
      if (data.hasOwnProperty('age20w')) {
        obj['age20w'] = ApiClient.convertToType(data['age20w'], 'Number')
      }
      if (data.hasOwnProperty('age30m')) {
        obj['age30m'] = ApiClient.convertToType(data['age30m'], 'Number')
      }
      if (data.hasOwnProperty('age30w')) {
        obj['age30w'] = ApiClient.convertToType(data['age30w'], 'Number')
      }
      if (data.hasOwnProperty('age40m')) {
        obj['age40m'] = ApiClient.convertToType(data['age40m'], 'Number')
      }
      if (data.hasOwnProperty('age40w')) {
        obj['age40w'] = ApiClient.convertToType(data['age40w'], 'Number')
      }
      if (data.hasOwnProperty('age50m')) {
        obj['age50m'] = ApiClient.convertToType(data['age50m'], 'Number')
      }
      if (data.hasOwnProperty('age50w')) {
        obj['age50w'] = ApiClient.convertToType(data['age50w'], 'Number')
      }
      if (data.hasOwnProperty('age60m')) {
        obj['age60m'] = ApiClient.convertToType(data['age60m'], 'Number')
      }
      if (data.hasOwnProperty('age60w')) {
        obj['age60w'] = ApiClient.convertToType(data['age60w'], 'Number')
      }
      if (data.hasOwnProperty('age70m')) {
        obj['age70m'] = ApiClient.convertToType(data['age70m'], 'Number')
      }
      if (data.hasOwnProperty('age70w')) {
        obj['age70w'] = ApiClient.convertToType(data['age70w'], 'Number')
      }
      if (data.hasOwnProperty('age_total_m')) {
        obj['age_total_m'] = ApiClient.convertToType(data['age_total_m'], 'Number')
      }
      if (data.hasOwnProperty('age_total_w')) {
        obj['age_total_w'] = ApiClient.convertToType(data['age_total_w'], 'Number')
      }
      if (data.hasOwnProperty('occupations')) {
        obj['occupations'] = ApiClient.convertToType(data['occupations'], [InlineResponse20027Occupations])
      }
      if (data.hasOwnProperty('nations')) {
        obj['nations'] = ApiClient.convertToType(data['nations'], [InlineResponse20027Occupations])
      }
    }
    return obj
  }

  /**
   * @member {Number} search_hotel_id
   */
  search_hotel_id = undefined
  /**
   * @member {Date} search_date
   */
  search_date = undefined
  /**
   * @member {Number} age0m
   */
  age0m = undefined
  /**
   * @member {Number} age0w
   */
  age0w = undefined
  /**
   * @member {Number} age10m
   */
  age10m = undefined
  /**
   * @member {Number} age10w
   */
  age10w = undefined
  /**
   * @member {Number} age20m
   */
  age20m = undefined
  /**
   * @member {Number} age20w
   */
  age20w = undefined
  /**
   * @member {Number} age30m
   */
  age30m = undefined
  /**
   * @member {Number} age30w
   */
  age30w = undefined
  /**
   * @member {Number} age40m
   */
  age40m = undefined
  /**
   * @member {Number} age40w
   */
  age40w = undefined
  /**
   * @member {Number} age50m
   */
  age50m = undefined
  /**
   * @member {Number} age50w
   */
  age50w = undefined
  /**
   * @member {Number} age60m
   */
  age60m = undefined
  /**
   * @member {Number} age60w
   */
  age60w = undefined
  /**
   * @member {Number} age70m
   */
  age70m = undefined
  /**
   * @member {Number} age70w
   */
  age70w = undefined
  /**
   * @member {Number} age_total_m
   */
  age_total_m = undefined
  /**
   * @member {Number} age_total_w
   */
  age_total_w = undefined
  /**
   * @member {Array.<module:model/InlineResponse20027Occupations>} occupations
   */
  occupations = undefined
  /**
   * @member {Array.<module:model/InlineResponse20027Occupations>} nations
   */
  nations = undefined
}
