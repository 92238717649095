import ReservationEvent from '../event/ReservationEvent'
import AppDispatcher from '../core/AppDispatcher'
import ApiClient from '../ApiClient'
import ReservationApi from '../api/ReservationApi'
import reservationRepository from '../infra/ReservationRepository'
import { appActions } from '../action/AppActions'

export default class AppRepository {
  fetchRequest = async (requestEvent, serverRequestFunction, fallbackErrorMsg) => {
    const a = await serverRequestFunction().catch(e => e)

    if (a instanceof Error) {
      appActions.showError(a.response.body, fallbackErrorMsg)
      return false
    }

    AppDispatcher.dispatchWithType(requestEvent, { response: a })

    return true
  }

  /**
   * 更新リクエストを送信中にボタンをdisableにして、終わったら戻すのを表現しているつもりのメソッド
   */
  updateRequest = async (requestEvent, serverRequestFunction, fallbackErrorMsg) => {
    const a = await AppDispatcher.promisedDispatch(requestEvent, { disabled: true })
    const b = await serverRequestFunction().catch(e => e)
    const c = AppDispatcher.dispatchWithDelay(requestEvent, { disabled: false }, 1000)

    if (b instanceof Error) {
      appActions.showAlert(b, fallbackErrorMsg)
      return false
    }

    return true
  }
}
