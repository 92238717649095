/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The PlanBreakfasts model module.
 * @module model/PlanBreakfasts
 * @version 1.0.0
 */
export default class PlanBreakfasts {
  /**
   * Constructs a new <code>PlanBreakfasts</code>.
   * @alias module:model/PlanBreakfasts
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>PlanBreakfasts</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PlanBreakfasts} obj Optional instance to populate.
   * @return {module:model/PlanBreakfasts} The populated <code>PlanBreakfasts</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PlanBreakfasts()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String')
      }
      if (data.hasOwnProperty('unit_price')) {
        obj['unit_price'] = ApiClient.convertToType(data['unit_price'], 'Number')
      }
      if (data.hasOwnProperty('sale_unit')) {
        obj['sale_unit'] = ApiClient.convertToType(data['sale_unit'], 'String')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} name
   */
  name = undefined
  /**
   * @member {Number} unit_price
   */
  unit_price = undefined
  /**
   * @member {String} sale_unit
   */
  sale_unit = undefined
}
