/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import AccommodationTax from '../model/AccommodationTax'
import Body27 from '../model/Body27'
import Body29 from '../model/Body29'
import Body30 from '../model/Body30'
import DailyClosingHead from '../model/DailyClosingHead'
import InlineResponse20025 from '../model/InlineResponse20025'
import InlineResponse20027 from '../model/InlineResponse20027'

/**
 * MonthlyReport service.
 * @module api/MonthlyReportApi
 * @version 1.0.0
 */
export default class MonthlyReportApi {
  /**
   * Constructs a new MonthlyReportApi.
   * @alias module:api/MonthlyReportApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 日次締めIDに紐づく宿泊データなどを取得する
   * @param {Number} accommodationTaxId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20025} and HTTP response
   */
  getDailyDlosingsOfAccommodationTaxWithHttpInfo(accommodationTaxId) {
    let postBody = null

    // verify the required parameter 'accommodationTaxId' is set
    if (accommodationTaxId === undefined || accommodationTaxId === null) {
      throw new Error("Missing the required parameter 'accommodationTaxId' when calling getDailyDlosingsOfAccommodationTax")
    }

    let pathParams = {
      accommodation_tax_id: accommodationTaxId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20025

    return this.apiClient.callApi(
      '/monthly/report/accommodation_tax/{accommodation_tax_id}',
      'GET',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
  }

  /**
   * 日次締めIDに紐づく宿泊データなどを取得する
   * @param {Number} accommodationTaxId Target monthly closing head id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20025}
   */
  getDailyDlosingsOfAccommodationTax(accommodationTaxId) {
    return this.getDailyDlosingsOfAccommodationTaxWithHttpInfo(accommodationTaxId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * get accommodation tax
   * @param {module:model/Body27} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AccommodationTax>} and HTTP response
   */
  searchAccommodationTaxWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchAccommodationTax")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [AccommodationTax]

    return this.apiClient.callApi('/monthly/report/accommodation_tax/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * get accommodation tax
   * @param {module:model/Body27} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AccommodationTax>}
   */
  searchAccommodationTax(body) {
    return this.searchAccommodationTaxWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊者名簿集計を検索する
   * @param {module:model/Body30} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20027} and HTTP response
   */
  searchMonthlyCheckInWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchMonthlyCheckIn")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20027

    return this.apiClient.callApi('/reports/owner/check_in/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊者名簿集計を検索する
   * @param {module:model/Body30} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20027}
   */
  searchMonthlyCheckIn(body) {
    return this.searchMonthlyCheckInWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 月別売上を検索する
   * @param {module:model/Body29} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/DailyClosingHead>} and HTTP response
   */
  searchMonthlySalesWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchMonthlySales")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [DailyClosingHead]

    return this.apiClient.callApi('/reports/owner/monthly_sales/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 月別売上を検索する
   * @param {module:model/Body29} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/DailyClosingHead>}
   */
  searchMonthlySales(body) {
    return this.searchMonthlySalesWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
