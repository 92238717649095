import ApiClient from '../ApiClient'
import NeppanApi from '../api/NeppanApi'

export class NeppanRepository {
  constructor() {
    this._api = new NeppanApi(ApiClient.instance)
  }

  requestSyncInventory(roomId) {
    return this._api.syncNeppanInventory(roomId)
  }
}

// singleton
export default new NeppanRepository()
