import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import accountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleDetailEvent from '../../event/AccountTitleDetailEvent'

const fetchAccountTitleDetails = async () => {
  const res = await accountTitleRepository.getAccountTitleDetails().catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '勘定明細情報の一覧取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(AccountTitleDetailEvent.fetchDetails, { details: res })

  return true
}

export default fetchAccountTitleDetails
