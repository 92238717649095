import uploadPassport from './passport/uploadPassport'
import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'
import { appActions } from '../../action/AppActions'

const onEdit = (token, event, index) => {
  const name = event.target.name
  const value = event.target.value

  if (name === 'passport_url') {
    // When target is passport, upload passport datafile to S3 and receive URL, and dispatch this url
    if (!token) {
      appActions.snackbar('Error: Unable to send check-in form to the server. Please ask the staff to solve this problem.', 'error', true)
      return false
    }
    // パスポート画像アップロード
    uploadPassport(token, event.target.files[0], index)
    return true
  }

  AppDispatcher.dispatchWithType(CheckInEvent.onEdit, {
    index: index,
    name: name,
    value: value
  })
  return true
}

export default onEdit
