import ApiClient from '../ApiClient'
import DailyClosingApi from '../api/DailyClosingApi'

export class DailyClosingRepository {
  constructor() {
    this._api = new DailyClosingApi(ApiClient.instance)
  }

  getDailyClosingHeads() {
    return this._api.getDailyClosingHeads()
  }

  fetchBySearchCondition(date, state) {
    const params = {
      search_keywords: state.search_keywords,
      search_date: date.toISODate(),
      search_room_ids: state.search_room_ids,
      search_status_cancelled: state.search_status_cancelled,
      search_status_booked: state.search_status_booked,
      search_status_not_a_reservation: state.search_status_not_a_reservation
    }
    return this._api.searchDailyClosing({ daily_closing_head: params })
  }

  fetchOne(dailyClosingHeadId) {
    return this._api.getDailyClosing(dailyClosingHeadId)
  }

  fetchOneByCondition(date, room_id) {
    const params = { search_date: date.toISODate(), search_room_ids: [room_id] }
    return this._api.searchDailyClosingForEdit({ daily_closing_head: params })
  }

  requestCreate(head, details, daily_closing_reservations) {
    const body = {
      daily_closing_head: head.toRequestBody(),
      daily_closing_details: details,
      daily_closing_reservations: daily_closing_reservations.map(r => r.toRequestBody())
    }
    return this._api.createDailyClosing(body)
  }

  requestUpdate(id, head, details, deleted_detail_ids, daily_closing_reservations, deleted_reservation_ids) {
    const body = {
      daily_closing_head: head.toRequestBody(),
      daily_closing_details: details,
      deleted_detail_ids: deleted_detail_ids,
      daily_closing_reservations: daily_closing_reservations.map(r => r.toRequestBody()),
      deleted_reservation_ids: deleted_reservation_ids
    }
    return this._api.updateDailyClosing(id, body)
  }

  requestUnlock(id) {
    return this._api.unlockDailyClosing(id)
  }

  requestDelete(id) {
    return this._api.deleteDailyClosing(id)
  }
}

// singleton
export default new DailyClosingRepository()
