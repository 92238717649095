import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import AccountTitleEvent from '../../event/AccountTitleEvent'
import AccountTitleState from '../state/AccountTitleState'

class AccountTitleStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return new AccountTitleState()
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case AccountTitleEvent.fetchOne:
        return new AccountTitleState(payload.title)
      case AccountTitleEvent.onEdit:
        return state.set(payload.name, payload.value)
      case AccountTitleEvent.onClear:
        return state.clear()
      default:
        return state
    }
  }
}

export default new AccountTitleStore()
