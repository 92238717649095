class Quantity {
  constructor(value) {
    if (!value) {
      this.value = value
    } else {
      const validity = /^\d+$/.test(value)
      const valid_value = validity ? value : value.replace(/[^0-9]/g, '')
      const before_check_range_str = valid_value ? String(valid_value) : '0'
      const ranged_value = before_check_range_str.length > 6 ? parseInt(before_check_range_str.substring(0, 6)) : parseInt(before_check_range_str)
      this.value = ranged_value
    }
  }

  val() {
    return this.value
  }
}

export default Quantity
