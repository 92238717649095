import fetchOne from './fetchOne'
import fetchReservation from './fetchReservation'
import hideDetailView from './hideDetailView'
import onEditSearchCondition from './onEditSearchCondition'
import onEdit from './onEdit'
import onEditAccommodationTax from './onEditAccommodationTax'
import onEditRefund from './onEditRefund'
import onRequestCreate from './onRequestCreate'
import onSearch from './onSearch'
import onUpdateAccommodationTax from './onUpdateAccommodationTax'
import onUpdateUncollectible from './onUpdateUncollectible'
import onUpdateInvoicing from './onUpdateInvoicing'
import onUpdatePaid from './onUpdatePaid'
import onRequestDelete from './onRequestDelete'
import onRequestRefund from './onRequestRefund'
import requestSave from './requestSave'
import showDetailView from './showDetailView'
import showRefundDialog from './showRefundDialog'
import onDialogClose from './onDialogClose'

const AccommodationInvoiceAction = {
  fetchOne,
  fetchReservation,
  hideDetailView,
  onDialogClose,
  onEditSearchCondition,
  onEdit,
  onEditAccommodationTax,
  onEditRefund,
  onRequestCreate,
  onSearch,
  onUpdateUncollectible,
  onUpdateInvoicing,
  onUpdatePaid,
  onRequestDelete,
  onRequestRefund,
  onUpdateAccommodationTax,
  requestSave,
  showDetailView,
  showRefundDialog
}

export default AccommodationInvoiceAction
