const SegmentationEvent = {
  addRow: 'segment/add-row',
  fetchAll: 'segment/fetch-all',
  fetchOne: 'segment/fetch-one',
  onClear: 'segment/on-clear',
  onEdit: 'segment/on-edit',
  onEditValue: 'segment/on-edit-value'
}

export default SegmentationEvent
