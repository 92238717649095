import SelfCheckInRepository from '../../infra/SelfCheckInRepository'
import { appActions } from '../AppActions'
import AppDispatcher from '../../core/AppDispatcher'
import CheckInEvent from '../../event/CheckInEvent'

const onRequestSubmit = async (reservationId, token, state) => {
  if (reservationId === null || token === null) {
    appActions.snackbar('Error: Unable to send check-in form to the server. Please ask the staff to solve this problem.', 'error', true)
    return false
  }

  const response = await new SelfCheckInRepository(token).checkIn(reservationId, state).catch(e => e)
  if (response instanceof Error) {
    const errors = JSON.parse(response.response.text)
    appActions.showError(errors, 'Failed to check in. チェックインに失敗しました。')
    return false
  }
  AppDispatcher.dispatchWithType(CheckInEvent.initialize)
  AppDispatcher.dispatchWithType(CheckInEvent.complete)
  return true
}
export default onRequestSubmit
