import ApiHandle from '../../util/ApiHandle'
import history from '../../util/history'
import { appActions } from '../AppActions'
import roomRepository from '../../infra/RoomRepository'

const onRequestCreate = async room => {
  const res = await roomRepository.requestCreate(room).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '宿の追加に失敗しました')
    return false
  }

  history.push('/setting')
  appActions.snackbar('宿を追加しました', 'success', true)
  return true
}

export default onRequestCreate
