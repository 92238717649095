import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const hideDetailView = () => {
  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.hideDetail)
  history.push('/invoice')

  return true
}

export default hideDetailView
