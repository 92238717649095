import ReservationAction from './index'

const getReservation = async (tab, id) => {
  ReservationAction.showDetail(id)
  switch (tab) {
    case 'checkin':
      ReservationAction.fetchCheckIns(id)
      break
    case 'payments':
      ReservationAction.fetchPayments(id)
      break
    case 'invoices':
      ReservationAction.fetchInvoices(id)
      break
    case 'log':
      ReservationAction.fetchAuditLog(id)
      break
    case 'auto_action_log':
      ReservationAction.fetchAutoActionLog(id)
      break
    default:
      ReservationAction.fetchTasks(id)
      break
  }

  return true
}

export default getReservation
