import ApiClient from '../ApiClient'
import TransactionApi from '../api/TransactionApi'

export class TransactionRepository {
  constructor() {
    this._api = new TransactionApi(ApiClient.instance)
  }

  requestSave(reservation_id, reservation_pay, accommodation_tax, transaction_head, transaction_details) {
    const body = {
      reservation_id: reservation_id,
      reservation_pay: reservation_pay,
      accommodation_tax: accommodation_tax,
      transaction_head: transaction_head,
      transaction_details: transaction_details
    }

    return this._api.newTransaction(body)
  }
}

// singleton
export default new TransactionRepository()
