import React from 'react'
import r from '../../../util/Renderer'
import { BaseLayout, ListViewLayout } from 'ui-library/dist/external/layouts'
import {
  DataTable,
  DataTableColumn as TD,
  DataTableHeader as TH,
  DataTableRow as TR,
  Detail,
  List,
  ListRow,
  ListRowItem,
  NavigationDrawer,
  Pagination,
  TabHeader,
  ToolbarDateNavigation
} from 'ui-library/dist/external/components'
import LocalDate from '../../unit/Date'

export default props => {
  const toolbar = { label: `月別売上`, items: props.accountMenu }
  const paginations = {
    current: {
      label: '今月',
      onClick: e => {
        const target = LocalDate.today()
          .beginningOfMonth()
          .toISODate()
      }
    },
    prev: {
      onClick: e => {}
    },
    next: {
      onClick: e => {}
    }
  }

  const meta = {
    component: ToolbarDateNavigation,
    data: { label: '2019年11月', paginations: paginations }
  }

  return (
    <React.Fragment>
      <NavigationDrawer toolbar={toolbar} navigations={props.navigations} meta={meta} />

      <BaseLayout>
        <ListViewLayout>
          <DataTable
            headers={[
              <TH key={1} width="25%">
                宿泊施設
              </TH>,
              <TH key={2} width="10%">
                取引数
              </TH>,
              <TH key={3} width="25%">
                合計
              </TH>,
              <TH key={4} width="18%">
                レポート
              </TH>
            ]}
            rows={[
              <TR key={1}>
                <TD>Status</TD>
                <TD>aa</TD>
                <TD>invoice.room_name</TD>
                <TD>invoice.guest_name</TD>
              </TR>
            ]}
          />
        </ListViewLayout>

        {r.renderIf(props.showDetail)(
          <Detail
            showHeader
            showFooter
            primaryButton={{ label: '入金確認済み', onClick: e => console.log('完了') }}
            menuOptions={[
              {
                label: '未払いにする',
                onClick: () => {}
              },
              {
                label: '削除',
                onClick: () => {}
              }
            ]}
          >
            <TabHeader tabs={[]} />
            <List>
              <ListRow key={1}>
                <ListRowItem width={'100%'} overline={`請求ID`} primaryText={'props.invoice.id'} />
              </ListRow>
              <ListRow key={2}>
                <ListRowItem width={'100%'} overline={`請求ID`} primaryText={'props.invoice.id'} />
              </ListRow>
            </List>
          </Detail>
        )}
      </BaseLayout>
      <Pagination name="pagination" perPage={50} total={100} page={1} prev={e => {}} next={e => {}} perPageChanged={e => {}} />
    </React.Fragment>
  )
}
