import ReservationAction from './index'
import AppDispatcher from '../../core/AppDispatcher'
import ReservationEvent from '../../event/ReservationEvent'

const showDetail = async reservationId => {
  const result = await ReservationAction.fetchOne(reservationId)

  if (result !== false) {
    AppDispatcher.dispatchWithType(ReservationEvent.showDetail)
    return false
  }

  return true
}

export default showDetail
