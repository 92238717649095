import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import ReservationEvent from '../../event/ReservationEvent'

const cancelEditView = () => {
  history.goBack()

  return true
}

export default cancelEditView
