import fetchAccountTitleDetail from './fetchAccountTitleDetail'
import fetchAccountTitleDetails from './fetchAccountTitleDetails'
import onCancelDetailEditView from './onCancelDetailEditView'
import onEditDetail from './onEditDetail'
import onRequestCreateDetail from './onRequestCreateDetail'
import onRequestUpdateDetail from './onRequestUpdateDetail'
import onRequestDeleteDetail from './onRequestDeleteDetail'
import toAccountTitleDetailEditView from './toAccountTitleDetailEditView'

const AccountTitleDetailAction = {
  fetchAccountTitleDetail,
  fetchAccountTitleDetails,
  onCancelDetailEditView,
  onEditDetail,
  onRequestCreateDetail,
  onRequestUpdateDetail,
  onRequestDeleteDetail,
  toAccountTitleDetailEditView
}

export default AccountTitleDetailAction
