/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The AccommodationInvoice model module.
 * @module model/AccommodationInvoice
 * @version 1.0.0
 */
export default class AccommodationInvoice {
  /**
   * Constructs a new <code>AccommodationInvoice</code>.
   * @alias module:model/AccommodationInvoice
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AccommodationInvoice</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccommodationInvoice} obj Optional instance to populate.
   * @return {module:model/AccommodationInvoice} The populated <code>AccommodationInvoice</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccommodationInvoice()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('invoice_type')) {
        obj['invoice_type'] = ApiClient.convertToType(data['invoice_type'], 'String')
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String')
      }
      if (data.hasOwnProperty('total')) {
        obj['total'] = ApiClient.convertToType(data['total'], 'Number')
      }
      if (data.hasOwnProperty('currency')) {
        obj['currency'] = ApiClient.convertToType(data['currency'], 'String')
      }
      if (data.hasOwnProperty('customerid')) {
        obj['customerid'] = ApiClient.convertToType(data['customerid'], 'String')
      }
      if (data.hasOwnProperty('invoiceid')) {
        obj['invoiceid'] = ApiClient.convertToType(data['invoiceid'], 'String')
      }
      if (data.hasOwnProperty('invoice_url')) {
        obj['invoice_url'] = ApiClient.convertToType(data['invoice_url'], 'String')
      }
      if (data.hasOwnProperty('note')) {
        obj['note'] = ApiClient.convertToType(data['note'], 'String')
      }
      if (data.hasOwnProperty('guest_name')) {
        obj['guest_name'] = ApiClient.convertToType(data['guest_name'], 'String')
      }
      if (data.hasOwnProperty('room_name')) {
        obj['room_name'] = ApiClient.convertToType(data['room_name'], 'String')
      }
      if (data.hasOwnProperty('check_in')) {
        obj['check_in'] = ApiClient.convertToType(data['check_in'], 'Date')
      }
      if (data.hasOwnProperty('check_out')) {
        obj['check_out'] = ApiClient.convertToType(data['check_out'], 'Date')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {String} invoice_type
   */
  invoice_type = undefined
  /**
   * @member {String} status
   */
  status = undefined
  /**
   * @member {Number} total
   */
  total = undefined
  /**
   * @member {String} currency
   */
  currency = undefined
  /**
   * @member {String} customerid
   */
  customerid = undefined
  /**
   * @member {String} invoiceid
   */
  invoiceid = undefined
  /**
   * @member {String} invoice_url
   */
  invoice_url = undefined
  /**
   * @member {String} note
   */
  note = undefined
  /**
   * @member {String} guest_name
   */
  guest_name = undefined
  /**
   * @member {String} room_name
   */
  room_name = undefined
  /**
   * @member {Date} check_in
   */
  check_in = undefined
  /**
   * @member {Date} check_out
   */
  check_out = undefined
}
