import { appActions } from '../AppActions'
import StripeApiKeyRepository from '../../infra/StripeApiKeyRepository'

const checkConection = async state => {
  const response = await StripeApiKeyRepository.testConnection(state).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '接続できませんした')
    return false
  }

  window.alert('テスト接続に成功しました')
  return true
}

export default checkConection
