import { appActions } from '../AppActions'
import roomRepository from '../../infra/RoomRepository'
import fetchOne from './fetchOne'

const onRequestUpdate = async room => {
  const res = await roomRepository.requestUpdate(room).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '宿の更新に失敗しました')
    return false
  }

  fetchOne(room.id)
  appActions.snackbar('更新しました', 'success', true)
  return true
}

export default onRequestUpdate
