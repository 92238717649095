import { appActions } from '../../AppActions'
import ReservationAction from './'
import ReportAccommodationTaxRepository from '../../../infra/ReportAccommodationTaxRepository'

const onRequestUpdate = async (reservationId, accommodationTax) => {
  const response = await ReportAccommodationTaxRepository.requestUpdate(reservationId, accommodationTax).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '更新に失敗しました')
    return false
  }
  appActions.snackbar('更新しました', 'success', true)
  ReservationAction.fetchOne(reservationId)

  return true
}

export default onRequestUpdate
