/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Hotel from '../model/Hotel'
import Hotel1 from '../model/Hotel1'
import InlineResponse20013 from '../model/InlineResponse20013'
import InlineResponse20034 from '../model/InlineResponse20034'
import Room from '../model/Room'

/**
 * Hotel service.
 * @module api/HotelApi
 * @version 1.0.0
 */
export default class HotelApi {
  /**
   * Constructs a new HotelApi.
   * @alias module:api/HotelApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * 宿泊施設(ホテル)を新規登録する
   * @param {module:model/Hotel1} hotel
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Hotel} and HTTP response
   */
  createHotelWithHttpInfo(hotel) {
    let postBody = hotel

    // verify the required parameter 'hotel' is set
    if (hotel === undefined || hotel === null) {
      throw new Error("Missing the required parameter 'hotel' when calling createHotel")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Hotel

    return this.apiClient.callApi('/hotels', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊施設(ホテル)を新規登録する
   * @param {module:model/Hotel1} hotel
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Hotel}
   */
  createHotel(hotel) {
    return this.createHotelWithHttpInfo(hotel).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊施設(ホテル)を削除する
   * @param {Number} hotelId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteHotelWithHttpInfo(hotelId) {
    let postBody = null

    // verify the required parameter 'hotelId' is set
    if (hotelId === undefined || hotelId === null) {
      throw new Error("Missing the required parameter 'hotelId' when calling deleteHotel")
    }

    let pathParams = {
      hotel_id: hotelId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/hotels/{hotel_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊施設(ホテル)を削除する
   * @param {Number} hotelId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteHotel(hotelId) {
    return this.deleteHotelWithHttpInfo(hotelId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * プランを取得する
   * @param {Number} hotelId Target Hotel id
   * @param {Number} planCode Target Plan code
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20013} and HTTP response
   */
  findPlanWithHttpInfo(hotelId, planCode) {
    let postBody = null

    // verify the required parameter 'hotelId' is set
    if (hotelId === undefined || hotelId === null) {
      throw new Error("Missing the required parameter 'hotelId' when calling findPlan")
    }

    // verify the required parameter 'planCode' is set
    if (planCode === undefined || planCode === null) {
      throw new Error("Missing the required parameter 'planCode' when calling findPlan")
    }

    let pathParams = {
      hotel_id: hotelId,
      plan_code: planCode
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20013

    return this.apiClient.callApi('/hotels/{hotel_id}/plan/{plan_code}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * プランを取得する
   * @param {Number} hotelId Target Hotel id
   * @param {Number} planCode Target Plan code
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20013}
   */
  findPlan(hotelId, planCode) {
    return this.findPlanWithHttpInfo(hotelId, planCode).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊施設(ホテル)を1件取得する
   * @param {Number} hotelId Target hotel id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Hotel} and HTTP response
   */
  getHotelWithHttpInfo(hotelId) {
    let postBody = null

    // verify the required parameter 'hotelId' is set
    if (hotelId === undefined || hotelId === null) {
      throw new Error("Missing the required parameter 'hotelId' when calling getHotel")
    }

    let pathParams = {
      hotel_id: hotelId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = Hotel

    return this.apiClient.callApi('/hotels/{hotel_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊施設(ホテル)を1件取得する
   * @param {Number} hotelId Target hotel id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Hotel}
   */
  getHotel(hotelId) {
    return this.getHotelWithHttpInfo(hotelId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊施設(ホテル)一覧を返す
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Hotel>} and HTTP response
   */
  getHotelsWithHttpInfo() {
    let postBody = null

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Hotel]

    return this.apiClient.callApi('/hotels', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊施設(ホテル)一覧を返す
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Hotel>}
   */
  getHotels() {
    return this.getHotelsWithHttpInfo().then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊施設(ホテル)一覧を返す
   * @param {Number} hotelId parent hotel id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Room>} and HTTP response
   */
  getRoomsByHotelIdWithHttpInfo(hotelId) {
    let postBody = null

    // verify the required parameter 'hotelId' is set
    if (hotelId === undefined || hotelId === null) {
      throw new Error("Missing the required parameter 'hotelId' when calling getRoomsByHotelId")
    }

    let pathParams = {
      hotel_id: hotelId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = [Room]

    return this.apiClient.callApi('/hotels/{hotel_id}/rooms', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊施設(ホテル)一覧を返す
   * @param {Number} hotelId parent hotel id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Room>}
   */
  getRoomsByHotelId(hotelId) {
    return this.getRoomsByHotelIdWithHttpInfo(hotelId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * 宿泊施設(ホテル)を更新する
   * @param {Number} hotelId Target hotel id
   * @param {module:model/Hotel} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20034} and HTTP response
   */
  updateHotelWithHttpInfo(hotelId, body) {
    let postBody = body

    // verify the required parameter 'hotelId' is set
    if (hotelId === undefined || hotelId === null) {
      throw new Error("Missing the required parameter 'hotelId' when calling updateHotel")
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateHotel")
    }

    let pathParams = {
      hotel_id: hotelId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20034

    return this.apiClient.callApi('/hotels/{hotel_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * 宿泊施設(ホテル)を更新する
   * @param {Number} hotelId Target hotel id
   * @param {module:model/Hotel} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20034}
   */
  updateHotel(hotelId, body) {
    return this.updateHotelWithHttpInfo(hotelId, body).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
