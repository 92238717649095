/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The ReservationSegment model module.
 * @module model/ReservationSegment
 * @version 1.0.0
 */
export default class ReservationSegment {
  /**
   * Constructs a new <code>ReservationSegment</code>.
   * @alias module:model/ReservationSegment
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>ReservationSegment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ReservationSegment} obj Optional instance to populate.
   * @return {module:model/ReservationSegment} The populated <code>ReservationSegment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ReservationSegment()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('reservation_id')) {
        obj['reservation_id'] = ApiClient.convertToType(data['reservation_id'], 'Number')
      }
      if (data.hasOwnProperty('segment_category_id')) {
        obj['segment_category_id'] = ApiClient.convertToType(data['segment_category_id'], 'Number')
      }
      if (data.hasOwnProperty('segment_value_id')) {
        obj['segment_value_id'] = ApiClient.convertToType(data['segment_value_id'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} reservation_id
   */
  reservation_id = undefined
  /**
   * @member {Number} segment_category_id
   */
  segment_category_id = undefined
  /**
   * @member {Number} segment_value_id
   */
  segment_value_id = undefined
}
