import { appActions } from '../AppActions'
import reservationRepository from '../../infra/ReservationRepository'
import ReservationEvent from '../../event/ReservationEvent'

const onRequestCreate = async reservation => {
  const result = await reservationRepository.updateRequest(ReservationEvent.requestSave, () => reservationRepository.requestCreate(reservation), '作成に失敗しました')

  if (result) appActions.snackbar('作成しました', 'success', true)

  return true
}

export default onRequestCreate
