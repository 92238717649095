const ReservationEvent = {
  cancelCreation: 'receptionist/cancel-creation',
  dateChange: 'reservation/date-change',
  downloadCsv: 'reservation/download-csv',
  fetchAll: 'reservation/fetch-all',
  fetchAuditLog: 'reservation/fetch-audit-logs',
  fetchAutoActionLog: 'reservation/fetch-auto-action-logs',
  fetchByDate: 'reservation/fetch-by-date',
  fetchCheckIns: 'reservation/fetch-check-ins',
  fetchInvoices: 'reservation/fetch-invoices',
  fetchMonthlyByDate: 'reservation/fetch-monthly-by-date',
  fetchOne: 'reservation/fetch-one',
  fetchPayments: 'reservation/fetch-payments',
  fetchRooms: 'reservation/fetch-rooms',
  fetchTasks: 'reservation/fetch-tasks',
  handleEdit: 'receptionist/editted-reservation',
  hideDetail: 'receptionist/hide-detail',
  initialize: 'reservation/initialize',
  monthChange: 'reservation/month-change',
  onEdit: 'reservation/on-edit',
  onEditSearchCondition: 'reservation-search/on-edit',
  onReceptionistSearch: 'receptionist-search',
  onRequestCancel: 'reservation/on-cancel-request',
  onSearch: 'reservation-search/on-search',
  onUpdateReservationPay: 'reservation/on-update-reservation_pay',
  onUpdateNoshow: 'reservation/on-update-noshow',
  receptionistOnEditSearch: 'receptionist/on-edit-search',
  requestSave: 'reservation/request-save',
  searchOff: 'receptionist/search-off',
  showDetail: 'receptionist/show-detail',
  startCreation: 'receptionist/start-creation'
}

export default ReservationEvent
