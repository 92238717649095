import ApiClient from '../ApiClient'
import MealApi from '../api/MealApi'

export class MealRepository {
  constructor() {
    this._api = new MealApi(ApiClient.instance)
  }

  onSearch(pagination) {
    const body = { pagination: pagination }
    return this._api.searchMeal(body)
  }
}

// singleton
export default new MealRepository()
