import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import repository from '../../infra/InvoiceAutomationRepository'
import InvoiceAutomationEvent from '../../event/InvoiceAutomationEvent'

const fetchOne = async () => {
  const response = await repository.fetchOne().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(InvoiceAutomationEvent.fetchOne, {
    invoice_automation: response.invoice_automation
  })
  return true
}

export default fetchOne
