import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import AccountTitleRepository from '../../infra/AccountTitleRepository'
import AccountTitleEvent from '../../event/AccountTitleEvent'

const onRequestCreate = async title => {
  const response = await AccountTitleRepository.requestCreateByTitle(title).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '登録できませんでした')
    return false
  }

  appActions.snackbar('登録しました', 'success', true)
  AppDispatcher.dispatchWithType(AccountTitleEvent.fetchOne, { title: response })
  return true
}

export default onRequestCreate
