import { Record } from 'immutable'
import TaxCalculator from '../../../../domain/model/tax/AccommodationTax'

const _initialState = {
  id: null,
  booking_price: null, // 予約料金
  total_price: 0, // 予約合計（OTA手数料含む）
  people: 0,
  stay: 0,
  tax_price: 0,
  total_tax_price: 0,
  include_commission: false,
  commission_rate: 15,
  accommodation_fee_include_tax: 0, // 宿泊料金（税込）
  accommodation_fee: 0, // 宿泊料金
  memo: '',
  isUncollected: false,
  plan: null,
  plan_price: 0
}

class AccommodationTaxState extends Record(_initialState) {
  recalculate(keep_accommodation_fee_and_tax) {
    const people = this.people
    const stay = this.stay
    const maybe_total_price = this.booking_price
    const include_commission = this.include_commission
    const commission_rate = this.commission_rate
    const total_price = TaxCalculator.calculateTotalPrice(maybe_total_price, include_commission, commission_rate)
    const plan_price = this.getPlanPrice()

    let accommodation_fee_include_tax = total_price - plan_price
    if (accommodation_fee_include_tax < 0) {
      accommodation_fee_include_tax = 0
    }
    let accommodation_fee = TaxCalculator.calculateExcludingPrice(accommodation_fee_include_tax)
    if (keep_accommodation_fee_and_tax) {
      accommodation_fee = this.accommodation_fee
    }

    const newstate = this.merge({
      plan_price: plan_price,
      people: people,
      stay: stay,
      total_price: total_price,
      accommodation_fee_include_tax: accommodation_fee_include_tax,
      accommodation_fee: accommodation_fee,
      include_commission: include_commission,
      commission_rate: commission_rate
    })

    if (keep_accommodation_fee_and_tax) {
      return newstate
    }

    return newstate.calculateAccommodationTax()
  }

  calculateAccommodationTax() {
    const { accommodation_fee, stay, people } = this
    const { tax_price, total_tax_price } = TaxCalculator.calculate(accommodation_fee, stay, people)

    return this.merge({
      tax_price: tax_price,
      total_tax_price: total_tax_price
    })
  }

  load(tax) {
    if (!tax) return this

    const isCollected = !!(tax.tax_per_day > 0)
    const total_tax_price = tax.tax_per_day * tax.number_of_guests * tax.number_of_nights

    return this.merge({
      id: tax.id,
      booking_price: tax.booking_price,
      accommodation_fee: tax.accommodation_fee,
      people: tax.number_of_guests,
      stay: tax.number_of_nights,
      tax_price: tax.tax_per_day,
      total_tax_price: total_tax_price,
      memo: tax.memo,
      include_commission: tax.ota_commission_include,
      commission_rate: tax.ota_commission_rate,
      isUncollected: !isCollected
    })
  }

  uncollected(isUncollected) {
    return isUncollected ? this.merge({ tax_price: 0, total_tax_price: 0, isUncollected: true }) : this.merge({ isUncollected: false })
  }

  getPlanPrice() {
    if (!this.plan) {
      return 0
    }

    return this.plan.total({ people: this.people, stay: this.stay }) || 0
  }
}

const accommodationTaxInitialState = new AccommodationTaxState()

export { AccommodationTaxState, accommodationTaxInitialState }
