import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import roomRepository from '../../infra/RoomRepository'
import RoomEvent from '../../event/RoomEvent'

const fetchByUser = async () => {
  const response = await roomRepository.fetchByUser().catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '宿情報の取得に失敗しました')
    return false
  }
  AppDispatcher.dispatchWithType(RoomEvent.fetchByUser, {
    houses: response
  })
  return true
}

export default fetchByUser
