import onRequestUpdate from './onRequestUpdate'

const onUpload = (hotel, e) => {
  const name = e.target.name
  if (name !== 'terms_url') return
  let reader = new FileReader()
  const file = e.target.files[0]
  if (!file) return

  reader.onload = () => {
    onRequestUpdate(hotel.set(name, reader.result))
  }
  reader.readAsDataURL(file)
}

export default onUpload
