/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * Neppan service.
 * @module api/NeppanApi
 * @version 1.0.0
 */
export default class NeppanApi {
  /**
   * Constructs a new NeppanApi.
   * @alias module:api/NeppanApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * PMS在庫をねっぱんに同期する
   * @param {Number} roomId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  syncNeppanInventoryWithHttpInfo(roomId) {
    let postBody = null

    // verify the required parameter 'roomId' is set
    if (roomId === undefined || roomId === null) {
      throw new Error("Missing the required parameter 'roomId' when calling syncNeppanInventory")
    }

    let pathParams = {
      room_id: roomId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/neppan/{room_id}/sync_inventory', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * PMS在庫をねっぱんに同期する
   * @param {Number} roomId Target id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  syncNeppanInventory(roomId) {
    return this.syncNeppanInventoryWithHttpInfo(roomId).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
