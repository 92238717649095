import { appActions } from '../AppActions'
import HotelRepository from '../../infra/HotelRepository'
import fetchOne from './fetchOne'
import history from '../../util/history'

const onRequestUpdate = async hotel => {
  const res = await HotelRepository.requestUpdate(hotel).catch(e => e)
  if (res instanceof Error) {
    appActions.showAlert(res, '宿の更新に失敗しました')
    return false
  }

  fetchOne(hotel.id)
  appActions.snackbar('更新しました', 'success', true)
  history.push(`/hotels/${hotel.id}/details`)
  return true
}

export default onRequestUpdate
