import HotelManualEvent from '../../event/HotelManualEvent'
import history from '../../util/history'
import AppDispatcher from '../../core/AppDispatcher'

const onCancel = () => {
  AppDispatcher.dispatchWithType(HotelManualEvent.onCancelAndCloseDialog)
  history.goBack()
  return true
}

export default onCancel
