/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import DailyClosingSummaryOtaComsn from './DailyClosingSummaryOtaComsn'
import DailyClosingSummaryRsvnSales from './DailyClosingSummaryRsvnSales'

/**
 * The DailyClosingSummary model module.
 * @module model/DailyClosingSummary
 * @version 1.0.0
 */
export default class DailyClosingSummary {
  /**
   * Constructs a new <code>DailyClosingSummary</code>.
   * @alias module:model/DailyClosingSummary
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>DailyClosingSummary</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DailyClosingSummary} obj Optional instance to populate.
   * @return {module:model/DailyClosingSummary} The populated <code>DailyClosingSummary</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyClosingSummary()

      if (data.hasOwnProperty('rsvn_sales')) {
        obj['rsvn_sales'] = DailyClosingSummaryRsvnSales.constructFromObject(data['rsvn_sales'])
      }
      if (data.hasOwnProperty('ota_comsn')) {
        obj['ota_comsn'] = DailyClosingSummaryOtaComsn.constructFromObject(data['ota_comsn'])
      }
      if (data.hasOwnProperty('rsvn_pay_comsn')) {
        obj['rsvn_pay_comsn'] = ApiClient.convertToType(data['rsvn_pay_comsn'], 'Number')
      }
      if (data.hasOwnProperty('sales')) {
        obj['sales'] = ApiClient.convertToType(data['sales'], 'Number')
      }
      if (data.hasOwnProperty('variable_costs')) {
        obj['variable_costs'] = ApiClient.convertToType(data['variable_costs'], 'Number')
      }
      if (data.hasOwnProperty('fixed_costs')) {
        obj['fixed_costs'] = ApiClient.convertToType(data['fixed_costs'], 'Number')
      }
      if (data.hasOwnProperty('variable_expenses')) {
        obj['variable_expenses'] = ApiClient.convertToType(data['variable_expenses'], 'Number')
      }
      if (data.hasOwnProperty('fixed_expenses')) {
        obj['fixed_expenses'] = ApiClient.convertToType(data['fixed_expenses'], 'Number')
      }
      if (data.hasOwnProperty('accom_tax')) {
        obj['accom_tax'] = ApiClient.convertToType(data['accom_tax'], 'Number')
      }
      if (data.hasOwnProperty('accom_pay_comsn')) {
        obj['accom_pay_comsn'] = ApiClient.convertToType(data['accom_pay_comsn'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {module:model/DailyClosingSummaryRsvnSales} rsvn_sales
   */
  rsvn_sales = undefined
  /**
   * @member {module:model/DailyClosingSummaryOtaComsn} ota_comsn
   */
  ota_comsn = undefined
  /**
   * @member {Number} rsvn_pay_comsn
   */
  rsvn_pay_comsn = undefined
  /**
   * @member {Number} sales
   */
  sales = undefined
  /**
   * @member {Number} variable_costs
   */
  variable_costs = undefined
  /**
   * @member {Number} fixed_costs
   */
  fixed_costs = undefined
  /**
   * @member {Number} variable_expenses
   */
  variable_expenses = undefined
  /**
   * @member {Number} fixed_expenses
   */
  fixed_expenses = undefined
  /**
   * @member {Number} accom_tax
   */
  accom_tax = undefined
  /**
   * @member {Number} accom_pay_comsn
   */
  accom_pay_comsn = undefined
}
