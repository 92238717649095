import addDetail from './addDetail'
import fetchByDate from './fetchByDate'
import fetchDailySummaryById from './fetchDailySummaryById'
import fetchDailySummaryByHotelId from './fetchDailySummaryByHotelId'
import fetchOne from './fetchOne'
import fetchSummary from './fetchSummary'
import hideDetailView from './hideDetailView'
import onCancelEditView from './onCancelEditView'
import onEdit from './onEdit'
import onEditDetail from './onEditDetail'
import onDeleteDetail from './onDeleteDetail'
import onMonthChanged from './onMonthChanged'
import onRequestCreate from './onRequestCreate'
import onRequestUpdate from './onRequestUpdate'
import showDetailView from './showDetailView'
import toEditView from './toEditView'

const MonthlyClosingAction = {
  addDetail,
  fetchByDate,
  fetchDailySummaryById,
  fetchDailySummaryByHotelId,
  fetchOne,
  fetchSummary,
  hideDetailView,
  onCancelEditView,
  onEdit,
  onEditDetail,
  onDeleteDetail,
  onMonthChanged,
  onRequestCreate,
  onRequestUpdate,
  showDetailView,
  toEditView
}

export default MonthlyClosingAction
