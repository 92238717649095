import AppDispatcher from '../../core/AppDispatcher'
import SegmentationEvent from '../../event/SegmentationEvent'

const addRow = () => {
  AppDispatcher.dispatchWithType(SegmentationEvent.addRow)

  return true
}

export default addRow
