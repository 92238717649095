import AppDispatcher from '../../core/AppDispatcher'
import MonthlyClosingEvent from '../../event/MonthlyClosingEvent'
import monthlyClosingRepository from '../../infra/MonthlyClosingRepository'
import { appActions } from '../AppActions'

export default async (hotel_id, date) => {
  const response = await monthlyClosingRepository.fetchDailySummaryByHotelId(hotel_id, date).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんでした')
    return false
  }

  AppDispatcher.dispatchWithType(MonthlyClosingEvent.showDetail, response)

  return true
}
