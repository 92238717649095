const trigger_events = [
  { value: 'after_booking', label: 'After booking' },
  { value: 'check_in', label: 'Check in' },
  { value: 'check_out', label: 'Check out' },
  { value: 'cancelled', label: 'Cancelled' }
]

const trigger_times = [
  { value: '-960', label: '40日前' },
  { value: '-936', label: '39日前' },
  { value: '-912', label: '38日前' },
  { value: '-888', label: '37日前' },
  { value: '-864', label: '36日前' },
  { value: '-840', label: '35日前' },
  { value: '-816', label: '34日前' },
  { value: '-792', label: '33日前' },
  { value: '-768', label: '32日前' },
  { value: '-744', label: '31日前' },
  { value: '-720', label: '30日前' },
  { value: '-696', label: '29日前' },
  { value: '-672', label: '28日前' },
  { value: '-648', label: '27日前' },
  { value: '-624', label: '26日前' },
  { value: '-600', label: '25日前' },
  { value: '-576', label: '24日前' },
  { value: '-552', label: '23日前' },
  { value: '-528', label: '22日前' },
  { value: '-504', label: '21日前' },
  { value: '-480', label: '20日前' },
  { value: '-456', label: '19日前' },
  { value: '-432', label: '18日前' },
  { value: '-408', label: '17日前' },
  { value: '-384', label: '16日前' },
  { value: '-360', label: '15日前' },
  { value: '-336', label: '14日前' },
  { value: '-312', label: '13日前' },
  { value: '-288', label: '12日前' },
  { value: '-264', label: '11日前' },
  { value: '-240', label: '10日前' },
  { value: '-216', label: '9日前' },
  { value: '-192', label: '8日前' },
  { value: '-168', label: '7日前' },
  { value: '-144', label: '6日前' },
  { value: '-120', label: '5日前' },
  { value: '-96', label: '4日前' },
  { value: '-72', label: '3日前' },
  { value: '-48', label: '2日前' },
  { value: '-24', label: '1日前' },
  { value: '-23', label: '23時間前' },
  { value: '-22', label: '22時間前' },
  { value: '-21', label: '21時間前' },
  { value: '-20', label: '20時間前' },
  { value: '-19', label: '19時間前' },
  { value: '-18', label: '18時間前' },
  { value: '-17', label: '17時間前' },
  { value: '-16', label: '16時間前' },
  { value: '-15', label: '15時間前' },
  { value: '-14', label: '14時間前' },
  { value: '-13', label: '13時間前' },
  { value: '-12', label: '12時間前' },
  { value: '-11', label: '11時間前' },
  { value: '-10', label: '10時間前' },
  { value: '-9', label: '9時間前' },
  { value: '-8', label: '8時間前' },
  { value: '-7', label: '7時間前' },
  { value: '-6', label: '6時間前' },
  { value: '-5', label: '5時間前' },
  { value: '-4', label: '4時間前' },
  { value: '-3', label: '3時間前' },
  { value: '-2', label: '2時間前' },
  { value: '-1', label: '1時間前' },
  { value: '0', label: '即時' },
  { value: '1', label: '1時間後' },
  { value: '2', label: '2時間後' },
  { value: '3', label: '3時間後' },
  { value: '4', label: '4時間後' },
  { value: '5', label: '5時間後' },
  { value: '6', label: '6時間後' },
  { value: '7', label: '7時間後' },
  { value: '8', label: '8時間後' },
  { value: '9', label: '9時間後' },
  { value: '10', label: '10時間後' },
  { value: '11', label: '11時間後' },
  { value: '12', label: '12時間後' },
  { value: '13', label: '13時間後' },
  { value: '14', label: '14時間後' },
  { value: '15', label: '15時間後' },
  { value: '16', label: '16時間後' },
  { value: '17', label: '17時間後' },
  { value: '18', label: '18時間後' },
  { value: '19', label: '19時間後' },
  { value: '20', label: '20時間後' },
  { value: '21', label: '21時間後' },
  { value: '22', label: '22時間後' },
  { value: '23', label: '23時間後' },
  { value: '24', label: '1日後' },
  { value: '48', label: '2日後' },
  { value: '72', label: '3日後' },
  { value: '96', label: '4日後' },
  { value: '120', label: '5日後' },
  { value: '144', label: '6日後' },
  { value: '168', label: '7日後' },
  { value: '192', label: '8日後' },
  { value: '216', label: '9日後' },
  { value: '240', label: '10日後' },
  { value: '264', label: '11日後' },
  { value: '288', label: '12日後' },
  { value: '312', label: '13日後' },
  { value: '336', label: '14日後' },
  { value: '360', label: '15日後' },
  { value: '384', label: '16日後' },
  { value: '408', label: '17日後' },
  { value: '432', label: '18日後' },
  { value: '456', label: '19日後' },
  { value: '480', label: '20日後' },
  { value: '504', label: '21日後' },
  { value: '528', label: '22日後' },
  { value: '552', label: '23日後' },
  { value: '576', label: '24日後' },
  { value: '600', label: '25日後' },
  { value: '624', label: '26日後' },
  { value: '648', label: '27日後' },
  { value: '672', label: '28日後' },
  { value: '696', label: '29日後' },
  { value: '720', label: '30日後' },
  { value: '744', label: '31日後' },
  { value: '768', label: '32日後' },
  { value: '792', label: '33日後' },
  { value: '816', label: '34日後' },
  { value: '840', label: '35日後' },
  { value: '864', label: '36日後' },
  { value: '888', label: '37日後' },
  { value: '912', label: '38日後' },
  { value: '936', label: '39日後' },
  { value: '960', label: '40日後' }
]

const retry_periods = [
  { value: '24', label: '1日後' },
  { value: '48', label: '2日後' },
  { value: '72', label: '3日後' },
  { value: '96', label: '4日後' },
  { value: '120', label: '5日後' },
  { value: '144', label: '6日後' },
  { value: '168', label: '7日後' },
  { value: '192', label: '8日後' },
  { value: '216', label: '9日後' },
  { value: '240', label: '10日後' },
  { value: '264', label: '11日後' },
  { value: '288', label: '12日後' },
  { value: '312', label: '13日後' },
  { value: '336', label: '14日後' }
]

export { trigger_events, trigger_times, retry_periods }
