import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import repository from '../../infra/AccommodationInvoiceRepository'
import AccommodationInvoiceEvent from '../../event/AccommodationInvoiceEvent'

const fetchOne = async invoiceId => {
  const response = await repository.fetchOne(invoiceId).catch(e => e)
  if (response instanceof Error) {
    appActions.showAlert(response, '取得できませんした')
    return false
  }

  AppDispatcher.dispatchWithType(AccommodationInvoiceEvent.fetchOne, {
    invoice: response.invoice,
    invoice_details: response.invoice_details,
    reservation: response.reservation
  })
  return true
}

export default fetchOne
