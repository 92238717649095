import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../../core/AppDispatcher'
import { List } from 'immutable'
import AccountTitleEvent from '../../event/AccountTitleEvent'
import AccountTitleState from '../state/AccountTitleState'

class AccountTitlesStore extends ReduceStore {
  constructor() {
    super(AppDispatcher)
  }

  getInitialState() {
    return List.of([])
  }

  reduce(state, action) {
    const payload = action.payload
    switch (action.type) {
      case AccountTitleEvent.fetchAll:
        const titles = payload.titles.map(title => new AccountTitleState(title))
        return List(titles)
      case AccountTitleEvent.onClear:
        return state.clear()
      default:
        return state
    }
  }
}

export default new AccountTitlesStore()
