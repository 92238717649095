/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import Body31 from '../model/Body31'
import InlineResponse20028 from '../model/InlineResponse20028'
import TaskBody from '../model/TaskBody'
import TaskBody1 from '../model/TaskBody1'
import TaskSummary from '../model/TaskSummary'

/**
 * Task service.
 * @module api/TaskApi
 * @version 1.0.0
 */
export default class TaskApi {
  /**
   * Constructs a new TaskApi.
   * @alias module:api/TaskApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance
  }

  /**
   * タスクを削除する
   * @param {Number} taskId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteTaskWithHttpInfo(taskId) {
    let postBody = null

    // verify the required parameter 'taskId' is set
    if (taskId === undefined || taskId === null) {
      throw new Error("Missing the required parameter 'taskId' when calling deleteTask")
    }

    let pathParams = {
      task_id: taskId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/tasks/{task_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * タスクを削除する
   * @param {Number} taskId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteTask(taskId) {
    return this.deleteTaskWithHttpInfo(taskId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * タスクを取得する
   * @param {Number} taskId Target task id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TaskSummary} and HTTP response
   */
  getTaskWithHttpInfo(taskId) {
    let postBody = null

    // verify the required parameter 'taskId' is set
    if (taskId === undefined || taskId === null) {
      throw new Error("Missing the required parameter 'taskId' when calling getTask")
    }

    let pathParams = {
      task_id: taskId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = TaskSummary

    return this.apiClient.callApi('/tasks/{task_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * タスクを取得する
   * @param {Number} taskId Target task id
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TaskSummary}
   */
  getTask(taskId) {
    return this.getTaskWithHttpInfo(taskId).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * タスクを新規登録する
   * @param {module:model/TaskBody} taskBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  newTaskWithHttpInfo(taskBody) {
    let postBody = taskBody

    // verify the required parameter 'taskBody' is set
    if (taskBody === undefined || taskBody === null) {
      throw new Error("Missing the required parameter 'taskBody' when calling newTask")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/tasks', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * タスクを新規登録する
   * @param {module:model/TaskBody} taskBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  newTask(taskBody) {
    return this.newTaskWithHttpInfo(taskBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * タスクを検索する
   * @param {module:model/Body31} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse20028} and HTTP response
   */
  searchTaskWithHttpInfo(body) {
    let postBody = body

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchTask")
    }

    let pathParams = {}
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = InlineResponse20028

    return this.apiClient.callApi('/tasks/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * タスクを検索する
   * @param {module:model/Body31} body
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse20028}
   */
  searchTask(body) {
    return this.searchTaskWithHttpInfo(body).then(function(response_and_data) {
      return response_and_data.data
    })
  }

  /**
   * タスクを更新する
   * @param {Number} taskId Target task id
   * @param {module:model/TaskBody1} taskBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateTaskWithHttpInfo(taskId, taskBody) {
    let postBody = taskBody

    // verify the required parameter 'taskId' is set
    if (taskId === undefined || taskId === null) {
      throw new Error("Missing the required parameter 'taskId' when calling updateTask")
    }

    // verify the required parameter 'taskBody' is set
    if (taskBody === undefined || taskBody === null) {
      throw new Error("Missing the required parameter 'taskBody' when calling updateTask")
    }

    let pathParams = {
      task_id: taskId
    }
    let queryParams = {}
    let headerParams = {}
    let formParams = {}

    let authNames = ['Bearer']
    let contentTypes = ['application/json']
    let accepts = ['application/json']
    let returnType = null

    return this.apiClient.callApi('/tasks/{task_id}', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType)
  }

  /**
   * タスクを更新する
   * @param {Number} taskId Target task id
   * @param {module:model/TaskBody1} taskBody
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updateTask(taskId, taskBody) {
    return this.updateTaskWithHttpInfo(taskId, taskBody).then(function(response_and_data) {
      return response_and_data.data
    })
  }
}
