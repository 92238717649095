/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'

/**
 * The SensorValue model module.
 * @module model/SensorValue
 * @version 1.0.0
 */
export default class SensorValue {
  /**
   * Constructs a new <code>SensorValue</code>.
   * @alias module:model/SensorValue
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>SensorValue</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SensorValue} obj Optional instance to populate.
   * @return {module:model/SensorValue} The populated <code>SensorValue</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SensorValue()

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Number')
      }
      if (data.hasOwnProperty('temperature')) {
        obj['temperature'] = ApiClient.convertToType(data['temperature'], 'Number')
      }
      if (data.hasOwnProperty('humidity')) {
        obj['humidity'] = ApiClient.convertToType(data['humidity'], 'Number')
      }
      if (data.hasOwnProperty('sound_level')) {
        obj['sound_level'] = ApiClient.convertToType(data['sound_level'], 'Number')
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number')
      }
    }
    return obj
  }

  /**
   * @member {Number} id
   */
  id = undefined
  /**
   * @member {Number} temperature
   */
  temperature = undefined
  /**
   * @member {Number} humidity
   */
  humidity = undefined
  /**
   * @member {Number} sound_level
   */
  sound_level = undefined
  /**
   * @member {Number} created_at
   */
  created_at = undefined
}
