import AppDispatcher from '../../core/AppDispatcher'
import StripeKeyEvent from '../../event/StripeKeyEvent'

const onEdit = (name, value) => {
  AppDispatcher.dispatchWithType(StripeKeyEvent.onEdit, {
    name: name,
    value: value
  })

  return true
}

export default onEdit
