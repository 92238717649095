import AppDispatcher from '../../core/AppDispatcher'
import history from '../../util/history'
import loginRepository from '../../infra/LoginRepository'
import SessionEvent from '../../event/SessionEvent'
import onRequestLogout from './onRequestLogout'

const checkStatus = async () => {
  const response = await loginRepository.checkLoginStatus().catch(e => e)
  if (response instanceof Error) {
    if (response.status === 401) {
      onRequestLogout()
      return false
    }

    history.push('/')
    return false
  }

  AppDispatcher.dispatchWithType(SessionEvent.loginSuccess, response)
  return true
}

export default checkStatus
