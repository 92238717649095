import AppDispatcher from '../../core/AppDispatcher'
import { appActions } from '../AppActions'
import UserEvent from '../../event/UserEvent'
import RoomRepository from '../../infra/RoomRepository'

/**
 *
 * Deprecated.
 * SessionAction.fetchUserRoomsを利用してください
 *
 */
const fetchUserRooms = async () => {
  const response = await RoomRepository.fetchByUser().catch(() => false)
  if (response === false) {
    appActions.showAlert('部屋情報の取得に失敗しました')
    return false
  }

  AppDispatcher.dispatchWithType(UserEvent.fetchUserRooms, {
    rooms: response
  })

  return true
}

export default fetchUserRooms
