/**
 * Tomaruba Guest API
 * Tomaruba Guest API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: development@tomaruba.me
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient'
import ReservationSegment from './ReservationSegment'
import SegmentCategoryOption from './SegmentCategoryOption'

/**
 * The InlineResponse20010 model module.
 * @module model/InlineResponse20010
 * @version 1.0.0
 */
export default class InlineResponse20010 {
  /**
   * Constructs a new <code>InlineResponse20010</code>.
   * @alias module:model/InlineResponse20010
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>InlineResponse20010</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20010} obj Optional instance to populate.
   * @return {module:model/InlineResponse20010} The populated <code>InlineResponse20010</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20010()

      if (data.hasOwnProperty('segment_category_options')) {
        obj['segment_category_options'] = SegmentCategoryOption.constructFromObject(data['segment_category_options'])
      }
      if (data.hasOwnProperty('reservation_segments')) {
        obj['reservation_segments'] = ApiClient.convertToType(data['reservation_segments'], [ReservationSegment])
      }
    }
    return obj
  }

  /**
   * @member {module:model/SegmentCategoryOption} segment_category_options
   */
  segment_category_options = undefined
  /**
   * @member {Array.<module:model/ReservationSegment>} reservation_segments
   */
  reservation_segments = undefined
}
