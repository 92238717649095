import ApiClient from '../ApiClient'
import LoginApi from '../api/LoginApi'
import HotelApi from '../api/HotelApi'

export class HotelRepository {
  constructor() {
    this._loginApi = new LoginApi(ApiClient.instance)
    this._hotelApi = new HotelApi(ApiClient.instance)
  }

  fetchByUser() {
    return this._loginApi.getUserHotels()
  }

  fetchAll() {
    return this._hotelApi.getHotels()
  }

  fetchRoomsById(hotelId) {
    return this._hotelApi.getRoomsByHotelId(hotelId)
  }

  fetchOne(hotelId) {
    return this._hotelApi.getHotel(hotelId)
  }

  requestUpdate(hotel) {
    return this._hotelApi.updateHotel(hotel.id, { hotel: hotel })
  }

  requestDelete(hotelId) {
    return this._hotelApi.deleteHotel(hotelId)
  }

  // FIXME: PlanApiに移動する
  findPlan(hotelId, planCode) {
    return this._hotelApi.findPlan(hotelId, planCode)
  }
}

// singleton
export default new HotelRepository()
