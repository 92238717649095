import React from 'react'
import Money from '../../unit/Money'
import { compose, filter } from 'ramda'
import { DataTable, DataTableColumn as TD, DataTableHeader as TH, DataTableRow as TR } from 'ui-library/dist/external/components'
import { ListViewLayout } from 'ui-library/dist/external/layouts'
import TaxCalculator from '../../../domain/model/tax/AccommodationTax'

export default function SummaryAccommodationTax(props) {
  const taxes = props.accommodation_tax
  return (
    <ListViewLayout key={'accommodation_tax_summary'}>
      <DataTable
        headers={[
          <TH key={1} width="30%">
            区分
          </TH>,
          <TH key={2} width="20%">
            宿泊数
          </TH>,
          <TH key={3} width="30%" isNumber>
            税額
          </TH>,
          <TH key={4} width="20%" isNumber>
            手数料
          </TH>
        ]}
        rows={SummaryAccommodationTaxRows(taxes)}
      />
    </ListViewLayout>
  )
}

function SummaryAccommodationTaxRows(taxes) {
  if (!taxes.size) return []

  const uncollect = TotalAccommodationTaxByCondition(filter, calcTotalAccommodationTaxes)
  const rows = [200, 500, 1000].map(key => {
    const label = key === 1000 ? '5万円以上' : key === 500 ? '2万円以上5万円未満' : '2万円未満'
    // 本来何円の宿泊税として徴収されなければいけなかったか
    const condition = tax => {
      const calcAccommodationTaxPerPerson = TaxCalculator.calculateAccomodationTax(tax.accommodation_fee, tax.number_of_nights, tax.number_of_guests)
      return tax.tax_per_day === 0 && calcAccommodationTaxPerPerson === key ? tax : null
    }
    return (
      <TR>
        <TD>
          {label}
          <br />
          <small>
            (<Money>{key}</Money>/人泊)
          </small>
        </TD>
        <TD>{calcTotalStaingDays(taxes, key)}人泊</TD>
        <TD isNumber>
          <Money>{calcTotalAccommodationTaxesWrapper(taxes, key)}</Money>
          <br />(
          <small>
            徴収漏れ:&nbsp;<Money>{uncollect(condition, taxes)}</Money>含む
          </small>
          )
        </TD>
        <TD isNumber>
          <Money>{calcTaxCommission(taxes, key)}</Money>
        </TD>
      </TR>
    )
  })
  rows.push(
    <TR>
      <TD>合計</TD>
      <TD>{calcTotalStaingDays(taxes)}人泊</TD>
      <TD isNumber>
        <Money>{calcTotalAccommodationTaxesWrapper(taxes)}</Money>
        <br />(
        <small>
          徴収漏れ:&nbsp;<Money>{uncollect(tax => tax.tax_per_day === 0, taxes)}</Money>含む
        </small>
        )
      </TD>
      <TD isNumber>
        <Money>{calcTaxCommission(taxes)}</Money>
      </TD>
    </TR>
  )
  return rows
}

function TotalAccommodationTaxByCondition(f1, f2) {
  return compose((condition, values) => f2(f1(condition, values)))
}

function calcTotalAccommodationTaxesWrapper(accommodation_taxes, tax_per_day) {
  const condition = tax => {
    const calcAccommodationTaxPerPerson = TaxCalculator.calculateAccomodationTax(tax.accommodation_fee, tax.number_of_nights, tax.number_of_guests)
    return calcAccommodationTaxPerPerson === tax_per_day ? tax : null
  }
  const calculater = TotalAccommodationTaxByCondition(filter, calcTotalAccommodationTaxes)

  switch (tax_per_day) {
    case 200:
    case 500:
    case 1000:
      return calculater(condition, accommodation_taxes)
    default:
      return calcTotalAccommodationTaxes(accommodation_taxes)
  }
}

function calcTotalStaingDays(accommodation_taxes, tax_per_day) {
  const total = accommodation_taxes.reduce((sum, tax) => {
    // 対象の宿泊税(1人1泊あたり)の金額の合算のみを返す
    const calcAccommodationTaxPerPerson = TaxCalculator.calculateAccomodationTax(tax.accommodation_fee, tax.number_of_nights, tax.number_of_guests)
    if (tax_per_day && calcAccommodationTaxPerPerson !== tax_per_day) return sum
    return sum + tax.number_of_nights * tax.number_of_guests
  }, 0)

  return total
}

function calcTotalAccommodationTaxes(accommodation_taxes) {
  const total = accommodation_taxes.reduce((sum, tax) => {
    const calcAccommodationTaxPerPerson = TaxCalculator.calculateAccomodationTax(tax.accommodation_fee, tax.number_of_nights, tax.number_of_guests)
    const calcAccommodationTax = Math.ceil(calcAccommodationTaxPerPerson * tax.number_of_nights * tax.number_of_guests)
    return sum + calcAccommodationTax
  }, 0)

  return total
}

function calcTaxCommission(accommodation_taxes, tax_per_day) {
  const total = accommodation_taxes.reduce((sum, tax) => {
    const calcAccommodationTaxPerPerson = TaxCalculator.calculateAccomodationTax(tax.accommodation_fee, tax.number_of_nights, tax.number_of_guests)
    if (tax_per_day && calcAccommodationTaxPerPerson !== tax_per_day) return sum
    return sum + Math.ceil(tax.payment_commission)
  }, 0)

  return total
}
